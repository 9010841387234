import { SubscriptionPlan } from 'briefpoint-client';
import { Modal } from 'components/Modals';
import { InfoCircle } from 'react-bootstrap-icons';

export interface ActivateModel {
  userId: string;
  isActive: boolean;
  planType: SubscriptionPlan | undefined;
  isFutureDeactivated: boolean;
}

interface Props {
  model: ActivateModel | undefined;
  onClose: () => void;
  onSubmit: (userId: string, activate: boolean) => Promise<void>;
}

export default function ActivateModal({ model, onClose, onSubmit }: Props) {
  const onConfirm = async () => { await handleClick(); };

  function handleClose() {
    onClose();
  }

  async function handleClick() {
    await onSubmit(model!.userId, !model!.isActive);
    handleClose();
  }


  return (
    <Modal
      show={!!model}
      onClose={handleClose}
      onConfirm={onConfirm}
      title={`${model?.isActive ? 'Deactivate' : 'Activate'} User`}
      confirmText={model?.isActive ? 'Deactivate' : 'Activate'}
      showCancelButton={true}
      showConfirmButton={true}
    >
      <div><InfoCircle /> {model?.isActive ?
        `Are you sure you want to deactivate this user? They will no longer be able to use Briefpoint${model.planType !== SubscriptionPlan.AlaCarte ? ' at the end of this billing cycle' : ''} until reactivated.` :
        `Are you sure you want to ${model?.isFutureDeactivated ? 're' : ''}activate this user?${(model?.planType === SubscriptionPlan.Yearly || model?.planType === SubscriptionPlan.YearlyBundle) && model?.isFutureDeactivated ? ' They still have time remaining in the year and will not be charged until the next billing period.' : ''}${model?.planType === SubscriptionPlan.Monthly ? ' They will be billed for monthly usage starting this billing cycle.' : ''}`}</div>
    </Modal>
  );
}
