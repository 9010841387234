import { Button, Form } from 'react-bootstrap';
import { GripVertical, Plus, X } from 'react-bootstrap-icons';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { classes } from 'utils';
import { FormModal } from 'components/Modals/Modal';
import { MiscContent } from 'briefpoint-client';
import { useAuth } from 'hooks/useAuth';
import CustomEditor from 'components/Editor/CustomEditor';

interface Props {
  generalObjections: MiscContent;
  onUpdate: (update: MiscContent) => Promise<MiscContent>;
}

interface GeneralObjection {
  Id?: string;
  Order: number;
  Content: string;
}

export default function GeneralObjections({ generalObjections, onUpdate }: Props) {
  const [list, setList] = useState<GeneralObjection[]>([]);
  const [intro, setIntro] = useState<string>('');
  const [confirmRemove, setConfirmRemove] = useState<number | null>(null);
  const [newItems, setNewItems] = useState<Map<number, number>>(new Map());
  const { user, updatePreferences } = useAuth()!;
  const [showInputs, setShowInputs] = useState<boolean>(false);

  useEffect(() => {
    // setList();
    if (generalObjections?.content) {
      var objectionList: GeneralObjection[] = [];
      var json = JSON.parse(generalObjections.content);
      // For Changing stuff if i break stuff while editing
      // const itemBeingSaved = {Intro: json.Intro, Items: []};
      // onUpdate(MiscContentType.GeneralObjections, JSON.stringify(itemBeingSaved));
      for (const item of json.Items) {
        objectionList.push({ Id: item.Order, Order: item.Order, Content: item.Content });
      }
      setIntro(json.Intro);
      setList(objectionList);
    }
  }, [generalObjections]);

  useEffect(() => {
    if (user?.preferences) {
      setShowInputs(user?.preferences?.includeGeneralObjections || false);
    }
  }, [user]);

  async function handleCheckChange(checked: boolean) {
    setShowInputs(checked);
    if (user?.preferences) {
      user.preferences.includeGeneralObjections = checked;
      await updatePreferences(user.preferences);
    }
  }

  async function onAdd(newOrder: number, newContent: string) {
    const result = Array.from(list);
    var orderedItemList: GeneralObjection[] = [];
    const newItem = { Order: newOrder, Content: newContent }
    result.splice(newOrder, 0, newItem);
    var index = 0;
    for (const item of result) {
      item.Order = index + 1;
      index++;
      orderedItemList.push(item);
    }
    await onUpdate({ ...generalObjections, content: JSON.stringify({ Intro: intro, Items: orderedItemList }) });
    setList(orderedItemList);
  }

  async function onEdit(index: number, text: string) {
    list[index - 1].Content = text;
    onUpdate({ ...generalObjections, content: JSON.stringify({ Intro: intro, Items: list }) });
  }

  async function handleConfirmDelete() {
    if (confirmRemove !== null) {
      const objection = list[confirmRemove - 1];
      if (objection) {
        const result = Array.from(list);
        result.splice(confirmRemove - 1, 1);
        var orderedItemList: GeneralObjection[] = [];
        var index = 0;
        for (const item of result) {
          item.Order = index + 1;
          index++;
          orderedItemList.push(item);
        }
        setList(orderedItemList);
        onUpdate({ ...generalObjections, content: JSON.stringify({ Intro: intro, Items: orderedItemList }) });
        const count = newItems.get(objection.Order) || 0;
        if (count > 0) {
          setNewItems(new Map(newItems.set(objection.Order, 0)));
          setNewItems(new Map(newItems.set(objection.Order - 1, count)));
        }
        setConfirmRemove(null);
      }
    }
  }

  function getEmptyObjections(index: number) {
    const count = newItems.get(index);
    const emptyObjections = [];
    if (count) {
      for (let i = 0; i < count; i++) {
        emptyObjections.push({ Id: `${index}-${i}`, empty: true, parentNumber: index });
      }
    }
    return emptyObjections;
  }

  function removeNewItem(number: number, addNew = false) {
    const count = newItems.get(number) || 0;
    if (addNew) {
      if (count > 0) {
        setNewItems(new Map(newItems.set(number, 0)));
        setNewItems(new Map(newItems.set(number + 1, count - 1)));
      }
    }
    else {
      if (count > 0) {
        setNewItems(new Map(newItems.set(number, count - 1)));
      }
    }
  }

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    const removedOrder = removed.Order;
    var orderedItemList: any[] = [];
    var newIndex = 0;
    result.splice(endIndex, 0, removed);
    var index = 0;
    for (const item of result) {
      if (!item.empty) {
        item.Order = index + 1;
        index++;
        if (item.Order === removedOrder) {
          newIndex = item.Order;
        }
        orderedItemList.push(item);
      }
    }
    // result.forEach((item, index) => (item.number = index + 1));
    return { orderedList: orderedItemList, newIndex: newIndex };
  };

  const onDragEnd = (res: DropResult) => {
    const { destination, source } = res;
    if (!destination) return;
    if (destination.index === source.index) return;

    const sourceItem = combinedList[source.index];
    const destinationItem = combinedList[destination.index];
    var parentNumber = destinationItem.empty ? destinationItem.parentNumber : destinationItem.Order;

    if (sourceItem.empty) {
      if (sourceItem.parentNumber !== parentNumber || !destinationItem.empty) {
        if (destination.index < source.index && !destinationItem.empty) {
          parentNumber = parentNumber - 1;
        }
        removeNewItem(sourceItem.parentNumber);
        setNewItems(new Map(newItems.set(parentNumber, (newItems.get(parentNumber) || 0) + 1)));
      }
    } else {
      const newMap = newItems;
      var setNew = false;
      for (var i = 0; i < combinedList.length; i++) {
        if (combinedList[i].empty) {
          setNew = true;
          if (source.index > destination.index) {
            if (i >= destination.index && i < source.index) {
              newMap.set(combinedList[i].parentNumber, (newMap.get(combinedList[i].parentNumber) ?? 0) - 1);
              newMap.set(
                combinedList[i].parentNumber + 1,
                (newMap.get(combinedList[i].parentNumber + 1) ?? 0) + 1
              );
            }
          } else {
            if (i > source.index && i <= destination.index) {
              newMap.set(combinedList[i].parentNumber, (newMap.get(combinedList[i].parentNumber) ?? 0) - 1);
              newMap.set(
                combinedList[i].parentNumber - 1,
                (newMap.get(combinedList[i].parentNumber - 1) ?? 0) + 1
              );
            }
          }
        }
        if (sourceItem.order !== parentNumber) {
          const { orderedList } = reorder(combinedList, source.index, destination.index);
          setList(orderedList);
          if (setNew) {
            setNewItems(newMap);
          }
          onUpdate({ ...generalObjections, content: JSON.stringify({ Intro: intro, Items: orderedList }) });
        } else {
          if (setNew) {
            setNewItems(new Map(newMap));
          }
        }
      }
    }
  };

  var combinedList: any[] = getEmptyObjections(0);

  for (const objection of list) {
    combinedList.push(objection);
    combinedList = [...combinedList, ...getEmptyObjections(objection.Order)];
  }
  return (
    <div className="interrogatory-list-wrapper">
      <div style={{ marginLeft: '35px' }}>
        <Form.Check
          id={'use-general-objections'}
          type={'checkbox'}
          label={'Include General Objections in every output document'}
          onChange={e => handleCheckChange(e.currentTarget.checked)}
          checked={showInputs}
        />
      </div>
      {showInputs && <>
        {combinedList.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="interrogatories-list">
              {(provided) => (
                <div className="interrogatories-edit mt-2" {...provided.droppableProps} ref={provided.innerRef}>
                  {combinedList.map((objection, index) => (
                    <Draggable key={objection.Id} draggableId={`${objection.Id}`} index={index}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          className={classes('selection-item', {
                            isDragging: snapshot.isDragging,
                          })}
                        >
                          {objection.empty ? (
                            <ObjectionAdd
                              onAdd={async (text: string) => {
                                await onAdd(objection.parentNumber, text);
                                removeNewItem(objection.parentNumber, true);
                              }}
                              onDelete={() => removeNewItem(objection.parentNumber)}
                            />
                          ) : (
                            <ObjectionEdit
                              objection={objection}
                              onAdd={() => {
                                const count = newItems.get(objection.Order) || 0;
                                setNewItems(new Map(newItems.set(objection.Order, count + 1)));
                              }}
                              onEdit={onEdit}
                              onDelete={() => {
                                setConfirmRemove(objection.Order);
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <Button
            variant="outline-primary"
            onClick={() => {
              setNewItems(new Map(newItems.set(0, 1)));
            }}
          >
            <Plus></Plus>Add new
          </Button>
        )}
      </>}
      <FormModal
        title={'Are you sure?'}
        cancelText="Cancel"
        confirmText="Yes, remove"
        show={confirmRemove != null}
        onClose={() => setConfirmRemove(null)}
        onConfirm={handleConfirmDelete}
      >
        <div>Are you sure you want to remove this objection? This cannot be undone.</div>
      </FormModal>
    </div>
  );
}

export function ObjectionEdit({
  objection,
  onAdd,
  onEdit,
  onDelete,
}: {
  objection: GeneralObjection;
  onAdd: () => void;
  onEdit: (index: number, text: string) => void;
  onDelete: () => void;
}) {
  const [content, setContent] = useState(objection.Content);

  const [isHovering, setIsHovering] = useState(false);

  const editingRef = useRef<HTMLTextAreaElement>(null);

  function handleEditingBlur() {
    if (content !== objection.Content && content !== '') {
      onEdit(objection.Order, content);
      // onEdit(objection.id, objection.order, content);
    }
  }

  function handleOnKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Escape') {
      setContent(objection.Content);
      editingRef.current?.blur();
    } 
  }

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleContentUpdate = (val: string) => {
    setContent(val);
  }

  return (
    <div
      className="mb-3 interrogatory-item"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="main-item">
        <div className="d-flex justify-content-between">
          <div className="interrogatory-title">General Objection {objection.Order}</div>
          <div>
            <X className="remove-item" onClick={onDelete} />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <GripVertical className="drag-selection-item" />
          <CustomEditor 
            isFullWidth
            editingRef={editingRef}
            handleKeyDown={handleOnKeyDown}
            handleBlur={handleEditingBlur}
            handleChange={handleContentUpdate}
            shouldBlurOnEnter
            editorContent={content}
          />
        </div>
      </div>
      <div className={classNames('add-item-wrapper', { 'hide-item': !isHovering })}>
        <div className="border-line">
          <div className="button-wrapper">
            <Button variant="outline-primary" onClick={onAdd}>
              <Plus></Plus>Add new
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ObjectionAdd({
  onAdd,
  onDelete,
}: {
  onAdd: (text: string) => void;
  onDelete: () => void;
}) {
  const [content, setContent] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Reset state on rebuild.  Not doing this causes other list items to take over
    // the objects ID.  Doing this outside a useEffect causes an "unmounted" error
    setContent('');
    setIsSaving(false);
  }, [onAdd]);

  async function handleBlur() {
    if (content !== '') {
      setIsSaving(true);
      await onAdd(content);
    }
  }

  function handleOnContentUpdate(val: string) {
    setContent(val);
  }

  return (
    <div className="interrogatory-item pb-3">
      <div className="main-item">
        <div className="d-flex justify-content-between">
          <div className="interrogatory-title">Adding new item</div>
          <div>
            <X className="remove-item" onClick={onDelete} />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <GripVertical className="drag-selection-item" />
          <CustomEditor 
            isFullWidth
            isDisabled={isSaving}
            handleChange={handleOnContentUpdate}
            handleBlur={handleBlur}
            editorContent={content}
            shouldBlurOnEnter
          />
        </div>
      </div>
    </div>
  );
}
