
import { Modal } from 'components/Modals';
import { FileEarmark, CheckLg } from 'react-bootstrap-icons';
import "./LinkandCodeModal.scss"
import { useEffect, useState } from 'react';
import { CredentialResult } from 'briefpoint-client';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  isShow: boolean;
  onClose: (showNewCode: boolean) => void;
  credential?: CredentialResult;
  showNewCodeSuccess?: boolean;
}

export default function LinkAndCodeModal({ isShow, onClose, credential, showNewCodeSuccess }: Props) {
  const [copiedContent, setCopiedContent] = useState<string | null>(null);

  useEffect(() => {
    if (copiedContent !== null) {
      const timer = setTimeout(() => {
        setCopiedContent(null);
      }, 2000)
      return () => clearTimeout(timer);
    }
  }, [copiedContent]);

  function handleClose() {
    onClose(false)
  }

  const handleCopyClick = async (txt: string) => {
    try {
      await navigator.clipboard.writeText(txt);
      setCopiedContent(txt);
    } catch (err) {
    }
  };

  const fCode = credential?.credential?.firmCode ?? "";
  const login = credential?.credential?.login ?? "";
  const copyUrl = `${process.env.REACT_APP_BRIDGE_URL}?firmcode=${encodeURIComponent(credential?.credential?.firmCode ?? "")}&email=${encodeURIComponent(login)}`;
  const cCode = credential?.credential?.passcode ?? "";

  function ModalContent() {
    return (
      <div>
        <table style={{ width: "100%" }}>
          <tbody>
            {showNewCodeSuccess &&
            (<tr>
              <td />
              <td className="d-flex align-items-center gap-2"
                style={{ background: "#E9f7f1" }}><CheckLg className="checkCopied" />
                <span style={{ fontSize: "12px", color: "#397A44" }}>New Code Generated</span></td>
            </tr>)}
            <tr>
              <th>Link</th>
              <th style={{ minWidth: "9em" }}>Client Code</th>
            </tr>
            <tr>
              <td className='table-left'>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id={`tooltip-${copyUrl}`}>{`${process.env.REACT_APP_BRIDGE_URL}?`}
                    <wbr />{`firmcode=${encodeURIComponent(credential?.credential?.firmCode ?? "")}&`}
                    <wbr />{`email=${encodeURIComponent(login)}`} </Tooltip>}
                >
                  <span>{copyUrl}</span>
                </OverlayTrigger>
                <div className="icon-container">
                  {copiedContent === copyUrl ?
                    <CheckLg className="checkCopied" /> :
                    <FileEarmark onClick={() => { handleCopyClick(copyUrl) }} />
                  }
                </div>
              </td>
              <td className='table-right'>
                <div className="d-flex align-items-center gap-2">
                  {`${cCode.substring(0, 4)}-${cCode.substring(4)}`}
                  {copiedContent === cCode
                    ? <CheckLg className="checkCopied" />
                    : <FileEarmark onClick={() => { handleCopyClick(cCode); }} />}
                </div>
              </td>
            </tr>
            <tr>
              <td />
              <td style={{ paddingTop: "8px", paddingBottom: "16px" }}>
                <button
                  className="btn btn-outline-primary btn-sm wide"
                  style={{ fontSize: "12px", paddingTop: "4px", paddingBottom: "3px" }}
                  onClick={() => { onClose(true) }}>
                  Generate New Code
                </button>
              </td>
            </tr>
            <tr>
              <th>Client Contact Email Address</th>
              <th>Firm Code</th>
            </tr>
            <tr>
              <td className='table-left'>{login}
                {copiedContent === login ? <CheckLg className="checkCopied" /> : <FileEarmark style={{ marginLeft: "4px" }} onClick={() => { handleCopyClick(login); }} />}
              </td>
              <td className='table-right'>{`${fCode.substring(0, 4)}-${fCode.substring(4)}`}
                {copiedContent === credential?.credential?.firmCode ? <CheckLg className="checkCopied" /> : <FileEarmark style={{ marginLeft: "4px" }} onClick={() => { handleCopyClick(credential?.credential?.firmCode ?? ""); }} />}
              </td>
            </tr>
          </tbody>
        </table>
      </div >
    );
  }

  return (
    <Modal
      show={isShow}
      onClose={handleClose}
      onConfirm={handleClose}
      title={`View Link and Code`}
      showCancelButton={false}
      showConfirmButton={false}
    >
      {ModalContent()}
    </Modal>
  );
}
