import { Case, Client, DocumentStatus, DocumentSubType, ExternalPartners, Party, Role, Source, User, Venue } from 'briefpoint-client';
import DocumentsTable from 'components/CaseManagement/DocumentsTable';
import Help from 'components/Help';
import { useCaseApi, useClientApi, useTagApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import useDocuments from 'hooks/useDocuments';
import { useInterval } from 'hooks/useInterval';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Container, Dropdown, Form, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { Modal as BPModal } from 'components/Modals';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { getUploadingDocument } from 'screens/DocumentList';
import { DocumentClient, sanitizeFiles } from 'services/DocumentService';
import { hasActiveSubscription, hasCanceledSubscription, isInTrialOrHasActiveSubscription } from 'services/FirmService';
import './styles.scss';
import './case.scss';
import Loading from 'components/Loading';
import AddEditCaseModal, { CaliforniaId } from 'components/CaseManagement/AddEditCaseModal';
import useFirm from 'hooks/useFirm';
import { formatLocalDate, sortDocumentDates, toDateOrMin } from 'components/DocumentList/DocumentList';
import { filterTagsForParties } from 'utils/Requests/utils';
import { caseTypeDisplay, tagsList } from 'screens/Library/QuestionsTable';
import SelectPartyModal from 'screens/DocumentGeneration/SelectPartyModal';
import SignUpModal from 'components/Modals/SignUpModal';
import rg4js from 'raygun4js';
import GeneralInformationScreen from 'components/ConfirmInfo/GeneralInformation';
import RequiredCaseInfo from 'screens/DocumentGeneration/RequiredCaseInfo';
import ExternalFileBrowser from 'components/ExternalPartner/ExternalFileBrowser';
import { ReactComponent as BriefpointLogo } from '../../images/logo.svg';
import { ReactComponent as ClioLogo } from '../../images/Glyph_Clio Glyph Blue.svg';
import { ReactComponent as SmokeballLogo } from '../../images/Smokeball_logo_mark.svg';
import { ReactComponent as MyCaseLogo } from '../../images/MyCase.svg';
import ExternalPartnerMenu from 'components/ExternalPartner/ExternalPartnerMenu';
import ExternalPartnerConnection from 'components/ExternalPartner/ExternalPartnerConnection';

export function leadAttorneyName(_case: Case, client: Client, users: User[]) {
  if (_case && client) {
    let primary = _case.attorneys?.find(x => x.isPrimary) ?? client.attorneys?.find(x => x.isPrimary);

    if (!primary) {
      primary = _case.attorneys?.at(0) ?? client.attorneys?.at(0);
    }

    if (primary) {
      const attorney = users?.find(x => x.id === primary?.id);

      if (attorney) {
        return `${attorney.lastName}, ${attorney.firstName}`;
      }
    }
  }

  return '-';
}

export const partySpecificTags = ['Business',
  'Care Provider',
  'Debtor',
  'Driver',
  'Employer',
  'Government',
  'Insurance Company',
  'Landlord',
  'Manufacturer',
  'Owner',
  'Professional',
  'Tenant',
  'Officer',
  'Expert',
  'Witness',
  'Third Party',
  'Bank',
  'Broker',
  'Real Estate Agent'];

type CaseParams = {
  clientId: string;
  id: string;
};

export default function CasePage() {
  const { user, firm } = useAuth()!;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const location = useLocation();
  const { clientId, id } = useParams<CaseParams>();
  const clientApi = useClientApi();
  const caseApi = useCaseApi();
  const [users, , , ,] = useFirm(firm?.id, false);
  const [uploadingDocuments, setUploadingDocuments] = useState<DocumentClient[]>([]);
  const [isSignUpModalShow, setIsSignUpModalShow] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [documents, loadDocuments, uploadFiles, archiveDocument, , updateDocument, downloadForm, , downloadRequest] = useDocuments(true, id);
  const [, setError] = useState('');
  const [client, setClient] = useState<Client>((location.state as any)?.client);
  const [_case, setCase] = useState<Case>((location.state as any)?.case);
  const [showEditCase, setShowEditCase] = useState(false);
  const { jurisdictions } = useAuth()!;
  const tagApi = useTagApi();
  const history = useHistory();
  const [tags, setTags] = useState<Map<number, string> | undefined>();
  const [typeToGenerate, setTypeToGenerate] = useState<DocumentSubType>();
  const [showCloseCase, setShowCloseCase] = useState(false);
  const [showUploadDocs, setShowUploadDocs] = useState(false);
  const [deleteDocuments, setDeleteDocuments] = useState(false);
  const pendingDraftType = useRef<DocumentSubType>();
  const [isExternalUploading, setIsExternalUploading] = useState<boolean>(false);

  const jurisdiction = useMemo(() => {
    if (!jurisdictions || !firm) {
      return undefined;
    }

    const firmJx = firm.offices?.find(x => x.jurisdictions?.length)?.jurisdictions![0]?.jurisdictionId ?? CaliforniaId;
    const jx = jurisdictions?.find(j => j.id === _case?.jurisdiction) ?? jurisdictions?.find(j => j.id === firmJx);
    return jx;
  }, [jurisdictions, firm, _case?.jurisdiction]);

  const venue = (_case?.venue && jurisdiction?.venues?.find(x => x.id === _case.venue)) as Venue | undefined;
  const nonArchivedDocs = documents?.filter(x => !x.isArchived);
  const partyTags = useRef(new Map<number, string>());
  const hasProvidedInfo =
    user?.firstName &&
    user?.lastName &&
    user?.barNumber &&
    (!user?.role?.includes(Role.FirmAdmin) || (firm?.name &&
      firm?.address?.street &&
      firm?.address.postalCode));

  const missingRequiredInfo = useMemo(() => { return !_case?.caseType || !_case?.clientPosition; }, [_case]);

  const processingDocuments = nonArchivedDocs?.filter(
    (d: DocumentClient) => (d.status || 0) < DocumentStatus.Processed);

  const loadClient = useCallback(async (user: User | undefined, client: Client | undefined) => {
    if (user && user.firmId && !client) {
      const client = await clientApi.clientGet({ firmId: user.firmId!, id: clientId });
      setClient(client);
    }
  }, [clientApi, clientId]);

  useEffect(() => {
    loadClient(user, client);
  }, [user, loadClient, client]);

  useEffect(() => {
    let tagsLoaded = false
    async function loadTags() {
      const tagsList = new Map<number, string>();

      const loadedTags = await tagApi.tagFindTags({});

      loadedTags.forEach(t => {
        tagsList.set(t.id, t.name);
        if (t.ownerId || partySpecificTags.find(x => x === t.name)) {
          partyTags.current.set(t.id, t.name);
        }
      });
      setTags(tagsList);
    };
    if (!tagsLoaded) {
      loadTags();
      return () => {
        tagsLoaded = true;
      }
    }
  }, [tagApi]);

  const loadCase = useCallback(async (user: User | undefined, _case: Case | undefined) => {
    if (user && user.firmId && !_case) {
      const c = await caseApi.caseGet({ firmId: user.firmId!, id });
      setCase(c);
    }
  }, [caseApi, id]);

  useEffect(() => {
    loadCase(user, _case);
  }, [user, loadCase, _case]);

  useInterval(loadDocuments, processingDocuments && processingDocuments.length > 0 ? 3000 : null);

  function handleChooseFile(e: React.MouseEvent) {
    e.preventDefault();
    fileInputRef?.current?.click();
  }

  async function handleUpload(fileList: FileList) {
    setError('');
    var files = Array.from(fileList);
    if (files.length) {
      const sanitizedFiles = sanitizeFiles(files);
      if (sanitizedFiles.length) {
        var newUploadingDocs = sanitizedFiles.map((file) => getUploadingDocument(file.name));
        setUploadingDocuments([...uploadingDocuments, ...newUploadingDocs]);
        await uploadFiles(sanitizedFiles, id);
        setUploadingDocuments([...uploadingDocuments.filter((doc) => !newUploadingDocs.includes(doc))]);
        //loadDocuments();
      } else {
        setError('Only .pdf files are currently supported.');
      }
    }
  }

  // async function handleDropFiles(files: FileList) {
  //   handleUpload(files);
  //   setIsOver(false);
  // }

  async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.target;
    if (files) {
      handleUpload(files);
    }
    e.target.value = '';
  }

  async function saveCase(_case: Case) {
    await caseApi.casePut({ firmId: user?.firmId!, id: _case.id!, _case });

    setCase(_case);
    setShowEditCase(false)
  }

  function nextDueDate(docs: DocumentClient[] | null) {
    let next;
    if (!docs) {
      return '-';
    }
    if (docs.length > 1) {
      const ordered = docs?.sort((a, b) => sortDocumentDates(a, b, "realDueDate", true));
      next = ordered?.find(x => toDateOrMin(x.info?.dueDateOverridden || x.info?.dueDate) > new Date());
    } else {
      next = docs[0];
    }
    return next ? formatLocalDate(next.info?.dueDateOverridden || next.info?.dueDate || undefined) : '-';
  }

  function handleSelectGenerate(value: DocumentSubType): void {
    pendingDraftType.current = undefined;
    // show info page if user has not provided info yet, else generate word document
    if (hasProvidedInfo) {
      if ((!user!.isInactive && isInTrialOrHasActiveSubscription(firm!))) {
        setTypeToGenerate(value);

      } else {
        showSignupPage(value);
      }
    } else {
      pendingDraftType.current = value;
      setShowInfoModal(true);
    }
  }

  function showSignupPage(value: DocumentSubType) {
    rg4js('trackEvent', {
      type: 'pageView',
      path: `/signup-modal`,
    });
    pendingDraftType.current = value;
    setIsSignUpModalShow(true);
  }

  async function handleRequiredCase(_case: Case) {
    await caseApi.casePut({ firmId: user?.firmId!, id: _case.id!, _case });

    setCase(_case);
  }

  async function handleChoseParty(party: Party): Promise<void> {
    setTypeToGenerate(undefined);
    const caseToPush = _case;
    if (!caseToPush.otherParties) {
      caseToPush.otherParties = [];
    }

    const selected = caseToPush.otherParties.findIndex(p => p.id === party.id);

    if (selected === -1) {
      caseToPush.otherParties.push(party);
    } else {
      caseToPush.otherParties[selected] = party;
    }
    const previous = nonArchivedDocs?.filter(x => x.info?.otherPartyId === party.id && x.info?.documentSubType === typeToGenerate);

    previous?.sort((a, b) => (a.info?.setNumber ?? 1) - (b.info?.setNumber ?? 1));
    const highest = previous?.pop();
    const start = (highest?.info?.startingNumber ?? 1) + (highest?.interrogatories?.length ?? highest?.info?.interrogatoryCount ?? 0);

    history.push({
      pathname: `/clients/${_case.clientId}/cases/${_case.id}/generateDiscoveryRequest`,
      search: `?type=${typeToGenerate}&partyId=${party.id}&set=${(highest?.info?.setNumber ?? 0) + 1}&start=${start}`,
      state: { case: caseToPush, client: client },
    });
  }

  async function onCaseCloseClose() {
    setShowCloseCase(false);
    setDeleteDocuments(false);
  }

  async function handleCaseClose() {
    await caseApi.caseDelete({ firmId: user?.firmId!, id: _case!.id!, deleteDocumentData: deleteDocuments });

    history.push("/clients");
  }

  function externalUploadRefresh() {
    setShowUploadDocs(false);
    setTimeout(function () {
      loadDocuments();
    }, 3000);
  }

  function getCaseSourceLogo(partner?: ExternalPartners) {
    if (!partner || partner === ExternalPartners.None) {
      return <BriefpointLogo title="Briefpoint Case" />;
    }

    switch (partner!) {
      case ExternalPartners.Clio:
        return <ClioLogo title="Clio Case" />;
      case ExternalPartners.Smokeball:
        return <SmokeballLogo title="Smokeball Case" />;
      case ExternalPartners.MyCase:
        return <MyCaseLogo title="MyCase Case" />;
      default:
        return <BriefpointLogo title="Briefpoint Case" />;
    }
  }

  return (
    <>
      <Container className="page-container">
        <Loading isLoading={_case === undefined && client === undefined && documents === undefined && jurisdictions === undefined && users === undefined}>
          <Row key='heading'>
            <Col>
              <div className="mb-4 bread-crumbs"><Link to={'/clients'}>Clients</Link>{' > '}<Link to={{
                pathname: `/clients/${client?.id}`,
                state: {
                  client: client
                }
              }}>{client?.name}</Link>{` > ${_case?.shortTitle || _case?.title}`}</div>
            </Col>
          </Row>
          <Row key='class-details-wrapper' className='class-details-wrapper'>
            <Row className='case-details-title-wrapper'>
              <Col className='case-details-title'>{!!firm?.preferences?.enabledExternalProviders?.size && <span className='sourceLogo'>{getCaseSourceLogo(_case?.integration?.partner)}</span>}{_case?.shortTitle || _case?.title}<Button variant='link' className='close-case-btn' title='Close Case' onClick={() => setShowCloseCase(true)}>Close Case</Button></Col>
            </Row>
            <Row className='case-details'>
              <Col className='col-10'>
                <Row key='case-headers-main' className='case-headers'>
                  <Col className='col-2'>Case Type</Col>
                  <Col className='col-3'>Tags</Col>
                  <Col>Case Number</Col>
                  <Col>Venue</Col>
                  <Col>Lead Attorney</Col>
                  {!!jurisdiction?.supportsDueDates && <Col>Next Due Date</Col>}
                </Row>
                <Row key='case-details-main'>
                  <Col className='col-2'>{caseTypeDisplay(_case?.caseType, jurisdiction, true, '-')}</Col>
                  <Col className='col-3'>{tagsList(_case?.tagIds, tags, _case?.id, 0)}</Col>
                  <Col>{_case?.caseNumber ?? '-'}</Col>
                  <Col title={venue?.shortName ?? ''}>{venue?.shortName}</Col>
                  <Col>{leadAttorneyName(_case, client, users!)}</Col>
                  {!!jurisdiction?.supportsDueDates && <Col>{nextDueDate(documents)}</Col>}
                </Row>
              </Col>
              <Col className='actions-cell'>
                <Button variant="outline-secondary fs-6" onClick={() => setShowEditCase(true)}>
                  Edit Case Details
                </Button>
              </Col>
            </Row>
          </Row>

          <Row key='actions' className='table-actions-row'>
            {_case?.integration?.identifier && (
              <Col className='col-auto'>
                <ExternalPartnerConnection />
              </Col>
            )}
            <Col className='col-auto'>
              {user?.externalConnection?.isActive ? (
                <ExternalPartnerMenu _case={_case} externalAction={setShowUploadDocs} bpDefault={handleChooseFile} />
              ) : (
                <Button variant="primary" size="sm" onClick={handleChooseFile}>
                  Upload Document
                </Button>
              )}
            </Col>
            <Col className='col-auto'>
              <Dropdown>
                <Dropdown.Toggle variant='outline-primary' size='sm' id="draft-dropdown">
                  Draft Request (Beta)
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleSelectGenerate(DocumentSubType.RequestsForAdmission)}>Request for Admission</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSelectGenerate(DocumentSubType.RequestsForProduction)}>Request for Production</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSelectGenerate(DocumentSubType.SpecialInterrogatorries)}>{jurisdiction?.id === CaliforniaId ? 'Special Interrogatories' : 'Interrogatories'}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {!user!.isInactive && !hasCanceledSubscription(firm!) && <Col className='col-auto'>
              <Help id="document-help" text={`Briefpoint currently accepts the following state court documents: <ul><li class="tooltip-row">Requests for Admission</li><li class="tooltip-row">Requests for Production</li><li class="tooltip-row">Interrogatories</li>${jurisdiction?.id === CaliforniaId ? '<li class="tooltip-row">Form Interrogatories</li>' : ''}</ul>`} direction={'bottom'} />
              <input
                type="file"
                id="file-input"
                name="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="d-none"
                multiple
              /></Col>}
          </Row>
          {nonArchivedDocs?.find(x => x.info?.otherPartyId) ?
            <><Tab.Container id="case-modal-tabs" defaultActiveKey="all">
              <Nav className="case-modal-nav">
                <Nav.Item>
                  <Nav.Link eventKey="all">All</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="mine">To: {client?.name}</Nav.Link>
                </Nav.Item>
                {_case?.otherParties?.filter(p => !p.isDeleted).map(p => {
                  return (
                    <Nav.Item>
                      <Nav.Link eventKey={p.id}>To: {p.name}</Nav.Link>
                    </Nav.Item>
                  );
                })
                }
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="all">
                  <DocumentsTable documents={nonArchivedDocs} _case={_case} jurisdiction={jurisdiction} keyPrefix={'all'} uploadDocAction={handleChooseFile} updateDocument={updateDocument} archiveDocument={archiveDocument} downloadForm={downloadForm} downloadRequest={downloadRequest} />
                </Tab.Pane>
                <Tab.Pane eventKey="mine">
                  <DocumentsTable documents={nonArchivedDocs?.filter(d => d.source === Source.ExternalUploaded)} _case={_case} jurisdiction={jurisdiction} keyPrefix={'mine'} uploadDocAction={handleChooseFile} updateDocument={updateDocument} archiveDocument={archiveDocument} downloadForm={downloadForm} downloadRequest={downloadRequest} />
                </Tab.Pane>
                {_case?.otherParties?.filter(p => !p.isDeleted).map(p => {
                  return (
                    <Tab.Pane eventKey={p.id}>
                      <DocumentsTable keyPrefix={p.id ?? ''} _case={_case} jurisdiction={jurisdiction} documents={nonArchivedDocs?.filter(d => d.source === Source.InternalGenerated && d.info?.otherPartyId === p.id)} updateDocument={updateDocument} archiveDocument={archiveDocument} downloadForm={downloadForm} downloadRequest={downloadRequest} />
                    </Tab.Pane>
                  );
                })
                }
              </Tab.Content>
            </Tab.Container></>
            : <DocumentsTable keyPrefix='old' _case={_case} jurisdiction={jurisdiction} documents={nonArchivedDocs} uploadDocAction={handleChooseFile} updateDocument={updateDocument} archiveDocument={archiveDocument} downloadForm={downloadForm} downloadRequest={downloadRequest} />}
          {_case && client && firm && <AddEditCaseModal
            availableTags={tags}
            setAvailableTags={setTags}
            title='Edit Case'
            confirm='Save'
            isShow={showEditCase}
            onClose={() => setShowEditCase(false)}
            onConfirm={saveCase}
            users={users}
            jurisdictions={jurisdictions!}
            _case={_case}
            client={client}
            firm={firm} />}
          {_case && jurisdictions && missingRequiredInfo && <RequiredCaseInfo
            show={!!typeToGenerate && missingRequiredInfo}
            onClose={() => setTypeToGenerate(undefined)}
            onConfirm={handleRequiredCase}
            _case={_case}
            jurisdictions={jurisdictions} />}
          {_case && tags && jurisdiction && <SelectPartyModal
            availableTags={filterTagsForParties(tags, _case, partyTags.current)}
            show={!!typeToGenerate && !missingRequiredInfo}
            type={typeToGenerate}
            onClose={() => setTypeToGenerate(undefined)}
            onConfirm={handleChoseParty}
            jurisdiction={jurisdiction}
            _case={_case} />}
          {(user!.isInactive || !hasActiveSubscription(firm!)) && <SignUpModal
            isShow={isSignUpModalShow}
            onClose={(success) => {
              setIsSignUpModalShow(false);
              if (success && pendingDraftType.current) {
                handleSelectGenerate(pendingDraftType.current);
              }
            }}
          />}
          {!hasProvidedInfo && <Modal
            show={showInfoModal}
            onClose={() => setShowInfoModal(false)}
            size="xl"
            title={"Information for output document"}
            showCancelButton={false}
            showConfirmButton={false}
            preventBackgroundClick>
            <GeneralInformationScreen
              documentId={"anything"}
              onSave={async () => {
                setShowInfoModal(false);
                if (pendingDraftType.current) {
                  handleSelectGenerate(pendingDraftType.current);
                }
              }}
            />
          </Modal>}
        </Loading>
      </Container>
      <BPModal onClose={onCaseCloseClose}
        onConfirm={handleCaseClose}
        show={showCloseCase}
        title='Close Case'
        confirmText={`Close Case${deleteDocuments ? ' and Delete Documents' : ''}`} >
        <div>
          Please confirm you wish to close this Case, it will no longer show up in search results or on your dashboard.
          <br />
          <Form.Check id='delete-docs' name='delete-docs' checked={deleteDocuments} onChange={(e) => setDeleteDocuments(e.target.checked)} label='Also delete all associated Documents.' />
          {deleteDocuments && <p style={{ color: "#bc3f34" }}>This will delete all Documents associated with this Case along with any content entered for those documents: objections, responses, etc. THIS ACTION CANNOT BE UNDONE!</p>}
        </div>
      </BPModal>
      {_case?.integration?.identifier && <Modal onClose={() => setShowUploadDocs(false)}
        className="externalModal"
        show={showUploadDocs}
        title='Upload Files'
        confirmText='Upload Files' >
        <ExternalFileBrowser _case={_case} setIsExternalUploading={setIsExternalUploading} isExternalUploading={isExternalUploading} loadDocuments={loadDocuments} onFinish={externalUploadRefresh} />
      </Modal>}
    </>
  );
}
