import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FileEarmarkArrowUpFill } from 'react-bootstrap-icons';
import { DocumentClient } from 'services/DocumentService';

export default function RestoreIcon({
  document,
  restoreDocument
}: {
  document: DocumentClient;
  restoreDocument: (document: DocumentClient) => void;
}) {
  return (
    <div className="px-2 text-end">
      <OverlayTrigger overlay={<Tooltip className="text-capitalize">Restore</Tooltip>}>
        <FileEarmarkArrowUpFill className="restore-icon" style={{ cursor: 'pointer' }} onClick={() => restoreDocument(document)} />
      </OverlayTrigger>
    </div>
  );
}