import { User } from "@auth0/auth0-spa-js";
import { Container } from "react-bootstrap";

export default function Validate({ user }: { user?: User }) {
  return (
    <Container className="d-flex justify-content-center align-items-center">
      <div className="w-50 mt-5 d-flex flex-column justify-content-center align-items-center text-center">
        <h1 className="mb-3 font-weight-bolder">Please Verify Your Email</h1>
        <p className="w-75">A verification email <b>has been sent</b> to {user?.email ?? "the address you registered with"}.<br />If you did not receive it, check your spam folder, reach out to your IT department, or contact us at <a href="mailto:support@briefpoint.ai?subject=Email Validation Issue">support@briefpoint.ai</a>.</p>
        <p className="w-75 mb-4">If your law firm blocks emails from unapproved domains, you will need to approve the "@briefpoint.ai" domain in order to receive the email verifying your account.</p>
        <h2>Verification Complete?</h2>
        {// eslint-disable-next-line jsx-a11y/anchor-is-valid
          <p>Refresh this page or <a href="#" onClick={() => window.location.reload()}>reload</a> to get started.</p>
        }
      </div>
    </Container>
  );
}
