import { FormEventHandler, FunctionComponent } from 'react';

interface FormPreventDefaultProps {
  onSubmit: FormEventHandler;
}

export const FormPreventDefault: FunctionComponent<FormPreventDefaultProps> = ({ children, onSubmit }) => {
  function handleSubmit(event: any) {
    event.preventDefault();
    onSubmit(event);
  }
  return <form onSubmit={handleSubmit}>{children}</form>;
};
