
import { Modal } from 'components/Modals';
import "./LinkandCodeModal.scss"

interface Props {
  isShow: boolean;
  onClose: (success: boolean) => void;
}

export default function StartOverWarningModal({isShow, onClose}: Props) {

  function handleConfirm() {
    onClose(true)
  }

  function handleClose() {
    onClose(false)
  }

  function ModalContent() {
    return (
    <div>
     <b>YOUR CLIENT HAS PROVIDED RESPONSES TO SOME OF THESE REQUESTS, STARTING OVER WILL PERMANENTLY DELETE THOSE RESPONSES.</b>
    </div>
    );
  }


  return (
    <Modal
      show={isShow}
      onClose={handleClose}
      onConfirm={handleConfirm}
      title={`Warning!`}
      showCancelButton={true}
      showConfirmButton={true}
    >
      {ModalContent()}
    </Modal>
  );
}
