import mixpanel, { Dict } from "mixpanel-browser";
type UseMixpanelType = [
  (event_name: string,
    properties?: Dict) => Promise<void>,
  (unique_id?: string) => Promise<void>
];
export default function useMixpanel(): UseMixpanelType {

  const MIXPANEL_APIKEY: string = (window as any)._env_?.REACT_APP_MIXPANEL_APIKEY || process.env.REACT_APP_MIXPANEL_APIKEY;

  const track = async (event_name: string,
    properties?: Dict) => {
    if (MIXPANEL_APIKEY) {
      mixpanel.track(event_name, properties);
    }
  };

  const identify = async (unique_id?: string) => {
    if (MIXPANEL_APIKEY) {
      mixpanel.identify(unique_id);
    }
  };

  return [track, identify];
}