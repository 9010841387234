import { Definition, Role } from 'briefpoint-client';
import Loading from 'components/Loading';
import { FormModal } from 'components/Modals';
import { useDefinitionsApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { HashLink as Link } from 'react-router-hash-link';

export default function Definitions() {
  const { user } = useAuth()!;
  const [definitions, setDefinitions] = useState<Definition[]>();
  const [defToDelete, setDefToDelete] = useState<number>();

  const definitionsApi = useDefinitionsApi();

  const canSetFirmStandard = user?.role?.includes(Role.FirmAdmin);


  useEffect(() => {
    async function loadDefs() {
      const defs = await definitionsApi.definitionGetList({});
      setDefinitions(defs);
    };

    if (!definitions) {
      loadDefs();
    }

  }, [definitions, setDefinitions, definitionsApi]);

  async function handleDefinitionChange(value: string | undefined, index: number, field: string, saveImmediately: boolean = false) {
    setDefinitions(ds => {
      const update = [...ds!];
      const item = update[index] as any;
      item[field] = value;
      return update;
    });
    if (saveImmediately) {
      await handleDefinitionBlur(index);
    }
  }

  // TODO: retries/error handling
  async function handleDefinitionBlur(index: number) {
    let definition = definitions![index];
    // Update
    if (definition.id) {
      await definitionsApi.definitionUpdate({ definition });
    } else if (definition.content) { // Create
      definition.id = undefined;
      definition.isInternal = true;
      const created = await definitionsApi.definitionCreate({ definition });
      if (created.id) {
        setDefinitions(ds => {
          const update = [...ds!];
          update[index] = created;
          return update;
        });
      }
    }
  }

  async function handleDefinitionRemove(id: number) {
    let remove: Definition[] = [];
    setDefinitions(ds => {
      const update = [...ds!];

      const index = update.findIndex(x => x.id === id);

      remove = update.splice(index, 1);

      return update;
    });

    const toRemove = remove[0];
    toRemove.isDeleted = true;
    await definitionsApi.definitionUpdate({ definition: toRemove });
    setDefToDelete(undefined);
  }

  return (
    <>
      <div className="flex-grow-1 ms-5">
        <Loading isLoading={!definitions}>
          <div className='definitions-container'>
            {definitions?.sort((a, b) => {
              if (!a.id && b.id) {
                return 1
              }

              if (a.id && !b.id) {
                return -1;
              }

              if (!a.term && b.term) {
                return 1;
              }

              if (a.term && !b.term) {
                return -1;
              }

              if (!a.term && !b.term) {
                return 0;
              }

              return a.term!.localeCompare(b.term!);
            }).map((def, index) => {
              if (def.isDeleted) return null;
              return (
                <div key={`definition-${index}`}>
                  <label htmlFor={`definition-term-${index}`}>Term</label>
                  <Form.Control id={`definition-term-${index}`} value={def.term ?? ''} onChange={(e) => handleDefinitionChange(e.target.value, index, "term")} onBlur={() => handleDefinitionBlur(index)} />
                  <label htmlFor={`definition-alias-${index}`}>Aliases (Optional)</label>
                  <Form.Control id={`definition-alias-${index}`} value={def.aliases ?? ''} placeholder="Separate multiple aliases with a comma" onChange={(e) => handleDefinitionChange(e.target.value, index, "aliases")} onBlur={() => handleDefinitionBlur(index)} />
                  <label htmlFor={`definition-content-${index}`}>Content</label>
                  <Form.Control as='textarea' id={`definition-content-${index}`} value={def.content ?? ''} onChange={(e) => handleDefinitionChange(e.target.value, index, "content")} onBlur={() => handleDefinitionBlur(index)} />
                  {canSetFirmStandard && <Form.Group id='new-firm-select' className='indent-radio'>
                    <Form.Check id='new-firm-select-personal' type='radio' inline label='Personal Library' checked={!def.firmStandardId} onChange={_ => handleDefinitionChange(undefined, index, "firmStandardId", true)} />
                    <Form.Check id='new-firm-select-firm' type='radio' inline label='Firm Library' checked={!!def.firmStandardId} onChange={_ => handleDefinitionChange(user!.firmId!, index, "firmStandardId", true)} />
                  </Form.Group>}
                  <Button variant='link' onClick={() => setDefToDelete(def.id)}>Remove</Button>
                </div>
              );
            })}
          </div>
          <Button variant="outline-primary" size="sm" onClick={() => setDefinitions(d => [...d ?? [], {}])}>+ Add Another Term</Button>
          <p className={'placeholder-link'}><InfoCircle className="mr-2" style={{ marginRight: 10 }} /><Link to="/how-to/#using_placeholders" target={'_blank'}>Learn how to customize your content with Placeholders.</Link></p>
          <FormModal
            title={'Are you sure?'}
            cancelText="Cancel"
            confirmText="Yes, remove"
            show={!!defToDelete}
            onClose={() => setDefToDelete(undefined)}
            onConfirm={() => handleDefinitionRemove(defToDelete!)}
          >
            <div>Are you sure you want to remove this Definition? This cannot be undone.</div>
          </FormModal>
        </Loading>
      </div>
    </>
  );
}
