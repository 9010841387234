/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Margins
 */
export interface Margins {
    /**
     * 
     * @type {number}
     * @memberof Margins
     */
    top?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Margins
     */
    bottom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Margins
     */
    left?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Margins
     */
    right?: number | null;
}

export function MarginsFromJSON(json: any): Margins {
    return MarginsFromJSONTyped(json, false);
}

export function MarginsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Margins {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'top': !exists(json, 'top') ? undefined : json['top'],
        'bottom': !exists(json, 'bottom') ? undefined : json['bottom'],
        'left': !exists(json, 'left') ? undefined : json['left'],
        'right': !exists(json, 'right') ? undefined : json['right'],
    };
}

export function MarginsToJSON(value?: Margins | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'top': value.top,
        'bottom': value.bottom,
        'left': value.left,
        'right': value.right,
    };
}


