import {
  DocumentSubType,
  Respondent,
  PropoundingParty,
  DocumentInfo,
  FormInt,
  FormIntQuestion,
  Representative,
  Venue,
} from 'briefpoint-client';
import { CaliforniaId } from 'components/CaseManagement/AddEditCaseModal';

export function getRespondentDisplay(documentInfo: DocumentInfo, toUpperNonCustomRespondentNames:boolean = false) {
  const {
    respondent,
    respondentCustom
  } = documentInfo;
  if (respondent === Respondent.Custom && respondentCustom) {
    return respondentCustom;
  } else if (respondent === Respondent.PlaintiffOrDefendant) {
    return toUpperNonCustomRespondentNames?  getPartyDisplay(documentInfo.respondingParty, documentInfo.isCrossComplaint).toUpperCase() : getPartyDisplay(documentInfo.respondingParty, documentInfo.isCrossComplaint);
  }
  return toUpperNonCustomRespondentNames ? (respondent === Respondent.RespondingParty ? 'Responding Party' :'Respondent').toUpperCase() : (respondent === Respondent.RespondingParty ? 'Responding Party' :'Respondent');
}

export function getPartyDisplay(party: PropoundingParty | undefined, isCrossComplaint: boolean | undefined){
  const propoundingPartyNameMap = new Map([
    [PropoundingParty.Plaintiff, 'Plaintiff'],
    [PropoundingParty.Defendant, 'Defendant'],
    [PropoundingParty.CrossComplainant, 'Cross-Complainant'],
    [PropoundingParty.CrossDefendant, 'Cross-Defendant'],
  ]);

  let respondingPartyType = '';
  if (party !== undefined) {
    for (let [key, value] of propoundingPartyNameMap) {
      let helper = new PropoundingPartyEnumHelper(key);
      if (helper.is(party)) {
        respondingPartyType = value;
        break;
      }
    }
    return respondingPartyType;
  } else {
    return (party === PropoundingParty.Plaintiff ? 'Defendant' + (isCrossComplaint ? '/Cross-Complainant' : '') : 'Plaintiff' + (isCrossComplaint ? '/Cross-Defendant' : ''));
  }
}

export function getRequestType(subType: DocumentSubType, jurisdiction: string){
  switch(subType){
    case DocumentSubType.RequestsForAdmission:
      return "Requests for Admission";
    case DocumentSubType.RequestsForProduction:
      return "Requests for Production";
    case DocumentSubType.SpecialInterrogatorries:
      return jurisdiction === CaliforniaId ? "Special Interrogatories" :"Interrogatories";
    case DocumentSubType.FormInterrogatorries:
      return "Form Interrogatories";
    case DocumentSubType.Petition:
      return "Petition";
    case DocumentSubType.Complaint:
    default:
      return "Complaint";
  }
}

export function getRequestTypeSingular(subType: DocumentSubType, jurisdiction: string){
  switch(subType){
    case DocumentSubType.RequestsForAdmission:
      return "Request for Admission";
    case DocumentSubType.RequestsForProduction:
      return "Request for Production";
    case DocumentSubType.SpecialInterrogatorries:
      return jurisdiction === CaliforniaId ? "Special Interrogatory" :"Interrogatory";
    case DocumentSubType.FormInterrogatorries:
      return "Form Interrogatory";
    case DocumentSubType.Petition:
      return "Petition";
    case DocumentSubType.Complaint:
    default:
      return "Complaint";
  }
}

export class PropoundingPartyEnumHelper {
  role: number;

  constructor(role: PropoundingParty) {
    this.role = role;
  }
  getRole() {
    return this.role;
  }
  is(role: PropoundingParty) {
    return !!(this.role & role);
  }
}

export function replaceTokens(
  documentInfo: DocumentInfo | undefined,
  text: string,
  toUpperNonCustomRespondentNames: boolean,
  representative?: Representative,
) {
  if (!documentInfo) {
    return text;
  }

  const {
    propoundingPartyName,
    documentSubType,
    propoundingParty,
    isCrossComplaint,
    respondingParty,
    respondingPartyName
  } = documentInfo;

  const respondentDisplay = getRespondentDisplay(documentInfo, toUpperNonCustomRespondentNames);

  const request = documentSubType === DocumentSubType.SpecialInterrogatorries || documentSubType === DocumentSubType.FormInterrogatorries
    ? 'Interrogatory'
    : 'Request';
  const requestObject =
    documentSubType === DocumentSubType.RequestsForProduction ? 'documents' : 'information';
  const requestObjectPlurality =
    documentSubType === DocumentSubType.RequestsForProduction ? 'are' : 'is';

  const repMembers = representative ? 'Representative Members' : `${(representative === Representative.ClassAction ? 'CLASS MEMBERS' : 'AGGRIEVED EMPLOYEES')}`; 
  const repPeriod = representative ? 'Representative Period' : `${(representative === Representative.ClassAction ? 'CLASS' : 'PAGA')} PERIOD`;

  const plaintiffName = propoundingParty === PropoundingParty.Plaintiff ? propoundingPartyName : (respondingParty === PropoundingParty.Plaintiff ? respondingPartyName : undefined);
  const defendantName = propoundingParty === PropoundingParty.Defendant ? propoundingPartyName : (respondingParty === PropoundingParty.Defendant ? respondingPartyName : undefined);

  return text
    .replace(/\{\{ ?REQUEST ?\}\}/g, request)
    .replace(/\{\{ ?REQUEST_OBJECT ?\}\}/g, requestObject)
    .replace(/\{\{ ?REQUEST_TYPE ?\}\}/g, getRequestType(documentSubType, documentInfo.jurisdiction ?? ''))
    .replace(/\{\{ ?REQUEST_TYPE_SINGULAR ?\}\}/g, getRequestTypeSingular(documentSubType, documentInfo.jurisdiction ?? ''))
    .replace(/\{\{ ?LINKING_VERB ?\}\}/g, requestObjectPlurality)
    .replace(/\{\{ ?REPRESENTATIVE_MEMBERS ?\}\}/g, repMembers)
    .replace(/\{\{ ?REPRESENTATIVE_PERIOD ?\}\}/g, repPeriod)
    .replace(/\{\{ ?PROPOUNDING_PARTY ?\}\}/g, propoundingPartyName ?? 'Propounding Party')
    .replace(/\{\{ ?RESPONDING_PARTY ?\}\}/g, respondingPartyName ?? 'Responding Party')
    .replace(/\{\{ ?DEFENDANT_NAME ?\}\}/g, defendantName ?? '{{DEFENDANT_NAME}}')
    .replace(/\{\{ ?PLAINTIFF_NAME ?\}\}/g, plaintiffName ?? '{{PLAINTIFF_NAME}}')
    .replace(/\{\{ ?PROPOUNDING_PARTY_TYPE ?\}\}/g, getPartyDisplay(propoundingParty, isCrossComplaint))
    .replace(/\{\{ ?RESPONDING_PARTY_TYPE ?\}\}/g, getPartyDisplay(respondingParty, isCrossComplaint))
    .replace(/\{\{ ?RESPONDENT ?\}\}/g, respondentDisplay);
}

function formatAnswersForQuestions(questions: FormIntQuestion[], answers: string[], startAtAnswer: number) {
  return questions.map((question, index) => {
    const questionNumber = question.number ? `${question.number})` : '';
    const answer = answers[index + startAtAnswer] ?? '';
    return `${questionNumber} ${answer}<br />`;
  }).join('');
}

export function TryParseJsonToObject(text: string){
  try{
    return JSON.parse(text || '[]') || []
  } catch (e: any){
    return [];
  }
}

export function formatFormIntResponseForDisplay(text: string, formInt: FormInt | null) {
  if (!formInt) return '';

  let conditionalText = '';
  if (formInt?.conditionalAnswerDirection !== null && formInt?.conditionalAnswerDirection !== undefined) {
    if (formInt?.conditionalOnly) {
      conditionalText = formInt.conditionalAnswerDirection === true ? 'Yes<br />' : 'No<br />';
    } else {
      if (formInt.conditionalAnswerDirection === true) {
        conditionalText = text ? 'Yes<br />' : 'No<br />';
      } else {
        conditionalText = text ? 'No<br />' : 'Yes<br />';
      }

    }
  }
  try {
    let parsed: any;
    if (text) {
      parsed = JSON.parse(text);
    }
    if (parsed && Array.isArray(parsed)) {
      if (formInt?.canAddAdditonal) {
        if (formInt.questions?.length) {
          // canAddAdditional MultiQuestion - response is array of arrays of strings
          const output: string[] = [];
          output.push(`
              <br />
              ${conditionalText}`);
          const questions = formInt.questions || [];
          parsed.forEach((item: string, index: number) => {
            const answers: string[] = (item && JSON.parse(item)) || [];
            if (formInt.itemName) {
              let toAdd = '';
              if (parsed.length > 1) {
                const label = answers[0] || `${formInt.itemName} ${(index + 1).toString()}`;
                toAdd = `<b>${label}</b>
                <br />`;
              }
              output.push(toAdd + `${formatAnswersForQuestions(questions, answers, 1)}
            `);
            } else {
              output.push(`
              ${formatAnswersForQuestions(questions, answers, 0)}
            `);
            }
          });
          return output.join('<br />');
        }

        // canAddAdditional Simple - response is array of strings
        return `
          <br />
          ${conditionalText}
          ${parsed && parsed.join('<br />')}
        `;
      }

      // MultiQuestion - response is array of strings
      if (formInt?.questions?.length) {
        const questions = formInt.questions || [];
        return `
          <br />
          ${conditionalText}
          ${parsed && formatAnswersForQuestions(questions, parsed as string[], 0)}
        `;
      }

      // not sure how/if we'd ever get here
      return parsed && parsed.join('\n');
    }

    // Simple - response is just a string
    return `
    <br />
    ${conditionalText}
    ${parsed || text}
  `;
  } catch (e: any) {
    return `
    <br />
    ${conditionalText}
    ${text}
  `;
  }
}

const venueSortRegex = new RegExp(/^(\d{1,3})\w{2}/);
export function SortVenue(a: Venue, b: Venue){
  const aDigits = venueSortRegex.exec(a.shortName!);
  const bDigits = venueSortRegex.exec(b.shortName!);

  if(aDigits && bDigits){
    const numberSort = Number(aDigits[1])-Number(bDigits[1]);
    if(numberSort !== 0){
      return numberSort;
    }
    const aWithoutDigits = a.shortName!.substring(aDigits.length+2);
    const bWithoutDigits = b.shortName!.substring(bDigits.length+2);
    return aWithoutDigits.localeCompare(bWithoutDigits);
  }

  return a.shortName!.localeCompare(b.shortName!);
}