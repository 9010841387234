import { Case, Jurisdiction, User } from 'briefpoint-client';
import { Button, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import { getCaseSourceLogo } from 'utils/ExternalPartner/utils';
import { leadAttorneyName } from 'screens/CaseManagementMulti/case';

interface Props {
  addCase: () => void;
  cases: Case[] | undefined
  jurisdictions: Jurisdiction[] | undefined;
  users: User[] | null;
  showIfEmpty: boolean
}

export default function CaseTableMulti({ addCase, cases, jurisdictions, users, showIfEmpty }: Props) {

  function venueDisplay(_case: Case) {
    const j = jurisdictions?.find(x => x.id === _case.jurisdiction);
    return j?.venues?.find(x => x.id === _case.venue)?.shortName ?? '-';
  }

  return (
    <Loading isLoading={cases === undefined && jurisdictions === undefined && users === undefined}>
      {(showIfEmpty || (cases?.length !== undefined && cases?.length > 0))
        && (<div>
          {cases?.length ?
            <>
              <Row>
                <Table striped hover style={{ marginLeft: '12px' }}>
                  <thead>
                    <tr className="table-header">
                      <th className="table-header-cell" scope="">
                        Title
                      </th>
                      <th className="table-header-cell" scope="">
                        Case Number
                      </th>
                      <th className="table-header-cell" scope="">
                        Venue
                      </th>
                      <th className="table-header-cell" scope="">
                        Lead Attorney
                      </th>
                      <th className="table-header-cell" scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cases?.map(c => {
                      return (
                        <tr className='table-row' key={c.id}>
                          <td className='table-cell'><span style={{ width: '18px', display: 'inline-block' }}>{getCaseSourceLogo(c.integration?.partner)}</span> {c.shortTitle || c.title}</td>
                          <td className='table-cell'>{c.caseNumber ?? '-'}</td>
                          <td className='table-cell'>{venueDisplay(c)}</td>
                          <td className='table-cell'>{leadAttorneyName(c, users!)}</td>
                          <td className='actions-cell'>
                            <Link className="btn btn-outline-primary btn-26"
                              to={{
                                pathname: `/cases/${c.id}`,
                                state: {
                                  case: c
                                }
                              }}>View Case</Link>
                          </td>
                        </tr>)
                    })}
                  </tbody>
                </Table>
              </Row>
            </>
            : <div><p>There are no Cases yet: <Button variant='link' size='sm' onClick={addCase}>Add one now</Button> or just start by uploading a document.</p></div>}
        </div>)}
    </Loading>
  );
}
