import { SubscriptionPlan, SubscriptionPrice } from 'briefpoint-client';
import { useCallback, useEffect, useState } from 'react';
import { SubscriptionPlans, SubscriptionPlanType } from 'services/FirmService';
import { useStripeApi } from './useApi';

export default function useStripePlans() {
  const [prices, setPrices] = useState<SubscriptionPrice[]>()
  const stripeApi = useStripeApi();

  const loadPrices = useCallback(async () => {
    const prices = await stripeApi.stripeGetPrices();
    setPrices(prices);
  }, [stripeApi]);

  useEffect(() => {
    loadPrices();
  }, [loadPrices]);

  return getPlanTypesForPrices(prices);
}

export function getPlanTypesForPrices(prices: SubscriptionPrice[] | undefined) {
  const monthly = prices?.find(price => price.frequency === SubscriptionPlan.Monthly);
  const yearly = prices?.find(price => price.frequency === SubscriptionPlan.Yearly);
  const yearlyBundle = prices?.find(price => price.frequency === SubscriptionPlan.YearlyBundle);
  const alaCarte = prices?.find(price => price.frequency === SubscriptionPlan.AlaCarte);

  const planTypes: { [key in SubscriptionPlan]: SubscriptionPlanType } = {
    [SubscriptionPlan.None]: {
      plan: SubscriptionPlan.None,
      title: 'None',
      priceString: 'NA',
      isUnlimited: true,
      price: 0
    },
    [SubscriptionPlan.Monthly]: {
      plan: SubscriptionPlan.Monthly,
      title: 'Monthly',
      priceString: getPriceInDollars(monthly?.originalPrice) ?? SubscriptionPlans[SubscriptionPlan.Monthly].priceString,
      price: monthly?.originalPrice ?? SubscriptionPlans[SubscriptionPlan.Monthly].price,
      discountedPriceString: getPriceInDollars(monthly?.discountedPrice) || undefined,
      discountedPrice: monthly?.discountedPrice,
      discountCode: monthly?.discountCode,
      discountLength: monthly?.discountLength, 
      discountType: monthly?.discountType,
      isUnlimited: true,
      paralegalPrice: SubscriptionPlans[SubscriptionPlan.Monthly].paralegalPrice,
      freeParalegals: SubscriptionPlans[SubscriptionPlan.Monthly].freeParalegals
    },
    [SubscriptionPlan.Yearly]: {
      plan: SubscriptionPlan.Yearly,
      title: 'Yearly',
      priceString: getPriceInDollars(yearly?.originalPrice) ?? SubscriptionPlans[SubscriptionPlan.Yearly].priceString,
      price: yearly?.originalPrice ?? SubscriptionPlans[SubscriptionPlan.Yearly].price,
      discountedPriceString: getPriceInDollars(yearly?.discountedPrice) || undefined,
      discountedPrice: yearly?.discountedPrice,
      discountCode: yearly?.discountCode,
      discountLength: yearly?.discountLength,
      discountType: yearly?.discountType,
      isUnlimited: true,
      monthlyPrice: yearly?.originalPrice ? getPriceInDollars(yearly?.originalPrice/12) : SubscriptionPlans[SubscriptionPlan.Yearly].monthlyPrice,
      paralegalPrice: SubscriptionPlans[SubscriptionPlan.Yearly].paralegalPrice,
      freeParalegals: SubscriptionPlans[SubscriptionPlan.Yearly].freeParalegals
    },
    [SubscriptionPlan.YearlyBundle]: {
      plan: SubscriptionPlan.YearlyBundle,
      title: 'Yearly Bundle',
      priceString: getPriceInDollars(yearlyBundle?.originalPrice) ?? SubscriptionPlans[SubscriptionPlan.YearlyBundle].priceString,
      price: yearlyBundle?.originalPrice ?? SubscriptionPlans[SubscriptionPlan.YearlyBundle].price,
      discountedPriceString: getPriceInDollars(yearlyBundle?.discountedPrice) || undefined,
      discountedPrice: yearlyBundle?.discountedPrice,
      discountCode: yearlyBundle?.discountCode,
      discountLength: yearlyBundle?.discountLength,
      discountType: yearlyBundle?.discountType,
      isUnlimited: true
    },
    [SubscriptionPlan.AlaCarte]: {
      plan: SubscriptionPlan.AlaCarte,
      title: 'À la Carte',
      priceString: getPriceInDollars(alaCarte?.originalPrice) ?? SubscriptionPlans[SubscriptionPlan.AlaCarte].priceString,
      price: alaCarte?.originalPrice ?? SubscriptionPlans[SubscriptionPlan.AlaCarte].price,
      discountedPriceString: getPriceInDollars(alaCarte?.discountedPrice) || undefined,
      discountedPrice: alaCarte?.discountedPrice,
      discountCode: alaCarte?.discountCode,
      discountLength: alaCarte?.discountLength,
      discountType: alaCarte?.discountType,
      isUnlimited: false
    },
    [SubscriptionPlan.YearlyTiered]:{ plan: SubscriptionPlan.YearlyTiered, isUnlimited:true, title:"Yearly Tiered",price:1000, priceString:"$1000"} // Not actually being used, just preventing build errors
  };

  return planTypes;
}

export function getPriceInDollars(value: number | undefined | null) {
  if (value === null || value === undefined) {
    return null;
  }
  return `$${value / 100}`;
};
