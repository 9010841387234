/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Respondent {
    Respondent = 0,
    PlaintiffOrDefendant = 1,
    Custom = 2,
    RespondingParty = 3
}

export function RespondentFromJSON(json: any): Respondent {
    return RespondentFromJSONTyped(json, false);
}

export function RespondentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Respondent {
    return json as Respondent;
}

export function RespondentToJSON(value?: Respondent | null): any {
    return value as any;
}

