import api from 'api/Api';
import { ContactInfoType, DocumentSubType, DocumentType, Firm, FormInt, InterrogatoryResponseItem, Objection, ObjectionType, User } from 'briefpoint-client';
import polly from 'polly-js';
import { useCallback, useEffect, useState } from 'react';
import { FormIntExtended } from 'screens/DocumentWizard';
import { getRespondentDisplay } from 'screens/DocumentWizard/SpecialInterrogatoriesStep/Selections/utils';
import { DocumentDetailClient, InterrogatoryResponseMultiple, transformDocument } from 'services/DocumentService';
import { useDocumentApi, useObjectionApi } from './useApi';
import useMixpanel from './useMixpanel';
import rg4js from 'raygun4js';

export type SetResponseForInterrogatoryFunction = (
  interrogatoryId: number,
  newSelections: InterrogatoryResponseMultiple,
  objections: Objection[],
  formInt: FormIntExtended | null,
  user: User | undefined,
  firm: Firm | undefined,
  justAddedObjectionId: number | undefined,
) => SaveInterrogatoryParams | undefined;

export type SaveInterrogatoryParams = {
  documentId: string,
  interrogatoryId: number,
  updateInterrogatory: {
    responseList: {
      [key: string]: InterrogatoryResponseItem[];
    };
    complete: boolean;
  }
}

type UseDocumentsType = [
  DocumentDetailClient | undefined,
  SetResponseForInterrogatoryFunction,
  (document: DocumentDetailClient) => Promise<void>,
  (checkLocalHost?: boolean) => Promise<string>,
  (number: number, text?: string, interrogatoryId?: number, pleadDocId?: string, sourceId?: string) => Promise<void>,
  (interrogatoryId: number, pleadDocId?: string) => Promise<void>,
  (document: DocumentDetailClient) => Promise<void>,
  (toSave: SaveInterrogatoryParams) => Promise<void>,
  () => Promise<void>,
];
export default function useDocumentDetail(documentId: string): UseDocumentsType {
  const [document, setDocument] = useState<DocumentDetailClient>();
  const documentApi = useDocumentApi();
  const [mixpanelTrack] = useMixpanel()!;

  const loadInterrogatories = useCallback(async () => {
    const document = await documentApi.documentGet({ documentId });
    setDocument(transformDocument(document) as DocumentDetailClient);
  }, [documentId, documentApi]);

  const addEditInterrogatory = useCallback(async (number: number, text?: string, interrogatoryId?: number, pleadDocId?: string, sourceId?: string) => {
    if (interrogatoryId) {
      if (text) {
        await documentApi.documentUpdateInterrogatory({ documentId: pleadDocId || documentId, interrogatoryId: interrogatoryId, updateInterrogatory: { text: text, number: number, sourceId } });
      }
      else {
        await documentApi.documentUpdateInterrogatory({ documentId: pleadDocId || documentId, interrogatoryId: interrogatoryId, updateInterrogatory: { number: number, sourceId } });
      }
    }
    else {
      await documentApi.documentAddInterrogatory({ documentId: pleadDocId || documentId, updateInterrogatory: { text: text, number: number, sourceId } });
    }
    // TODO: could this be done w/o re-getting the full document from the server?
    // especially for editing could we just update the interrogatory in place?s
    await loadInterrogatories();

  }, [documentApi, documentId, loadInterrogatories]);

  const deleteInterrogatory = useCallback(async (interrogatoryId: number, pleadDocId?: string) => {
    await documentApi.documentDeleteInterrogatory({ documentId: pleadDocId || documentId, interrogatoryId: interrogatoryId });
    await loadInterrogatories();
  }, [documentApi, documentId, loadInterrogatories]);

  const clearMissingFormInterrogatory = useCallback(async () => {
    await documentApi.documentClearMissingFormInterrogatory({ documentId: documentId });
    await loadInterrogatories();
  }, [documentApi, documentId, loadInterrogatories]);

  const updateDocumentCallback = useCallback(
    async (doc: DocumentDetailClient) => {
      await documentApi.documentUpdateDocument({ documentId: doc.id, document: doc });
      setDocument({ ...doc! });
    },
    [documentApi]
  );

  const clearMismatchWarning = useCallback(
    async (doc: DocumentDetailClient) => {
      doc.info!.firstMismatchedRequest = null;
      await documentApi.documentDismissMismatchWarning({ documentId: doc.id });
      setDocument({ ...doc! });
    },
    [documentApi]
  );

  // I updated this to return a function to the actual API call so we can make a queue of actual saves, while still updating the UI as users click
  // there's probably a better way to do this, if you know what that is, be my guest!
  const setResponse: SetResponseForInterrogatoryFunction = (
    interrogatoryId,
    newSelections,
    objections,
    formInt,
    user,
    firm,
    justAddedObjectionId
  ) => {
    const interrogatory = document!.interrogatories!.find((ig) => ig.id === interrogatoryId);
    let complete = false;
    if (document!.info?.documentType !== DocumentType.Pleading) {
      let finishObjections = objections.filter((obj) => obj.objectionType === ObjectionType.Response || obj.objectionType === ObjectionType.NoResponse);
      interrogatory!.responseList = Object.assign({}, newSelections);
      if (finishObjections && finishObjections.some((obj) => interrogatory!.responseList && interrogatory!.responseList[obj.id])) {
        // finish is selected
        complete = true;
      }

      // This whole mess decides if we want to mark other requests completed based on the answer to this one
      // currently this only happens for 'conditionalSkipsEntireSection' FormInts
      finishObjections = objections.filter((obj) => obj.objectionType === ObjectionType.Response);
      const finish = finishObjections.find((obj) => interrogatory!.responseList && interrogatory!.responseList[obj.id]);

      if (finish && formInt && interrogatory!.responseList[finish.id].find(x => x.objectionId === justAddedObjectionId)) {
        interrogatory!.responseList[finish.id][0].additionalText = undefined; // Response type objections are only allowed 1 response
      }

      // oh, also autofill their attorney info for 1.1, 70.1, 101.1, 301.1, 200.1, 
      if (user && firm && finish && formInt && (formInt.section === 1 || formInt.section === 70 || formInt.section === 101 || formInt.section === 301 || formInt.section === 200) && formInt.number === 1) {
        const response = interrogatory!.responseList[finish.id][0];
        if (!response.additionalText) {
          let customResponse = `${user.firstName} ${user.lastName}, ${firm.name}`;
          var phone = firm!.contactInfo.find((c) => c.type === ContactInfoType.Phone);

          if (firm.address && firm.address.street) {
            customResponse += `, ${firm.address.street}`;
            if (firm.address.street2) {
              customResponse += `, ${firm.address.street2}`;
            }

            customResponse += `, ${firm.address.city}, ${firm.address.stateProvince} ${firm.address.postalCode}`;
          }
          customResponse += ',';
          if (phone) {
            customResponse += ` ${phone.value},`;
          }
          customResponse += ` Attorney for ${getRespondentDisplay(document!.info!)}.`;
          response.additionalText = customResponse;
        }
      }

      // if this interrogatory is already complete (which means it previously had a Response) or we are now Finishing,
      // AND this is an item that might want to manage completion of other items
      if ((interrogatory!.complete || finish) && formInt && formInt.conditionalSkipsEntireSection && formInt.conditionalSkipsIds) {
        const response = finish && interrogatory!.responseList && interrogatory!.responseList[finish.id];
        // if there are any interrogatories in this form int's 'conditionalSkipsIds' that don't have any response items (don't override people being weird)
        const intsToSkip = document!.interrogatories!.filter((ig) => formInt.conditionalSkipsIds?.includes(ig.text) && !Object.entries(ig.response).length);
        // for each one, mark it complete if they answered "no" to this question
        intsToSkip!.forEach(async (ig, _) => {
          const currentComplete = ig.complete;
          const newComplete = ig.complete = complete && (!!response && response[0].additionalText !== "yes");
          formInt.shouldSkipRemainderOfSection = newComplete;
          // and save the other question if we're changing the value
          if (currentComplete !== newComplete) {
            polly()
              .waitAndRetry(2)
              .executeForPromise(async () => {
                await documentApi.documentUpdateInterrogatory({
                  documentId,
                  interrogatoryId: ig.id,
                  updateInterrogatory: { response: ig.response, complete: newComplete },
                });
              }).catch(c => console.log(c));
          }
        });
      }

      interrogatory!.complete = complete;
    }
    // if (finishObjection && response.has(finishObjection.id) && !newSelectionsMap.has(finishObjection.id)) {
    //   // finish unselected
    // } else if (
    //   finishObjection &&
    //   !response.has(finishObjection.id) &&
    //   newSelectionsMap.has(finishObjection.id)
    // ) {
    //   // finish selected
    //   complete = true;
    // }
    setDocument({ ...document!, interrogatories: [...document!.interrogatories!] });
    return {
      documentId,
      interrogatoryId,
      updateInterrogatory: { responseList: interrogatory!.responseList!, complete },
    };
  };

  const updateInterrogatory = useCallback(
    async (toSave: SaveInterrogatoryParams) => {
      await polly()
        .waitAndRetry(2)
        .executeForPromise(async () => {
          await documentApi.documentUpdateInterrogatory({
            documentId: toSave.documentId,
            interrogatoryId: toSave.interrogatoryId,
            updateInterrogatory: { responseList: toSave.updateInterrogatory.responseList, complete: toSave.updateInterrogatory.complete },
          });
        }).catch(e => {
          return (e?.text?.() || Promise.resolve("unknown"))
            .then((error: any) => {
              rg4js('send', {
                error: new Error(`Unable to save interrogatory response for ${toSave.interrogatoryId} on document ${toSave.documentId}. Data: {${error}}`),
              });
              throw e;
            });
        })
    },
    [documentApi]
  );

  const getReviewPreviewUrl = useCallback(
    async (checkLocalhost: boolean = false, viewType: string = "Download") => {
      if (checkLocalhost && api.API_URL.includes('localhost')) {
        // the word document previewer can't use localhost urls
        let url = await documentApi.documentGenerateDocument({ documentId, upload: true });
        return url;
      } else {
        let token = await documentApi.documentGenerateAccessToken({ documentId });
        let url = api.getUrl(`document/generate-doc-accesstoken`, { documentId, tokenId: token.id, t: Date.now() });
        mixpanelTrack("DocumentView", { "Method": viewType, "DocumentName": document!.name, "DocumentId": documentId });
        return url;
      }
    },
    [documentApi, documentId, document, mixpanelTrack]
  );

  useEffect(() => {
    loadInterrogatories();
  }, [loadInterrogatories]);

  return [
    document,
    setResponse,
    updateDocumentCallback,
    getReviewPreviewUrl,
    addEditInterrogatory,
    deleteInterrogatory,
    clearMismatchWarning,
    updateInterrogatory,
    clearMissingFormInterrogatory
  ];
}

export function useDocumentFormInterrogatories(subType?: DocumentSubType): FormInt[] | null {
  const [formInterrogatories, setFormInterrogatories] = useState<FormInt[] | null>(null);
  const objectionApi = useObjectionApi();

  useEffect(() => {
    if (!formInterrogatories && subType === DocumentSubType.FormInterrogatorries)
      objectionApi.objectionGetFormInts().then(formInts => {
        setFormInterrogatories(formInts);
      });
  }, [objectionApi, subType, formInterrogatories]);

  return formInterrogatories;
}
