import { Container } from 'react-bootstrap';

export default function Footer() {
  return (
    <footer className="py-1 border-top">
      <Container>
        <small className="text-muted">&copy; {new Date().getFullYear()} Briefpoint </small>
      </Container>
    </footer>
  );
}
