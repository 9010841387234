/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactInfoCategory,
    ContactInfoCategoryFromJSON,
    ContactInfoCategoryFromJSONTyped,
    ContactInfoCategoryToJSON,
    ContactInfoType,
    ContactInfoTypeFromJSON,
    ContactInfoTypeFromJSONTyped,
    ContactInfoTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    value?: string | null;
    /**
     * 
     * @type {ContactInfoType}
     * @memberof ContactInfo
     */
    type?: ContactInfoType;
    /**
     * 
     * @type {ContactInfoCategory}
     * @memberof ContactInfo
     */
    category?: ContactInfoCategory;
}

export function ContactInfoFromJSON(json: any): ContactInfo {
    return ContactInfoFromJSONTyped(json, false);
}

export function ContactInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'type': !exists(json, 'type') ? undefined : ContactInfoTypeFromJSON(json['type']),
        'category': !exists(json, 'category') ? undefined : ContactInfoCategoryFromJSON(json['category']),
    };
}

export function ContactInfoToJSON(value?: ContactInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'type': ContactInfoTypeToJSON(value.type),
        'category': ContactInfoCategoryToJSON(value.category),
    };
}


