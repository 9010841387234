import { useMemo, useState } from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';
import { BriefcaseFill, Person, PersonCircle } from 'react-bootstrap-icons';


export interface VariantOption {
  label: string;
  value: number | null;
  key: string;
}

interface Props {
  options: VariantOption[];
  className?: string;
  selected?: number;
  onChange: (value: number | undefined) => void;
  onClickAdd?: () => void;
  deemphasizeSelected?: boolean;
  openPos?: MenuPlacement;
  isClearable?: boolean; 
}

export function formatGroupLabelString(key: string) {
  if (key.indexOf("Firm") > -1) {
    return (
      <div><BriefcaseFill /><span style={{ paddingLeft: 5, verticalAlign: 'middle' }}>{key}</span></div>
    );
  }

  if (key.indexOf("Personal") > -1) {
    return (
      <div><PersonCircle /><span style={{ paddingLeft: 5, verticalAlign: 'middle' }}>{key}</span></div>
    );
  }

  if (key) {
    return (
      <div><Person /><span style={{ paddingLeft: 5, verticalAlign: 'middle' }}>{key}</span></div>
    );
  }

  return (
    <div>{key}</div>
  );
};

export default function Select({
  onClickAdd,
  options,
  className,
  selected,
  onChange,
  deemphasizeSelected,
  openPos,
  isClearable
}: Props) {
  const [selectedOption, setSelectedOption] = useState<VariantOption | undefined>(options.find((o) => o.value === selected));

  const customStyles = useMemo(() => {
    return {
      indicatorSeparator: () => ({
        display: 'none',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        padding: '2px 8px'
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: deemphasizeSelected && 'grey',
      }),
      group: (provided: any, state: any) => ({
        ...provided,
        padding: '0px',
        ...(onClickAdd
          ? {
            ':last-child': {
              borderTop: '1pt solid #cccccc',
            },
          }
          : {}),
      }),
      groupHeading: (provided: any, state: any) => {
        if (!state.data.key) {
          return provided;
        }

        return {
          ...provided,
          borderTop: '1pt solid #cccccc',
          borderBottom: '1pt solid #cccccc',
        }
      },
    };
  }, [onClickAdd, deemphasizeSelected]);

  const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);

  const transformedOptions = useMemo(() => {
    const group = groupBy(options, i => i.key);
    const opts = []

    for (let k in group) {
      opts.push({ key: k, options: group[k].map(v => { return { label: v.label, value: v.value }; }) });
    }

    if (onClickAdd) {
      opts.push({ key: '', options: [{ label: '+ Add New', value: null }] })
    }

    return opts;
  }, [onClickAdd, options]);

  const handleChange = (option: any) => {
    if (option === null) {
      setSelectedOption(undefined);
      onChange(undefined);
    } else {
      setSelectedOption(option);
      if (option?.value) {
        onChange(option.value);
      } else if (option?.label === '+ Add New' && onClickAdd) {
        onClickAdd();
      }
    }
  };

  const formatGroupLabel = (option: any) => {
    return formatGroupLabelString(option.key);
  };

  return (
    <ReactSelect
      className={className}
      classNamePrefix="brief-point-select"
      options={transformedOptions}
      styles={customStyles}
      value={{ label: selectedOption?.label, value: selectedOption?.value }}
      formatGroupLabel={formatGroupLabel}
      onChange={handleChange}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#074f6b',
          primary25: '#efefea',
        },
      })}
      //menuIsOpen={true}
      menuPlacement={openPos ?? 'top'}
      isClearable={(isClearable ?? false) && !!selectedOption}
    ></ReactSelect>
  );
}
