/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DocumentSubType,
    DocumentSubTypeFromJSON,
    DocumentSubTypeToJSON,
    DocumentType,
    DocumentTypeFromJSON,
    DocumentTypeToJSON,
    MiscContent,
    MiscContentFromJSON,
    MiscContentToJSON,
    MiscContentType,
    MiscContentTypeFromJSON,
    MiscContentTypeToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    PropoundingParty,
    PropoundingPartyFromJSON,
    PropoundingPartyToJSON,
} from '../models';

export interface ContentDeleteMiscContentRequest {
    contentId: string;
}

export interface ContentGetAllMiscContentRequest {
    type?: MiscContentType;
    documentType?: DocumentType;
}

export interface ContentGetDefaultMiscContentFilteredRequest {
    type?: MiscContentType;
    documentType?: DocumentType;
    documentSubType?: DocumentSubType;
    caseType?: string;
    tagIds?: Array<number>;
    isForRepresentative?: boolean;
    respondingParty?: PropoundingParty;
    jursidictionId?: string;
    skipTagFiltering?: boolean;
}

export interface ContentGetMiscContentRequest {
    type?: MiscContentType;
    documentType?: DocumentType;
    documentSubType?: DocumentSubType;
}

export interface ContentGetMiscContentFilteredRequest {
    type?: MiscContentType;
    documentType?: DocumentType;
    documentSubType?: DocumentSubType;
    caseType?: string;
    tagIds?: Array<number>;
    isForRepresentative?: boolean;
    respondingParty?: PropoundingParty;
    jursidictionId?: string;
    skipTagFiltering?: boolean;
}

export interface ContentPostMiscContentRequest {
    miscContent?: MiscContent;
}

export interface ContentPostMultipleMiscContentRequest {
    miscContent?: Array<MiscContent>;
}

export interface ContentPutMiscContentRequest {
    miscContent?: MiscContent;
}

/**
 * 
 */
export class ContentApi extends runtime.BaseAPI {

    /**
     */
    async contentDeleteMiscContentRaw(requestParameters: ContentDeleteMiscContentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contentId === null || requestParameters.contentId === undefined) {
            throw new runtime.RequiredError('contentId','Required parameter requestParameters.contentId was null or undefined when calling contentDeleteMiscContent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Content/misc-content/{contentId}`.replace(`{${"contentId"}}`, encodeURIComponent(String(requestParameters.contentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async contentDeleteMiscContent(requestParameters: ContentDeleteMiscContentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.contentDeleteMiscContentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async contentGetAllMiscContentRaw(requestParameters: ContentGetAllMiscContentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MiscContent>>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.documentType !== undefined) {
            queryParameters['documentType'] = requestParameters.documentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Content/misc-content/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MiscContentFromJSON));
    }

    /**
     */
    async contentGetAllMiscContent(requestParameters: ContentGetAllMiscContentRequest, initOverrides?: RequestInit): Promise<Array<MiscContent>> {
        const response = await this.contentGetAllMiscContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contentGetDefaultMiscContentFilteredRaw(requestParameters: ContentGetDefaultMiscContentFilteredRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MiscContent>>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.documentType !== undefined) {
            queryParameters['documentType'] = requestParameters.documentType;
        }

        if (requestParameters.documentSubType !== undefined) {
            queryParameters['documentSubType'] = requestParameters.documentSubType;
        }

        if (requestParameters.caseType !== undefined) {
            queryParameters['caseType'] = requestParameters.caseType;
        }

        if (requestParameters.tagIds) {
            queryParameters['tagIds'] = requestParameters.tagIds;
        }

        if (requestParameters.isForRepresentative !== undefined) {
            queryParameters['isForRepresentative'] = requestParameters.isForRepresentative;
        }

        if (requestParameters.respondingParty !== undefined) {
            queryParameters['respondingParty'] = requestParameters.respondingParty;
        }

        if (requestParameters.jursidictionId !== undefined) {
            queryParameters['jursidictionId'] = requestParameters.jursidictionId;
        }

        if (requestParameters.skipTagFiltering !== undefined) {
            queryParameters['skipTagFiltering'] = requestParameters.skipTagFiltering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Content/misc-content-filtererd/default`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MiscContentFromJSON));
    }

    /**
     */
    async contentGetDefaultMiscContentFiltered(requestParameters: ContentGetDefaultMiscContentFilteredRequest, initOverrides?: RequestInit): Promise<Array<MiscContent>> {
        const response = await this.contentGetDefaultMiscContentFilteredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contentGetMiscContentRaw(requestParameters: ContentGetMiscContentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MiscContent>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.documentType !== undefined) {
            queryParameters['documentType'] = requestParameters.documentType;
        }

        if (requestParameters.documentSubType !== undefined) {
            queryParameters['documentSubType'] = requestParameters.documentSubType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Content/misc-content`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MiscContentFromJSON(jsonValue));
    }

    /**
     */
    async contentGetMiscContent(requestParameters: ContentGetMiscContentRequest, initOverrides?: RequestInit): Promise<MiscContent> {
        const response = await this.contentGetMiscContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contentGetMiscContentFilteredRaw(requestParameters: ContentGetMiscContentFilteredRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MiscContent>>> {
        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.documentType !== undefined) {
            queryParameters['documentType'] = requestParameters.documentType;
        }

        if (requestParameters.documentSubType !== undefined) {
            queryParameters['documentSubType'] = requestParameters.documentSubType;
        }

        if (requestParameters.caseType !== undefined) {
            queryParameters['caseType'] = requestParameters.caseType;
        }

        if (requestParameters.tagIds) {
            queryParameters['tagIds'] = requestParameters.tagIds;
        }

        if (requestParameters.isForRepresentative !== undefined) {
            queryParameters['isForRepresentative'] = requestParameters.isForRepresentative;
        }

        if (requestParameters.respondingParty !== undefined) {
            queryParameters['respondingParty'] = requestParameters.respondingParty;
        }

        if (requestParameters.jursidictionId !== undefined) {
            queryParameters['jursidictionId'] = requestParameters.jursidictionId;
        }

        if (requestParameters.skipTagFiltering !== undefined) {
            queryParameters['skipTagFiltering'] = requestParameters.skipTagFiltering;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Content/misc-content-filtererd`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MiscContentFromJSON));
    }

    /**
     */
    async contentGetMiscContentFiltered(requestParameters: ContentGetMiscContentFilteredRequest, initOverrides?: RequestInit): Promise<Array<MiscContent>> {
        const response = await this.contentGetMiscContentFilteredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contentPostMiscContentRaw(requestParameters: ContentPostMiscContentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MiscContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Content/misc-content`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MiscContentToJSON(requestParameters.miscContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MiscContentFromJSON(jsonValue));
    }

    /**
     */
    async contentPostMiscContent(requestParameters: ContentPostMiscContentRequest, initOverrides?: RequestInit): Promise<MiscContent> {
        const response = await this.contentPostMiscContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contentPostMultipleMiscContentRaw(requestParameters: ContentPostMultipleMiscContentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Content/misc-content-multiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.miscContent?.map(MiscContentToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async contentPostMultipleMiscContent(requestParameters: ContentPostMultipleMiscContentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.contentPostMultipleMiscContentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async contentPutMiscContentRaw(requestParameters: ContentPutMiscContentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MiscContent>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Content/misc-content`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MiscContentToJSON(requestParameters.miscContent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MiscContentFromJSON(jsonValue));
    }

    /**
     */
    async contentPutMiscContent(requestParameters: ContentPutMiscContentRequest, initOverrides?: RequestInit): Promise<MiscContent> {
        const response = await this.contentPutMiscContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
