/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationInfo,
    CommunicationInfoFromJSON,
    CommunicationInfoFromJSONTyped,
    CommunicationInfoToJSON,
    CommunicationStatus,
    CommunicationStatusFromJSON,
    CommunicationStatusFromJSONTyped,
    CommunicationStatusToJSON,
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
    ObjectTypeEnum,
    ObjectTypeEnumFromJSON,
    ObjectTypeEnumFromJSONTyped,
    ObjectTypeEnumToJSON,
    ViewCommunicationItem,
    ViewCommunicationItemFromJSON,
    ViewCommunicationItemFromJSONTyped,
    ViewCommunicationItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ViewCommunication
 */
export interface ViewCommunication {
    /**
     * 
     * @type {string}
     * @memberof ViewCommunication
     */
    id?: string;
    /**
     * 
     * @type {ObjectTypeEnum}
     * @memberof ViewCommunication
     */
    objectType?: ObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ViewCommunication
     */
    objectId?: string;
    /**
     * 
     * @type {CommunicationType}
     * @memberof ViewCommunication
     */
    communicationType?: CommunicationType;
    /**
     * 
     * @type {CommunicationStatus}
     * @memberof ViewCommunication
     */
    status?: CommunicationStatus;
    /**
     * 
     * @type {CommunicationInfo}
     * @memberof ViewCommunication
     */
    communicationInfo?: CommunicationInfo;
    /**
     * 
     * @type {string}
     * @memberof ViewCommunication
     */
    firmId?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewCommunication
     */
    caseId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ViewCommunication
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ViewCommunication
     */
    lastActivityDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ViewCommunication
     */
    createdBy?: string;
    /**
     * 
     * @type {ObjectTypeEnum}
     * @memberof ViewCommunication
     */
    sharedWithObjectType?: ObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ViewCommunication
     */
    sharedWithObjectId?: string | null;
    /**
     * 
     * @type {Array<ViewCommunicationItem>}
     * @memberof ViewCommunication
     */
    externalCommunicationItems?: Array<ViewCommunicationItem> | null;
}

export function ViewCommunicationFromJSON(json: any): ViewCommunication {
    return ViewCommunicationFromJSONTyped(json, false);
}

export function ViewCommunicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewCommunication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'objectType': !exists(json, 'objectType') ? undefined : ObjectTypeEnumFromJSON(json['objectType']),
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'communicationType': !exists(json, 'communicationType') ? undefined : CommunicationTypeFromJSON(json['communicationType']),
        'status': !exists(json, 'status') ? undefined : CommunicationStatusFromJSON(json['status']),
        'communicationInfo': !exists(json, 'communicationInfo') ? undefined : CommunicationInfoFromJSON(json['communicationInfo']),
        'firmId': !exists(json, 'firmId') ? undefined : json['firmId'],
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'lastActivityDate': !exists(json, 'lastActivityDate') ? undefined : (json['lastActivityDate'] === null ? null : new Date(json['lastActivityDate'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'sharedWithObjectType': !exists(json, 'sharedWithObjectType') ? undefined : ObjectTypeEnumFromJSON(json['sharedWithObjectType']),
        'sharedWithObjectId': !exists(json, 'sharedWithObjectId') ? undefined : json['sharedWithObjectId'],
        'externalCommunicationItems': !exists(json, 'externalCommunicationItems') ? undefined : (json['externalCommunicationItems'] === null ? null : (json['externalCommunicationItems'] as Array<any>).map(ViewCommunicationItemFromJSON)),
    };
}

export function ViewCommunicationToJSON(value?: ViewCommunication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'objectType': ObjectTypeEnumToJSON(value.objectType),
        'objectId': value.objectId,
        'communicationType': CommunicationTypeToJSON(value.communicationType),
        'status': CommunicationStatusToJSON(value.status),
        'communicationInfo': CommunicationInfoToJSON(value.communicationInfo),
        'firmId': value.firmId,
        'caseId': value.caseId,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'lastActivityDate': value.lastActivityDate === undefined ? undefined : (value.lastActivityDate === null ? null : value.lastActivityDate.toISOString()),
        'createdBy': value.createdBy,
        'sharedWithObjectType': ObjectTypeEnumToJSON(value.sharedWithObjectType),
        'sharedWithObjectId': value.sharedWithObjectId,
        'externalCommunicationItems': value.externalCommunicationItems === undefined ? undefined : (value.externalCommunicationItems === null ? null : (value.externalCommunicationItems as Array<any>).map(ViewCommunicationItemToJSON)),
    };
}


