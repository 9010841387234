/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Case,
    CaseFromJSON,
    CaseToJSON,
    CreateOfficeModel,
    CreateOfficeModelFromJSON,
    CreateOfficeModelToJSON,
    CreateSubscription,
    CreateSubscriptionFromJSON,
    CreateSubscriptionToJSON,
    Firm,
    FirmFromJSON,
    FirmToJSON,
    FirmPage,
    FirmPageFromJSON,
    FirmPageToJSON,
    FirmPreferences,
    FirmPreferencesFromJSON,
    FirmPreferencesToJSON,
    FirmRevenueLineItem,
    FirmRevenueLineItemFromJSON,
    FirmRevenueLineItemToJSON,
    InviteUserToFirm,
    InviteUserToFirmFromJSON,
    InviteUserToFirmToJSON,
    Office,
    OfficeFromJSON,
    OfficeToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SetSubscription,
    SetSubscriptionFromJSON,
    SetSubscriptionToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserInvite,
    UserInviteFromJSON,
    UserInviteToJSON,
} from '../models';

export interface FirmActivateUserRequest {
    id: string;
    userId: string;
}

export interface FirmAddCreditsRequest {
    id: string;
    expiryDays?: number;
    body?: number;
}

export interface FirmCreateOfficeRequest {
    id: string;
    createOfficeModel?: CreateOfficeModel;
}

export interface FirmCreateSubscriptionRequest {
    createSubscription?: CreateSubscription;
}

export interface FirmDeactivateUserRequest {
    id: string;
    userId: string;
}

export interface FirmExtendTrialRequest {
    id: string;
    body?: number;
}

export interface FirmGetRequest {
    id: string;
}

export interface FirmGetByCodeRequest {
    firmCode: string;
}

export interface FirmGetFirmCasesRequest {
    firmId: string;
    includeArchived?: boolean;
}

export interface FirmGetFirmsByNameRequest {
    firmName?: string;
    itemsPerPage?: number;
    pageNumber?: number;
}

export interface FirmGetInvitesRequest {
    id: string;
    includePickedUp?: boolean;
}

export interface FirmGetUsersRequest {
    id: string;
    includeInactive?: boolean;
}

export interface FirmGetUsersCountRequest {
    id: string;
    includeInactive?: boolean;
}

export interface FirmInviteUserRequest {
    id: string;
    inviteUserToFirm?: InviteUserToFirm;
}

export interface FirmPutRequest {
    id: string;
    firm?: Firm;
}

export interface FirmSetSubscriptionRequest {
    id: string;
    setSubscription?: SetSubscription;
}

export interface FirmUpdatePreferencesRequest {
    id: string;
    firmPreferences?: FirmPreferences;
}

/**
 * 
 */
export class FirmApi extends runtime.BaseAPI {

    /**
     */
    async firmActivateUserRaw(requestParameters: FirmActivateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmActivateUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling firmActivateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/activate-user/{userId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async firmActivateUser(requestParameters: FirmActivateUserRequest, initOverrides?: RequestInit): Promise<void> {
        await this.firmActivateUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async firmAddCreditsRaw(requestParameters: FirmAddCreditsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmAddCredits.');
        }

        const queryParameters: any = {};

        if (requestParameters.expiryDays !== undefined) {
            queryParameters['expiryDays'] = requestParameters.expiryDays;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/add-credits`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async firmAddCredits(requestParameters: FirmAddCreditsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.firmAddCreditsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async firmChurnReportRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/churn`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async firmChurnReport(initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.firmChurnReportRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async firmCreateOfficeRaw(requestParameters: FirmCreateOfficeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Office>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmCreateOffice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/offices`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOfficeModelToJSON(requestParameters.createOfficeModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfficeFromJSON(jsonValue));
    }

    /**
     */
    async firmCreateOffice(requestParameters: FirmCreateOfficeRequest, initOverrides?: RequestInit): Promise<Office> {
        const response = await this.firmCreateOfficeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async firmCreateSubscriptionRaw(requestParameters: FirmCreateSubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Firm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSubscriptionToJSON(requestParameters.createSubscription),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirmFromJSON(jsonValue));
    }

    /**
     */
    async firmCreateSubscription(requestParameters: FirmCreateSubscriptionRequest, initOverrides?: RequestInit): Promise<Firm> {
        const response = await this.firmCreateSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async firmDeactivateUserRaw(requestParameters: FirmDeactivateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmDeactivateUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling firmDeactivateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/deactivate-user/{userId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async firmDeactivateUser(requestParameters: FirmDeactivateUserRequest, initOverrides?: RequestInit): Promise<void> {
        await this.firmDeactivateUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async firmExtendTrialRaw(requestParameters: FirmExtendTrialRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmExtendTrial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/extend-trial`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async firmExtendTrial(requestParameters: FirmExtendTrialRequest, initOverrides?: RequestInit): Promise<void> {
        await this.firmExtendTrialRaw(requestParameters, initOverrides);
    }

    /**
     */
    async firmGetRaw(requestParameters: FirmGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Firm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirmFromJSON(jsonValue));
    }

    /**
     */
    async firmGet(requestParameters: FirmGetRequest, initOverrides?: RequestInit): Promise<Firm> {
        const response = await this.firmGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async firmGetByCodeRaw(requestParameters: FirmGetByCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Firm>> {
        if (requestParameters.firmCode === null || requestParameters.firmCode === undefined) {
            throw new runtime.RequiredError('firmCode','Required parameter requestParameters.firmCode was null or undefined when calling firmGetByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/firm-code/{firmCode}`.replace(`{${"firmCode"}}`, encodeURIComponent(String(requestParameters.firmCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirmFromJSON(jsonValue));
    }

    /**
     */
    async firmGetByCode(requestParameters: FirmGetByCodeRequest, initOverrides?: RequestInit): Promise<Firm> {
        const response = await this.firmGetByCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async firmGetFirmCasesRaw(requestParameters: FirmGetFirmCasesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Case>>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling firmGetFirmCases.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeArchived !== undefined) {
            queryParameters['includeArchived'] = requestParameters.includeArchived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/cases`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CaseFromJSON));
    }

    /**
     */
    async firmGetFirmCases(requestParameters: FirmGetFirmCasesRequest, initOverrides?: RequestInit): Promise<Array<Case>> {
        const response = await this.firmGetFirmCasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async firmGetFirmsByNameRaw(requestParameters: FirmGetFirmsByNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FirmPage>> {
        const queryParameters: any = {};

        if (requestParameters.firmName !== undefined) {
            queryParameters['firmName'] = requestParameters.firmName;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirmPageFromJSON(jsonValue));
    }

    /**
     */
    async firmGetFirmsByName(requestParameters: FirmGetFirmsByNameRequest, initOverrides?: RequestInit): Promise<FirmPage> {
        const response = await this.firmGetFirmsByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async firmGetInvitesRaw(requestParameters: FirmGetInvitesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserInvite>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmGetInvites.');
        }

        const queryParameters: any = {};

        if (requestParameters.includePickedUp !== undefined) {
            queryParameters['includePickedUp'] = requestParameters.includePickedUp;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/invites`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserInviteFromJSON));
    }

    /**
     */
    async firmGetInvites(requestParameters: FirmGetInvitesRequest, initOverrides?: RequestInit): Promise<Array<UserInvite>> {
        const response = await this.firmGetInvitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async firmGetUsersRaw(requestParameters: FirmGetUsersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmGetUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeInactive !== undefined) {
            queryParameters['includeInactive'] = requestParameters.includeInactive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async firmGetUsers(requestParameters: FirmGetUsersRequest, initOverrides?: RequestInit): Promise<Array<User>> {
        const response = await this.firmGetUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async firmGetUsersCountRaw(requestParameters: FirmGetUsersCountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmGetUsersCount.');
        }

        const queryParameters: any = {};

        if (requestParameters.includeInactive !== undefined) {
            queryParameters['includeInactive'] = requestParameters.includeInactive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/users-count`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async firmGetUsersCount(requestParameters: FirmGetUsersCountRequest, initOverrides?: RequestInit): Promise<number> {
        const response = await this.firmGetUsersCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async firmInviteUserRaw(requestParameters: FirmInviteUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmInviteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/invite-user`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteUserToFirmToJSON(requestParameters.inviteUserToFirm),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async firmInviteUser(requestParameters: FirmInviteUserRequest, initOverrides?: RequestInit): Promise<void> {
        await this.firmInviteUserRaw(requestParameters, initOverrides);
    }

    /**
     */
    async firmPutRaw(requestParameters: FirmPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Firm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FirmToJSON(requestParameters.firm),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirmFromJSON(jsonValue));
    }

    /**
     */
    async firmPut(requestParameters: FirmPutRequest, initOverrides?: RequestInit): Promise<Firm> {
        const response = await this.firmPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async firmRevenueRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FirmRevenueLineItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/revenue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FirmRevenueLineItemFromJSON));
    }

    /**
     */
    async firmRevenue(initOverrides?: RequestInit): Promise<Array<FirmRevenueLineItem>> {
        const response = await this.firmRevenueRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async firmRevenueCsvRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/revenue-csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async firmRevenueCsv(initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.firmRevenueCsvRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async firmSetSubscriptionRaw(requestParameters: FirmSetSubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmSetSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/set-subscription`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetSubscriptionToJSON(requestParameters.setSubscription),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async firmSetSubscription(requestParameters: FirmSetSubscriptionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.firmSetSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async firmUpdatePreferencesRaw(requestParameters: FirmUpdatePreferencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling firmUpdatePreferences.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{id}/update-preferences`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FirmPreferencesToJSON(requestParameters.firmPreferences),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async firmUpdatePreferences(requestParameters: FirmUpdatePreferencesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.firmUpdatePreferencesRaw(requestParameters, initOverrides);
    }

}
