/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Languages {
    Unknown = 0,
    EnglishUS = 1,
    Spanish = 2
}

export function LanguagesFromJSON(json: any): Languages {
    return LanguagesFromJSONTyped(json, false);
}

export function LanguagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Languages {
    return json as Languages;
}

export function LanguagesToJSON(value?: Languages | null): any {
    return value as any;
}

