/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FirmNameStyle,
    FirmNameStyleFromJSON,
    FirmNameStyleFromJSONTyped,
    FirmNameStyleToJSON,
    Margins,
    MarginsFromJSON,
    MarginsFromJSONTyped,
    MarginsToJSON,
} from './';

/**
 * 
 * @export
 * @interface DocumentPreferences
 */
export interface DocumentPreferences {
    /**
     * 
     * @type {Margins}
     * @memberof DocumentPreferences
     */
    margins?: Margins;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPreferences
     */
    useVsInsteadOfV?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPreferences
     */
    boldAttorneyNames?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DocumentPreferences
     */
    fontSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentPreferences
     */
    footerFontSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentPreferences
     */
    firmNameFontSize?: number | null;
    /**
     * 
     * @type {FirmNameStyle}
     * @memberof DocumentPreferences
     */
    firmNameStyle?: FirmNameStyle;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPreferences
     */
    deptInCaption?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentPreferences
     */
    hideRequestsInResponses?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof DocumentPreferences
     */
    documentTemplates?: { [key: string]: string; } | null;
}

export function DocumentPreferencesFromJSON(json: any): DocumentPreferences {
    return DocumentPreferencesFromJSONTyped(json, false);
}

export function DocumentPreferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentPreferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'margins': !exists(json, 'margins') ? undefined : MarginsFromJSON(json['margins']),
        'useVsInsteadOfV': !exists(json, 'useVsInsteadOfV') ? undefined : json['useVsInsteadOfV'],
        'boldAttorneyNames': !exists(json, 'boldAttorneyNames') ? undefined : json['boldAttorneyNames'],
        'fontSize': !exists(json, 'fontSize') ? undefined : json['fontSize'],
        'footerFontSize': !exists(json, 'footerFontSize') ? undefined : json['footerFontSize'],
        'firmNameFontSize': !exists(json, 'firmNameFontSize') ? undefined : json['firmNameFontSize'],
        'firmNameStyle': !exists(json, 'firmNameStyle') ? undefined : FirmNameStyleFromJSON(json['firmNameStyle']),
        'deptInCaption': !exists(json, 'deptInCaption') ? undefined : json['deptInCaption'],
        'hideRequestsInResponses': !exists(json, 'hideRequestsInResponses') ? undefined : json['hideRequestsInResponses'],
        'documentTemplates': !exists(json, 'documentTemplates') ? undefined : json['documentTemplates'],
    };
}

export function DocumentPreferencesToJSON(value?: DocumentPreferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'margins': MarginsToJSON(value.margins),
        'useVsInsteadOfV': value.useVsInsteadOfV,
        'boldAttorneyNames': value.boldAttorneyNames,
        'fontSize': value.fontSize,
        'footerFontSize': value.footerFontSize,
        'firmNameFontSize': value.firmNameFontSize,
        'firmNameStyle': FirmNameStyleToJSON(value.firmNameStyle),
        'deptInCaption': value.deptInCaption,
        'hideRequestsInResponses': value.hideRequestsInResponses,
        'documentTemplates': value.documentTemplates,
    };
}


