import { DocumentSubType, MiscContent, MiscContentType, Role } from 'briefpoint-client';
import { CaliforniaId } from 'components/CaseManagement/AddEditCaseModal';
import CustomEditor from 'components/Editor/CustomEditor';
import { FormModal } from 'components/Modals';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Alert, Button, CloseButton, Form, Toast, ToastContainer } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { Prompt } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { classes } from 'utils';

interface Props {
  preliminaryStatements: MiscContent[];
  onUpdate: (update: MiscContent) => Promise<MiscContent>;
}

export default function PreliminaryStatement({ preliminaryStatements, onUpdate }: Props) {
  const [content, setContent] = useState('');
  const { user, firm, defaultJurisdiction } = useAuth()!;
  const [isSharedWithFirm, setIsSharedWithFirm] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [showDirtyConfirm, setShowDirtyConfirm] = useState(false);
  const [nextSubType, setNextSubType] = useState<DocumentSubType | undefined>();
  const [toastMessage, setToastMessage] = useState('');
  const [selectedStatement, setSelectedStatement] = useState<MiscContent | null>();
  const [selectedStatementSubType, setSelectedStatementSubType] = useState<DocumentSubType | undefined>();

  const canSetFirmStandard = user?.role?.includes(Role.FirmAdmin);

  let subTypes: { [key: number]: string } = {
    1: 'Interrogatories',
    2: 'Requests for Production',
    3: 'Requests for Admission',
  };
  
  if(CaliforniaId === defaultJurisdiction?.id){
    subTypes = {
      1: 'Special Interrogatories',
      2: 'Requests for Production',
      3: 'Requests for Admission',
      5: 'Form Interrogatories'
    };
  }

  useEffect(() => {
    if (selectedStatement && selectedStatement.content) {
      setContent(selectedStatement.content);
      setIsDirty(false);
      setIsSharedWithFirm(!!selectedStatement.sharedWithFirmId);
      return;
    }

    if (!selectedStatementSubType) {
      setSelectedStatement(preliminaryStatements.find(x => !x.documentSubType));
    }
  }, [selectedStatement, preliminaryStatements, selectedStatementSubType]);

  function handleChangeContent(val: string) {
    if (val !== content) {
      setContent(val);
      checkIsDirty();
    }
  }

  async function onSave() {
    if (selectedStatement) {
      const update = { ...selectedStatement, content: content, sharedWithFirmId: isSharedWithFirm ? firm!.id : undefined };
      const result = await onUpdate(update);
      setToastMessage('');
      setToastMessage('Preliminary Statement Saved!');
      setIsDirty(false);
      setSelectedStatement(result);
    }
  }

  function handleChangeSelectedSubType(subType: DocumentSubType | undefined) {
    if (!isDirty) {
      handleSetSelectedStatementSubType(subType);
    } else {
      setNextSubType(subType);
      setShowDirtyConfirm(true);
    }
  }

  function handleSetSelectedStatementSubType(subType: DocumentSubType | undefined) {
    const next = subType ?? nextSubType;
    const statement = preliminaryStatements.find(x => x.documentSubType === (next));
    setSelectedStatement(statement);
    setSelectedStatementSubType(next);
    setShowDirtyConfirm(false);
    setNextSubType(undefined);
  }

  function handleCreateOverrideClick() {
    const defaultText = preliminaryStatements.find(x => !x.documentSubType);

    setSelectedStatement({ content: defaultText!.content, documentType: defaultText!.documentType, type: MiscContentType.PreliminaryStatement, documentSubType: selectedStatementSubType! });
  }

  function handleChangeCancel() {
    setContent(selectedStatement?.content || '');
    setIsSharedWithFirm(!!selectedStatement?.sharedWithFirmId);
    setIsDirty(false);
  }

  function handleFirmStandardClick(checked: boolean) {
    setIsSharedWithFirm(checked);
    checkIsDirty();
  }

  function checkIsDirty() {
    const dirty = content !== selectedStatement?.content || isSharedWithFirm !== !selectedStatement?.sharedWithFirmId;
    setIsDirty(dirty);
  }

  return (
    <div className="step-container" style={{ gap: 0 }}>
      <ToastContainer className="p-3" position="top-end">
        <Toast onClose={() => setToastMessage('')} show={!!toastMessage} delay={2500} bg="success" autohide>
          <Toast.Body>
            <div className="d-flex text-white">
              <div>{toastMessage}</div>
              <CloseButton
                className="btn-close btn-close-white me-2 m-auto"
                onClick={() => setToastMessage('')}
              />
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Prompt
        when={isDirty}
        message={location =>
          `You have unsaved changes, continue and lose them?`
        }
      />
      <div className="border-end" style={{ width: 250 }}>
        {
          <div
            key={'default'}
            onClick={() => {
              handleChangeSelectedSubType(undefined);
            }}
            className={classes('library-objection', {
              'library-objection-selected': !selectedStatementSubType,
            })}
          >
            Default
          </div>
        }
        {Object.keys(DocumentSubType)
          .map((o) => {
            const i = Number(o);
            const t = subTypes[i as keyof typeof subTypes];

            return t && (
              <div
                key={o}
                onClick={() => {
                  handleChangeSelectedSubType(i);
                }}
                className={classes('library-objection', {
                  'library-objection-selected': i === selectedStatementSubType,
                })}
              >
                {t}
              </div>);
          })}
      </div>
      <div className="flex-grow-1 ms-5">
        {selectedStatement ? (
          <>
            {!canSetFirmStandard && !!selectedStatement.sharedWithFirmId &&
              <Alert variant='info'>{'This Preliminary Statement is set as your Firm\'s Standard, contact your Firm Admin if you need to make changes.'}</Alert>}

            <Form.Group className="mb-4">
              <Form.Label>Content</Form.Label>
              <CustomEditor 
                handleChange={handleChangeContent} 
                editorContent={content}
                isDisabled={!canSetFirmStandard && !!selectedStatement.sharedWithFirmId}
              />
              {canSetFirmStandard && <Form.Check
                id={'sharedwithfirm'}
                type={'checkbox'}
                label="Use as Firm Standard"
                checked={isSharedWithFirm}
                onChange={(e) => handleFirmStandardClick(e.target.checked)}
              />}
              <FormModal
                title={'Abandon changes?'}
                cancelText="Cancel"
                confirmText="Yes, abandon"
                show={showDirtyConfirm}
                onClose={() => setShowDirtyConfirm(false)}
                onConfirm={() => handleSetSelectedStatementSubType(undefined)}
              >
                <div>Are you sure you want to abandon your saved changes?</div>
              </FormModal>
            </Form.Group>
            <p className={'placeholder-link'}><InfoCircle className="mr-2" style={{ marginRight: 10 }} /><Link to="/how-to/#using_placeholders" target={'_blank'}>Learn how to customize your content with Placeholders.</Link></p>
            <div className="d-flex">
              <Button variant="primary" onClick={onSave} disabled={!isDirty && !!selectedStatement.id}>
                Save
              </Button>
              <Button variant="link" onClick={handleChangeCancel} disabled={!isDirty && !!selectedStatement.id}>
                Cancel
              </Button>
            </div>
          </>) : <div>
          <p>This Document Type will use the Default Preliminary Statement.</p>
          <Button onClick={handleCreateOverrideClick}>Create New</Button>
        </div>
        }
      </div>
    </div>
  );
}
