/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Client,
    ClientFromJSON,
    ClientToJSON,
    ExternalClientIEnumerableExternalPartnerResponse,
    ExternalClientIEnumerableExternalPartnerResponseFromJSON,
    ExternalClientIEnumerableExternalPartnerResponseToJSON,
    ExternalIntegration,
    ExternalIntegrationFromJSON,
    ExternalIntegrationToJSON,
} from '../models';

export interface ClientDeleteRequest {
    firmId: string;
    id: string;
}

export interface ClientExternalIntegrationRequest {
    firmId: string;
    id: string;
    externalIntegration?: ExternalIntegration;
}

export interface ClientGetRequest {
    firmId: string;
    id: string;
}

export interface ClientGetAllRequest {
    firmId: string;
    usersClientsOnly?: boolean;
    includeArchived?: boolean;
}

export interface ClientGetExternalRequest {
    firmId: string;
    externalCaseId?: string;
}

export interface ClientPostRequest {
    firmId: string;
    client?: Client;
}

export interface ClientPutRequest {
    firmId: string;
    id: string;
    client?: Client;
}

/**
 * 
 */
export class ClientApi extends runtime.BaseAPI {

    /**
     */
    async clientDeleteRaw(requestParameters: ClientDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling clientDelete.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Client/{id}`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientDelete(requestParameters: ClientDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.clientDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async clientExternalIntegrationRaw(requestParameters: ClientExternalIntegrationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling clientExternalIntegration.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientExternalIntegration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Client/{id}/external-integration`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalIntegrationToJSON(requestParameters.externalIntegration),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientExternalIntegration(requestParameters: ClientExternalIntegrationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.clientExternalIntegrationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async clientGetRaw(requestParameters: ClientGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Client>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling clientGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Client/{id}`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientFromJSON(jsonValue));
    }

    /**
     */
    async clientGet(requestParameters: ClientGetRequest, initOverrides?: RequestInit): Promise<Client> {
        const response = await this.clientGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientGetAllRaw(requestParameters: ClientGetAllRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Client>>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling clientGetAll.');
        }

        const queryParameters: any = {};

        if (requestParameters.usersClientsOnly !== undefined) {
            queryParameters['usersClientsOnly'] = requestParameters.usersClientsOnly;
        }

        if (requestParameters.includeArchived !== undefined) {
            queryParameters['includeArchived'] = requestParameters.includeArchived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Client`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientFromJSON));
    }

    /**
     */
    async clientGetAll(requestParameters: ClientGetAllRequest, initOverrides?: RequestInit): Promise<Array<Client>> {
        const response = await this.clientGetAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientGetExternalRaw(requestParameters: ClientGetExternalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalClientIEnumerableExternalPartnerResponse>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling clientGetExternal.');
        }

        const queryParameters: any = {};

        if (requestParameters.externalCaseId !== undefined) {
            queryParameters['externalCaseId'] = requestParameters.externalCaseId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Client/external`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalClientIEnumerableExternalPartnerResponseFromJSON(jsonValue));
    }

    /**
     */
    async clientGetExternal(requestParameters: ClientGetExternalRequest, initOverrides?: RequestInit): Promise<ExternalClientIEnumerableExternalPartnerResponse> {
        const response = await this.clientGetExternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientPostRaw(requestParameters: ClientPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Client>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling clientPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Client`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClientToJSON(requestParameters.client),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientFromJSON(jsonValue));
    }

    /**
     */
    async clientPost(requestParameters: ClientPostRequest, initOverrides?: RequestInit): Promise<Client> {
        const response = await this.clientPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clientPutRaw(requestParameters: ClientPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling clientPut.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Client/{id}`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ClientToJSON(requestParameters.client),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientPut(requestParameters: ClientPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.clientPutRaw(requestParameters, initOverrides);
    }

}
