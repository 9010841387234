import { MiscContent, ObjectionVariant, Role, User } from "briefpoint-client/models";

export function findDefaultVariant(variants: ObjectionVariant[], userId?: string) {
  // first: if there is a variant owned by this user and it's set to default
  let found = variants.find(v => v.ownerId === userId && v.isDefault && !v.sharedWithFirmId);

  // or if there's a firm default
  if (!found) {
    found = variants.find(v => v.sharedWithFirmId && v.isDefault);
  }

  // otherwise, find the earliest firm content
  if (!found) {
    const firms = variants.filter(v => v.sharedWithFirmId);
    if (firms.length) {
      found = firms.reduce((v1, v2) => {
        return v1.id < v2.id ? v1 : v2;
      });
    }
  }

  if (!found) {
    found = variants.reduce((v1, v2) => {
      return v1.id < v2.id ? v1 : v2;
    });
  }

  return found;
}

export function isDeletable(variant: ObjectionVariant, variants: ObjectionVariant[] | undefined, user: User) {
  return userCanEditVariant(variant, user) && !variant.isDefault && !!variants?.filter(v => v.objectionId === variant.objectionId && v.id !== variant.id).length;
}

export function userCanEditVariant(variant: ObjectionVariant, user: User) {
  return !variant || !variant.ownerId || variant.ownerId === user.id || (variant.sharedWithFirmId && user.role?.includes(Role.FirmAdmin));
}

export function userCanEditContent(content: MiscContent, user: User) {
  return !content || !content.ownerId || content.ownerId === user.id || (content.sharedWithFirmId && user.role?.includes(Role.FirmAdmin));
}