import useDocumentDetail from 'hooks/useDocumentDetail';
import { Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PdfViewer from '../../../components/Wizard/PdfViewer';
import FormInterrogatoriesList, { FormIntLi } from './FormInterrogatoriesList';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import React, { useState } from 'react';

export default function FormInterrogatoriesSelect({
  formIntData, 
  setFormIntData
}: {
  formIntData: FormIntLi[];
  setFormIntData: React.Dispatch<React.SetStateAction<FormIntLi[]>>;
}) {

  const { documentId } = useParams<{ documentId: string }>();
  const [document, , , , addEditInterrogatory, deleteInterrogatory] = useDocumentDetail(documentId);

  const onUpdateInterrogatory = async (updatedInterrogatory: FormIntLi) => {
    const updatedData = formIntData.map(item =>
      item.id === updatedInterrogatory.id ? updatedInterrogatory : item
    );
    const existingRog = document?.interrogatories.find(frog => frog.text === updatedInterrogatory.id)

    if (updatedInterrogatory.selected && updatedInterrogatory.action === 'cb' && !existingRog) {
        let num = 0;
        for (let i = 0; i < updatedData.length; i++) {
          if (updatedData[i].id === updatedInterrogatory.id) break;
          if (updatedData[i].selected) num++;
        }
        await addEditInterrogatory(num, updatedInterrogatory.id);
    }
    else if(updatedInterrogatory.action === 'cb' && existingRog && !updatedInterrogatory.selected) {
        await deleteInterrogatory(existingRog.id);
    }
    else if(updatedInterrogatory.action === 'confirm'){
      const nxt =  formIntData.find(frog => frog.highlighted &&
         Number(`${frog.section}.${frog.number}`) > Number(`${updatedInterrogatory.section}.${updatedInterrogatory.number}`))
         if(nxt){
          scrollToView(`${nxt.section}.${nxt.number}`);
         }
    }
    setFormIntData(updatedData);
  };

  const[linkRef, setLinkRefs]=useState<{[key: string]: React.RefObject<HTMLDivElement>;}>({});

  const updateLinkRefs = (newRefs: {[key: string]: React.RefObject<HTMLDivElement>}) => {
    setLinkRefs(newRefs);
  };

  const onClick = ( target:string )=>{
    scrollToView(target);
  };

  const scrollToView = ( target:string )=>{
    const ref = linkRef[target];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <div className="mb-4">
      <div className="d-flex">
      <Col xs="7">
          <h2>Source Document</h2>
          <div className="document-preview-container" style={{marginRight: '7px'}}>
            <PdfViewer documentId={documentId} height={800} width={365} />
          </div>
        </Col>
        <Col xs="5">
          <div className="d-flex justify-content-between">
            <h2>Select Form Interrogatories</h2> 
        </div>
        <div className='frogs-bounds'>
          <div className='action-required'>
            <ExclamationCircleFill className='alert-icon'/> 
            <div className='action-text'><b>Action Required</b> 
              <p>Occasionally we're unable to determine if a question is selected or not. Please review each question highlighted below, and confirm the selection state is accurate.</p>  
              <p style={{marginBottom : '0px'}}>Quick links to questions: 
              {formIntData.filter(d => d.highlighted).map((frog)=>(
                <span  key={`${frog.section}.${frog.number}`} className='sp-link' onClick={()=>onClick(`${frog.section}.${frog.number}`)}> <u>{`${frog.section}.${frog.number}`}</u>,</span>
              ))}
              </p>
            </div>
          </div>
          <div className="document-preview-container list-column frogs-list">
            <FormInterrogatoriesList 
            interrogatoriesList={formIntData} 
            onUpdateInterrogatory={onUpdateInterrogatory}
            setLinkRefs={updateLinkRefs}
           />
          </div>
          </div>
        </Col>
      </div>
    </div>
  );
}
