/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ObjectionType {
    Standard = 0,
    Response = 1,
    NoResponse = 2,
    Other = 3
}

export function ObjectionTypeFromJSON(json: any): ObjectionType {
    return ObjectionTypeFromJSONTyped(json, false);
}

export function ObjectionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectionType {
    return json as ObjectionType;
}

export function ObjectionTypeToJSON(value?: ObjectionType | null): any {
    return value as any;
}

