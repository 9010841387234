import { Button, Form } from 'react-bootstrap';
import { GripVertical, Plus, X } from 'react-bootstrap-icons';
import { DocumentSubType, Interrogatory } from 'briefpoint-client';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { classes } from 'utils';
import { FormModal } from 'components/Modals/Modal';
import { GetDocumentSubTypeRequestName } from "../../../services/DocumentService";

export default function InterrogatoriesList({
  interrogatoriesList,
  interrogatoryId,
  onAdd,
  onEdit,
  onDelete,
  documentSubType,
  startingNumber,
}: {
  interrogatoriesList: Interrogatory[];
  interrogatoryId: string;
  onAdd: (index: number, text: string) => void;
  onEdit: (id: number, index: number, text?: string) => void;
  onDelete: (interrogatoryId: number) => void;
  documentSubType: DocumentSubType | undefined;
  startingNumber: number
}) {
  useEffect(() => {
    setList(interrogatoriesList);
  }, [interrogatoriesList]);

  const [list, setList] = useState(interrogatoriesList);
  const [confirmRemoveId, setConfirmRemoveId] = useState<number | null>(null);

  const [newItems, setNewItems] = useState<Map<number, number>>(new Map());

  async function handleConfirmDelete() {
    if (confirmRemoveId !== null) {
      const interrogatory = list.find((i) => i.id === confirmRemoveId);
      if (interrogatory) {
        await onDelete(confirmRemoveId);
        const count = newItems.get(interrogatory.number) || 0;
        if (count > 0) {
          setNewItems(new Map(newItems.set(interrogatory.number, 0)));
          setNewItems(new Map(newItems.set(interrogatory.number - 1, count)));
        }
        setConfirmRemoveId(null);
      }
    }
  }

  function getEmptyInterrogatories(index: number) {
    const count = newItems.get(index);
    const emptyInterrogatories = [];
    if (count) {
      for (let i = 0; i < count; i++) {
        emptyInterrogatories.push({ id: `${index}-${i}`, empty: true, parentNumber: index });
      }
    }
    return emptyInterrogatories;
  }

  function removeNewItem(number: number, addNew = false) {
    const count = newItems.get(number) || 0;
    if (addNew) {
      if (count > 0) {
        setNewItems(new Map(newItems.set(number, 0)));
        setNewItems(new Map(newItems.set(number + 1, count - 1)));
      }
    }
    else {
      if (count > 0) {
        setNewItems(new Map(newItems.set(number, count - 1)));
      }
    }
  }

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    const removedId = removed.id;
    var orderedItemList: any[] = [];
    var newIndex = 0;
    result.splice(endIndex, 0, removed);
    var index = 0;
    for (const item of result) {
      if (!item.empty) {
        item.number = index + 1;
        index++;
        if (item.id === removedId) {
          newIndex = item.number;
        }
        orderedItemList.push(item);
      }
    }
    // result.forEach((item, index) => (item.number = index + 1));
    return { orderedList: orderedItemList, newIndex: newIndex };
  };

  const onDragEnd = (res: DropResult) => {
    const { draggableId, destination, source } = res;
    if (!destination) return;
    if (destination.index === source.index) return;

    const sourceItem = combinedList[source.index];
    const destinationItem = combinedList[destination.index];
    var parentNumber = destinationItem.empty ? destinationItem.parentNumber : destinationItem.number;

    if (sourceItem.empty) {
      if (sourceItem.parentNumber !== parentNumber || !destinationItem.empty) {
        if (destination.index < source.index && !destinationItem.empty) {
          parentNumber = parentNumber - 1;
        }
        removeNewItem(sourceItem.parentNumber);
        setNewItems(new Map(newItems.set(parentNumber, (newItems.get(parentNumber) || 0) + 1)));
      }
    } else {
      const newMap = newItems;
      var setNew = false;
      for (var i = 0; i < combinedList.length; i++) {
        if (combinedList[i].empty) {
          setNew = true;
          if (source.index > destination.index) {
            if (i >= destination.index && i < source.index) {
              newMap.set(combinedList[i].parentNumber, (newMap.get(combinedList[i].parentNumber) ?? 0) - 1);
              newMap.set(
                combinedList[i].parentNumber + 1,
                (newMap.get(combinedList[i].parentNumber + 1) ?? 0) + 1
              );
            }
          } else {
            if (i > source.index && i <= destination.index) {
              newMap.set(combinedList[i].parentNumber, (newMap.get(combinedList[i].parentNumber) ?? 0) - 1);
              newMap.set(
                combinedList[i].parentNumber - 1,
                (newMap.get(combinedList[i].parentNumber - 1) ?? 0) + 1
              );
            }
          }
        }
        if (sourceItem.number !== parentNumber) {
          const { orderedList, newIndex } = reorder(combinedList, source.index, destination.index);
          setList(orderedList);
          if (setNew) {
            setNewItems(newMap);
          }
          onEdit(parseInt(draggableId), newIndex);
        } else {
          if (setNew) {
            setNewItems(new Map(newMap));
          }
        }
      }
    }
  };

  var combinedList: any[] = getEmptyInterrogatories(0);

  for (const interrogatory of list) {
    combinedList.push(interrogatory);
    combinedList = [...combinedList, ...getEmptyInterrogatories(interrogatory.number)];
  }
  return (
    <>
      {combinedList.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="interrogatories-list">
            {(provided) => (
              <div className="interrogatories-edit mt-2" {...provided.droppableProps} ref={provided.innerRef}>
                {combinedList.map((interrogatory, index) => (
                  <Draggable key={interrogatory.id} draggableId={`${interrogatory.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className={classes('selection-item', {
                          isDragging: snapshot.isDragging,
                        })}
                      >
                        {interrogatory.empty ? (
                          <InterrogatoryAdd
                            onAdd={async (text: string) => {
                              await onAdd(interrogatory.parentNumber, text);
                              removeNewItem(interrogatory.parentNumber, true);
                            }}
                            onDelete={() => removeNewItem(interrogatory.parentNumber)}
                          />
                        ) : (
                          <InterrogatoryEdit
                            interrogatory={interrogatory}
                            startingId={interrogatoryId === interrogatory.id.toString()}
                            onAdd={() => {
                              const count = newItems.get(interrogatory.number) || 0;
                              setNewItems(new Map(newItems.set(interrogatory.number, count + 1)));
                            }}
                            onEdit={onEdit}
                            onDelete={() => {
                              setConfirmRemoveId(interrogatory.id);
                            }}
                            documentSubType={documentSubType}
                            startingNumber={startingNumber}
                          />
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Button
          variant="outline-primary"
          onClick={() => {
            setNewItems(new Map(newItems.set(0, 1)));
          }}
        >
          <Plus></Plus>Add new
        </Button>
      )}
      <FormModal
        title={'Are you sure?'}
        cancelText="Cancel"
        confirmText="Yes, remove"
        show={confirmRemoveId != null}
        onClose={() => setConfirmRemoveId(null)}
        onConfirm={handleConfirmDelete}
      >
        <div>Are you sure you want to remove this interrogatory? This cannot be undone.</div>
      </FormModal>
    </>
  );
}

export function InterrogatoryEdit({
  interrogatory,
  startingId,
  onAdd,
  onEdit,
  onDelete,
  documentSubType,
  startingNumber
}: {
  interrogatory: Interrogatory;
  startingId: boolean;
  onAdd: () => void;
  onEdit: (id: number, index: number, text?: string) => void;
  onDelete: () => void;
  documentSubType: DocumentSubType | undefined;
  startingNumber: number
}) {
  const [content, setContent] = useState(interrogatory.text);
  const [hoverEffect, setHoverEffect] = useState(false);

  const [isHovering, setIsHovering] = useState(false);

  const editingRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (startingId && editingRef.current) {
      setTimeout(() => {
        setHoverEffect(false);
      }, 1000);
      setHoverEffect(true);
      editingRef.current.focus();
    }
  }, [editingRef, startingId]);

  function handleEditingBlur() {
    if (content !== interrogatory.text && content !== '') {
      onEdit(interrogatory.id, interrogatory.number, content);
    }
  }

  function handleOnKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Escape') {
      setContent(interrogatory.text);
      editingRef.current?.blur();
    } else if (e.key === 'Enter') {
      e.preventDefault();
      editingRef.current?.blur();
    }
  }

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div
      className={classNames('mb-3 interrogatory-item', { 'selected': hoverEffect })}
      key={interrogatory.id}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className="main-item">
        <div className="d-flex justify-content-between">
          <div className="interrogatory-title">{documentSubType ? GetDocumentSubTypeRequestName(documentSubType, '') : "Question"} {interrogatory.number - 1 + startingNumber}</div>
          <div>
            <X className="remove-item" onClick={onDelete} />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <GripVertical className="drag-selection-item" />
          <Form.Control
            as="textarea"
            value={content}
            ref={editingRef}
            style={{ height: '100px' }}
            onChange={(e) => setContent(e.target.value)}
            onBlur={handleEditingBlur}
            onKeyDown={handleOnKeyDown}
          />
        </div>
      </div>
      <div className={classNames('add-item-wrapper', { 'hide-item': !isHovering })}>
        <div className="border-line">
          <div className="button-wrapper">
            <Button variant="outline-primary" onClick={onAdd}>
              <Plus></Plus>Add new
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function InterrogatoryAdd({
  onAdd,
  onDelete,
}: {
  onAdd: (text: string) => void;
  onDelete: () => void;
}) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [content, setContent] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Reset state on rebuild.  Not doing this causes other list items to take over
    // the objects ID.  Doing this outside a useEffect causes an "unmounted" error
    setContent('');
    setIsSaving(false);
  }, [onAdd]);

  async function handleBlur() {
    if (content !== '') {
      setIsSaving(true);
      await onAdd(content);
    }
  }

  function handleOnKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      e.preventDefault();
      ref.current?.blur();
    }
  }

  return (
    <div className="interrogatory-item pb-3">
      <div className="main-item">
        <div className="d-flex justify-content-between">
          <div className="interrogatory-title">Adding new item</div>
          <div>
            <X className="remove-item" onClick={onDelete} />
          </div>
        </div>
        <div className="d-flex align-items-center">
          <GripVertical className="drag-selection-item" />
          <Form.Control
            className="new-item-form"
            as="textarea"
            value={content}
            ref={ref}
            onChange={(e) => setContent(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleOnKeyDown}
            disabled={isSaving}
          />
        </div>
      </div>
    </div>
  );
}
