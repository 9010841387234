import { Plugin, PluginKey } from '@tiptap/pm/state'
import { Fragment } from 'prosemirror-model'
import { Schema, Slice } from "@tiptap/pm/model";
import { Extension } from "@tiptap/core";

//this is a custom plugin to filter data pasted into tiptap and flatten pasted list
const FlattenListsPluginKey = new PluginKey('FlattenAndFilterOnPaste')

//only these types will be allowed to be pasted in
const allowedTypes = ['listItem', 'orderedList', 'bulletList', 'paragraph', 'text', 'hardBreak'];
//only these types will keep their attributes
const keepAttributes = ['paragraph', 'text'];

//this is a recursive function to filter the paste data before it is applied to the document
const filterPasteData = (
  fragment: Fragment,
  schema: Schema
): Fragment => {
  const nodes: any[] = [];

  fragment.forEach((node) => {
    if (allowedTypes.includes(node.type.name)) {
      const shouldKeepAttributes = keepAttributes.includes(node.type.name);

      if (node.isLeaf) {
        if (shouldKeepAttributes) {
          nodes.push(node);
        }
        else {
          nodes.push(node.type.create({}));
        }

      } else {
        const filtered = filterPasteData(node.content, schema);
        if (shouldKeepAttributes) {
          nodes.push(node.type.create(node.attrs, filtered));
        }
        else {
          nodes.push(node.type.create({}, filtered));
        }
      }
    }
  });

  return Fragment.from(nodes);
};

//this is a recursive function to flatten a list into only one layer deep
const flattenListItems = (
  fragment: Fragment,
  schema: Schema,
  isTopLevel: boolean = true,
  isInLi: boolean = false
): Fragment => {
  const nodes: any[] = []
  fragment.forEach((node) => {

    //handle list
    if (node.type.name === 'bulletList' || node.type.name === 'orderedList') {
      //no nested list only add if its the top level list
      if (isTopLevel) {
        //create the list and add its children
        const flattenedContent = flattenListItems(node.content, schema, false, isInLi)
        nodes.push(node.type.create(node.attrs, flattenedContent))
      } else {
        //ignore the list and add its children
        flattenListItems(node.content, schema, false, isInLi).forEach(child => nodes.push(child))
      }
    }

    else if (node.type.name === 'listItem') {
      //list items are created when we reach the leaf to prevent nesting
      //ignore this one and set the list item flag
      flattenListItems(node.content, schema, isTopLevel, true).forEach(child => nodes.push(child))
    }

    //keep searching through remaining nodes
    else if (!node.isLeaf) {
      //if we are not in a list add anything we find
      if (!isInLi) {
        const flattened = flattenListItems(node.content, schema, isTopLevel, isInLi)
        nodes.push(node.type.create(node.attrs, flattened))
      }
      //if we are in li return the leafs that are created as li
      else {
        flattenListItems(node.content, schema, isTopLevel, isInLi).forEach(child => nodes.push(child))
      }
    }

    //add any leafs
    else {
      //create a list item to use
      if (isInLi) {
        const listItemType = schema.nodes.listItem
        const paragraphType = schema.nodes.paragraph
        const paragraphNode = paragraphType.create(null, Fragment.from(node))
        const listItemNode = listItemType.create(null, Fragment.from(paragraphNode))
        nodes.push(listItemNode)
      }
      //return whatever we find
      else {
        nodes.push(node)
      }
    }
  })
  return Fragment.from(nodes)
}

export const FlattenAndFilterOnPaste = Extension.create({
  name: 'FlattenAndFilterOnPaste',
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: FlattenListsPluginKey,
        props: {
          handlePaste: (view, event, slice) => {

            const { state, dispatch } = view
            const schema = state.schema
            const filteredData = filterPasteData(slice.content, schema);
            const newSlice = new Slice(Fragment.from(filteredData), slice.openStart, slice.openEnd);
            let tr = state.tr.replaceSelection(newSlice);
            const flattenedDoc = flattenListItems(tr.doc.content, schema);
            tr = tr.replaceWith(0, tr.doc.content.size, flattenedDoc);
            dispatch(tr);

            return true;
          },
        },
      }),
    ]
  },
})