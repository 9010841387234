/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CaseMetadata,
    CaseMetadataFromJSON,
    CaseMetadataFromJSONTyped,
    CaseMetadataToJSON,
    DocumentSubType,
    DocumentSubTypeFromJSON,
    DocumentSubTypeFromJSONTyped,
    DocumentSubTypeToJSON,
    DocumentType,
    DocumentTypeFromJSON,
    DocumentTypeFromJSONTyped,
    DocumentTypeToJSON,
    PropoundingParty,
    PropoundingPartyFromJSON,
    PropoundingPartyFromJSONTyped,
    PropoundingPartyToJSON,
    Respondent,
    RespondentFromJSON,
    RespondentFromJSONTyped,
    RespondentToJSON,
    ServiceManner,
    ServiceMannerFromJSON,
    ServiceMannerFromJSONTyped,
    ServiceMannerToJSON,
} from './';

/**
 * 
 * @export
 * @interface DocumentInfo
 */
export interface DocumentInfo {
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    jurisdiction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    venue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    caseNumber?: string | null;
    /**
     * 
     * @type {DocumentType}
     * @memberof DocumentInfo
     */
    documentType: DocumentType;
    /**
     * 
     * @type {DocumentSubType}
     * @memberof DocumentInfo
     */
    documentSubType: DocumentSubType;
    /**
     * 
     * @type {PropoundingParty}
     * @memberof DocumentInfo
     */
    propoundingParty: PropoundingParty;
    /**
     * 
     * @type {PropoundingParty}
     * @memberof DocumentInfo
     */
    respondingParty?: PropoundingParty;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    otherPartyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    propoundingPartyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    respondingPartyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentInfo
     */
    setNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentInfo
     */
    startingNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentInfo
     */
    interrogatoryCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentInfo
     */
    firstMismatchedRequest?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentInfo
     */
    requestsStartOnPg?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentInfo
     */
    requestsEndOnPg?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    contactInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    defendants?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    plaintiffs?: string | null;
    /**
     * 
     * @type {Respondent}
     * @memberof DocumentInfo
     */
    respondent?: Respondent;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    respondentCustom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    judge?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocumentInfo
     */
    parsingProgress?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    trialDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    filedDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfo
     */
    isCrossComplaint?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    dateOfService?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    formIntType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    dueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    dueDateOverridden?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DocumentInfo
     */
    lastViewDate?: Date | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentInfo
     */
    missingFormInts?: Array<string> | null;
    /**
     * 
     * @type {ServiceManner}
     * @memberof DocumentInfo
     */
    serviceManner?: ServiceManner;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentInfo
     */
    shouldFindSpecificRequestType?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfo
     */
    externalOutputId?: string | null;
    /**
     * 
     * @type {CaseMetadata}
     * @memberof DocumentInfo
     */
    caseMetadata?: CaseMetadata;
}

export function DocumentInfoFromJSON(json: any): DocumentInfo {
    return DocumentInfoFromJSONTyped(json, false);
}

export function DocumentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jurisdiction': !exists(json, 'jurisdiction') ? undefined : json['jurisdiction'],
        'venue': !exists(json, 'venue') ? undefined : json['venue'],
        'caseNumber': !exists(json, 'caseNumber') ? undefined : json['caseNumber'],
        'documentType': DocumentTypeFromJSON(json['documentType']),
        'documentSubType': DocumentSubTypeFromJSON(json['documentSubType']),
        'propoundingParty': PropoundingPartyFromJSON(json['propoundingParty']),
        'respondingParty': !exists(json, 'respondingParty') ? undefined : PropoundingPartyFromJSON(json['respondingParty']),
        'otherPartyId': !exists(json, 'otherPartyId') ? undefined : json['otherPartyId'],
        'propoundingPartyName': !exists(json, 'propoundingPartyName') ? undefined : json['propoundingPartyName'],
        'respondingPartyName': !exists(json, 'respondingPartyName') ? undefined : json['respondingPartyName'],
        'setNumber': !exists(json, 'setNumber') ? undefined : json['setNumber'],
        'startingNumber': !exists(json, 'startingNumber') ? undefined : json['startingNumber'],
        'interrogatoryCount': !exists(json, 'interrogatoryCount') ? undefined : json['interrogatoryCount'],
        'firstMismatchedRequest': !exists(json, 'firstMismatchedRequest') ? undefined : json['firstMismatchedRequest'],
        'requestsStartOnPg': !exists(json, 'requestsStartOnPg') ? undefined : json['requestsStartOnPg'],
        'requestsEndOnPg': !exists(json, 'requestsEndOnPg') ? undefined : json['requestsEndOnPg'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'contactInfo': !exists(json, 'contactInfo') ? undefined : json['contactInfo'],
        'defendants': !exists(json, 'defendants') ? undefined : json['defendants'],
        'plaintiffs': !exists(json, 'plaintiffs') ? undefined : json['plaintiffs'],
        'respondent': !exists(json, 'respondent') ? undefined : RespondentFromJSON(json['respondent']),
        'respondentCustom': !exists(json, 'respondentCustom') ? undefined : json['respondentCustom'],
        'judge': !exists(json, 'judge') ? undefined : json['judge'],
        'parsingProgress': !exists(json, 'parsingProgress') ? undefined : json['parsingProgress'],
        'trialDate': !exists(json, 'trialDate') ? undefined : json['trialDate'],
        'filedDate': !exists(json, 'filedDate') ? undefined : json['filedDate'],
        'isCrossComplaint': !exists(json, 'isCrossComplaint') ? undefined : json['isCrossComplaint'],
        'dateOfService': !exists(json, 'dateOfService') ? undefined : json['dateOfService'],
        'formIntType': !exists(json, 'formIntType') ? undefined : json['formIntType'],
        'dueDate': !exists(json, 'dueDate') ? undefined : json['dueDate'],
        'dueDateOverridden': !exists(json, 'dueDateOverridden') ? undefined : json['dueDateOverridden'],
        'lastViewDate': !exists(json, 'lastViewDate') ? undefined : (json['lastViewDate'] === null ? null : new Date(json['lastViewDate'])),
        'missingFormInts': !exists(json, 'missingFormInts') ? undefined : json['missingFormInts'],
        'serviceManner': !exists(json, 'serviceManner') ? undefined : ServiceMannerFromJSON(json['serviceManner']),
        'shouldFindSpecificRequestType': !exists(json, 'shouldFindSpecificRequestType') ? undefined : json['shouldFindSpecificRequestType'],
        'externalOutputId': !exists(json, 'externalOutputId') ? undefined : json['externalOutputId'],
        'caseMetadata': !exists(json, 'caseMetadata') ? undefined : CaseMetadataFromJSON(json['caseMetadata']),
    };
}

export function DocumentInfoToJSON(value?: DocumentInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jurisdiction': value.jurisdiction,
        'venue': value.venue,
        'caseNumber': value.caseNumber,
        'documentType': DocumentTypeToJSON(value.documentType),
        'documentSubType': DocumentSubTypeToJSON(value.documentSubType),
        'propoundingParty': PropoundingPartyToJSON(value.propoundingParty),
        'respondingParty': PropoundingPartyToJSON(value.respondingParty),
        'otherPartyId': value.otherPartyId,
        'propoundingPartyName': value.propoundingPartyName,
        'respondingPartyName': value.respondingPartyName,
        'setNumber': value.setNumber,
        'startingNumber': value.startingNumber,
        'interrogatoryCount': value.interrogatoryCount,
        'firstMismatchedRequest': value.firstMismatchedRequest,
        'requestsStartOnPg': value.requestsStartOnPg,
        'requestsEndOnPg': value.requestsEndOnPg,
        'title': value.title,
        'contactInfo': value.contactInfo,
        'defendants': value.defendants,
        'plaintiffs': value.plaintiffs,
        'respondent': RespondentToJSON(value.respondent),
        'respondentCustom': value.respondentCustom,
        'judge': value.judge,
        'parsingProgress': value.parsingProgress,
        'trialDate': value.trialDate,
        'filedDate': value.filedDate,
        'isCrossComplaint': value.isCrossComplaint,
        'dateOfService': value.dateOfService,
        'formIntType': value.formIntType,
        'dueDate': value.dueDate,
        'dueDateOverridden': value.dueDateOverridden,
        'lastViewDate': value.lastViewDate === undefined ? undefined : (value.lastViewDate === null ? null : value.lastViewDate.toISOString()),
        'missingFormInts': value.missingFormInts,
        'serviceManner': ServiceMannerToJSON(value.serviceManner),
        'shouldFindSpecificRequestType': value.shouldFindSpecificRequestType,
        'externalOutputId': value.externalOutputId,
        'caseMetadata': CaseMetadataToJSON(value.caseMetadata),
    };
}


