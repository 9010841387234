import { Dispatch, SetStateAction, useMemo } from 'react';
import { MenuPlacement, OptionsType } from 'react-select';
import CreatableSelect from 'react-select/creatable';

export interface TagOption {
  label: string;
  value: number | null;
}

interface Props {
  availableTags?: Map<number, string>;
  addingTags: Map<number, string>;
  setAddingTags: Dispatch<SetStateAction<Map<number, string>>>;
  extraInfo?: Map<number, any>;
  className?: string;
  selected?: number[];
  onChange: (value: OptionsType<TagOption>) => void;
  id: string;
  label: string;
  disabled?: boolean;
  menuPlacement?: MenuPlacement | undefined
}

export default function SelectCreate({
  availableTags,
  addingTags,
  setAddingTags,
  extraInfo,
  className,
  selected,
  onChange,
  id,
  label,
  disabled,
  menuPlacement
}: Props) {
  const allTags = useMemo(() => availableTags && new Map([...availableTags, ...addingTags]), [availableTags, addingTags]);
  function buildItem(index: number, label: string, ex?: Map<number, any>) {
    const extra = ex && ex.get(index);
    return { value: index, label: `${label}${extra ? ` [${extra}]` : ''}` }
  }

  const transformedOptions = useMemo(() => {
    const options = allTags && Array.from(allTags.entries()).map(([index, t]) => { return buildItem(index, t, extraInfo) });

    return options?.sort((a, b) => {
      const aHasExtra = a.label.endsWith(']');
      const bHasExtra = b.label.endsWith(']');

      if (aHasExtra && !bHasExtra) {
        return -1;
      }

      if (bHasExtra && !aHasExtra) {
        return 1;
      }

      return a.label.localeCompare(b.label);
    });
  }, [allTags, extraInfo]);

  const selectedOptions = useMemo(() => {
    if (allTags) {
      const ts = selected?.map(s => { const tag = allTags.get(s); return { label: tag, value: s } as TagOption });
      return ts ?? [];
    }
    return [];
  }, [allTags, selected]);

  const handleCreate = (inputValue: string) => {
    const existingAdd = Array.from(addingTags.keys()).reduce<number>((prev, n) => n < prev ? n : prev, 0);
    const newOption = { label: inputValue, value: existingAdd - 1 };
    setAddingTags(q => {
      const update = new Map([...q]);
      update.set(newOption.value, newOption.label);

      return update;
    });
    onChange(selectedOptions.concat(newOption));
  };

  return (
    <>
      <label htmlFor={id} className='form-label padding' >{label}</label>
      <CreatableSelect
        id={id}
        isDisabled={disabled}
        isLoading={!availableTags}
        className={className}
        classNamePrefix="briefpoint-tag-select"
        options={transformedOptions}
        value={selectedOptions}
        onChange={onChange}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#074f6b',
            primary25: '#efefea',
          },
        })}
        //menuIsOpen={true}
        menuPlacement= {menuPlacement ?? 'auto'}
        isMulti={true}
        onCreateOption={handleCreate}
        isValidNewOption={(inputValue, _, selectOptions) => {
          if (
            inputValue.trim().length === 0 ||
            selectOptions.find(option => inputValue.toLocaleLowerCase().localeCompare(option.label.toLocaleLowerCase())===0)
          ) {
            return false;
          }
          return true;
        }}
      />
    </>
  );
}
