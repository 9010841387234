/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentPreferences,
    DocumentPreferencesFromJSON,
    DocumentPreferencesFromJSONTyped,
    DocumentPreferencesToJSON,
    ExternalPartners,
    ExternalPartnersFromJSON,
    ExternalPartnersFromJSONTyped,
    ExternalPartnersToJSON,
} from './';

/**
 * 
 * @export
 * @interface FirmPreferences
 */
export interface FirmPreferences {
    /**
     * 
     * @type {Set<ExternalPartners>}
     * @memberof FirmPreferences
     */
    enabledExternalProviders?: Set<ExternalPartners> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FirmPreferences
     */
    allowBriefpointAdminAccess?: boolean;
    /**
     * 
     * @type {DocumentPreferences}
     * @memberof FirmPreferences
     */
    documentPreferences?: DocumentPreferences;
}

export function FirmPreferencesFromJSON(json: any): FirmPreferences {
    return FirmPreferencesFromJSONTyped(json, false);
}

export function FirmPreferencesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirmPreferences {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabledExternalProviders': !exists(json, 'enabledExternalProviders') ? undefined : (json['enabledExternalProviders'] === null ? null : new Set((json['enabledExternalProviders'] as Array<any>).map(ExternalPartnersFromJSON))),
        'allowBriefpointAdminAccess': !exists(json, 'allowBriefpointAdminAccess') ? undefined : json['allowBriefpointAdminAccess'],
        'documentPreferences': !exists(json, 'documentPreferences') ? undefined : DocumentPreferencesFromJSON(json['documentPreferences']),
    };
}

export function FirmPreferencesToJSON(value?: FirmPreferences | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabledExternalProviders': value.enabledExternalProviders === undefined ? undefined : (value.enabledExternalProviders === null ? null : Array.from(value.enabledExternalProviders as Set<any>).map(ExternalPartnersToJSON)),
        'allowBriefpointAdminAccess': value.allowBriefpointAdminAccess,
        'documentPreferences': DocumentPreferencesToJSON(value.documentPreferences),
    };
}


