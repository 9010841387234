import { Modal } from "components/Modals";
import styles from './PropoundSelectionModal.module.scss';
import { ReactComponent as UploadIcon } from '../../images/icon-propound-upload.svg';
import { PencilSquare } from "react-bootstrap-icons";
import React from "react";

export default function PropoundSelectionModal(
  {
    isShow,
    onClose,
    handleQuickActionModal,
    handleQuickActionUpload,
    setShowPropoundIntroModal,
    setIsPleadingUpload
  }: {
    isShow: boolean;
    onClose: () => void;
    handleQuickActionModal: () => void;
    handleQuickActionUpload: (e: React.MouseEvent) => void;
    setShowPropoundIntroModal: React.Dispatch<React.SetStateAction<boolean>>,
    setIsPleadingUpload: React.Dispatch<React.SetStateAction<boolean>>
  },
) {

  function showQuickActionModal() {
    setShowPropoundIntroModal(false);
    handleQuickActionModal();
  }

  function showQuickActionUploadModal(e: React.MouseEvent) {
    handleQuickActionUpload(e);
    setIsPleadingUpload(true);
  }

  return (
    <Modal show={isShow}
      onClose={onClose}
      onConfirm={() => setShowPropoundIntroModal(false)}
      showConfirmButton={false}
      showCancelButton={false}
    >
      <div className={styles.propoundSelection}>
        <h2>How would you like to start?</h2>
        <div onClick={showQuickActionUploadModal} className={styles.propoundSelectionItem}>
          <span><UploadIcon /></span>
          <p>Upload a Pleading PDF</p>
        </div>
        <div onClick={showQuickActionModal} className={styles.propoundSelectionItem}>
          <span><PencilSquare className={styles.edit} /></span>
          <p>Enter Case Information Manually</p>
        </div>
      </div>
    </Modal>
  );
}
