/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CommunicationStatus,
    CommunicationStatusFromJSON,
    CommunicationStatusToJSON,
    CreateClientCommunication,
    CreateClientCommunicationFromJSON,
    CreateClientCommunicationToJSON,
    ObjectTypeEnum,
    ObjectTypeEnumFromJSON,
    ObjectTypeEnumToJSON,
    PostCommunicationItem,
    PostCommunicationItemFromJSON,
    PostCommunicationItemToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    PutClientCommunication,
    PutClientCommunicationFromJSON,
    PutClientCommunicationToJSON,
    PutCommunicationItem,
    PutCommunicationItemFromJSON,
    PutCommunicationItemToJSON,
    ViewCommunication,
    ViewCommunicationFromJSON,
    ViewCommunicationToJSON,
    ViewCommunicationItem,
    ViewCommunicationItemFromJSON,
    ViewCommunicationItemToJSON,
} from '../models';

export interface CommunicationAddCommunicationItemRequest {
    id: string;
    postCommunicationItem?: PostCommunicationItem;
}

export interface CommunicationCreateClientCommunicationRequest {
    createClientCommunication?: CreateClientCommunication;
}

export interface CommunicationDeleteClientCommunicationForObjectIdRequest {
    id: string;
}

export interface CommunicationGetCommunicationsForObjectRequest {
    objectId?: string;
    type?: ObjectTypeEnum;
}

export interface CommunicationGetFirmCommunicationsRequest {
    lowerBound?: CommunicationStatus;
    upperBound?: CommunicationStatus;
}

export interface CommunicationPutCommunicationItemRequest {
    id: string;
    putCommunicationItem?: PutCommunicationItem;
}

export interface CommunicationUpdateCommunicationRequest {
    id: string;
    putClientCommunication?: PutClientCommunication;
}

/**
 * 
 */
export class CommunicationApi extends runtime.BaseAPI {

    /**
     */
    async communicationAddCommunicationItemRaw(requestParameters: CommunicationAddCommunicationItemRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ViewCommunicationItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling communicationAddCommunicationItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Communication/{id}/communication-item`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCommunicationItemToJSON(requestParameters.postCommunicationItem),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewCommunicationItemFromJSON(jsonValue));
    }

    /**
     */
    async communicationAddCommunicationItem(requestParameters: CommunicationAddCommunicationItemRequest, initOverrides?: RequestInit): Promise<ViewCommunicationItem> {
        const response = await this.communicationAddCommunicationItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async communicationCreateClientCommunicationRaw(requestParameters: CommunicationCreateClientCommunicationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ViewCommunication>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Communication`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClientCommunicationToJSON(requestParameters.createClientCommunication),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewCommunicationFromJSON(jsonValue));
    }

    /**
     */
    async communicationCreateClientCommunication(requestParameters: CommunicationCreateClientCommunicationRequest, initOverrides?: RequestInit): Promise<ViewCommunication> {
        const response = await this.communicationCreateClientCommunicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async communicationDeleteClientCommunicationForObjectIdRaw(requestParameters: CommunicationDeleteClientCommunicationForObjectIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling communicationDeleteClientCommunicationForObjectId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Communication/objectid/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async communicationDeleteClientCommunicationForObjectId(requestParameters: CommunicationDeleteClientCommunicationForObjectIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.communicationDeleteClientCommunicationForObjectIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async communicationGetCommunicationsForObjectRaw(requestParameters: CommunicationGetCommunicationsForObjectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ViewCommunication>>> {
        const queryParameters: any = {};

        if (requestParameters.objectId !== undefined) {
            queryParameters['objectId'] = requestParameters.objectId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Communication/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ViewCommunicationFromJSON));
    }

    /**
     */
    async communicationGetCommunicationsForObject(requestParameters: CommunicationGetCommunicationsForObjectRequest, initOverrides?: RequestInit): Promise<Array<ViewCommunication>> {
        const response = await this.communicationGetCommunicationsForObjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async communicationGetFirmCommunicationsRaw(requestParameters: CommunicationGetFirmCommunicationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ViewCommunication>>> {
        const queryParameters: any = {};

        if (requestParameters.lowerBound !== undefined) {
            queryParameters['lowerBound'] = requestParameters.lowerBound;
        }

        if (requestParameters.upperBound !== undefined) {
            queryParameters['upperBound'] = requestParameters.upperBound;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Communication/firm-communications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ViewCommunicationFromJSON));
    }

    /**
     */
    async communicationGetFirmCommunications(requestParameters: CommunicationGetFirmCommunicationsRequest, initOverrides?: RequestInit): Promise<Array<ViewCommunication>> {
        const response = await this.communicationGetFirmCommunicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async communicationPutCommunicationItemRaw(requestParameters: CommunicationPutCommunicationItemRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ViewCommunicationItem>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling communicationPutCommunicationItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Communication/{id}/communication-item`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutCommunicationItemToJSON(requestParameters.putCommunicationItem),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewCommunicationItemFromJSON(jsonValue));
    }

    /**
     */
    async communicationPutCommunicationItem(requestParameters: CommunicationPutCommunicationItemRequest, initOverrides?: RequestInit): Promise<ViewCommunicationItem> {
        const response = await this.communicationPutCommunicationItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async communicationUpdateCommunicationRaw(requestParameters: CommunicationUpdateCommunicationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ViewCommunication>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling communicationUpdateCommunication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Communication/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutClientCommunicationToJSON(requestParameters.putClientCommunication),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewCommunicationFromJSON(jsonValue));
    }

    /**
     */
    async communicationUpdateCommunication(requestParameters: CommunicationUpdateCommunicationRequest, initOverrides?: RequestInit): Promise<ViewCommunication> {
        const response = await this.communicationUpdateCommunicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
