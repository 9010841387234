/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoleUpdate
 */
export interface RoleUpdate {
    /**
     * 
     * @type {Role}
     * @memberof RoleUpdate
     */
    role?: Role;
    /**
     * 
     * @type {boolean}
     * @memberof RoleUpdate
     */
    remove?: boolean;
}

export function RoleUpdateFromJSON(json: any): RoleUpdate {
    return RoleUpdateFromJSONTyped(json, false);
}

export function RoleUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'remove': !exists(json, 'remove') ? undefined : json['remove'],
    };
}

export function RoleUpdateToJSON(value?: RoleUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': RoleToJSON(value.role),
        'remove': value.remove,
    };
}


