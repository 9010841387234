import { Case, Jurisdiction, PartyPosition } from 'briefpoint-client';
import { Modal } from 'components/Modals';
import './RequiredCaseInfo.scss';
import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import CaseTypeSelect, { CaseTypeOption } from 'components/CaseManagement/CaseTypeSelect';
import { CaliforniaId } from 'components/CaseManagement/AddEditCaseModal';

interface Props {
  show: boolean;
  _case: Case;
  jurisdictions: Jurisdiction[];
  onClose: () => void;
  onConfirm: (_case: Case) => Promise<void>;
}

export default function RequiredCaseInfo({ show, _case, jurisdictions, onClose, onConfirm }: Props) {
  const [saving, setSaving] = useState(false);
  const [caseType, setCaseType] = useState(_case?.caseType ?? '');
  const [position, setPosition] = useState(_case?.clientPosition || PartyPosition.Unknown);

  const jurisdiction = jurisdictions?.find(j => j.id === _case?.jurisdiction) ?? jurisdictions?.find(j => j.id === CaliforniaId);
  const caseTypes = jurisdiction?.caseClasses?.filter(c => c.subTypes).flatMap(c => [c.subTypes!]).flat();

  async function doSave() {
    setSaving(true);
    const toSave = { ..._case };
    toSave.clientPosition = position;
    // toSave.representativeType = representative;
    toSave.caseType = caseType === `` ? undefined : caseType;
    toSave.lastActivity = new Date(Date.now()); //not passed to the back end, used to update the local model to display recently updated

    await onConfirm(toSave);
  }

  function handleClose() {
    onClose();
  }

  function handleCaseTypeChange(caseType: string) {
    setCaseType(caseType);
  }

  const options: CaseTypeOption[] = useMemo(
    () => {
      if (!caseTypes || !jurisdiction?.caseClasses) {
        return [];
      }
      let vs = caseTypes?.flatMap((v) =>
        [
          {
            value: v.id!,
            label: v.shortName!,
            key: jurisdiction.caseClasses!.find(x => x.id === v.caseClassId)?.name ?? ""
          },
        ]
      );
      return vs;
    },
    [caseTypes, jurisdiction?.caseClasses]
  );

  // need to do this jazz because UserService enums are being returned as strings not numbers like the DocumentService,
  // probably need to change UserService to behave like DocumentService but there are too many cases to handle right now
  const positionValues = position.split(',').map(item => item.trim());
  function flipPosition(val: PartyPosition) {
    var existing = positionValues.indexOf(val);

    if (existing !== -1) {
      positionValues.splice(existing, 1);
    } else {
      positionValues.push(val);
    }

    let positionVal = positionValues.join();
    if (positionVal.length && positionVal[0] === ",") {
      positionVal = positionVal.substring(1);
    }
    setPosition((positionVal || "Unknown") as PartyPosition);
  }

  return (
    <Modal
      show={show}
      onClose={handleClose}
      onConfirm={doSave}
      title={`Complete Case Setup`}
      confirmText={'Continue'}
      showConfirmButton={true}
      contentClassName='required-select-modal'
      confirming={saving}
      disableConfirmButton={!caseType || (!position || position === PartyPosition.Unknown)}
    >
      {<div>
        <div className="client-input-row">
          <Form.Group>
            <Form.Label className="required">Client Position</Form.Label>
            <div className="checkbox-container">
              <span>
                <Form.Check
                  id={'propounding-party-plaintiff'}
                  type={'checkbox'}
                  label="Plaintiff"
                  checked={positionValues.includes(PartyPosition.Plaintiff)}
                  onChange={() => flipPosition(PartyPosition.Plaintiff)}
                  isInvalid={position === PartyPosition.Unknown}
                />
              </span>
              <span>
                <Form.Check
                  id={'propounding-party-cross-defendant'}
                  type={'checkbox'}
                  label="Cross-Defendant"
                  checked={positionValues.includes(PartyPosition.CrossDefendant)}
                  onChange={() => flipPosition(PartyPosition.CrossDefendant)}
                  isInvalid={position === PartyPosition.Unknown}
                />
              </span>
              <span>
                <Form.Check
                  id={'propounding-party-defendant'}
                  type={'checkbox'}
                  label="Defendant"
                  checked={positionValues.includes(PartyPosition.Defendant)}
                  onChange={() => flipPosition(PartyPosition.Defendant)}
                  isInvalid={position === PartyPosition.Unknown}
                />
              </span>
              <span>
                <Form.Check
                  id={'propounding-party-cross-complainant'}
                  type={'checkbox'}
                  label="Cross-Complainant"
                  checked={positionValues.includes(PartyPosition.CrossComplainant)}
                  onChange={() => flipPosition(PartyPosition.CrossComplainant)}
                  isInvalid={position === PartyPosition.Unknown}
                />
              </span>
            </div>
          </Form.Group>
        </div>
        <div className="client-input-row">
          <label htmlFor='case-type' className='required form-label'>Case Type</label>
          <CaseTypeSelect
            className='select'
            options={options}
            onChange={handleCaseTypeChange}
            selected={caseType}
          />
        </div>
      </div>}
    </Modal>
  );
}
