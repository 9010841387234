/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FormData,
    FormDataFromJSON,
    FormDataToJSON,
} from '../models';

export interface FormDownloadFormDataWithDocRequest {
    formId: string;
    caseId: string;
    documentId: string;
    clientId?: string;
}

export interface FormGetFormDataRequest {
    formId: string;
    caseId: string;
}

export interface FormGetFormDataWithDocRequest {
    formId: string;
    caseId: string;
    documentId: string;
    clientId?: string;
}

export interface FormGetFormDownloadRequest {
    formId: string;
    caseId: string;
    formData?: FormData;
}

/**
 * 
 */
export class FormApi extends runtime.BaseAPI {

    /**
     */
    async formDownloadFormDataWithDocRaw(requestParameters: FormDownloadFormDataWithDocRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling formDownloadFormDataWithDoc.');
        }

        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling formDownloadFormDataWithDoc.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling formDownloadFormDataWithDoc.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Form/{formId}/case/{caseId}/document/{documentId}/download`.replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))).replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))).replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async formDownloadFormDataWithDoc(requestParameters: FormDownloadFormDataWithDocRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.formDownloadFormDataWithDocRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formGetFormDataRaw(requestParameters: FormGetFormDataRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FormData>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling formGetFormData.');
        }

        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling formGetFormData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Form/{formId}/case/{caseId}`.replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))).replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormDataFromJSON(jsonValue));
    }

    /**
     */
    async formGetFormData(requestParameters: FormGetFormDataRequest, initOverrides?: RequestInit): Promise<FormData> {
        const response = await this.formGetFormDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formGetFormDataWithDocRaw(requestParameters: FormGetFormDataWithDocRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FormData>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling formGetFormDataWithDoc.');
        }

        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling formGetFormDataWithDoc.');
        }

        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling formGetFormDataWithDoc.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Form/{formId}/case/{caseId}/document/{documentId}`.replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))).replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))).replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormDataFromJSON(jsonValue));
    }

    /**
     */
    async formGetFormDataWithDoc(requestParameters: FormGetFormDataWithDocRequest, initOverrides?: RequestInit): Promise<FormData> {
        const response = await this.formGetFormDataWithDocRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async formGetFormDownloadRaw(requestParameters: FormGetFormDownloadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.formId === null || requestParameters.formId === undefined) {
            throw new runtime.RequiredError('formId','Required parameter requestParameters.formId was null or undefined when calling formGetFormDownload.');
        }

        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling formGetFormDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Form/{formId}/case/{caseId}`.replace(`{${"formId"}}`, encodeURIComponent(String(requestParameters.formId))).replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormDataToJSON(requestParameters.formData),
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async formGetFormDownload(requestParameters: FormGetFormDownloadRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.formGetFormDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
