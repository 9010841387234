import { Case } from 'briefpoint-client';
import Loading from 'components/Loading';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { DefinitionItem } from 'screens/DocumentGeneration/GenerateDiscoveryRequestPage';

interface Props {
  _case?: Case;
  definitions?: DefinitionItem[];
  onChange: (value: string, index: number, field: string) => void;
  onBlur: (index: number) => Promise<void>;
  onRemove: (index: number) => void;
}

export default function Definitions({ _case, definitions, onChange, onBlur, onRemove }: Props) {
  return (
    <div className="definitions-container">
      <Loading isLoading={!_case || !definitions}>
        <ol>
          {definitions?.sort((a, b) => a.term!.localeCompare(b.term!)).map((def, index) => {
            return !def.isDeleted && !def.toDelete ? (
              <li key={`definition-${index}`} id={`definition-${index}`}>
                <Row>
                  <Col>
                    <label htmlFor={`definition-term-${index}`}>Term</label>
                    <Form.Control id={`definition-term-${index}`} value={def.term ?? ''} onChange={(e) => onChange(e.target.value, index, "term")} onBlur={() => onBlur(index)} />
                  </Col>
                  <Col>
                    <label htmlFor={`definition-alias-${index}`}>Aliases (Optional)</label>
                    <Form.Control id={`definition-alias-${index}`} value={def.aliases ?? ''} onChange={(e) => onChange(e.target.value, index, "aliases")} onBlur={() => onBlur(index)} /><span className='alias-help'>Separate multiple aliases with a comma</span>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    <label htmlFor={`definition-content-${index}`}>Content</label>
                    <Form.Control as='textarea' id={`definition-content-${index}`} value={def.content ?? ''} onChange={(e) => onChange(e.target.value, index, "content")} onBlur={() => onBlur(index)} />
                    {(!def.id || !def.content) && <Button variant='link' onClick={() => onRemove(index)}>Remove</Button>}
                  </Col>
                </Row>
              </li>
            ) : null;
          })}
        </ol>
      </Loading>
    </div>
  );
}
