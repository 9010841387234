export class SaveQueue {
  private isSaving?: any;
  private saveQueue: (() => Promise<void>)[];
  private update: React.Dispatch<React.SetStateAction<any>>;
  private setError: React.Dispatch<React.SetStateAction<(() => Promise<void>) | undefined>>;

  constructor(update: React.Dispatch<React.SetStateAction<any>>, setError: React.Dispatch<React.SetStateAction<(() => Promise<void>) | undefined>>) {
    this.isSaving = undefined;
    this.saveQueue = [];
    this.update = update;
    this.setError = setError;
  }

  private updateSave(saving: any) {
    this.isSaving = saving;
    this.update(saving);
  }

  async addSave(saveFunction: () => Promise<void>, saving: any): Promise<void> {
    if (!this.isSaving) {
      // If there's nothing saving, just fire off the call
      this.updateSave(saving);
      // After a successful save, clear any errors.
      // On an error, if there's not already a queued update that might work, stash it in the error object for a later retry
      await saveFunction().then(_ => this.setError(undefined), _ => {
        if (this.saveQueue.length === 0) {
          this.setError(() => saveFunction);
        }
      });
      this.isSaving = undefined;
      await this.checkQueue(saving);
    } else {
      // If there's a call pending, and already 1 item in the queue, just replace that item
      if (this.saveQueue.length > 0) {
        this.saveQueue[0] = saveFunction;
      } else {
        // If there's a call pending, add it to the queue
        this.saveQueue.push(saveFunction);
      }
    }
  }

  private async checkQueue(saving: any): Promise<void> {
    if (this.saveQueue.length > 0 && !this.isSaving) {
      const saveFunction = this.saveQueue.shift();
      if (saveFunction) {
        await this.addSave(saveFunction, saving);
      }
    } else if (this.saveQueue.length === 0) {
      this.updateSave(undefined);
    }
  }
}
