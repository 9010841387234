/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupportedJurisdiction
 */
export interface SupportedJurisdiction {
    /**
     * 
     * @type {string}
     * @memberof SupportedJurisdiction
     */
    jurisdictionId?: string;
}

export function SupportedJurisdictionFromJSON(json: any): SupportedJurisdiction {
    return SupportedJurisdictionFromJSONTyped(json, false);
}

export function SupportedJurisdictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedJurisdiction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jurisdictionId': !exists(json, 'jurisdictionId') ? undefined : json['jurisdictionId'],
    };
}

export function SupportedJurisdictionToJSON(value?: SupportedJurisdiction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jurisdictionId': value.jurisdictionId,
    };
}


