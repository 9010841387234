/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CredentialResult,
    CredentialResultFromJSON,
    CredentialResultToJSON,
} from '../models';

export interface CredentialCreateRequest {
    clientId: string;
}

export interface CredentialSyncRequest {
    clientId: string;
    newEmail?: string;
}

export interface CredentialUpdateRequest {
    clientId: string;
}

/**
 * 
 */
export class CredentialApi extends runtime.BaseAPI {

    /**
     */
    async credentialCreateRaw(requestParameters: CredentialCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CredentialResult>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling credentialCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Credential/client/{clientId}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CredentialResultFromJSON(jsonValue));
    }

    /**
     */
    async credentialCreate(requestParameters: CredentialCreateRequest, initOverrides?: RequestInit): Promise<CredentialResult> {
        const response = await this.credentialCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async credentialSyncRaw(requestParameters: CredentialSyncRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CredentialResult>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling credentialSync.');
        }

        const queryParameters: any = {};

        if (requestParameters.newEmail !== undefined) {
            queryParameters['newEmail'] = requestParameters.newEmail;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Credential/client/{clientId}/sync`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CredentialResultFromJSON(jsonValue));
    }

    /**
     */
    async credentialSync(requestParameters: CredentialSyncRequest, initOverrides?: RequestInit): Promise<CredentialResult> {
        const response = await this.credentialSyncRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async credentialUpdateRaw(requestParameters: CredentialUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CredentialResult>> {
        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling credentialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Credential/client/{clientId}/generate-new-code`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CredentialResultFromJSON(jsonValue));
    }

    /**
     */
    async credentialUpdate(requestParameters: CredentialUpdateRequest, initOverrides?: RequestInit): Promise<CredentialResult> {
        const response = await this.credentialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
