import { Client, Role, User } from 'briefpoint-client';
import AddEditClientModal from 'components/CaseManagement/AddEditClientModal';
import ClientTable from 'components/CaseManagement/ClientTable';
import Loading from 'components/Loading';
import { useClientApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import useFirm from 'hooks/useFirm';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import './styles.scss';

export default function ClientsMulti() {
  const { user, firm } = useAuth()!;
  const clientApi = useClientApi();
  const [showAddClient, setShowAddClient] = useState(false);
  const [clients, setClients] = useState<Client[]>();
  const [search, setSearch] = useState('');
  const [users, , , ,] = useFirm(firm?.id, false);

  const loadClients = useCallback(async (user: User | undefined) => {
    if (user && user.firmId) {
      const clients = await clientApi.clientGetAll({ firmId: user.firmId!, usersClientsOnly: !user?.role?.includes(Role.FirmAdmin) });
      setClients(clients);
    }
  }, [clientApi]);

  useEffect(() => {
    loadClients(user);
  }, [user, loadClients]);

  async function AddClient(client: Client) {
    const newClient = await clientApi.clientPost({ firmId: user?.firmId!, client });

    if (newClient) {
      if (clients) {
        setClients(c => [...c!, newClient]);
      } else {
        setClients([newClient]);
      }
    }

    closeAddClient();
  }

  function closeAddClient() {
    setShowAddClient(false);
  }

  const filteredClients = clients?.filter(x => {
    if (!search) return true;

    const name = x.name?.toLocaleLowerCase() ?? "";
    if (name.includes(search.toLowerCase())) {
      return true;
    }

    return false;
  });

  return (
    <>
      <Container className="page-container-multi">
        <Loading isLoading={clients === undefined}>
          <Row>
            <Col>
              <h1 style={{ marginTop: "2rem", marginBottom: "1rem" }}>Clients</h1>
            </Col>
            <Col className='actions-cell'>
              <Button variant="primary" style={{ marginTop: "2rem" }} onClick={() => setShowAddClient(true)}>
                Add New Client
              </Button>
            </Col>
          </Row>
          <Row style={{ marginBottom: "1rem" }}>
            <Col xs="4">
              <div id='search-box' className="input-group input-group-search mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">⌕</div>
                </div>
                <Form.Control
                  value={search}
                  className="form-control defense-search-input"
                  placeholder="Search clients"
                  onChange={(e) => setSearch(e.target.value.toLowerCase())}
                />
              </div>
            </Col>
          </Row>
          <AddEditClientModal title='Add Client' confirm='Add' isShow={showAddClient} onClose={closeAddClient} onConfirm={AddClient} users={users} />
          {((filteredClients?.length ?? 0) > 5) &&
            (<div>
              <h2 style={{ marginTop: '0px' }}>Recent Clients</h2>
              <ClientTable clients={filteredClients?.slice().sort((a, b) => {
                if (a.lastActivity && b.lastActivity) {
                  return a.lastActivity > b.lastActivity ? -1 : 1;
                }
                else if (a.lastActivity) {
                  return -1;
                }
                else if (b.lastActivity) {
                  return 1;
                }
                else {
                  return 0;
                }
              }).slice(0, 5)} addClient={() => setShowAddClient(true)} showIfEmpty={false} />
              <span>&nbsp;&nbsp;</span>
              <h2 style={{ marginTop: '0px' }}>All Clients</h2>
            </div>)}
          {((filteredClients?.length ?? 0) <= 0 && !!search?.length) ?
            (<div>No results found.</div>) :
            (<ClientTable clients={filteredClients?.slice().sort((a, b) => {
              if (a.name && b.name) {
                return a.name.localeCompare(b.name);
              }
              else if (a.name) {
                return -1;
              }
              else if (b.name) {
                return 1;
              }
              else {
                return 0;
              }
            })} addClient={() => setShowAddClient(true)} showIfEmpty={true} />)}
        </Loading>
      </Container>
    </>
  );
}
