import { Client, User } from "briefpoint-client";
import Help from "components/Help";
import { FormModal } from "components/Modals";
import { useState } from "react";
import { Alert, Form } from "react-bootstrap";
import './AddEditClientModal.scss';
import Attorneys from "./Attorneys";
import { ExclamationCircleFill } from "react-bootstrap-icons";

interface Props {
  title: string;
  confirm: string;
  isShow: boolean;
  client?: Client;
  users: User[] | null;
  onClose: () => void;
  onConfirm: (client: Client) => Promise<void>;
  emailError?: string;
}

export default function AddEditClientModal({ title, confirm, isShow, client, users, onClose, onConfirm, emailError }: Props) {
  const [clientName, setClientName] = useState<string | undefined>(client?.name ?? '');
  const [alias, setAlias] = useState<string | undefined>(client?.aliases ?? '');
  const [respondentName, setRespondentName] = useState<string | undefined>(client?.respondentName ?? '');
  const [contactFirst, setContactFirst] = useState<string | undefined>(client?.contactFirstName ?? '');
  const [contactLast, setContactLast] = useState<string | undefined>(client?.contactLastName ?? '');
  const [contactEmail, setContactEmail] = useState<string | undefined>(client?.contactEmail ?? '');
  const [attorneys, setAttorneys] = useState(client?.attorneys ?? []);
  const [showAlert, setShowAlert] = useState(true);
  const [saving, setSaving] = useState(false);

  async function doSave() {
    setSaving(true);
    const toSave = client ?? {};

    toSave.name = clientName;
    toSave.aliases = alias;
    toSave.attorneys = attorneys;
    toSave.respondentName = respondentName;
    toSave.contactFirstName = contactFirst;
    toSave.contactLastName = contactLast;
    toSave.contactEmail = contactEmail;
    toSave.lastActivity = new Date(Date.now()); //not passed to the back end, used to update the local model to display recently updated
    await onConfirm(toSave);
    setSaving(false);
  }

  function reset() {
    setClientName(client?.name ?? '');
    setAlias(client?.aliases ?? '');
    setRespondentName(client?.respondentName ?? '');
    setContactFirst(client?.contactFirstName ?? '');
    setContactLast(client?.contactLastName ?? '');
    setContactEmail(client?.contactEmail ?? '');
    setAttorneys(client?.attorneys ?? []);
  }

  function doClose() {
    setTimeout(reset, 500);

    onClose();
  }

  return (
    <FormModal
      title={title}
      cancelText="Cancel"
      confirmText={confirm}
      show={isShow}
      onClose={doClose}
      onConfirm={doSave}
      size='lg'
      contentClassName='add-edit-client'
      preventBackgroundClick
      confirming={saving}
    >
      {!client && showAlert && <Alert variant="info" dismissible onClose={() => setShowAlert(false)}><b>Did you know?</b> You don't need to create a Client to upload documents, you can add the Client during Document Confirmation.</Alert>}
      <div className="client-input-row">
        <label htmlFor='client-name' className="required">Client Name</label>
        <Form.Control id='client-name' required value={clientName} onChange={e => setClientName(e.target.value)} />
      </div>
      <div className="client-input-row">
        <label htmlFor='client-refer'>Refer to Client As</label><Help id='refer-help' text='Will be used when selecting `Custom` for `Refer to responding party as` during Document Review.' />
        <Form.Control id='client-refer' value={respondentName} onChange={e => setRespondentName(e.target.value)} />
      </div>
      <div className="client-input-row full">
        <label htmlFor='client-aliases'>Aliases</label><Help id='aliases-help' text='Will be used when determining the Client for uploaded Documents if opposing council refers to them differently than you do. Separate aliases with a `;`.' />
        <Form.Control id='client-aliases' value={alias} onChange={e => setAlias(e.target.value)} />
      </div>
      <h4>Client Contact Information</h4>
      <div className="client-input-row contact">
        <label htmlFor='contact-first'>First Name</label>
        <Form.Control id='contact-first' value={contactFirst} onChange={e => setContactFirst(e.target.value)} />
      </div>
      <div className="client-input-row contact">
        <label htmlFor='contact-last'>Last Name</label>
        <Form.Control id='contact-last' value={contactLast} onChange={e => setContactLast(e.target.value)} />
      </div>
      <div className="client-input-row">
        <label htmlFor='contact-email'>Email Address</label>
        <Form.Control id='contact-email' value={contactEmail} onChange={e => setContactEmail(e.target.value)} />
        {emailError && (<span className="email-error-span"><ExclamationCircleFill style={{marginRight:"4px", minWidth:"20px"}}/>{emailError}</span>)}
      </div>

      <Attorneys users={users} assignedAttorneys={attorneys} topLevelAttorneys={[]} setAttorneys={setAttorneys} />

    </FormModal>
  );
}