import { Case, DocumentStatus, Source } from 'briefpoint-client';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { DocumentClient } from 'services/DocumentService';
import AddToCalendarModal from './AddToCalendarModal';
import { useAuth } from 'hooks/useAuth';

export default function DocumentActionsMenu({
  document,
  _case,
  archiveDocument,
  editDueDate,
  setShowSetCase,
  downloadForm,
  downloadRequest,
  createReview,
  sendReminder
}: {
  document: DocumentClient;
  _case?: Case;
  archiveDocument: (document: DocumentClient) => Promise<void>;
  editDueDate?: Dispatch<SetStateAction<DocumentClient | undefined>>;
  setShowSetCase?: Dispatch<SetStateAction<DocumentClient | undefined>>;
  downloadForm: (document: DocumentClient, formId: string, formTypeName: string) => Promise<void>;
  downloadRequest: (document: DocumentClient) => Promise<void>;
  createReview?: (document: DocumentClient) => void;
  sendReminder?: () => void;
}) {
  const [showAddToCalendar, setShowAddToCalendar] = useState(false);
  const { jurisdictions } = useAuth()!;

  const jurisdiction = useMemo(() => {
    if (!jurisdictions || !_case?.jurisdiction) {
      return undefined;
    }

    const jx = jurisdictions?.find(j => j.id === _case?.jurisdiction);
    return jx;
  }, [jurisdictions, _case?.jurisdiction]);

  const dueDate = document?.info?.dueDateOverridden || document?.info?.dueDate;
  const verificationFormId = jurisdiction?.verificationFormId;
  const isExternal = document.source !== Source.InternalGenerated;

  return (
    <>
      <NavDropdown
        title={<ThreeDotsVertical />}
        id="collapsible-nav-dropdown"
        className="doc-list-actions-dropdown"
      >
        {isExternal ? (
          <>
            {editDueDate && (document.status === DocumentStatus.Complete ||
              document.status === DocumentStatus.InfoConfirmed) && (
                <NavDropdown.Item onClick={() => editDueDate(document)}>
                  {`${dueDate ? 'Edit' : 'Set'} Due Date`}
                </NavDropdown.Item>
              )}
            {dueDate && (
              <NavDropdown.Item onClick={() => setShowAddToCalendar(true)}>Add to Calendar</NavDropdown.Item>
            )}
            {setShowSetCase && !document.caseId && (
              <NavDropdown.Item onClick={() => setShowSetCase(document)}>Set Case & Client</NavDropdown.Item>
            )}
            {createReview && (
              <NavDropdown.Item onClick={() => createReview(document)}>Send to Client</NavDropdown.Item>
            )}
            {sendReminder && (
              <NavDropdown.Item onClick={() => sendReminder()}>Send Client a Reminder</NavDropdown.Item>
            )}
            {!!document.caseId && (document.status ?? 0) > DocumentStatus.Processed && verificationFormId && (
              <NavDropdown.Item onClick={() => downloadForm(document, verificationFormId, 'Verification')}>
                Download Verification
              </NavDropdown.Item>
            )}
          </>
        ) : (
          <NavDropdown.Item onClick={() => downloadRequest(document)}>Download</NavDropdown.Item>
        )}
        <NavDropdown.Item onClick={() => archiveDocument(document)}>Archive</NavDropdown.Item>
      </NavDropdown>

      {dueDate && (
        <AddToCalendarModal
          isShow={showAddToCalendar}
          doc={document}
          _case={_case}
          onClose={() => setShowAddToCalendar(false)}
        />
      )}
    </>
  );
}
