import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Container from 'react-bootstrap/esm/Container';
import { HashLink as Link } from 'react-router-hash-link';
import styles from './HowTo.module.scss';

export default function HowTo() {
  return (
    <Container className="page-container how-to-container">
      <div>
        <h1 className='no-margin-top'>Contents</h1>
        <p>
          <Link to="/how-to#using_placeholders">Using Placeholders</Link>
        </p>
        <p>
          <Link to="/how-to#editing_content">Editing Content</Link>
        </p>
        <p>
          <Link to="/how-to#create_tailored_content">Create Tailored Content</Link>
        </p>
        <p>
          <Link to="/how-to#demo">Demo</Link>
        </p>
        <p>
          <Link to="/how-to#discovery_requests">Discovery Requests</Link>
        </p>
        <p>
          <Link to="/how-to#need_help">Additional Help</Link>
        </p>
        <div id="using_placeholders">
          <h1>Using Placeholders</h1>
          <p>
            Placeholders allow you to pull dynamic content into your document by typing a simple word or phrase surrounded by double curly brackets. The table below contains all the available placeholders and their related content.
          </p>
          <p>
            Please note that they must be entered exactly as they are displayed to function.
          </p>
          <h3>For Example</h3>
          <p>
            <b>In a case where the Plaintiff is the propounding party, when you type:</b>
          </p>
          <p className="mb-5">
            Responding Party objects to this Interrogatory on the ground that the information sought is in the possession of, known to, or otherwise equally available to{' '}
            <OverlayTrigger
              key={'example-1'}
              placement={'bottom'}
              overlay={<Tooltip id={`tooltip-1`}>Placeholder</Tooltip>}
            >
              <span className="primary-highlight">{'{{PROPOUNDING_PARTY}}'}</span>
            </OverlayTrigger>
            .
          </p>
          <p>
            <b>It will appear in your document as:</b>
          </p>
          <p>
            Responding Party objects to this Interrogatory on the ground that the information sought is in the possession of, known to, or otherwise equally available to{' '}
            <OverlayTrigger
              key={'example-2'}
              placement={'bottom'}
              overlay={<Tooltip id={`tooltip-1`}>Dynamic Content</Tooltip>}
            >
              <span className="primary-highlight">Plaintiff</span>
            </OverlayTrigger>
            .
          </p>
          <hr className="break-margin"></hr>
          <p>Definitions</p>
          <ul>
            <li>
              <div>
                <b>Data Field</b>
              </div>
              <p>A place where specific data is stored, like a cell in a spreadsheet</p>
            </li>
            <li>
              <div>
                <b>Placeholder</b>
              </div>
              <p>A unique, text-based identifier that corresponds to a specific data field</p>
            </li>
            <li>
              <div>
                <b>Dynamic Content</b>
              </div>
              <p>The content that is inserted into a document by a placeholder</p>
            </li>
          </ul>
          <hr className="break-margin"></hr>
          <table className="help-table mb-5">
            <thead>
              <tr>
                <th className={styles.noHeaderBorders}>Placeholder</th>
                <th className={styles.noHeaderBorders}>Dynamic Content</th>
                <th className={styles.noHeaderBorders}>Applies to</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="dark-background table-padding">{'{{REQUEST}}'}</td>
                <td>
                  <div className="dark-background table-padding">Request</div>
                  <div className="table-padding">Interrogatory</div>
                </td>
                <td>
                  <div className="dark-background table-padding">Requests for Admission/Production</div>
                  <div className="table-padding">Other Interrogatories</div>
                </td>
              </tr>
              <tr>
                <td className="dark-background table-padding">{'{{REQUEST_TYPE}}'}</td>
                <td>
                  <div className="dark-background table-padding">Requests for Admission</div>
                  <div className="table-padding">Requests for Production</div>
                  <div className="dark-background table-padding">Interrogatories</div>
                  <div className="table-padding">Form Interrogatories</div>
                </td>
                <td>
                  <div className="dark-background table-padding">Requests for Admission</div>
                  <div className="table-padding">Requests for Production</div>
                  <div className="dark-background table-padding">Interrogatories</div>
                  <div className="table-padding">Form Interrogatories</div>
                </td>
              </tr>
              <tr>
                <td className="dark-background table-padding">{'{{REQUEST_TYPE_SINGULAR}}'}</td>
                <td>
                  <div className="dark-background table-padding">Request for Admission</div>
                  <div className="table-padding">Request for Production</div>
                  <div className="dark-background table-padding">Interrogatory</div>
                  <div className="table-padding">Form Interrogatory</div>
                </td>
                <td>
                  <div className="dark-background table-padding">Requests for Admission</div>
                  <div className="table-padding">Requests for Production</div>
                  <div className="dark-background table-padding">Interrogatories</div>
                  <div className="table-padding">Form Interrogatories</div>
                </td>
              </tr>
              <tr>
                <td className="dark-background table-padding">{'{{REQUEST_OBJECT}}'} <Link to='/how-to#footnote-1'><sup>1</sup></Link></td>
                <td>
                  <div className="dark-background table-padding line-height-double">information</div>
                  <div className="table-padding">documents</div>
                </td>
                <td>
                  <div className="dark-background table-padding">
                    Requests for Admission, Interrogatories, or Form Interrogatories
                  </div>
                  <div className="table-padding">Requests for Production</div>
                </td>
              </tr>
              <tr>
                <td className="dark-background table-padding">{'{{LINKING_VERB}}'} <Link to="/how-to#footnote-1"><sup>1</sup></Link></td>
                <td>
                  <div className="dark-background table-padding line-height-double">is</div>
                  <div className="table-padding">are</div>
                </td>
                <td>
                  <div className="dark-background table-padding">
                    When referring to information (applicable to RFAs, Interrogatories, and Form Interrogatories)
                  </div>
                  <div className="table-padding">When referring to documents (applicable to RFPs)</div>
                </td>
              </tr>
              <tr>
                <td className="dark-background table-padding">{'{{RESPONDENT}}'} <Link to="/how-to#footnote-2"><sup>2</sup></Link></td>
                <td>
                  <div className="dark-background table-padding">Plaintiff</div>
                  <div className="table-padding">Defendant</div>
                  <div className="dark-background table-padding">Respondent</div>
                  <div className="table-padding">
                    A custom name if you entered one on the document confirmation page
                  </div>
                </td>
                <td>
                  <div className="table-padding">
                    This will display whatever name you chose to define your client as in the “Refer to responding party as” section of your document’s confirmation page
                  </div>
                </td>
              </tr>
              <tr>
                <td className="dark-background table-padding">{'{{PROPOUNDING_PARTY_TYPE}}'}</td>
                <td>
                  <div className="dark-background table-padding">Plaintiff</div>
                  <div className="table-padding">Defendant</div>
                </td>
                <td>
                  <div className="dark-background table-padding">When Propounding Party is the Plaintiff</div>
                  <div className="table-padding">When Propounding Party is the Defendant</div>
                </td>
              </tr>
              <tr>
                <td className="dark-background table-padding">{'{{PROPOUNDING_PARTY}}'}</td>
                <td>
                  <div className="table-padding">The propounding party's name</div>
                </td>
                <td>
                  <div className="table-padding">The propounding party name you confirmed in the “Propounding Party” section of your document’s confirmation page</div>
                </td>
              </tr>
            </tbody>
          </table>

          <div>
            <div id="footnote-1">
              <b>1. When you type:</b>
            </div>
            <div className="testing">
              <div className="mb-2">
                The{' '}
                <OverlayTrigger
                  key={'placeholder-1'}
                  placement={'bottom'}
                  overlay={<Tooltip id={`tooltip-1`}>Placeholder</Tooltip>}
                >
                  <span className="primary-highlight">{'{{REQUEST_OBJECT}}'}</span>
                </OverlayTrigger>
                {' '}sought{' '}
                <OverlayTrigger
                  key={'placeholder-2'}
                  placement={'bottom'}
                  overlay={<Tooltip id={`tooltip-1`}>Placeholder</Tooltip>}
                >
                  <span className="green-highlight">{'{{LINKING VERB}}'}</span>
                </OverlayTrigger>
                {' '}subject to the attorney-client privilege.
              </div>
              {/* <span style={{ marginLeft: 170 }} id="help-box" className="help-box">
                  Dynamic Content
                </span> */}
            </div>
            <div>
              <b>Content appears in your document as:</b>
            </div>
            <div className="mb-2">
              The{' '}
              <OverlayTrigger
                key={'dynamic-1'}
                placement={'bottom'}
                overlay={<Tooltip id={`tooltip-1`}>Dynamic Content</Tooltip>}
              >
                <span className="primary-highlight">information</span>
              </OverlayTrigger>{' '}
              sought{' '}
              <OverlayTrigger
                key={'dynamic-2'}
                placement={'bottom'}
                overlay={<Tooltip id={`tooltip-1`}>Dynamic Content</Tooltip>}
              >
                <span className="green-highlight">is</span>
              </OverlayTrigger>{' '}
              subject to the attorney-client privilege.
            </div>
            <div className="mb-2 ml-5" style={{ marginLeft: 120 }}>
              <b>OR</b>
            </div>
            <div className="mb-2">
              The{' '}
              <OverlayTrigger
                key={'dynamic-3'}
                placement={'bottom'}
                overlay={<Tooltip id={`tooltip-1`}>Dynamic Content</Tooltip>}
              >
                <span className="primary-highlight">documents</span>
              </OverlayTrigger>{' '}
              sought{' '}
              <OverlayTrigger
                key={'dynamic-4'}
                placement={'bottom'}
                overlay={<Tooltip id={`tooltip-1`}>Dynamic Content</Tooltip>}
              >
                <span className="green-highlight">are</span>
              </OverlayTrigger>{' '}
              subject to the attorney-client privilege.
            </div>
            <hr className="break-margin"></hr>
            <div id="footnote-2">
              <b>2. When you type:</b>
            </div>
            <div className="mb-2">
              <OverlayTrigger
                key={'dynamic-4'}
                placement={'bottom'}
                overlay={<Tooltip id={`tooltip-1`}>Placeholder</Tooltip>}
              >
                <span className="primary-highlight">{'{{RESPONDENT}}'}</span>
              </OverlayTrigger>
              {' '}objects to this interrogatory on the ground that the information sought is too remote from the
              subject matter of the action.
            </div>
            <div>
              <b>Content appears in your document as:</b>
            </div>
            <div>
              <OverlayTrigger
                key={'dynamic-4'}
                placement={'bottom'}
                overlay={<Tooltip id={`tooltip-1`}>Dynamic Content</Tooltip>}
              >
                <span className="primary-highlight">Mr. Jones</span>
              </OverlayTrigger>
              {' '}objects to this interrogatory on the ground that the information sought is too remote from the
              subject matter of the action.
            </div>
          </div>

        </div>
        <div id="editing_content">
          <h1 className='no-margin-bottom'>Editing Content</h1>
          <iframe src="https://player.vimeo.com/video/695541494?h=aa4aaeae29" title="editing_content" width="720" height="480" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div id="create_tailored_content">
          <h3 className='subtitle'>Drafting Discovery Requests and Interrogatories:</h3>
          <h1 className='no-margin-top'>How to Create Tailored Content </h1>
          <p className="maxWidth">Briefpoint allows you to draft discovery requests and interrogatories that are tailored to your case a few different ways.</p>
          <table className="help-table mb-1">
            <thead>
              <tr>
                <th className={styles.noHeaderBorders}></th>
                <th className={styles.noHeaderBorders}>How it works</th>
                <th className={styles.noHeaderBorders}>Examples</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fixedWidth dark-background table-padding"><span className={`${styles.hightlight} ${styles.docType}`}>Document Type</span></td>
                <td>
                  <div className="dark-background table-padding">Content that does not have a case type specified or any tags will appear in every discovery request or interrogatory of that type. These are “general” requests for production that you want to ask in every case.</div>
                </td>
                <td>
                  <div className="dark-background table-padding">If you create a discovery request for a request for production that doesn’t have a case type specified or any tags, it will be added to every request for production.</div>
                </td>
              </tr>
              <tr>
                <td className="fixedWidth table-padding"><span className={`${styles.hightlight} ${styles.caseType}`}>Case Type</span></td>
                <td className="table-padding">Content with a specific case type will be included in every case that shares that case type.</td>
                <td className="table-padding">If you create a request for production with “Auto Tort” as the case type, it will be included in every request for production drafted for an Auto Tort case.</td>
              </tr>
              <tr>
                <td className="fixedWidth dark-background table-padding"><span className={`${styles.hightlight} ${styles.rpcaseType}`}>Responding Party &  Case Tags</span></td>
                <td>
                  <div className="dark-background table-padding">Content with a specific responding party (e.g., “employer” or “driver”) or case tag (e.g., “Motorcycle”, “Unpaid Wages”, or “TBI”) will be included in every document that shares the same document type, case type, and tags.</div>
                </td>
                <td>
                  <div className="dark-background table-padding">If a request for production for an Auto Tort case is tagged “Truck”, it will be added to every request for production drafted for an Auto Tort case tagged with “Truck”.</div>
                </td>
              </tr>
            </tbody>
          </table>

          <img src="https://bppublic.blob.core.windows.net/public/tutorials/tutorial-tailored-cont-1-min.png" width='100%' title='General to Specific' alt='General to specific content' className={styles.cleanImgBorder} />

          <h1>Creating Discovery Requests in Your Library</h1>
          <p>Once you are on the main Library page, select the Discovery Requests tab, and the Requests for Production on the left side.</p>
          <img src="https://bppublic.blob.core.windows.net/public/tutorials/tutorial-tailored-cont-2-min.png" width='769' title='Discovery requests' alt='Creating Discovery Requests in Your Library' className={styles.cleanImgBorder} />

          <h2>Document Type</h2>
          <p>This request for production is generic and will be added to every request for production you draft in Briefpoint.</p>
          <img src="https://bppublic.blob.core.windows.net/public/tutorials/tutorial-tailored-cont-3-min.png" width='1027' title='Document Type' alt='Document type' className={styles.cleanImgBorder} />

          <h2>Document Type + Case Type</h2>
          <p>This request for production is for “Auto Tort - Auto” cases and will only be added to requests for production where the case type is specified as “Auto Tort - Auto”.</p>
          <img src="https://bppublic.blob.core.windows.net/public/tutorials/tutorial-tailored-cont-4-min.png" width='1027' title='Document and Case Type' alt='Adding document and case type' className={styles.cleanImgBorder} />

          <h2>Document Type + Case Type + One Case Tag</h2>
          <p>This request for production is for “Auto Tort - Auto” cases with the tag “Truck”. It will only be added to requests for production where the case type is specified as “Auto Tort - Auto” and the “Truck” tag is selected.</p>
          <img src="https://bppublic.blob.core.windows.net/public/tutorials/tutorial-tailored-cont-5-min.png" width='1027' title='Document, case, and one case tag' alt='Adding document, case, and one case tag' className={styles.cleanImgBorder} />

          <h2>Document Type + Case Type + Multiple Case Tags</h2>
          <p>This request for production is for “Auto Tort - Auto” cases with the tags “Truck” and “Cell Phone.” It will only be added to requests for production where the case type is specified as “Auto Tort - Auto” and both tags are selected.</p>
          <img src="https://bppublic.blob.core.windows.net/public/tutorials/tutorial-tailored-cont-6-min.png" width='1027' title='Document, case, and multiple case tags' alt='Adding document, case, and multiple case tags' className={styles.cleanImgBorder} />

        </div>
        <div id="discovery_requests">
          <h1 className='no-margin-top'>Out Now: Briefpoint Propounding Discovery Beta!</h1>
          <p>We’re excited to announce the beta launch of Briefpoint’s new propounding discovery automation feature!</p>
          <p>With over 600 out-of-the-box requests for admission, requests for production, and interrogatories, you can create flawless first drafts of your propounding discovery in minutes.</p>
          <p>To access the new feature, navigate to a case folder on your account, ensure your case is labeled with the appropriate case type under ‘Edit Case Details’, and select “Draft Request” from the case’s dashboard.</p>
          <p><b>NOTICE</b>: This feature is currently in <b>BETA</b> - <i>meaning that its functionality will change and improve as we continue to build it</i> - please help us improve this feature by providing feedback. Thank you for helping us make Briefpoint work better for you in advance.</p>
          <h1>Propounding Discovery Roadmap</h1>
          <ul>
            <li>Word Processing - Bold, underline, etc.</li>
            <li>Person Most Qualified (PMQ) Document Types</li>
            <li>Automated Dupe / Cumulative Request Checking</li>
            <li>Automated Request / Interrogatory Generation from Uploaded-Samples</li>
            <li>35 SROG/RFA Limit Notification & Declaration Generation</li>
            <li>Automated Generation of “If Contention” Sets</li>
            <li>Automated Definition Extraction from Uploads</li>
            <li>Customized Output Documents</li>
            <li>And more!</li>
          </ul>
          <p>Don’t see a feature you’d like in Version 2?</p>
          <p>Let us know!</p>
          <p>Simply select the feedback tab on the right of your page and state the feature you’d like to have included in our next update.</p>
        </div>
        <div id="discovery_requests">
          <h1 className='no-margin-top'>Out Now: Briefpoint Propounding Discovery Beta!</h1>
          <p>We’re excited to announce the beta launch of Briefpoint’s new propounding discovery automation feature!</p>
          <p>With over 600 out-of-the-box requests for admission, requests for production, and interrogatories, you can create flawless first drafts of your propounding discovery in minutes.</p>
          <p>To access the new feature, navigate to a case folder on your account, ensure your case is labeled with the appropriate case type under ‘Edit Case Details’, and select “Draft Request” from the case’s dashboard.</p>
          <p><b>NOTICE</b>: This feature is currently in <b>BETA</b> - <i>meaning that its functionality will change and improve as we continue to build it</i> - please help us improve this feature by providing feedback. Thank you for helping us make Briefpoint work better for you in advance.</p>
          <h1>Propounding Discovery Roadmap</h1>
          <ul>
            <li>Word Processing - Bold, underline, etc.</li>
            <li>Person Most Qualified (PMQ) Document Types</li>
            <li>Automated Dupe / Cumulative Request Checking</li>
            <li>Automated Request / Interrogatory Generation from Uploaded-Samples</li>
            <li>35 SROG/RFA Limit Notification & Declaration Generation</li>
            <li>Automated Generation of “If Contention” Sets</li>
            <li>Automated Definition Extraction from Uploads</li>
            <li>Customized Output Documents</li>
            <li>And more!</li>
          </ul>
          <p>Don’t see a feature you’d like in Version 2?</p>
          <p>Let us know!</p>
          <p>Simply select the feedback tab on the right of your page and state the feature you’d like to have included in our next update.</p>
        </div>
        <div id="discovery_requests_howto">
          <h1 className='no-margin-bottom'>How to Create Propounding Discovery on Briefpoint for a New Case or Client</h1>
          <iframe src="https://player.vimeo.com/video/868072970" title="Propounding Discovery" width="720" height='480' frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
          <h2 className='move-margin-up'>Step 1: Add New Client/Case</h2>
          <p>This step is automatically executed upon the upload of any of your opposing counsels’ discovery request .pdfs.</p>
          <p>However, if you have not been served with discovery in your case, you will need to create a new client and case folder by taking the following steps:</p>
          <p>Select “Add New Client” from your “Clients” page:</p>
          <img src='https://bppublic.blob.core.windows.net/public/tutorials/AddClient.png' width='720px' title='Add Client' alt='How to add a new Client' />
          <p>Type in your client’s name before selecting “Add”:</p>
          <img src='https://bppublic.blob.core.windows.net/public/tutorials/AddClientInfo.png' width='720px' title='Add Client Details' alt="How to set a Client's Name" />
          <p>Select “View Client” on the newly added client:</p>
          <img src='https://bppublic.blob.core.windows.net/public/tutorials/ViewClient.png' width='720px' title='View Client' alt='View Client button' />
          <p>Select “Add New Case”:</p>
          <img src='https://bppublic.blob.core.windows.net/public/tutorials/AddCase.png' width='720px' title='Add Case' alt="How to add a new Case" />
          <h2>Step 2: Configure your Case</h2>
          <p>Configuring your case with the appropriate type and tags will ensure the correct interrogatories and requests are inserted into your propounding discovery documents.</p>
          <p>To set your case type, select the Case Type:</p>
          <img src='https://bppublic.blob.core.windows.net/public/tutorials/CaseType.png' width='720px' title='Set CaseType' alt="How to set CaseType" />
          <p>Then, to the extent necessary, add “Tags,” which represent sets of requests or interrogatories that share that tag:</p>
          <img src='https://bppublic.blob.core.windows.net/public/tutorials/AddTag.png' width='720px' title='Add Tag(s)' alt="Adding Tags" />
          <p>The number in the brackets to the right of the tag represents how many requests and interrogatories you have on your account that share that tag.</p>
          <p>For example, under “Employer”, there are 282 requests for admission, requests for production, and special interrogatories on this Briefpoint account.</p>
          <p>The “Employer” tag contains requests and interrogatories designed to be propounded against a responding party that is an employer.</p>
          <h2>Step 3: Create a Discovery Request or Interrogatory</h2>
          <p>Now, from your case’s dashboard, select “Draft Request” and choose the type of discovery you would like to generate:</p>
          <img src='https://bppublic.blob.core.windows.net/public/tutorials/DraftRequest.png' width='720px' title='Draft Request' alt="Drafting a Request" />
          <p>Select which requests or interrogatories you would like to keep, edit those that you’d like to edit, and click “Open in Word” whenever you’re ready to finish the document on your own.</p>
          <h2 className='move-margin-down'>Add Your Own Requests and Interrogatories to Briefpoint</h2>
          <iframe src="https://player.vimeo.com/video/868088785" title="Add Requests" width="720" height="480" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div id="demo">
          <h1 className='no-margin-top no-margin-bottom' >Demo</h1>
          <iframe src="https://player.vimeo.com/video/690332510?h=2bc14f541c" title="demo" width="720" height="480" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div id={'need_help'}>
          <h1>
            Need help with something else?
          </h1>
          <p>
            Contact us at: <a href="mailto:support@briefpoint.ai?subject=App Help">
              support@briefpoint.ai
            </a>
          </p>
        </div>
      </div>
    </Container >
  );
}
