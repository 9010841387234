/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ObjectTypeEnum,
    ObjectTypeEnumFromJSON,
    ObjectTypeEnumFromJSONTyped,
    ObjectTypeEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface PostCommunicationItem
 */
export interface PostCommunicationItem {
    /**
     * 
     * @type {string}
     * @memberof PostCommunicationItem
     */
    parentCommunicationId?: string | null;
    /**
     * 
     * @type {ObjectTypeEnum}
     * @memberof PostCommunicationItem
     */
    objectType?: ObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostCommunicationItem
     */
    objectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCommunicationItem
     */
    communication?: string | null;
}

export function PostCommunicationItemFromJSON(json: any): PostCommunicationItem {
    return PostCommunicationItemFromJSONTyped(json, false);
}

export function PostCommunicationItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCommunicationItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentCommunicationId': !exists(json, 'parentCommunicationId') ? undefined : json['parentCommunicationId'],
        'objectType': !exists(json, 'objectType') ? undefined : ObjectTypeEnumFromJSON(json['objectType']),
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'communication': !exists(json, 'communication') ? undefined : json['communication'],
    };
}

export function PostCommunicationItemToJSON(value?: PostCommunicationItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentCommunicationId': value.parentCommunicationId,
        'objectType': ObjectTypeEnumToJSON(value.objectType),
        'objectId': value.objectId,
        'communication': value.communication,
    };
}


