/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionStatus {
    Unknown = 'Unknown',
    Active = 'Active',
    Canceled = 'Canceled',
    Incomplete = 'Incomplete',
    IncompleteExpired = 'IncompleteExpired',
    PastDue = 'PastDue',
    Trialing = 'Trialing',
    Unpaid = 'Unpaid'
}

export function SubscriptionStatusFromJSON(json: any): SubscriptionStatus {
    return SubscriptionStatusFromJSONTyped(json, false);
}

export function SubscriptionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionStatus {
    return json as SubscriptionStatus;
}

export function SubscriptionStatusToJSON(value?: SubscriptionStatus | null): any {
    return value as any;
}

