/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ReactionType {
    Unknown = 0,
    ThumbsUp = 1,
    ThumbsDown = 2,
    Revert = 4,
    Modify = 8
}

export function ReactionTypeFromJSON(json: any): ReactionType {
    return ReactionTypeFromJSONTyped(json, false);
}

export function ReactionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReactionType {
    return json as ReactionType;
}

export function ReactionTypeToJSON(value?: ReactionType | null): any {
    return value as any;
}

