/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    ContactInfo,
    ContactInfoFromJSON,
    ContactInfoFromJSONTyped,
    ContactInfoToJSON,
    FirmBillingInfo,
    FirmBillingInfoFromJSON,
    FirmBillingInfoFromJSONTyped,
    FirmBillingInfoToJSON,
    FirmPreferences,
    FirmPreferencesFromJSON,
    FirmPreferencesFromJSONTyped,
    FirmPreferencesToJSON,
    Office,
    OfficeFromJSON,
    OfficeFromJSONTyped,
    OfficeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Firm
 */
export interface Firm {
    /**
     * 
     * @type {string}
     * @memberof Firm
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Firm
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof Firm
     */
    createdOn?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Firm
     */
    bridgeCode?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof Firm
     */
    address?: Address;
    /**
     * 
     * @type {Array<ContactInfo>}
     * @memberof Firm
     */
    contactInfo: Array<ContactInfo>;
    /**
     * 
     * @type {FirmBillingInfo}
     * @memberof Firm
     */
    billingInfo: FirmBillingInfo;
    /**
     * 
     * @type {Array<string>}
     * @memberof Firm
     */
    users?: Array<string> | null;
    /**
     * 
     * @type {Array<Office>}
     * @memberof Firm
     */
    offices?: Array<Office> | null;
    /**
     * 
     * @type {FirmPreferences}
     * @memberof Firm
     */
    preferences?: FirmPreferences;
}

export function FirmFromJSON(json: any): Firm {
    return FirmFromJSONTyped(json, false);
}

export function FirmFromJSONTyped(json: any, ignoreDiscriminator: boolean): Firm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'createdOn': !exists(json, 'createdOn') ? undefined : (json['createdOn'] === null ? null : new Date(json['createdOn'])),
        'bridgeCode': !exists(json, 'bridgeCode') ? undefined : json['bridgeCode'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'contactInfo': ((json['contactInfo'] as Array<any>).map(ContactInfoFromJSON)),
        'billingInfo': FirmBillingInfoFromJSON(json['billingInfo']),
        'users': !exists(json, 'users') ? undefined : json['users'],
        'offices': !exists(json, 'offices') ? undefined : (json['offices'] === null ? null : (json['offices'] as Array<any>).map(OfficeFromJSON)),
        'preferences': !exists(json, 'preferences') ? undefined : FirmPreferencesFromJSON(json['preferences']),
    };
}

export function FirmToJSON(value?: Firm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'createdOn': value.createdOn === undefined ? undefined : (value.createdOn === null ? null : value.createdOn.toISOString()),
        'bridgeCode': value.bridgeCode,
        'address': AddressToJSON(value.address),
        'contactInfo': ((value.contactInfo as Array<any>).map(ContactInfoToJSON)),
        'billingInfo': FirmBillingInfoToJSON(value.billingInfo),
        'users': value.users,
        'offices': value.offices === undefined ? undefined : (value.offices === null ? null : (value.offices as Array<any>).map(OfficeToJSON)),
        'preferences': FirmPreferencesToJSON(value.preferences),
    };
}


