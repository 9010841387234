import React, { useEffect, useRef, useState } from 'react';
import { sanitizeHtmlString } from 'utils';
import './styles.scss';
import { useDocumentApi } from 'hooks/useApi';
import { Button } from 'react-bootstrap';

export default function CommunicationsList({
  interrogatoriesList,
  onUpdateInterrogatory,
  setLinkRefs,
  questionType,
  readOnly
}: {
  interrogatoriesList: IntLi[],
  onUpdateInterrogatory: (updatedInterrogatory: IntLi) => void;
  setLinkRefs: (newRefs: {
    [key: string]: React.RefObject<HTMLDivElement>;
  }) => void;
  questionType: string;
  readOnly: boolean;
}) {

  const refs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  useEffect(() => {
    if (interrogatoriesList.length < 1) {
      return;
    }
    interrogatoriesList.forEach(item => {
      if (!refs.current[`${item.number}`]) {
        refs.current[`${item.number}`] = React.createRef();
      }
    });
    setLinkRefs(refs.current);
  }, [interrogatoriesList, setLinkRefs]);

  return (
    <div className="interrogatories-edit">
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {interrogatoriesList.map((interrogatory) => (
          <li key={interrogatory.id} className="selection-item">
            <InterrogatoryLI
              interrogatory={interrogatory}
              onUpdateInterrogatory={onUpdateInterrogatory}
              liRef={refs.current[`${interrogatory.number}`]}
              questionType={questionType}
              readonly={readOnly} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export function InterrogatoryLI({
  interrogatory,
  onUpdateInterrogatory,
  liRef,
  questionType,
  readonly
}: {
  interrogatory: IntLi;
  onUpdateInterrogatory: (updatedInterrogatory: IntLi) => void;
  liRef: React.RefObject<HTMLDivElement>;
  questionType: string;
  readonly: boolean;
}) {
  const typingTimerInterval = 5000;
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();
  const [suggesting, setSuggesting] = useState(false);
  const [txt, setTxt] = useState(interrogatory.clientMessage);
  const documentApi = useDocumentApi();

  useEffect(() => {
    if (interrogatory?.clientMessage) {
      setTxt(interrogatory.clientMessage)
    }
  }, [interrogatory.clientMessage]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (readonly) {
      return;
    }

    const updatedInterrogatory = {
      ...interrogatory,
      selected: e.target.checked,
      action: 'cb'
    };
    onUpdateInterrogatory(updatedInterrogatory);
  };
  
  const handleSpanClick = async () => {
    if (readonly) {
      return;
    }

    const newCbState = !interrogatory.selected;
    const updatedInterrogatory = {
      ...interrogatory,
      selected: newCbState,
      action: 'cb'
    };
    onUpdateInterrogatory(updatedInterrogatory);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSpanClick();
    }
  };

  const onChangeTxt = (newText: string) => {
    setTxt(newText);
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(setTimeout(() => onTypingDone(), typingTimerInterval));
  };

  const onLooseFocusTxt = () => {
    if (timer) {
      clearTimeout(timer);
    }
    onTypingDone();
  };

  const plainEnglish = async () => {
    setSuggesting(true);
    const plain = await documentApi.documentTranslateToPlain({ documentId: interrogatory.documentId!, interrogatoryId: interrogatory.id! });
    if (plain) {
      const updatedInterrogatory = {
        ...interrogatory,
        action: interrogatory.clientMessage !== undefined ? 'text-update' : 'text-create'
      };
      updatedInterrogatory.clientMessage = !!updatedInterrogatory.clientMessage ? `${updatedInterrogatory.clientMessage}\n\n${plain}` : plain;
      updatedInterrogatory.wasTranslated = true;
      onUpdateInterrogatory(updatedInterrogatory);
    }
    setSuggesting(false);
  }

  const onTypingDone = () => {
    var noTxt = (!txt || txt.trim().length === 0);
    var msgEmpty = !interrogatory.clientMessage || interrogatory.clientMessage.trim().length === 0;
    if ((noTxt && msgEmpty) || (!msgEmpty && interrogatory.clientMessage === txt)) {
      return;
    }
    const updatedInterrogatory = {
      ...interrogatory,
      action: interrogatory.clientMessage !== undefined ? 'text-update' : 'text-create',
      clientMessage: txt
    };
    onUpdateInterrogatory(updatedInterrogatory);
  };

  return (
    <div className={`int-li ${!interrogatory.selected ? 'disabled' : ''}`}
      key={interrogatory.id}
      ref={liRef}
    >
      {
        <div className='d-flex flex-column'>
          <div className='interrogatory-question-header' >
            <input
              type="checkbox"
              className="interrogatory-checkbox"
              checked={interrogatory.selected}
              onChange={handleCheckboxChange}
              onKeyDownCapture={handleKeyPress}
              readOnly={readonly}
              id={`question-${interrogatory.number}`}
              name={`question-${interrogatory.number}`}
            /> <label htmlFor={`question-${interrogatory.number}`}>{questionType} {interrogatory.number}</label>
          </div>
          <div className='interrogatory-question-body mt-2'>
            <span className={'sp-click'}
              onClick={handleSpanClick}
              dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(`${interrogatory.text}`) }} />
            {//.documentid isn't set for FROGS
              interrogatory.documentId &&
              <div>
                <Button variant='outline-primary' className={`plain-button ${suggesting ? 'suggesting' : ''}`} onClick={plainEnglish} disabled={readonly || suggesting || interrogatory.wasTranslated || !interrogatory.selected}><svg style={{ marginTop: "-2px" }} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_15_3207)">
                    <path d="M0.208008 6.18145C0.208008 6.18145 2.23034 6.06688 3.56788 4.43251C4.90543 2.79814 5.36195 0.0146484 5.36195 0.0146484C5.36195 0.0146484 5.8385 2.78803 7.05991 4.40555C8.28132 6.02307 10.3918 6.18482 10.3918 6.18482C10.3918 6.18482 8.39746 6.33984 7.12399 7.8967C5.85052 9.45357 5.37797 11.9473 5.37797 11.9473C5.37797 11.9473 4.89742 9.58499 3.50782 7.85964C2.2784 6.32636 0.208008 6.18145 0.208008 6.18145Z" fill="currentColor" >
                      {suggesting &&
                        <animate attributeName="opacity"
                          values=".2;1;.2" dur="2.5s" begin='-2s'
                          repeatCount="indefinite" />
                      }
                    </path>
                    <path d="M7.09619 11.8428C7.09619 11.8428 8.55788 11.7619 9.527 10.5791C10.4961 9.39629 10.8245 7.38449 10.8245 7.38449C10.8245 7.38449 11.1689 9.38955 12.0539 10.5589C12.9389 11.7282 14.4647 11.8462 14.4647 11.8462C14.4647 11.8462 13.023 11.9574 12.102 13.0829C11.1809 14.2084 10.8405 16.0113 10.8405 16.0113C10.8405 16.0113 10.4921 14.3028 9.48695 13.0559C8.59392 11.9473 7.1002 11.8428 7.1002 11.8428H7.09619Z" fill="currentColor" >
                      {suggesting &&
                        <animate attributeName="opacity"
                          values=".2;1;.2" dur="2.5s" begin='-1s'
                          repeatCount="indefinite" />
                      }
                    </path>
                    <path d="M9.4669 3.73833C9.4669 3.73833 10.5802 3.6743 11.317 2.77792C12.0539 1.88154 12.3022 0.348267 12.3022 0.348267C12.3022 0.348267 12.5625 1.8748 13.2352 2.76107C13.908 3.64734 15.0654 3.7417 15.0654 3.7417C15.0654 3.7417 13.9681 3.82594 13.2673 4.68188C12.5665 5.53782 12.3062 6.90935 12.3062 6.90935C12.3062 6.90935 12.0419 5.60859 11.277 4.66166C10.6002 3.8192 9.46289 3.73833 9.46289 3.73833H9.4669Z" fill="currentColor" >
                      {suggesting &&
                        <animate attributeName="opacity"
                          values=".2;1;.2" dur="2.5s"
                          repeatCount="indefinite" />
                      }
                    </path>
                  </g>
                  <defs>
                    <clipPath id="clip0_15_3207">
                      <rect width="14.8571" height="16" fill="white" transform="translate(0.208008 0.0146484)" />
                    </clipPath>
                  </defs>
                </svg>&nbsp;{suggesting ? "Translating..." : (interrogatory.wasTranslated ? "Translated!" : "Translate to Plain English")}</Button></div>}
            <div className='d-flex flex-column'>

              <label htmlFor={`message-${interrogatory.number}`} className='client-msg-lbl'>Message to Client (Optional)</label>

              <textarea
                value={txt}
                onChange={e => onChangeTxt(e.target.value)}
                onBlur={_ => onLooseFocusTxt()}
                readOnly={readonly}
                className='txt-client-feedback'
                id={`message-${interrogatory.number}`}
                name={`message-${interrogatory.number}`}
                disabled={!interrogatory.selected}
                rows={5}
              />
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export interface IntLi {
  id?: number;
  documentId?: string;
  number?: string;
  text?: string | null;
  selected?: boolean;
  action?: string;
  includeTranslation?: boolean;
  clientMessage?: string;
  wasTranslated?: boolean;
}
