/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Firm,
    FirmFromJSON,
    FirmFromJSONTyped,
    FirmToJSON,
} from './';

/**
 * 
 * @export
 * @interface FirmPlusAdminInfo
 */
export interface FirmPlusAdminInfo {
    /**
     * 
     * @type {Firm}
     * @memberof FirmPlusAdminInfo
     */
    firm?: Firm;
    /**
     * 
     * @type {number}
     * @memberof FirmPlusAdminInfo
     */
    attorneyCount?: number;
}

export function FirmPlusAdminInfoFromJSON(json: any): FirmPlusAdminInfo {
    return FirmPlusAdminInfoFromJSONTyped(json, false);
}

export function FirmPlusAdminInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirmPlusAdminInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firm': !exists(json, 'firm') ? undefined : FirmFromJSON(json['firm']),
        'attorneyCount': !exists(json, 'attorneyCount') ? undefined : json['attorneyCount'],
    };
}

export function FirmPlusAdminInfoToJSON(value?: FirmPlusAdminInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firm': FirmToJSON(value.firm),
        'attorneyCount': value.attorneyCount,
    };
}


