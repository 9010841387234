import { DateTime } from 'luxon';

function formatDate(date: Date, options: Intl.DateTimeFormatOptions | undefined) {
  return new Intl.DateTimeFormat('en-us', options).format(date);
}

export function formatLongDate(date: Date, optionOverrides: Intl.DateTimeFormatOptions | undefined = {}) {
  const options: Intl.DateTimeFormatOptions | undefined = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    ...optionOverrides,
  };
  return formatDate(date, options);
}

export function formatShortDate(date: Date) {
  return formatDate(date, {});
}

export function toDateOrNow(val?: string | null) {
  return val ? DateTime.fromISO(val) : DateTime.now();
}

/**
 * Tests provided UserAgent against Known Mobile User Agents
 * @returns {bool} isMobileDevice
 */
export const isMobile = () =>
  /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
    window.navigator.userAgent || window.navigator.vendor
  );

/**
 * Tests userAgent to see if browser is IE
 * @returns {bool} isInternetExplorer
 */
export const isInternetExplorer = () =>
  /MSIE/.test(window.navigator.userAgent) || /Trident/.test(window.navigator.userAgent);

export const escapeICSDescription = (description: string) => description.replace(/(\r?\n|<br ?\/?>)/g, '\\n');

/**
 * Takes an event object and returns a Google Calendar Event URL
 * @param {string} event.description
 * @param {DateTime} event.startDatetime
 * @param {string} event.title
 * @returns {string} Google Calendar Event URL
 */
function googleShareUrl(description: string, startDatetime: DateTime, title: string) {
  const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${formatCalDate(
    startDatetime
  )}/${formatCalDate(startDatetime.plus({ days: 1 }))}&text=${title}&details=${description}`;
  return url;
}

/**
 * Takes an event object and returns an array to be downloaded as ics file
 * @param {string} event.description
 * @param {DateTime} event.startDatetime
 * @param {string} event.title
 * @returns {array} ICS Content
 */
function buildShareFile(description = '', startDatetime: DateTime, title = '', url = '') {
  let content = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `URL:${url}`,
    'METHOD:PUBLISH',
    // TODO: Will need to parse the date without Z for ics
    // This means I'll probably have to require a date lib - luxon most likely or datefns
    `DTSTART;VALUE=DATE:${formatCalDate(startDatetime)}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${escapeICSDescription(description)}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n');

  return isMobile() ? encodeURI(`data:text/calendar;charset=utf8,${content}`) : content;
}

/**
 * Takes an event object and a type of URL and returns either a calendar event
 * URL or the contents of an ics file.
 * @param {string} event.description
 * @param {string} event.startDatetime
 * @param {string} event.title
 * @param {enum} type One of SHARE_SITES from ./enums
 */
export const buildShareUrl = (
  description: string,
  startDatetime: string | null | undefined,
  title: string,
  type: string,
  url?: string
) => {
  const encodeURI = type !== SHARE_SITES.ICAL && type !== SHARE_SITES.OUTLOOK;

  const data = {
    description: encodeURI ? encodeURIComponent(description) : description,
    startDatetime: startDatetime,
    title: encodeURI ? encodeURIComponent(title) : title,
  };

  const start = startDatetime ? DateTime.fromISO(`${startDatetime}`) : DateTime.now();

  switch (type) {
    case SHARE_SITES.GOOGLE:
      return googleShareUrl(data.description, start, data.title);
    default:
      return buildShareFile(data.description, start, data.title, url);
  }
};

function formatCalDate(date: DateTime) {
  return `${date.toFormat('yyyyMMdd')}`;
}

export const SHARE_SITES = {
  GOOGLE: 'Google',
  ICAL: 'iCal',
  OUTLOOK: 'Outlook',
};
