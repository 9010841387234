/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationSource,
    CommunicationSourceFromJSON,
    CommunicationSourceFromJSONTyped,
    CommunicationSourceToJSON,
    ObjectTypeEnum,
    ObjectTypeEnumFromJSON,
    ObjectTypeEnumFromJSONTyped,
    ObjectTypeEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface ViewCommunicationItem
 */
export interface ViewCommunicationItem {
    /**
     * 
     * @type {string}
     * @memberof ViewCommunicationItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewCommunicationItem
     */
    externalCommunicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewCommunicationItem
     */
    parentCommunicationId?: string | null;
    /**
     * 
     * @type {ObjectTypeEnum}
     * @memberof ViewCommunicationItem
     */
    objectType?: ObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ViewCommunicationItem
     */
    objectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ViewCommunicationItem
     */
    communication?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ViewCommunicationItem
     */
    createdDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ViewCommunicationItem
     */
    lastActivityDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ViewCommunicationItem
     */
    createdBy?: string;
    /**
     * 
     * @type {CommunicationSource}
     * @memberof ViewCommunicationItem
     */
    source?: CommunicationSource;
}

export function ViewCommunicationItemFromJSON(json: any): ViewCommunicationItem {
    return ViewCommunicationItemFromJSONTyped(json, false);
}

export function ViewCommunicationItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewCommunicationItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'id': !exists(json, 'id') ? undefined : json['id'],
        'externalCommunicationId': !exists(json, 'externalCommunicationId') ? undefined : json['externalCommunicationId'],
        'parentCommunicationId': !exists(json, 'parentCommunicationId') ? undefined : json['parentCommunicationId'],
        'objectType': !exists(json, 'objectType') ? undefined : ObjectTypeEnumFromJSON(json['objectType']),
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'communication': !exists(json, 'communication') ? undefined : json['communication'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'lastActivityDate': !exists(json, 'lastActivityDate') ? undefined : (json['lastActivityDate'] === null ? null : new Date(json['lastActivityDate'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'source': !exists(json, 'source') ? undefined : CommunicationSourceFromJSON(json['source']),
    };
}

export function ViewCommunicationItemToJSON(value?: ViewCommunicationItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'id': value.id,
        'externalCommunicationId': value.externalCommunicationId,
        'parentCommunicationId': value.parentCommunicationId,
        'objectType': ObjectTypeEnumToJSON(value.objectType),
        'objectId': value.objectId,
        'communication': value.communication,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'lastActivityDate': value.lastActivityDate === undefined ? undefined : (value.lastActivityDate === null ? null : value.lastActivityDate.toISOString()),
        'createdBy': value.createdBy,
        'source': CommunicationSourceToJSON(value.source),
    };
}


