/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExtraUser,
    ExtraUserFromJSON,
    ExtraUserFromJSONTyped,
    ExtraUserToJSON,
    ObjectionVariant,
    ObjectionVariantFromJSON,
    ObjectionVariantFromJSONTyped,
    ObjectionVariantToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExtraVariants
 */
export interface ExtraVariants {
    /**
     * 
     * @type {Array<ExtraUser>}
     * @memberof ExtraVariants
     */
    users?: Array<ExtraUser> | null;
    /**
     * 
     * @type {Array<ObjectionVariant>}
     * @memberof ExtraVariants
     */
    variants?: Array<ObjectionVariant> | null;
}

export function ExtraVariantsFromJSON(json: any): ExtraVariants {
    return ExtraVariantsFromJSONTyped(json, false);
}

export function ExtraVariantsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtraVariants {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': !exists(json, 'users') ? undefined : (json['users'] === null ? null : (json['users'] as Array<any>).map(ExtraUserFromJSON)),
        'variants': !exists(json, 'variants') ? undefined : (json['variants'] === null ? null : (json['variants'] as Array<any>).map(ObjectionVariantFromJSON)),
    };
}

export function ExtraVariantsToJSON(value?: ExtraVariants | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': value.users === undefined ? undefined : (value.users === null ? null : (value.users as Array<any>).map(ExtraUserToJSON)),
        'variants': value.variants === undefined ? undefined : (value.variants === null ? null : (value.variants as Array<any>).map(ObjectionVariantToJSON)),
    };
}


