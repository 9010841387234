/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CaseClass,
    CaseClassFromJSON,
    CaseClassFromJSONTyped,
    CaseClassToJSON,
    Jurisdictions,
    JurisdictionsFromJSON,
    JurisdictionsFromJSONTyped,
    JurisdictionsToJSON,
    Venue,
    VenueFromJSON,
    VenueFromJSONTyped,
    VenueToJSON,
} from './';

/**
 * 
 * @export
 * @interface Jurisdiction
 */
export interface Jurisdiction {
    /**
     * 
     * @type {string}
     * @memberof Jurisdiction
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Jurisdiction
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Jurisdiction
     */
    searchNames?: Array<string> | null;
    /**
     * 
     * @type {Jurisdictions}
     * @memberof Jurisdiction
     */
    type?: Jurisdictions;
    /**
     * 
     * @type {string}
     * @memberof Jurisdiction
     */
    abbreviation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Jurisdiction
     */
    friendlyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Jurisdiction
     */
    venueFormat?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Jurisdiction
     */
    isFullySupported?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Jurisdiction
     */
    verificationFormId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Jurisdiction
     */
    supportsPOS?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Jurisdiction
     */
    supportsDueDates?: boolean;
    /**
     * 
     * @type {Array<Venue>}
     * @memberof Jurisdiction
     */
    venues?: Array<Venue> | null;
    /**
     * 
     * @type {Array<CaseClass>}
     * @memberof Jurisdiction
     */
    caseClasses?: Array<CaseClass> | null;
}

export function JurisdictionFromJSON(json: any): Jurisdiction {
    return JurisdictionFromJSONTyped(json, false);
}

export function JurisdictionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Jurisdiction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'searchNames': !exists(json, 'searchNames') ? undefined : json['searchNames'],
        'type': !exists(json, 'type') ? undefined : JurisdictionsFromJSON(json['type']),
        'abbreviation': !exists(json, 'abbreviation') ? undefined : json['abbreviation'],
        'friendlyName': !exists(json, 'friendlyName') ? undefined : json['friendlyName'],
        'venueFormat': !exists(json, 'venueFormat') ? undefined : json['venueFormat'],
        'isFullySupported': !exists(json, 'isFullySupported') ? undefined : json['isFullySupported'],
        'verificationFormId': !exists(json, 'verificationFormId') ? undefined : json['verificationFormId'],
        'supportsPOS': !exists(json, 'supportsPOS') ? undefined : json['supportsPOS'],
        'supportsDueDates': !exists(json, 'supportsDueDates') ? undefined : json['supportsDueDates'],
        'venues': !exists(json, 'venues') ? undefined : (json['venues'] === null ? null : (json['venues'] as Array<any>).map(VenueFromJSON)),
        'caseClasses': !exists(json, 'caseClasses') ? undefined : (json['caseClasses'] === null ? null : (json['caseClasses'] as Array<any>).map(CaseClassFromJSON)),
    };
}

export function JurisdictionToJSON(value?: Jurisdiction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'searchNames': value.searchNames,
        'type': JurisdictionsToJSON(value.type),
        'abbreviation': value.abbreviation,
        'friendlyName': value.friendlyName,
        'venueFormat': value.venueFormat,
        'isFullySupported': value.isFullySupported,
        'verificationFormId': value.verificationFormId,
        'supportsPOS': value.supportsPOS,
        'supportsDueDates': value.supportsDueDates,
        'venues': value.venues === undefined ? undefined : (value.venues === null ? null : (value.venues as Array<any>).map(VenueToJSON)),
        'caseClasses': value.caseClasses === undefined ? undefined : (value.caseClasses === null ? null : (value.caseClasses as Array<any>).map(CaseClassToJSON)),
    };
}


