import { Case, Jurisdiction, PartyPosition, Representative } from 'briefpoint-client';
import Loading from 'components/Loading';
import { useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { SortVenue } from 'screens/DocumentWizard/SpecialInterrogatoriesStep/Selections/utils';

interface Props {
  _case?: Case;
  jurisdiction?: Jurisdiction;
  onChange: (value: any, field: keyof Case) => void;
}

export default function CaseInfo({ _case, jurisdiction, onChange }: Props) {
  const position = _case?.clientPosition;
  const representative = _case?.representativeType ?? Representative.Individual;
  const typeaheadRef = useRef<any>();

  async function updateVenue(venue: string) {
    onChange(venue, "venue");
  }

  // need to do this jazz because UserService enums are being returned as strings not numbers like the DocumentService,
  // probably need to change UserService to behave like DocumentService but there are too many cases to handle right now
  const positionValues = position?.split(',').map(item => item.trim()) ?? [];
  function flipPosition(val: PartyPosition) {
    var existing = positionValues.indexOf(val);

    if (existing !== -1) {
      positionValues.splice(existing, 1);
    } else {
      positionValues.push(val);
    }
    const newPosition = positionValues.join(", ") as PartyPosition;
    onChange(newPosition ? newPosition : PartyPosition.Unknown, "clientPosition");
  }

  function updateRepresentative(val: Representative) {
    onChange(val, "representativeType");
  }

  return (
    <Loading isLoading={!_case || !jurisdiction}>
      <Row>
        <Col className='col-4'>
          <Form.Label className="required">Client Position</Form.Label>
          <Row>
            <Col className='col-3 position-check-col'>
              <Form.Check
                id={'propounding-party-plaintiff'}
                type={'checkbox'}
                label="Plaintiff"
                checked={positionValues.includes(PartyPosition.Plaintiff)}
                onChange={() => flipPosition(PartyPosition.Plaintiff)}
                isInvalid={position === PartyPosition.Unknown}
              />
              <Form.Check
                id={'propounding-party-defendant'}
                type={'checkbox'}
                label="Defendant"
                checked={positionValues.includes(PartyPosition.Defendant)}
                onChange={() => flipPosition(PartyPosition.Defendant)}
                isInvalid={position === PartyPosition.Unknown}
              />

            </Col>
            <Col>
              <Form.Check
                id={'propounding-party-cross-defendant'}
                type={'checkbox'}
                label="Cross-Defendant"
                checked={positionValues.includes(PartyPosition.CrossDefendant)}
                onChange={() => flipPosition(PartyPosition.CrossDefendant)}
                isInvalid={position === PartyPosition.Unknown}
              />
              <Form.Check
                id={'propounding-party-cross-complainant'}
                type={'checkbox'}
                label="Cross-Complainant"
                checked={positionValues.includes(PartyPosition.CrossComplainant)}
                onChange={() => flipPosition(PartyPosition.CrossComplainant)}
                isInvalid={position === PartyPosition.Unknown}
              />
            </Col>
          </Row>
        </Col>
        <Col className='col-4'>

          <Form.Label>Representative Action</Form.Label>

          <div>
            <Form.Check
              id={'representative-none'}
              inline
              type={'radio'}
              label="Individual"
              checked={representative === Representative.Individual}
              value={Representative.Individual}
              onChange={(e) => updateRepresentative(e.currentTarget.value as Representative)}
            />
            <Form.Check
              id={'representative-class'}
              inline
              type={'radio'}
              label="Class Action"
              checked={representative === Representative.ClassAction}
              value={Representative.ClassAction}
              onChange={(e) => updateRepresentative(e.currentTarget.value as Representative)}
            />
            <Form.Check
              id={'representative-paga'}
              inline
              type={'radio'}
              label="PAGA"
              checked={representative === Representative.Paga}
              value={Representative.Paga}
              onChange={(e) => updateRepresentative(e.currentTarget.value as Representative)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='col-4'>
          <label htmlFor='jurisdiction'>Jurisdiction</label>
          <Form.Control id='jurisdiction' value={jurisdiction?.searchNames![0]!} disabled />
          <label htmlFor='case-number'>Case Number</label>
          <Form.Control id='case-number' value={_case?.caseNumber ?? ''} onChange={(e) => onChange(e.target.value, "caseNumber")} />
          <label htmlFor='plaintiff'>Plaintiff(s)</label>
          <Form.Control id='plaintiff' value={_case?.plaintiffs ?? ''} onChange={(e) => onChange(e.target.value, "plaintiffs")} />
        </Col>
        <Col className='col-4'>
          <label htmlFor='venue' className="required">Venue</label>
          <Typeahead
            onChange={(e: any) => updateVenue(e[0]?.id)}
            selected={(_case?.venue && [jurisdiction?.venues!.find(x => x.id === _case.venue)!]) || []}
            isInvalid={!_case?.venue}
            ref={typeaheadRef}
            id="venue"
            options={jurisdiction?.venues?.sort(SortVenue) || []}
            placeholder="Select a Venue..."
            labelKey={(o: any) => `${o.shortName}`} />
          <label htmlFor='judge'>Judge</label>
          <Form.Control id='judge' value={_case?.judge ?? ''} onChange={(e) => onChange(e.target.value, "judge")} />
          <label htmlFor='defendant'>Defendant(s)</label>
          <Form.Control id='defendant' value={_case?.defendants ?? ''} onChange={(e) => onChange(e.target.value, "defendants")} />
        </Col>
      </Row>
    </Loading>
  );
}
