import React, { useCallback, useEffect, useState } from 'react';
import { DocumentClient, DocumentStatusDisplay, GetDocumentSubTypeName } from '../../services/DocumentService';
import { formatShortDate } from '../../utils/dateUtils';
import { DocumentIcon } from "./DocumentList";
import RestoreIcon from './RestoreIcon';
import { Link } from "react-router-dom";
import { useDocumentApi } from "../../hooks/useApi";
import { DocumentStatus, IncludedDocuments } from "../../briefpoint-client";
import { transformDocument } from "../../services/DocumentService";
import Loading from "../Loading";

function ArchiveDocumentRow({
  doc,
  restoreDocument
}: {
  doc: DocumentClient;
  restoreDocument: (document: DocumentClient) => void;
}) {
  return (
    <tr>
      <td>
        <DocumentIcon extension="pdf" />
      </td>
      <td>{doc.name}</td>
      <td>{doc.info?.caseNumber}</td>
      <td>{doc.info?.documentSubType && GetDocumentSubTypeName(doc.info.documentSubType, doc.info.jurisdiction ?? '')}</td>
      <td>{formatShortDate(doc.uploadDate)}</td>
      <td>{DocumentStatusDisplay[doc.status || 0].title}</td>
      <td className="text-end">
        <RestoreIcon document={doc} restoreDocument={restoreDocument} />
      </td>
    </tr>
  );
}

export default function ArchiveDocumentList({
  restoreDocument
}: {
  restoreDocument: (document: DocumentClient) => Promise<void>;
}) {
  const [documents, setDocuments] = useState<DocumentClient[]>();
  const documentApi = useDocumentApi();

  const nonErroredDocs = documents?.filter(x => x.status !== DocumentStatus.Error);

  const loadArchivedDocuments = useCallback(async () => {
    const documents = await documentApi.documentGetList({ includedDocuments: IncludedDocuments.Archived });
    setDocuments(documents.map((d: any) => transformDocument(d)));;
  }, [documentApi]);

  useEffect(() => {
    loadArchivedDocuments();
  }, [loadArchivedDocuments]);

  const handleRestoreDocument = (document: DocumentClient) => {
    restoreDocument(document);
    setDocuments(documents?.filter(i => i !== document))
  }

  return (
    <Loading isLoading={documents === undefined}>
      {documents?.length ? (
        <table id="document-list-table">
          <thead>
            <tr>
              <th></th>
              <th>Document Name</th>
              <th>Case Number</th>
              <th>Document Type</th>
              <th>Upload Date</th>
              <th>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {nonErroredDocs?.map((doc) => (
              <ArchiveDocumentRow key={doc.id} doc={doc} restoreDocument={handleRestoreDocument} />
            ))}
          </tbody>
        </table>
      ) : (
        <p>You have no archived documents. Please upload a document <Link to="/documents">here</Link></p>
      )}
    </Loading>
  );
}