import React, { FunctionComponent } from 'react';
import { Spinner } from 'react-bootstrap';

interface Props {
  isLoading: boolean;
  showText?: boolean;
  text?: string;
  className?: string;
  animation?: 'border' | 'grow';
  color?: string;
}

const Loading: FunctionComponent<Props> = ({
  isLoading = false,
  showText = true,
  text = 'Loading...',
  children,
  className,
  animation = 'border',
  color = 'text-primary',
}) => {
  return isLoading ? (
    <div className={`d-flex justify-content-center align-items-center ${color} ${className}`}>
      <Spinner className="me-2" animation={animation} size="sm" />
      {showText && text}
    </div>
  ) : (
    <>{children}</>
  );
};

export default Loading;
