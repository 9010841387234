import { AssignedAttorney, User } from "briefpoint-client";
import { useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Circle, RecordCircle, TrashFill } from "react-bootstrap-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import InitialsAvatar from 'react-initials-avatar';
import './Attorneys.scss';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';

interface Props {
  assignedAttorneys: AssignedAttorney[];
  topLevelAttorneys: AssignedAttorney[];
  users: User[] | null;
  setAttorneys: React.Dispatch<React.SetStateAction<AssignedAttorney[]>>;
}

export default function Attorneys({ users, assignedAttorneys, topLevelAttorneys, setAttorneys }: Props) {
  const [addAttorneyId, setAddAttorneyId] = useState('');
  const typeaheadRef = useRef<any>();
  const caseHasPrimary = !!assignedAttorneys.find(x => x.isPrimary);
  const clientAttorneys = caseHasPrimary ? [] : users?.filter(u => topLevelAttorneys.find(a => a.id === u.id));
  const attorneysToAdd = users?.filter(u => !assignedAttorneys?.find(a => a.id === u.id) && !clientAttorneys?.find(a => a.id === u.id));

  function addAttorney() {
    if (addAttorneyId) {
      setAttorneys(a => [...a, { id: addAttorneyId, isPrimary: !a.length && !topLevelAttorneys?.find(x => x.isPrimary), order: a.length }]);
      setAddAttorneyId('');
      typeaheadRef?.current?.clear();
    }
  }

  function removeAttorney(attorney: AssignedAttorney) {
    let attorneysTemp = assignedAttorneys.filter(x => x !== attorney);

    if (attorneysTemp.length !== assignedAttorneys.length) {
      if (attorneysTemp.length && !topLevelAttorneys?.find(x => x.isPrimary)) {
        attorneysTemp[0].isPrimary = true;
      }
      if (attorney.isPrimary && topLevelAttorneys?.length) {
        attorneysTemp = attorneysTemp.filter(x => !topLevelAttorneys?.find(t => t.id === x.id));
      }
      setAttorneys(attorneysTemp);
    }
  }

  function makePrimary(attorney: AssignedAttorney) {
    const attorneysTemp = [...assignedAttorneys];
    let primary = attorneysTemp.find(x => x.isPrimary);
    if (primary) {
      primary.isPrimary = false;
    }
    let update = attorneysTemp.find(x => x.id === attorney.id);
    if (update) {
      update.isPrimary = true;
    }

    setAttorneys(attorneysTemp);
  }

  function removePrimary(attorney: AssignedAttorney) {
    // If there isn't a toplevel (from Client) attorney that's primary, you can only swap primary's
    if (!topLevelAttorneys?.find(x => x.id)) {
      return;
    }

    let attorneysTemp = [...assignedAttorneys];
    let primary = attorneysTemp.find(x => x.id === attorney.id);
    if (primary && primary.isPrimary) {
      primary.isPrimary = false;
      if (topLevelAttorneys?.length) {
        attorneysTemp = attorneysTemp.filter(x => !topLevelAttorneys?.find(t => t.id === x.id));
      }
    } else {
      return;
    }

    setAttorneys(attorneysTemp);
  }

  return (
    <>
      {users && users.length > 1 &&
        <div id='attorneys-container'>
          <h3>Attorneys</h3>
          {attorneysToAdd && !!attorneysToAdd.length &&
            <Row className='add-attorneys'>
              <Col className='col-6'>
                <Typeahead
                  onChange={(e: any) => setAddAttorneyId(e[0]?.id)}
                  clearButton
                  ref={typeaheadRef}
                  id="attorney-select"
                  options={attorneysToAdd || []}
                  placeholder="Select an Attorney..."
                  labelKey={(o: any) => `${o.firstName} ${o.lastName}`} />
              </Col>
              <Col className='col-3'>
                <Button size='sm' variant='outline-primary' onClick={addAttorney} disabled={!addAttorneyId}>+ Assign Attorney</Button>
              </Col>
            </Row>}
          <Row className='headers'>
            <Col className='col-6'>Attorney Name</Col>
            <Col className='col-3'>Primary Attorney</Col>
            <Col className='col-1'>
            </Col>
          </Row>
          {!caseHasPrimary && !!topLevelAttorneys.length &&
            topLevelAttorneys?.map(a => {
              var u = users.find(x => x.id === a.id);
              const name = `${u?.firstName} ${u?.lastName} (From Client)`;
              return (
                <Row className="top-level" key={`top-${a.id}`}>
                  <Col className='col-6 bold'>
                    <InitialsAvatar name={name} />
                    {name}
                  </Col>
                  <Col className='col-3'>
                    {a.isPrimary ? <RecordCircle /> : <Circle />}
                  </Col>
                  <Col className='col-1'>
                  </Col>
                </Row>
              );
            })
          }
          {assignedAttorneys?.map(a => {
            const u = users.find(x => x.id === a.id);
            const name = `${u?.firstName} ${u?.lastName}`;
            return (
              <Row className="attorney-select" key={`this-${a.id}`}>
                <Col className='col-6 bold'>
                  <InitialsAvatar name={name} />
                  {name}
                </Col>
                <Col className='col-3 set-primary'>
                  {a.isPrimary ? <RecordCircle size={'20px'} onClick={() => removePrimary(a)} /> : <Circle size={'20px'} onClick={() => makePrimary(a)} />}
                </Col>
                <Col className='col-1'>
                  <TrashFill
                    onClick={() => removeAttorney(a)}
                    className="table-edit-item"
                    title='Unassign'
                  />
                </Col>
              </Row>
            );
          })}
        </div>}
    </>
  );
}