// takes strings and/or objects in format { [className]: boolean }
// and returns a string with space separated class names suitable
export function classes(...args: Array<any>) {
  let output: string[] = [];
  args.forEach(arg => {
    if (typeof arg === 'string') {
      output.push(arg);
    } else if (typeof arg === 'object') {
      output.push(...Object.keys(arg).filter(key => Boolean(arg[key])));
    }
  });
  return output.join(' ');
}

// strip <script> tags from strings containing HTML
export function sanitizeHtmlString(text?: string | null) {
  if (!text) {
    return '';
  }
  return text.replace(/<\/?script>/gi, '');
}

const MAX_RETRIES_DEFAULT = 5

export async function promiseRetry<T>(
  fn: () => Promise<T>,
  retries = MAX_RETRIES_DEFAULT,
  retryIntervalMillis: number,
  previousError?: Error
): Promise<T> {
  return !retries
    ? Promise.reject(previousError)
    : fn().catch(async (error) => {
      await new Promise((resolve) => setTimeout(resolve, retryIntervalMillis))
      return promiseRetry(fn, retries - 1, retryIntervalMillis, error)
    })
}