import React, { useCallback, useMemo, useRef, useState } from "react";
import { ButtonGroup, Form, ToggleButton } from "react-bootstrap";
import Button from "components/Button";
import { ExternalCase, type Case, type Client, type Jurisdiction, ExternalPartners, DocumentType } from "briefpoint-client";
import { type Option } from "react-bootstrap-typeahead/types/types";
import { Typeahead } from "react-bootstrap-typeahead";
import { CustomMenu } from "./utils";
import styles from "./ReviewPage.module.scss";
import { RenderMenuProps } from "react-bootstrap-typeahead/types/components/Typeahead";
import ExternalPartnerConnection from "components/ExternalPartner/ExternalPartnerConnection";
import { useAuth } from "hooks/useAuth";
import Loading from "components/Loading";
import { debounce } from "lodash";

export class CombinedCaseSearch {
  constructor(public title: string, public search: string, public partner?: ExternalPartners, public externalId?: string, public internalId?: string) { }
}


const buildSearchString = (_case: Case) => {
  return `${_case.shortTitle} ${_case.title}`;
}

const buildSearchStringExternal = (_case: ExternalCase) => {
  return `${_case.title}`;
}

export const mergeCases = (cases?: Case[], externalCases?: ExternalCase[]) => {
  let mappedCases = cases?.map(c => new CombinedCaseSearch(c.shortTitle ?? c.title ?? '', buildSearchString(c), c.integration?.partner, c.integration?.identifier ?? undefined, c.id)) ?? [];
  if (!externalCases?.length) {
    return mappedCases;
  }

  let mappedExternal = externalCases.filter(x => x.title).map(c => new CombinedCaseSearch(c.title!, buildSearchStringExternal(c), c.partner!, c.id!, undefined));

  for (let i = 0; i < mappedExternal.length; i++) {
    const element = mappedExternal[i];

    const internal = mappedCases.find(x => x.partner === element.partner && x.externalId === element.externalId);
    if (internal) {
      element.internalId = internal.internalId;
      mappedCases = mappedCases.filter(c => c.internalId !== internal.internalId);
    }
  }

  return mappedCases.concat(mappedExternal);
}


// Venue, Case#, Judge
function ReviewPageOne({
  documentType,
  jurisdiction,
  venue,
  setVenue,
  caseNumber,
  setCaseNumber,
  judge,
  setJudge,
  showEdit,
  setShowEdit,
  _case,
  setCase,
  client,
  setClient,
  cases,
  externalCases,
  filterExternalCases,
  isExternalCasesLoading
}: {
  documentType: any;
  jurisdiction: Jurisdiction | undefined;
  venue: string;
  setVenue: React.Dispatch<React.SetStateAction<string>>;
  caseNumber: string;
  setCaseNumber: React.Dispatch<React.SetStateAction<string>>;
  judge: string;
  setJudge: React.Dispatch<React.SetStateAction<string>>;
  caseId?: string;
  showEdit: boolean;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
  _case?: Case;
  setCase: React.Dispatch<React.SetStateAction<Case | undefined>>;
  client?: Client;
  clients?: Client[];
  setClient: React.Dispatch<React.SetStateAction<Client | undefined>>;
  cases?: Case[];
  externalCases?: ExternalCase[];
  filterExternalCases: (filter?: string) => Promise<void>;
  isExternalCasesLoading?: boolean;
}) {
  const { user } = useAuth()!;

  // Checks if the current case is an existing case, if not, change toggle to 'New Case'
  let isExistingCase = !!_case && !!_case.id;

  // if the current case matches, default to the 'find case' field
  const [caseAction, setCaseAction] = useState<string>(isExistingCase || !showEdit ? "2" : "1");
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isExternalLoading, setIsLoadingExternal] = useState(false);
  const caseTypeaheadRef = useRef<any>();
  const venues = jurisdiction?.venues;
  const caseActionTypes = [
    { name: "New Case", value: "1" },
    { name: "Existing Case", value: "2" },
  ];

  function handleCaseChange(e: Option[]): void {
    const val = e[0];

    if (val) {
      const c = val as CombinedCaseSearch;
      let _case = cases?.find(x => x.id === c.internalId && x.integration?.identifier === c.externalId);

      if (!_case) {
        const integration = c.externalId ? { partner: c.partner!, identifier: c.externalId! } : undefined;
        _case = { shortTitle: c.title, integration };
      }

      setCase(_case);
      setShowMenu(false);
      if (_case.venue) {
        setVenue(_case.venue);
      }

      if ((!!_case && !!_case.id) || _case.integration?.identifier) {
        setShowMenu(false);
        setCaseAction("2");
      } else {
        setCaseAction("1");
        setClient(undefined);
      }
    }

  }

  function handleToggleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    setCaseAction(value);
    setCase(undefined);
    caseTypeaheadRef.current.focus();
    if (value === "1") {
      if (cases) {
        caseTypeaheadRef.current.toggleMenu();
      }
      setClient(undefined);
    }
  }

  function handleOnInputChange(text: string) {
    if (text) {
      const isMatching = sortedCases.some(option => option.title?.toLowerCase().includes(text.toLowerCase()));
      setCase({ shortTitle: text });
      setCaseNumber(caseNumber);
      setCaseAction("1");
      setShowMenu(isMatching);
    } else {
      setCase(undefined);
      setShowMenu(false);
    }
    setClient(undefined);
    debouncedFilter(text);
  }

  function filterCallback(option: any, props: { text: string }) {
    return option.title.toLowerCase().includes(props.text.toLowerCase());
  }

  function handleCaseChangeClick() {
    setCase(undefined)
    setClient(undefined)
    setShowEdit(true);
  }

  const handleFilterChange = useCallback(async (filter?: string) => {
    setIsLoadingExternal(true);
    await filterExternalCases(filter);
    setIsLoadingExternal(false);
  }, [filterExternalCases]);

  const debouncedFilter = useMemo(() => debounce(async (filter?: string) => {
    await handleFilterChange(filter);
  }, 400), [handleFilterChange]);

  function renderForm() {

    if (!showEdit) {
      return (
        <>
          {!_case ? (
            <Loading isLoading={!_case} text="Loading case information..." />
          ) : (
            <>
              <div className="mt-3">
                <p className="label">Case</p>
                <p>
                  {_case?.shortTitle}{" "}
                  <Button
                    size="sm"
                    variant="link"
                    onClick={() => handleCaseChangeClick()}
                  >
                    Change
                  </Button>
                </p>
              </div>
              {_case?.clientId === client?.id && (
                <div>
                  <p className="label">Client</p>
                  <p>{client?.name ?? "-"}</p>
                </div>
              )}
              <div>
                <p className="label">Case Number</p>
                <p>{_case?.caseNumber ?? "-"}</p>
              </div>
              <div>
                <p className="label">Judge</p>
                <p>{_case?.judge ?? "-"}</p>
              </div>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {user?.externalConnection?.isActive && isExternalCasesLoading && !externalCases ? (
            <Loading isLoading={isExternalCasesLoading && !externalCases} text="Loading case information..."></Loading>
          ) : (
            <>
              <h3 className="venue-header ">State of {jurisdiction?.friendlyName}</h3>
              <div className={showEdit ? `mb-4` : ''}>
                <p className="label">Venue</p>
                <Form.Select
                  required
                  id="venue-select"
                  disabled={!showEdit || (_case && !!_case.id)}
                  value={_case?.venue ?? venue}
                  onChange={(event) => setVenue(event.currentTarget.value)}
                  defaultValue={""}
                >
                  {/* <option disabled value="" selected>
              Choose one
            </option> */}
                  <option value="" disabled>Please Select</option>
                  {venues?.sort((a, b) => a.shortName!.localeCompare(b.shortName!)).map((venue, index) => (
                    <option key={index} value={venue.id}>
                      {venue.shortName}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <ExternalPartnerConnection />
              <div className={styles.caseSwitchToggle}>
                <ButtonGroup className={styles.bpToggleTxtSwitch}>
                  {caseActionTypes.map((type, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      className={caseAction === type.value
                        ? styles.btnChecked
                        : ""}
                      type="radio"
                      name="radio"
                      value={type.value}
                      checked={caseAction === type.value}
                      onChange={handleToggleChange}
                    >
                      {type.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </div>
              <label htmlFor="case">Case</label>
              <div className="mb-2" style={{ minHeight: "40px" }}>
                <Typeahead
                  className={
                    _case && user?.externalConnection?.isActive
                      ? _case.integration?.partner
                        ? styles[`external-partner-${_case.integration.partner}`]
                        : styles[`external-partner-none`]
                      : ''
                  }
                  onChange={(e) => handleCaseChange(e)}
                  onInputChange={handleOnInputChange}
                  selected={(_case && [_case].map(c => new CombinedCaseSearch(c.shortTitle ?? c.title ?? '', buildSearchString(c), c.integration?.partner, c.integration?.identifier ?? undefined, c.id))) || []}
                  clearButton
                  filterBy={filterCallback}
                  flip={false}
                  isInvalid={!_case}
                  id="case"
                  emptyLabel={caseAction === "1" && "No matches found, this will create a new case."}
                  //
                  onFocus={caseAction === "1" ? () => setShowMenu(false) : () => setShowMenu(true)}
                  options={sortedCases}
                  open={showMenu}
                  size="sm"
                  placeholder={caseAction === "1" ? "Create a new case..." : "Find an existing case..."}
                  labelKey="title"
                  renderMenu={(results: any, menuProps: RenderMenuProps, state: any) => (
                    <CustomMenu results={results} menuProps={menuProps} state={state} showSourceLogos={!!externalCases?.length} />
                  )}
                  ref={caseTypeaheadRef}
                  isLoading={isExternalLoading}
                />
              </div>
                  {/* Make case # field required for any doc type except pleadings */}
                  <div className="mb-3">
                    <Form.Group>
                      <label htmlFor="case-number">Case Number</label>
                      <Form.Control
                      required={documentType !== DocumentType.Pleading ? true : false}
                      disabled={!!caseNumber && isExistingCase}
                      id="case-number"
                      size="sm"
                      value={_case?.caseNumber ?? caseNumber}
                      onChange={(event) => setCaseNumber(event.currentTarget.value)}
                    />
                    </Form.Group>
                    </div>
                <div>
                <label htmlFor="judge">Judge</label>
                <Form.Control
                  value={_case?.judge ?? judge}
                  disabled={!!(_case?.judge ?? judge) && isExistingCase}
                  id="judge"
                  size="sm"
                  onChange={(event) => setJudge(event.currentTarget.value)}
                />
              </div>
            </>
          )}
        </>
      )
    }

  }

  const sortedCases: CombinedCaseSearch[] = mergeCases(cases, externalCases).sort((a, b) => {
    // Prioritize cases with a defined "partner" to the top
    if (a.partner && !b.partner) {
      return -1;
    } else if (!a.partner && b.partner) {
      return 1;
    }

    // Prioritize new cases (cases without an ID) to the top
    const caseIdA = a.internalId || a.externalId || "";
    const caseIdB = b.internalId || b.externalId || "";
    if (!caseIdA && caseIdB) {
      return -1;
    } else if (caseIdA && !caseIdB) {
      return 1;
    }

    // Continue with the regular alphabetical sort by title
    const shortTitleA = a.title || "";
    const shortTitleB = b.title || "";
    return shortTitleA.localeCompare(shortTitleB);
  });

  return (
    <div id="case-client-confirm">
      {renderForm()}
    </div>
  );
}

export default ReviewPageOne;
