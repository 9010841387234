/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Announcement
 */
export interface Announcement {
    /**
     * 
     * @type {number}
     * @memberof Announcement
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof Announcement
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Announcement
     */
    stop?: Date;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    link?: string | null;
}

export function AnnouncementFromJSON(json: any): Announcement {
    return AnnouncementFromJSONTyped(json, false);
}

export function AnnouncementFromJSONTyped(json: any, ignoreDiscriminator: boolean): Announcement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'stop': !exists(json, 'stop') ? undefined : (new Date(json['stop'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'link': !exists(json, 'link') ? undefined : json['link'],
    };
}

export function AnnouncementToJSON(value?: Announcement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'stop': value.stop === undefined ? undefined : (value.stop.toISOString()),
        'title': value.title,
        'content': value.content,
        'link': value.link,
    };
}


