import { Case, DocumentStatus, DocumentType } from 'briefpoint-client';
import { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { DocumentClient, GetDocumentSubTypeName } from '../../services/DocumentService';
import { formatShortDate } from '../../utils/dateUtils';
import DocumentActionsMenu from './DocumentActionsMenu';
import { DocumentIcon, formatLocalDate, DueSoon, ReviewButton, FinishButton } from './DocumentList';

export function DocumentRow({
  doc, _case, archiveDocument, editDueDate, setShowSetCase, downloadForm, downloadRequest
}: {
  doc: DocumentClient;
  _case?: Case;
  archiveDocument: (document: DocumentClient) => Promise<void>;
  editDueDate?: Dispatch<SetStateAction<DocumentClient | undefined>>;
  setShowSetCase?: Dispatch<SetStateAction<DocumentClient | undefined>>;
  downloadForm: (document: DocumentClient, formId: string, formTypeName: string) => Promise<void>;
  downloadRequest: (document: DocumentClient) => Promise<void>;
}) {

  return (
    <tr>
      <td>
        <DocumentIcon extension="pdf" />
      </td>
      <td className='name'>{doc.name?.replace('.pdf', '').replace('.PDF', '')}</td>
      <td>{_case ? <Link title={_case!.shortTitle ?? ''} to={`/clients/${_case.clientId}/cases/${_case.id}`}>{_case!.shortTitle}</Link> : doc.info?.caseNumber ?? '-'}</td>
      <td>{formatShortDate(doc.uploadDate)}</td>
      <td>{doc.info?.documentSubType && GetDocumentSubTypeName(doc.info.documentSubType, doc.info.jurisdiction ?? '')}</td>
      {editDueDate && <>
        <td>{formatLocalDate(doc.info?.dueDateOverridden || doc.info?.dueDate || undefined) || <Button className='set-dueDate-button' variant='link' onClick={() => editDueDate(doc)}>Set Due Date</Button>}</td>
        <td>{DueSoon(doc.info?.dueDateOverridden || doc.info?.dueDate || undefined)}</td></>}
      {/* <td>{doc.status === DocumentStatus.Complete ? 'Complete' : 'In Progress'}</td> */}
      <td className="text-end">
        {doc.status === DocumentStatus.Complete ? (
          <ReviewButton documentId={doc.id} type={doc.info?.documentType ?? DocumentType.Unknown} />
        ) : (
          <FinishButton documentId={doc.id} type={doc.info?.documentType ?? DocumentType.Unknown} />
        )}
      </td>
      <td className='kebab-cell'>
        <DocumentActionsMenu
          document={doc}
          _case={_case}
          archiveDocument={archiveDocument}
          editDueDate={editDueDate}
          setShowSetCase={setShowSetCase}
          downloadForm={downloadForm}
          downloadRequest={downloadRequest} />
      </td>
    </tr>
  );
}
