/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PartyPosition {
    Unknown = 'Unknown',
    Plaintiff = 'Plaintiff',
    Defendant = 'Defendant',
    CrossDefendant = 'CrossDefendant',
    CrossComplainant = 'CrossComplainant'
}

export function PartyPositionFromJSON(json: any): PartyPosition {
    return PartyPositionFromJSONTyped(json, false);
}

export function PartyPositionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartyPosition {
    return json as PartyPosition;
}

export function PartyPositionToJSON(value?: PartyPosition | null): any {
    return value as any;
}

