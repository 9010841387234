/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentStatus {
    Unknown = 0,
    Uploaded = 100,
    Processing = 200,
    Processed = 300,
    InfoConfirmed = 400,
    Complete = 500,
    Deleted = 600,
    Error = 1000
}

export function DocumentStatusFromJSON(json: any): DocumentStatus {
    return DocumentStatusFromJSONTyped(json, false);
}

export function DocumentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentStatus {
    return json as DocumentStatus;
}

export function DocumentStatusToJSON(value?: DocumentStatus | null): any {
    return value as any;
}

