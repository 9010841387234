import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Role, SubscriptionPlan, UserType } from 'briefpoint-client';
import { Modal } from 'components/Modals';
import { PlanCard, SignupHeader } from 'components/Signup';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import CardSetupForm from 'screens/Signup/CardSetupForm';
import { SubscriptionPlanType } from 'services/FirmService';
import { ArrowLeft } from 'react-bootstrap-icons';
import useStripePlans from 'hooks/useStripe';
import SignupFooter from 'components/Signup/SignupFooter';
import { useAuth } from 'hooks/useAuth';
import { useFirmApi } from 'hooks/useApi';

const STRIPE_KEY: string = (window as any)._env_?.REACT_APP_STRIPE_KEY || process.env.REACT_APP_STRIPE_KEY;

const stripePromise = loadStripe(STRIPE_KEY);

interface Props {
  isShow: boolean;
  onClose: (success: boolean) => void;
}

export default function SignUpModal({ isShow, onClose }: Props) {
  const plans = useStripePlans();
  const { user, firm } = useAuth()!;
  const firmApi = useFirmApi();
  const onConfirm = () => { };
  const [plan, setPlan] = useState(plans[SubscriptionPlan.Monthly]);
  const [monthly, setMonthly] = useState(false);
  const [isShowPayment, setIsShowPayment] = useState(false);
  const [userQuantity, setUserQuantity] = useState(1);
  const [paralegalQuantity, setParalegalQuantity] = useState(0);

  function handleClose(success: boolean = false) {
    onClose(success);
    setTimeout(() => setIsShowPayment(false), 500); // small delay to account for modal transition
  }

  function handleClick(plan: SubscriptionPlanType) {
    setPlan(plan);
    setIsShowPayment(true);
  }

  useEffect(() => {
    async function fetch() {
      if (firm) {
        const users = await firmApi.firmGetUsers({ id: firm!.id, includeInactive: false });
        const userCount = users.filter(x => x.type !== UserType.Paralegal).length;
        setUserQuantity(userCount);
        setParalegalQuantity(users.length - userCount);
      }
    }
    fetch();
  }, [firmApi, firm]);

  const showAdminContent = !!(user && user.role && user.role.includes(Role.FirmAdmin));

  function plansModalContent(showAdminContent: boolean) {
    return (
      <>
        <SignupHeader showAdminContent={showAdminContent} userIsInactive={!!user?.isInactive} />
        <div className="px-5">
          {showAdminContent &&
            <Row>
              <Col lg={4}>
                <PlanCard plan={plans[SubscriptionPlan.AlaCarte]} onClick={handleClick} intervalSwitch={setMonthly} />
              </Col>
              <Col lg={4}>
                {!monthly ? <PlanCard plan={plans[SubscriptionPlan.Yearly]} onClick={handleClick} intervalSwitch={setMonthly} /> : <PlanCard plan={plans[SubscriptionPlan.Monthly]} onClick={handleClick} intervalSwitch={setMonthly} />}
              </Col>
              <Col lg={4}>
                <PlanCard plan={plans[SubscriptionPlan.YearlyBundle]} compareToPlan={plans[SubscriptionPlan.Monthly]} onClick={handleClick} intervalSwitch={setMonthly} />
              </Col>
              {showAdminContent && <SignupFooter />}
            </Row>
          }
        </div>
      </>
    );
  }

  function paymentModalContent() {
    return (
      <>
        <div className="p-0 mb-4 cursor-pointer d-inline-block" onClick={() => setIsShowPayment(false)}>
          <ArrowLeft /> <span className="text-decoration-underline">Back to all Plans</span>
        </div>
        <Elements stripe={stripePromise}>
          <CardSetupForm plan={plan} onFirmUpdated={() => handleClose(true)} attorneyQuantity={userQuantity} paralegalQuantity={paralegalQuantity} />
        </Elements>
      </>
    );
  }

  return (
    <Modal
      show={isShow}
      onClose={handleClose}
      onConfirm={onConfirm}
      size="xl"
      title={isShowPayment ? `Sign up for the ${plan.title} Plan` : ''}
      showCancelButton={false}
      showConfirmButton={false}
      preventBackgroundClick
    >
      {isShowPayment ? paymentModalContent() : plansModalContent(showAdminContent)}
    </Modal>
  );
}
