import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { classes } from 'utils';
import desk from '../../images/Desk_Illustration.svg';
import './EmptyList.scss';

interface Props {
  chooseFile: React.MouseEventHandler<HTMLButtonElement>;
  supportsFrogs: boolean;
}
export default function EmptyList({ chooseFile, supportsFrogs }: Props) {
  const [isOver, setIsOver] = useState(false);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    setIsOver(true);
    e.preventDefault();
  };
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    setIsOver(false);
    e.preventDefault();
  };

  return (
    <div
      id="empty-document-list"
      className={classes('mb-5', { dropping: isOver })}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDragLeave}
      style={{ pointerEvents: 'none' }}
    >
      <img src={desk} alt="upload document icon" />
      <p className="lead">Lighten your workload and reclaim your time!</p>
      <p className="smaller">
        Briefpoint currently accepts the following state court documents:
      </p>
      <ul>
        <li>Requests for Admission</li>
        <li>Requests for Production</li>
        <li>{supportsFrogs && 'Special '}Interrogatories</li>
        {supportsFrogs &&
          <li>Form Interrogatories</li>}
      </ul>
      <p>
        Drag PDF files here to upload, or{' '}
        <span className="btn-link p-0 cursor-pointer" style={{ pointerEvents: 'initial' }} onClick={chooseFile}>
          browse files
        </span>{' '}
        from your computer.
      </p>
      <Button className="mt-2" onClick={chooseFile} style={{ pointerEvents: 'initial' }}>
        Upload Document
      </Button>
    </div>
  );
}
