import { Role, SubscriptionPlan } from 'briefpoint-client';
import Button from 'components/Button';
import { useStripeApi } from 'hooks/useApi';
import { OnBehalfOfSessionKey, useAuth } from 'hooks/useAuth';
import { useProvideOAuth } from "hooks/useOAuth";
import logo from 'images/logo-wordmark-reverse.svg';
import { Container, Nav, Navbar as BNavbar, NavDropdown } from 'react-bootstrap';
import { FlagFill } from 'react-bootstrap-icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import Notifications from './Notifications';
import useConfig, { MULTI_CLIENT_FF } from "hooks/useConfig";

interface Props {
  fluid?: true | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

interface AccountLinkProps {
  isLoginPage?: boolean;
}

const AccountLink = ({ isLoginPage }: AccountLinkProps) => {
  const { isAuthenticated, logout } = useProvideOAuth();
  const { user, firm } = useAuth()!;
  const stripeApi = useStripeApi();

  async function handleGetPortalUrl() {
    const url = await stripeApi.stripePortalSession();
    window.location.href = url;
  }

  const showFirmAdmin = user && firm && user.role && user.role.includes(Role.FirmAdmin);

  return isAuthenticated ? (
    <>
      {user && <Notifications user={user!} />}
      {showFirmAdmin &&
        <NavDropdown title={firm?.name || 'Firm'} id="collapsible-nav-dropdown" align="end">
          {user && firm && (
            <>
              <NavDropdown.Item as={Link} to="/firm">
                Users
              </NavDropdown.Item>
              {firm.billingInfo && firm.billingInfo.subscriptionPlan !== SubscriptionPlan.None && (
                <NavDropdown.Item onClick={handleGetPortalUrl}>Billing</NavDropdown.Item>
              )}
            </>
          )}
        </NavDropdown>
      }
      <NavDropdown title={user?.firstName || 'Account'} id="collapsible-nav-dropdown" align="end">
        {user && firm && (
          <>
            <NavDropdown.Item as={Link} to="/general-info">
              Information
            </NavDropdown.Item>
          </>
        )}
        <NavDropdown.Item onClick={() => logout(window.location.origin)}>Logout</NavDropdown.Item>
      </NavDropdown>
    </>
  ) : !isLoginPage ? (
    <Nav.Link as={Link} to="/login">
      Login
    </Nav.Link>
  ) : null;
};

function AdminLink() {
  return (
    <NavDropdown title='Admin' id="collapsible-nav-dropdown">
      <>
        <NavDropdown.Item as={Link} to="/admin/documents">
          Documents
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/admin">
          Users
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/admin/Firm">
          Firms
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/admin/revenue">
          Revenue
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/admin/misc">
          Misc
        </NavDropdown.Item>
      </>
    </NavDropdown>
  );
};

export default function Navbar({ fluid }: Props) {
  const { isAuthenticated } = useProvideOAuth();
  const location = useLocation();
  const { user, firm, baseUser } = useAuth()!;
  const [, setOnBehalfOf] = useSessionStorage(OnBehalfOfSessionKey, undefined);
  const [, featureFlags] = useConfig();
  const useMultiClient = featureFlags()[MULTI_CLIENT_FF] ? featureFlags()[MULTI_CLIENT_FF](user, firm) : false;
  const links = getLinks(user && isAuthenticated, useMultiClient);
  const history = useHistory();
  const ENVIRONMENT: string = (window as any)._env_?.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT;
  const APP_VERSION: string = (window as any)._env_?.REACT_APP_VERSION || process.env.REACT_APP_VERSION;

  function clearOnBehalfOf() {
    setOnBehalfOf(undefined);
    if (baseUser?.role?.includes(Role.BriefpointAdmin)) {
      history.push("");
    }
  }

  return (
    <>
      <BNavbar expand="sm" sticky="top" bg="dark" variant="dark">
        <Container fluid={fluid}>
          <BNavbar.Brand href="/">
            <img src={logo} alt="logo" className="logo" />
            {ENVIRONMENT !== "Production" && <small className="fs-6 ms-2 text-muted">v{APP_VERSION}</small>}
          </BNavbar.Brand>
          <BNavbar.Toggle aria-controls="basic-navbar-nav" />
          <BNavbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" activeKey={location.pathname}>
              {links.map((link) => {
                const pathname = link.path.split('?')[0];

                const isActive =
                  pathname === location.pathname || (pathname === '/documents' && location.pathname === '/');

                return (
                  <Nav.Link key={link.path} as={Link} to={link.path} eventKey={link.path} active={isActive}>
                    {link.label}
                  </Nav.Link>
                );
              })}
              {user && isAuthenticated && user && user.role?.includes(Role.BriefpointAdmin) && <AdminLink />}
            </Nav>

            <Nav>
              <AccountLink isLoginPage={location.pathname === '/login'}></AccountLink>
            </Nav>
          </BNavbar.Collapse>
        </Container>
      </BNavbar>
      {user && baseUser && user?.id !== baseUser?.id &&
        <div className='onbehalfof-banner'>
          <div><FlagFill />&nbsp;&nbsp;{`You are working on behalf of ${user.firstName} ${user.lastName}`}&nbsp;&nbsp;<Button variant='link' onClick={clearOnBehalfOf}>Change Attorney</Button></div></div>}
    </>
  );
}

function getLinks(isAuthenticated?: boolean, useMultiClient?: boolean) {
  const links = [];
  if (isAuthenticated) {
    links.push({ path: '/documents', label: 'Document List' });
    links.push({ path: '/library?tab=objections', label: 'Library' });
    if(useMultiClient){
      links.push({ path: '/cases', label: 'Cases' });
    }  
    links.push({ path: '/clients', label: 'Clients' });
    links.push({ path: '/how-to', label: 'Tutorials' });
  }
  return links;
}
