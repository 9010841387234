import { Address, ContactInfo, ContactInfoCategory, ContactInfoType, Role, User } from 'briefpoint-client';
import Button from 'components/Button';
import Help from 'components/Help';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Form, Row } from 'react-bootstrap';
import { FirmClient } from 'services/FirmService';
import DocumentOutline from './DocumentOutline';
import usePositionActive from './utils';

export default function GeneralInformationScreen({
  documentId,
  onSave,
}: {
  documentId?: string;
  onSave?: () => void;
}) {
  const [setActiveRef, setActiveLineRef] = usePositionActive();
  const { user, updateUser, firm, updateFirm } = useAuth()!;
  const [tmpUser, setTmpUser] = useState<User>(user!);
  const [tmpFirm, setTmpFirm] = useState<FirmClient>(firm!);
  const [tmpAddress, setTmpAddress] = useState<Address>(firm!.address!);
  const [tmpPhone, setTmpPhone] = useState<ContactInfo>(
    (user!.role?.includes(Role.FirmAdmin) ? firm : user)!.contactInfo?.find((c) => c.type === ContactInfoType.Phone) || {
      value: '',
      type: ContactInfoType.Phone,
      category: ContactInfoCategory.Office,
    }
  );
  const [tmpFax, setTmpFax] = useState<ContactInfo>(
    (user!.role?.includes(Role.FirmAdmin) ? firm : user)!.contactInfo?.find((c) => c.type === ContactInfoType.Fax) || {
      value: '',
      type: ContactInfoType.Fax,
      category: ContactInfoCategory.Office,
    }
  );
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const isFirmAdmin = user && user.role && user.role.includes(Role.FirmAdmin);

  const altPhone = !isFirmAdmin ? firm!.contactInfo?.find((c) => c.type === ContactInfoType.Phone)?.value ?? '' : '';
  const altFax = !isFirmAdmin ? firm!.contactInfo?.find((c) => c.type === ContactInfoType.Fax)?.value ?? '' : '';

  useEffect(() => {
    tmpAddress.city = firm?.address?.city;
    tmpAddress.stateProvince = firm?.address?.stateProvince;
  }, [firm?.address?.city, firm?.address?.stateProvince, tmpAddress]);

  async function handleSave(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const item = isFirmAdmin ? tmpFirm : tmpUser;

    setSaving(true);
    setSaved(false);

    tmpFirm.address = tmpAddress;
    item.contactInfo = (isFirmAdmin ? firm : user)!.contactInfo?.filter(
      (c) => c.type !== ContactInfoType.Phone && c.type !== ContactInfoType.Fax
    );

    if (tmpPhone.value) {
      (item.contactInfo = item.contactInfo || []).push(tmpPhone);
    }
    if (tmpFax.value) {
      (item.contactInfo = item.contactInfo || []).push(tmpFax);
    }
    await Promise.all([updateUser(tmpUser!), isFirmAdmin && updateFirm(tmpFirm!)]);
    if (onSave) {
      onSave();
    } else {
      setSaved(true);
    }
    setSaving(false);
  }

  function handleOnUpdate(event: any, setter: Function, obj: any, trim: boolean) {
    setter({ ...obj!, [event.target.name]: trim ? event.target.value.trim() : event.target.value });
  }

  function updateUserEmail(email: string) {
    const newContactInfo: ContactInfo = {
      type: ContactInfoType.Email,
      value: email,
      category: ContactInfoCategory.Office
    };
  
    setTmpUser((prevUser) => {
      if (!prevUser) return prevUser; 
  
      const updatedContactInfo = prevUser.contactInfo ? [...prevUser.contactInfo] : [];
      const emailIndex = updatedContactInfo.findIndex(contact => contact.type === ContactInfoType.Email);
      
      if (emailIndex !== -1) {
        updatedContactInfo[emailIndex] = newContactInfo;
      } else {
        updatedContactInfo.push(newContactInfo);
      }
  
      return {
        ...prevUser,
        contactInfo: updatedContactInfo,
      };
    });
  }

  return (
    <Container className="page-container">
      {saved && (
        <Alert variant="success" onClose={() => setSaved(false)} dismissible>
          Success! Information saved.
        </Alert>
      )}
      <header className="mb-3">
        <h1>General Information</h1>
        {documentId && (
          <p>Because this is your first time creating a document, we need to gather a few details.</p>
        )}
      </header>
      <Row>
        <Col xs="7">
          <DocumentOutline
            activeSection="info"
            setActiveRef={setActiveRef}
            setActiveLineRef={setActiveLineRef}
          />
        </Col>
        <Col xs="5">
          <div className="confirm-information-container">
            <Form onSubmit={handleSave}>
              <h3 className="mb-3">Personal Info</h3>
              <Row className="gx-2">
                <Col md>
                  <Form.Group className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      onBlur={(e) => handleOnUpdate(e, setTmpUser, tmpUser, true)}
                      onChange={(e) => handleOnUpdate(e, setTmpUser, tmpUser, false)}
                      name="firstName"
                      value={tmpUser?.firstName || ''}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md>
                  <Form.Group className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      onBlur={(e) => handleOnUpdate(e, setTmpUser, tmpUser, true)}
                      onChange={(e) => handleOnUpdate(e, setTmpUser, tmpUser, false)}
                      name="lastName"
                      value={tmpUser?.lastName || ''}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xxl={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Bar Number</Form.Label>
                    <Form.Control
                      onBlur={(e) => handleOnUpdate(e, setTmpUser, tmpUser, true)}
                      onChange={(e) => handleOnUpdate(e, setTmpUser, tmpUser, false)}
                      name="barNumber"
                      value={checkUndefined(tmpUser?.barNumber)}
                      required
                    />
                  </Form.Group>
                  <Col xxl={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Contact Email For Documents</Form.Label>
                    <Form.Control
                      onChange={(e) => updateUserEmail(e.target.value)}
                      name="email"
                      value={tmpUser?.contactInfo?.find(contact => contact.type === ContactInfoType.Email)?.value ?? undefined}
                      required
                    />
                  </Form.Group>
                </Col>
                </Col>
              </Row>
              <hr />
              <h3 className="mb-3">Law Firm Info {!isFirmAdmin && <Help id="document-help" text='Please contact your firm admin to change this information.' direction={'bottom'} />}</h3>
              <Row>
                <Col xxl={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Firm Name</Form.Label>
                    <Form.Control
                      onBlur={(e) => handleOnUpdate(e, setTmpFirm, tmpFirm, true)}
                      onChange={(e) => handleOnUpdate(e, setTmpFirm, tmpFirm, false)}
                      name="name"
                      value={tmpFirm.name}
                      disabled={!isFirmAdmin}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xxl={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control
                      onBlur={(e) => handleOnUpdate(e, setTmpAddress, tmpAddress, true)}
                      onChange={(e) => handleOnUpdate(e, setTmpAddress, tmpAddress, false)}
                      name="street"
                      value={checkUndefined(tmpAddress.street)}
                      disabled={!isFirmAdmin}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xxl={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Address 2</Form.Label>
                    <Form.Control
                      placeholder="Suite, Bldg, Gate Code (optional)"
                      onBlur={(e) => handleOnUpdate(e, setTmpAddress, tmpAddress, true)}
                      onChange={(e) => handleOnUpdate(e, setTmpAddress, tmpAddress, false)}
                      name="street2"
                      disabled={!isFirmAdmin}
                      value={checkUndefined(tmpAddress.street2)}
                    />
                  </Form.Group>
                </Col>
                <Col xxl={12}>
                  <Form.Group className="mb-3">
                    <Row className="align-items-center">
                      <Col md={4}>
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                          onBlur={(e) => handleOnUpdate(e, setTmpAddress, tmpAddress, true)}
                          onChange={(e) => handleOnUpdate(e, setTmpAddress, tmpAddress, false)}
                          name="postalCode"
                          value={checkUndefined(tmpAddress.postalCode)}
                          maxLength={5}
                          disabled={!isFirmAdmin}
                          required
                        />
                      </Col>
                      {tmpAddress.city && tmpAddress.stateProvince && (
                        <Col md={4}>
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            onBlur={(e) => handleOnUpdate(e, setTmpAddress, tmpAddress, true)}
                            onChange={(e) => handleOnUpdate(e, setTmpAddress, tmpAddress, false)}
                            name="city"
                            value={checkUndefined(tmpAddress.city)}
                            disabled
                          />
                        </Col>
                      )}
                      {tmpAddress.city && tmpAddress.stateProvince && (
                        <Col md={4}>
                          <Form.Label>State</Form.Label>
                          <Form.Control
                            onBlur={(e) => handleOnUpdate(e, setTmpAddress, tmpAddress, true)}
                            onChange={(e) => handleOnUpdate(e, setTmpAddress, tmpAddress, false)}
                            name="stateProvince"
                            value={checkUndefined(tmpAddress.stateProvince)}
                            disabled
                          />
                        </Col>
                      )}
                      <Col>
                        <div className="small">
                          {!tmpAddress.city && !tmpAddress.stateProvince && (
                            <div>Enter Zip for City and State</div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col xxl={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number (optional)</Form.Label>
                    <Form.Control
                      onBlur={(e) => handleOnUpdate(e, setTmpPhone, tmpPhone, true)}
                      onChange={(e) => handleOnUpdate(e, setTmpPhone, tmpPhone, false)}
                      name="value"
                      placeholder={altPhone}
                      value={checkUndefined(tmpPhone.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xxl={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Fax Number (optional)</Form.Label>
                    <Form.Control
                      onBlur={(e) => handleOnUpdate(e, setTmpFax, tmpFax, true)}
                      onChange={(e) => handleOnUpdate(e, setTmpFax, tmpFax, false)}
                      name="value"
                      placeholder={altFax}
                      value={checkUndefined(tmpFax.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-end">
                <Button type="submit" loading={saving} text="Saving...">
                  {documentId ? 'Next' : 'Save'}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

function checkUndefined(val: any) {
  return val === undefined || val === null ? '' : val;
}
