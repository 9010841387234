/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CommunicationSource {
    Unknown = 'Unknown',
    Attorney = 'Attorney',
    Client = 'Client'
}

export function CommunicationSourceFromJSON(json: any): CommunicationSource {
    return CommunicationSourceFromJSONTyped(json, false);
}

export function CommunicationSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationSource {
    return json as CommunicationSource;
}

export function CommunicationSourceToJSON(value?: CommunicationSource | null): any {
    return value as any;
}

