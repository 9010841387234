/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalClient,
    ExternalClientFromJSON,
    ExternalClientFromJSONTyped,
    ExternalClientToJSON,
    ExternalPartners,
    ExternalPartnersFromJSON,
    ExternalPartnersFromJSONTyped,
    ExternalPartnersToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalCase
 */
export interface ExternalCase {
    /**
     * 
     * @type {string}
     * @memberof ExternalCase
     */
    id?: string | null;
    /**
     * 
     * @type {ExternalPartners}
     * @memberof ExternalCase
     */
    partner?: ExternalPartners;
    /**
     * 
     * @type {string}
     * @memberof ExternalCase
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalCase
     */
    caseNumber?: string | null;
    /**
     * 
     * @type {Array<ExternalClient>}
     * @memberof ExternalCase
     */
    clients?: Array<ExternalClient> | null;
}

export function ExternalCaseFromJSON(json: any): ExternalCase {
    return ExternalCaseFromJSONTyped(json, false);
}

export function ExternalCaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalCase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'partner': !exists(json, 'partner') ? undefined : ExternalPartnersFromJSON(json['partner']),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'caseNumber': !exists(json, 'caseNumber') ? undefined : json['caseNumber'],
        'clients': !exists(json, 'clients') ? undefined : (json['clients'] === null ? null : (json['clients'] as Array<any>).map(ExternalClientFromJSON)),
    };
}

export function ExternalCaseToJSON(value?: ExternalCase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'partner': ExternalPartnersToJSON(value.partner),
        'title': value.title,
        'caseNumber': value.caseNumber,
        'clients': value.clients === undefined ? undefined : (value.clients === null ? null : (value.clients as Array<any>).map(ExternalClientToJSON)),
    };
}


