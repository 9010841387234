/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalDocument
 */
export interface ExternalDocument {
    /**
     * 
     * @type {string}
     * @memberof ExternalDocument
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalDocument
     */
    folder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalDocument
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalDocument
     */
    path?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalDocument
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalDocument
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExternalDocument
     */
    size?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalDocument
     */
    version?: string | null;
}

export function ExternalDocumentFromJSON(json: any): ExternalDocument {
    return ExternalDocumentFromJSONTyped(json, false);
}

export function ExternalDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function ExternalDocumentToJSON(value?: ExternalDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'folder': value.folder,
        'location': value.location,
        'path': value.path,
        'type': value.type,
        'name': value.name,
        'size': value.size,
        'version': value.version,
    };
}


