/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterrogatoryResponseItem,
    InterrogatoryResponseItemFromJSON,
    InterrogatoryResponseItemFromJSONTyped,
    InterrogatoryResponseItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface Interrogatory
 */
export interface Interrogatory {
    /**
     * 
     * @type {number}
     * @memberof Interrogatory
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Interrogatory
     */
    documentId: string;
    /**
     * 
     * @type {number}
     * @memberof Interrogatory
     */
    number: number;
    /**
     * 
     * @type {string}
     * @memberof Interrogatory
     */
    text: string;
    /**
     * 
     * @type {boolean}
     * @memberof Interrogatory
     */
    complete: boolean;
    /**
     * 
     * @type {string}
     * @memberof Interrogatory
     */
    sourceId?: string | null;
    /**
     * 
     * @type {{ [key: string]: InterrogatoryResponseItem; }}
     * @memberof Interrogatory
     */
    response: { [key: string]: InterrogatoryResponseItem; };
    /**
     * 
     * @type {{ [key: string]: Array<InterrogatoryResponseItem>; }}
     * @memberof Interrogatory
     */
    responseList?: { [key: string]: Array<InterrogatoryResponseItem>; } | null;
}

export function InterrogatoryFromJSON(json: any): Interrogatory {
    return InterrogatoryFromJSONTyped(json, false);
}

export function InterrogatoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Interrogatory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'documentId': json['documentId'],
        'number': json['number'],
        'text': json['text'],
        'complete': json['complete'],
        'sourceId': !exists(json, 'sourceId') ? undefined : json['sourceId'],
        'response': (mapValues(json['response'], InterrogatoryResponseItemFromJSON)),
        'responseList': !exists(json, 'responseList') ? undefined : json['responseList'],
    };
}

export function InterrogatoryToJSON(value?: Interrogatory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'documentId': value.documentId,
        'number': value.number,
        'text': value.text,
        'complete': value.complete,
        'sourceId': value.sourceId,
        'response': (mapValues(value.response, InterrogatoryResponseItemToJSON)),
        'responseList': value.responseList,
    };
}


