/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Credential,
    CredentialFromJSON,
    CredentialFromJSONTyped,
    CredentialToJSON,
    CredentialAudit,
    CredentialAuditFromJSON,
    CredentialAuditFromJSONTyped,
    CredentialAuditToJSON,
} from './';

/**
 * 
 * @export
 * @interface CredentialResult
 */
export interface CredentialResult {
    /**
     * 
     * @type {Credential}
     * @memberof CredentialResult
     */
    credential?: Credential;
    /**
     * 
     * @type {Array<CredentialAudit>}
     * @memberof CredentialResult
     */
    recentAudits?: Array<CredentialAudit> | null;
}

export function CredentialResultFromJSON(json: any): CredentialResult {
    return CredentialResultFromJSONTyped(json, false);
}

export function CredentialResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credential': !exists(json, 'credential') ? undefined : CredentialFromJSON(json['credential']),
        'recentAudits': !exists(json, 'recentAudits') ? undefined : (json['recentAudits'] === null ? null : (json['recentAudits'] as Array<any>).map(CredentialAuditFromJSON)),
    };
}

export function CredentialResultToJSON(value?: CredentialResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credential': CredentialToJSON(value.credential),
        'recentAudits': value.recentAudits === undefined ? undefined : (value.recentAudits === null ? null : (value.recentAudits as Array<any>).map(CredentialAuditToJSON)),
    };
}


