/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParsePayload
 */
export interface ParsePayload {
    /**
     * 
     * @type {string}
     * @memberof ParsePayload
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ParsePayload
     */
    modelId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ParsePayload
     */
    firmId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ParsePayload
     */
    forceOCR?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ParsePayload
     */
    startAt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ParsePayload
     */
    endAt?: number | null;
}

export function ParsePayloadFromJSON(json: any): ParsePayload {
    return ParsePayloadFromJSONTyped(json, false);
}

export function ParsePayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParsePayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'modelId': !exists(json, 'modelId') ? undefined : json['modelId'],
        'firmId': !exists(json, 'firmId') ? undefined : json['firmId'],
        'forceOCR': !exists(json, 'forceOCR') ? undefined : json['forceOCR'],
        'startAt': !exists(json, 'startAt') ? undefined : json['startAt'],
        'endAt': !exists(json, 'endAt') ? undefined : json['endAt'],
    };
}

export function ParsePayloadToJSON(value?: ParsePayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'documentId': value.documentId,
        'modelId': value.modelId,
        'firmId': value.firmId,
        'forceOCR': value.forceOCR,
        'startAt': value.startAt,
        'endAt': value.endAt,
    };
}


