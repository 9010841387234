import { AppState, Auth0Provider, User } from '@auth0/auth0-react';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar/Navbar';
import { ProvideAuth } from 'hooks/useAuth';
import rg4js from 'raygun4js';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import useMixpanel from 'hooks/useMixpanel';
import { PopupWindow } from 'components/ExternalPartner/AuthPopup/PopupWindow';
import { UrlUtils } from 'components/ExternalPartner/AuthPopup/UrlUtils';
import { ConfigProvider } from 'contexts/ConfigContext';

const RAYGUN_APIKEY: string =
  (window as any)._env_?.REACT_APP_RAYGUN_API_KEY || process.env.REACT_APP_RAYGUN_API_KEY;
const MIXPANEL_APIKEY: string =
  (window as any)._env_?.REACT_APP_MIXPANEL_APIKEY || process.env.REACT_APP_MIXPANEL_APIKEY;
const ENVIRONMENT: string = (window as any)._env_?.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT;
const REACT_APP_API_URL: string = (window as any)._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
const REACT_APP_AUTH0_DOMAIN: string =
  (window as any)._env_?.REACT_APP_AUTH0_DOMAIN || process.env.REACT_APP_AUTH0_DOMAIN;
const REACT_APP_AUTH0_CLIENT_ID: string =
  (window as any)._env_?.REACT_APP_AUTH0_CLIENT_ID || process.env.REACT_APP_AUTH0_CLIENT_ID;
const REACT_APP_AUTH0_AUDIENCE: string =
  (window as any)._env_?.REACT_APP_AUTH0_AUDIENCE || process.env.REACT_APP_AUTH0_AUDIENCE;
const APP_VERSION: string = (window as any)._env_?.REACT_APP_VERSION || process.env.REACT_APP_VERSION;

if (RAYGUN_APIKEY) {
  rg4js('enableCrashReporting', true);
  if (ENVIRONMENT === 'Production') {
    rg4js('enablePulse', true);
  }
  rg4js('apiKey', RAYGUN_APIKEY);
  rg4js('options', { disableAnonymousUserTracking: true, apiEndpoint: `${REACT_APP_API_URL}` });
  rg4js('setVersion', APP_VERSION.replace('v', ''));
}

if (MIXPANEL_APIKEY) {
  mixpanel.init(MIXPANEL_APIKEY, { debug: true, ignore_dnt: true, api_host: `${REACT_APP_API_URL}` });
}

const useRedirectCallback = async (appState?: AppState | undefined, user?: User | undefined) => {
  moveQueryParamsToSession(window.location);
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
  useMixpanel()[0]("Login");
};

const moveQueryParamsToSession = (location: Location) => {
  const params = new URLSearchParams(location.search);
  params.forEach((value, key) => { if (key !== "code" && key !== "state") { sessionStorage.setItem(key, value); } });
}

// Need to intercept auth calls from external partners and handle them as part of the external auth popup
// any other requests should just fall through to the react router which will handle "normal" auth
const params = window.location.search.includes("state") && UrlUtils.readParams(window.location.href);
const state = params && params.get("state");
let decodedState: any;
if (state) {
  try {
    decodedState = JSON.parse(atob(state));
  } catch { }
}
if (decodedState && decodedState["partner"]) {
  PopupWindow.notifyOpener(window.location.href, false);
} else {
  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <Auth0Provider
          domain={REACT_APP_AUTH0_DOMAIN}
          clientId={REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
          audience={REACT_APP_AUTH0_AUDIENCE}
          onRedirectCallback={useRedirectCallback}
        >
          <ProvideAuth>
            <ConfigProvider>
              <Navbar />
              <main>
                <App />
              </main>
              <Footer />
            </ConfigProvider>
          </ProvideAuth>
        </Auth0Provider>
      </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
