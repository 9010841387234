/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalFile
 */
export interface ExternalFile {
    /**
     * 
     * @type {string}
     * @memberof ExternalFile
     */
    location?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalFile
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExternalFile
     */
    size?: number;
}

export function ExternalFileFromJSON(json: any): ExternalFile {
    return ExternalFileFromJSONTyped(json, false);
}

export function ExternalFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': !exists(json, 'location') ? undefined : json['location'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'size': !exists(json, 'size') ? undefined : json['size'],
    };
}

export function ExternalFileToJSON(value?: ExternalFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': value.location,
        'name': value.name,
        'size': value.size,
    };
}


