/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceManner {
    Unknown = 0,
    Regular = 1,
    InStateMail = 2,
    OutOfStateMail = 3,
    Electronic = 4,
    Other = 255
}

export function ServiceMannerFromJSON(json: any): ServiceManner {
    return ServiceMannerFromJSONTyped(json, false);
}

export function ServiceMannerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceManner {
    return json as ServiceManner;
}

export function ServiceMannerToJSON(value?: ServiceManner | null): any {
    return value as any;
}

