import { LoadingComponent } from 'App';
import { useProvideOAuth } from 'hooks/useOAuth';
import { ReactNode, Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface Props {
  children: ReactNode;
  path: string;
}

export function AnonymousRoute({ children, ...rest }: Props) {
  const { isAuthenticated } = useProvideOAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

export function PrivateRoute({ children, ...rest }: Props) {
  const { isAuthenticated } = useProvideOAuth();
  return (
    <Suspense fallback={<LoadingComponent />}>
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    </Suspense>
  );
}
