//  UTILITIES/TYPE DEFINITIONS
import { DataNode } from "antd/es/tree";
import {
  ExternalClientIEnumerableExternalPartnerResponse,
  ExternalDocument,
  ExternalFolder,
  ExternalPartners,
} from "briefpoint-client";
import {
  circuitBreaker,
  ConsecutiveBreaker,
  handleAll,
  retry,
  wrap,
} from "cockatiel";

// IMAGES
import { ReactComponent as BriefpointLogo } from '../../images/logo.svg';
import { ReactComponent as ClioLogo } from '../../images/Glyph_Clio Glyph Blue.svg';
import { ReactComponent as SmokeballLogo } from '../../images/Smokeball_logo_mark.svg';
import { ReactComponent as MyCaseLogo } from '../../images/MyCase.svg';
import { ReactComponent as SwitcherClosed } from "../../images/tree-switcher-closed.svg";
import { ReactComponent as SwitchedOpened } from "../../images/tree-switcher-opened.svg";
import { FileEarmark } from "react-bootstrap-icons";
import { ReactComponent as FolderClosed } from "../../images/external_folder_closed.svg";
import { PopupNavigator } from "components/ExternalPartner/AuthPopup/PopupNavigator";
import { GetExternalPartnerAuthUrl } from "components/ExternalPartner/ExternalPartnerConnection";
import { useAuth as UseAuth } from "hooks/useAuth";
import UseConfig from "hooks/useConfig";
import { UrlUtils } from "components/ExternalPartner/AuthPopup/UrlUtils";
import { useUserApi as UseUserApi } from "hooks/useApi";


export function delayDocumentRefresh(
  delay: number,
  loadDocuments: () => Promise<void>,
  setState: React.Dispatch<React.SetStateAction<boolean>>,
  finishAction: () => void,
): void {
  setTimeout(() => {
    loadDocuments();
    setState(false);
    finishAction();
  }, delay);
}

export function getCaseSourceLogo(partner?: ExternalPartners) {
  if (!partner || partner === ExternalPartners.None) {
    return <BriefpointLogo title="Briefpoint Case" />;
  }

  switch (partner!) {
    case ExternalPartners.Clio:
      return <ClioLogo title="Clio Case" />;
    case ExternalPartners.Smokeball:
      return <SmokeballLogo title="Smokeball Case" />;
    case ExternalPartners.MyCase:
      return <MyCaseLogo title="MyCase Case" />;
    default:
      return <BriefpointLogo title="Briefpoint Case" />;
  }
}


export const initiateAuth = async () => {

  const { user, setUser, firm } = UseAuth()!;
  const userApi = UseUserApi();
  const [config] = UseConfig();

  if (!user || !firm) {
    return null;
  }
  const firmProviders = firm.preferences?.enabledExternalProviders?.values();
  let enabledPartners = firmProviders ? Array.from(firmProviders) : null;

  if (!enabledPartners?.length) {
    return null;
  }

  const partner = enabledPartners[0];
  const authUrl = GetExternalPartnerAuthUrl(partner, user?.id!, config);
  const popupNavigator = new PopupNavigator();
  const handle = await popupNavigator.prepare({});

  try {
    const result = await handle.navigate({
      url: authUrl!,
    });

    const params = UrlUtils.readParams(result.url);
    const code = params.get("code");
    if (code) {
      const tokenResult = await userApi.userExternalPartnerAuth({
        userId: user.id!,
        code,
        partner: partner,
      });
      if (tokenResult.responseCode !== 200) {
        throw new Error(
          `Unable to authenticate to ${partner}. Status Code: ${tokenResult.responseCode}`,
        );
      }

      setUser((u) => {
        const newUser = { ...u };
        newUser.externalConnection = { partner: partner, isActive: true };
        return newUser;
      });
    } else {
      // setSaving(false);
      throw new Error(`Unexpected response from auth: ${params}`);
    }
    // setSaving(false);
  } catch (_e) {
    let message = "";
    if (typeof _e === "string") {
      message = _e;
    } else if (_e instanceof Error) {
      message = _e.message;
    }

    if (message.includes("closed by user")) {
      // setFormError("Authentication cancelled");
      throw new Error("Authentication cancelled");
    }
    // setSaving(false);
  }
};

// Function to transform data into a tree structure (for importing from External Partner to BP)
export const transformDataExternalToBp = (data: ExternalDocument[]) => {
  const tree: DataNode[] = [];
  const dataMap: Map<string, ExternalDocument> = new Map();

  data.forEach((item) => {
    const path = item.path?.split("/");
    const fileName = path?.pop();

    if (path?.length === 0 && fileName) {
      tree.push({
        title: fileName,
        key: item.id!,
        isLeaf: true,
        icon: <FileEarmark />,
      });
      dataMap.set(item.id!, item);
    } else {
      path?.reduce(
        (acc: DataNode[], part: string, index: number, array: string[]) => {
          const key = array.slice(0, index + 1).join("/");
          let existing = acc.find((n) => n.key === key);
          if (!existing) {
            existing = {
              title: part,
              key: key,
              children: [],
              icon: <FolderClosed />,
            };
            acc.push(existing);
          }

          if (index === array.length - 1 && fileName) {
            existing.children!.push({
              title: fileName,
              key: item.id!,
              isLeaf: true,
              icon: <FileEarmark />,
            });
            dataMap.set(item.id!, item);
          }

          return existing.children! || [];
        },
        tree,
      );
    }
  });

  return [tree, dataMap];
};

// Function to transform data into a tree structure (for exporting from BP Partner to External Partner)
export const transformDataBpToExternal = (
  data: ExternalFolder[],
  parentPath: string = "",
): any => {
  return data.map((item) => {
    const currentPath = parentPath ? `${parentPath}/${item.name}` : item.name;

    let children: any = [];

    if (item.folders && item.folders.length > 0) {
      children = children.concat(
        transformDataBpToExternal(item.folders, currentPath!),
      );
    }

    if (item.documents && item.documents.length > 0) {
      const docChildren = item.documents.map((doc) => ({
        title: doc.name?.endsWith(doc.type ?? "")
          ? doc.name
          : `${doc.name}${doc.type}`,
        key: doc.id,
        icon: <FileEarmark />,
        selectable: false,
        path: `${currentPath}/${doc.name}${doc.type}`, // Include path for documents as well
      }));
      children = children.concat(docChildren);
    }

    return {
      title: item.name,
      key: item.id || item.name,
      selectable: true,
      icon: <FolderClosed />,
      children,
      path: currentPath, // Include path for folders
    };
  });
};

export function renderSwitcherIcon(node: any): React.ReactNode {
  return node.expanded ? <SwitchedOpened /> : <SwitcherClosed />;
}

export async function externalRequestWrapper(
  fetchData: any,
  attempts: number = 1,
) {
  // Attempt to retry if no response/timeout/network error occurs
  const retryPolicy = retry(handleAll, { maxAttempts: attempts });

  // Stops calling the executed function for 10 seconds if it fails 5 times in a row.
  const circuitBreakerPolicy = circuitBreaker(handleAll, {
    halfOpenAfter: 10 * 1000,
    breaker: new ConsecutiveBreaker(5),
  });

  const retryWithBreaker = wrap(retryPolicy, circuitBreakerPolicy);

  const result = await retryWithBreaker.execute(() => {
    return fetchData;
  });

  return result;
}

export async function handleExternalReqResCodes(
  res: ExternalClientIEnumerableExternalPartnerResponse,
  onSuccess: (res: ExternalClientIEnumerableExternalPartnerResponse) => void,
  onError: () => void,
  initiateAuth: () => Promise<null | undefined>,
) {
  switch (res.responseCode) {
    case 200:
    case 201:
    case 202:
      onSuccess(res);
      break;
    case 401:
      initiateAuth();
      break;
    case 500:
    default:
      onError();
  }
}
