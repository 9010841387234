import GeneralInformationScreen from 'components/ConfirmInfo/GeneralInformation';
import Loading from 'components/Loading';
import { useAuth } from 'hooks/useAuth';
import useMixpanel from 'hooks/useMixpanel';
import rg4js from 'raygun4js';
import { lazy, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import UpdateInterrogatories from 'screens/DocumentWizard/UpdateInterrogatories';
import UpdateFormInterrogatories from 'screens/DocumentWizard/UpdateFormInterrogatories';
import Error404 from 'screens/Error/Error404';
import FirmScreen from 'screens/Firm';
import HowTo from 'screens/HowTo/HowTo';
import Library from 'screens/Library';
import Clients from 'screens/CaseManagement';
import ClientsMulti from 'screens/ClientManagementMulti';
import AddEditObjection from 'screens/Library/AddEditObjection';
import Quarantine from 'screens/Quarantine';
import './css/App.scss';
import { PrivateRoute } from './Route';
import DocumentList from './screens/DocumentList';
import Logout from './screens/Logout';
import TermsAndConditions from './screens/TermsAndConditions';
import ArchiveDocumentScreen from './screens/Archive/Archive';
import ClientPage from 'screens/CaseManagement/client';
import ClientPageMulti from 'screens/ClientManagementMulti/client';
import CasePage from 'screens/CaseManagement/case';
import UserAuth from 'UserAuth';
import { UserType } from 'briefpoint-client';
import OnBehalfOfSelect from 'screens/OnBehalfOfSelect';
import AddEditQuestion from 'screens/Library/AddEditQuestion';
import GenerateDiscoveryRequestPage from 'screens/DocumentGeneration/GenerateDiscoveryRequestPage';
import JurisdictionSelect from 'screens/JurisdictionSelect';
import Cases from 'screens/CaseManagementMulti';
import CaseOverviewPage from 'screens/CaseManagementMulti/case';
import useConfig, { MULTI_CLIENT_FF } from "hooks/useConfig";

const AdminScreen = lazy(() => import('screens/Admin'));
const AdminDocumentsScreen = lazy(() => import('screens/Admin/adminDocumentList'));
const AdminRevenueScreen = lazy(() => import('screens/Admin/adminRevenueScreen'));
const AdminFirmScreen = lazy(() => import('screens/Admin/adminFirmScreen'));
const AdminFirmDetails = lazy(() => import('screens/Admin/adminFirmDetails'));
const AdminMiscScreen = lazy(() => import('screens/Admin/adminMisc'));
const UserDocument = lazy(() => import('components/Admin/UserDocument'));
const UserDocuments = lazy(() => import('components/Admin/UserDocuments'));
const DocumentWizard = lazy(() => import('./screens/DocumentWizard'));
const DocumentWizardSourceDocumentReview = lazy(() => import('screens/DocumentWizard/SourceDocumentReview'));
const PropoundDocumentReview = lazy(() => import('screens/DocumentWizard/PropoundDocumentReview/index'));
const ClientFeedback = lazy(() => import('screens/ClientFeedback'));
const ClientInfo = lazy(() => import('screens/ClientInfo'));
// function ErrorComponent({ error }: { error: any }) {
//   return <p>An Error Occurred: {JSON.stringify(error)}</p>;
// }

export function LoadingComponent() {
  return <Loading className="mt-3 mainApp" isLoading />;
}

function App() {
  return (
    <UserAuth>
      <AppAuthenticated />
    </UserAuth>
  );
}

function AppAuthenticated() {
  const { user, firm, getUser, createUser, baseUser, firmAddOffice } = useAuth()!;
  const [showTerms, setShowTerms] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);
  const [mixpanelTrack] = useMixpanel()!;
  const [, featureFlags] = useConfig();
  const useMultiClient = featureFlags()[MULTI_CLIENT_FF] ? featureFlags()[MULTI_CLIENT_FF](user, firm) : false;
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const effect = () => {
      rg4js('trackEvent', { type: 'pageView', path: history.location.pathname });
    }
    return effect;
  }, [history, location]);

  useEffect(() => {
    const getUserInner = async () => {
      try {
        await getUser();
      } catch (error) {
        if (error instanceof Response && error.status === 401) {
          createUserInner();
          //setShowTerms(true); There is currently a bug in mobile safari that prevents users from agreeing to T&C
        }
      }
    };
    getUserInner();
    // depending on getUser causes the terms page to briefly show on logout
    // there is probably a better way to fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createUserInner = async () => {
    setCreatingUser(true);
    await createUser(false);
    mixpanelTrack("Signup");
    setShowTerms(false);
  };

  if (showTerms) {
    return <TermsAndConditions isLoading={creatingUser} onAgree={createUserInner} />;
  }

  if (!user || !firm || !baseUser) {
    return <LoadingComponent />;
  }

  if (baseUser.isQuarantined || user.isQuarantined) {
    return <Quarantine />;
  }
  const showParalegalSelect = baseUser.type === UserType.Paralegal && user.id === baseUser.id;

  if (showParalegalSelect) {
    return <OnBehalfOfSelect />
  }

  if (!firm?.offices?.length) {
    return <JurisdictionSelect firm={firm} addOffice={firmAddOffice} />
  }
  const DefaultScreen = DocumentList;

  return (
    <Switch>
      <Route exact path="/">
        <DefaultScreen />
      </Route>
      {/* <AnonymousRoute path="/login">
            <Login />
          </AnonymousRoute> */}
      <PrivateRoute path="/logout">
        <Logout />
      </PrivateRoute>
      <PrivateRoute path="/documents/archive">
        <ArchiveDocumentScreen />
      </PrivateRoute>
      <PrivateRoute path="/documents/:documentId/client-feedback/:communicationId/confirm-client-info">
        <ClientInfo />
      </PrivateRoute>
      <PrivateRoute path="/documents/:documentId/client-feedback">
        <ClientFeedback />
      </PrivateRoute>
      <PrivateRoute path="/documents">
        <DocumentList />
      </PrivateRoute>
      <PrivateRoute path="/general-info">
        <GeneralInformationScreen />
      </PrivateRoute>
      <PrivateRoute path="/wizard/:documentId/source-document-review">
        <DocumentWizardSourceDocumentReview />
      </PrivateRoute>
      <PrivateRoute path="/wizard/propound/:documentId">
        <PropoundDocumentReview />
      </PrivateRoute>
      <PrivateRoute path="/wizard/:documentId/update-interrogatories/:interrogatoryId">
        <UpdateInterrogatories />
      </PrivateRoute>
      <PrivateRoute path="/wizard/:documentId/update-form-interrogatories">
        <UpdateFormInterrogatories />
      </PrivateRoute>
      <PrivateRoute path="/wizard/:documentId/:currentStep?">
        <DocumentWizard />
      </PrivateRoute>
      <PrivateRoute path="/library/addedit">
        <AddEditObjection />
      </PrivateRoute>
      <PrivateRoute path="/library/addeditquestion">
        <AddEditQuestion />
      </PrivateRoute>
      <PrivateRoute path="/library">
        <Library />
      </PrivateRoute>
      <PrivateRoute path="/clients/:clientId/cases/:id/generateDiscoveryRequest">
        <GenerateDiscoveryRequestPage />
      </PrivateRoute>
      <PrivateRoute path="/clients/:clientId/cases/:id">
        <CasePage />
      </PrivateRoute>
      {useMultiClient ?
        <PrivateRoute path="/clients/:id">
          <ClientPageMulti />
        </PrivateRoute> :
        <PrivateRoute path="/clients/:id">
          <ClientPage />
        </PrivateRoute>
      }
      {useMultiClient ?
        <PrivateRoute path="/clients">
          <ClientsMulti />
        </PrivateRoute> :
        <PrivateRoute path="/clients">
          <Clients />
        </PrivateRoute>
      }
      {useMultiClient &&
        <PrivateRoute path="/cases/:id">
          <CaseOverviewPage />
        </PrivateRoute>
      }
      {useMultiClient &&
        <PrivateRoute path="/cases">
          <Cases />
        </PrivateRoute>
      }
      <PrivateRoute path="/how-to">
        <HowTo />
      </PrivateRoute>
      <PrivateRoute path="/admin/users/:userId/documents/:documentId">
        <UserDocument />
      </PrivateRoute>
      <PrivateRoute path="/admin/users/:userId/documents">
        <UserDocuments />
      </PrivateRoute>
      <PrivateRoute path="/admin/documents">
        <AdminDocumentsScreen />
      </PrivateRoute>
      <PrivateRoute path="/admin/revenue">
        <AdminRevenueScreen />
      </PrivateRoute>
      <PrivateRoute path="/admin/firm/:firmId">
        <AdminFirmDetails />
      </PrivateRoute>
      <PrivateRoute path="/admin/firm">
        <AdminFirmScreen />
      </PrivateRoute>
      <PrivateRoute path="/admin/misc">
        <AdminMiscScreen />
      </PrivateRoute>
      <PrivateRoute path="/admin">
        <AdminScreen />
      </PrivateRoute>
      <PrivateRoute path="/firm">
        <FirmScreen />
      </PrivateRoute>
      <Route>
        <Error404 />
      </Route>
    </Switch>
  );
}

export default App;
