import useFirm from 'hooks/useFirm';
import { Container } from 'react-bootstrap';
import Loading from 'components/Loading';
import { useAuth } from 'hooks/useAuth';
import FirmUserList from 'components/Firm/FirmUserList';
import { UserType } from 'briefpoint-client';
import Toggle from 'components/Toggle';
import { transformFirm } from 'services/FirmService';

export default function FirmScreen() {
  const { firm, setFirm } = useAuth()!;
  const [users, invites, , sendInvite, activateDeactivateUser, updatePreferences] = useFirm(firm?.id);

  async function SubmitInvite(email: string, type: UserType) {
    await sendInvite(email, type);
  }

  async function ActivateDeactivateUser(userId: string, activate: boolean) {
    await activateDeactivateUser(userId, activate);
  }

  async function UpdateAllowBPAccess() {
    const prefs = firm?.preferences ?? {};
    prefs.allowBriefpointAdminAccess = !prefs.allowBriefpointAdminAccess;
    await updatePreferences(prefs);
    setFirm(transformFirm({ ...firm, preferences: prefs }));
  }

  return (
    <Container className="page-container">
      <Loading isLoading={users === null}>
        {users ? (
          <FirmUserList
            invites={invites}
            users={users!}
            onInvite={SubmitInvite}
            onActivateDeactivate={ActivateDeactivateUser}
          />
        ) : (
          <p>No Users</p>
        )}
        <Toggle checked={firm?.preferences?.allowBriefpointAdminAccess ?? false}
          label="Allow Briefpoint staff to access your account for implementation and/or troubleshooting purposes?"
          checkedName='Yes'
          unCheckedName='No'
          onToggle={UpdateAllowBPAccess} />
      </Loading>
    </Container>
  );
}
