/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    ContactInfo,
    ContactInfoFromJSON,
    ContactInfoFromJSONTyped,
    ContactInfoToJSON,
    SupportedJurisdiction,
    SupportedJurisdictionFromJSON,
    SupportedJurisdictionFromJSONTyped,
    SupportedJurisdictionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Office
 */
export interface Office {
    /**
     * 
     * @type {string}
     * @memberof Office
     */
    id?: string;
    /**
     * 
     * @type {Array<SupportedJurisdiction>}
     * @memberof Office
     */
    jurisdictions?: Array<SupportedJurisdiction> | null;
    /**
     * 
     * @type {Array<ContactInfo>}
     * @memberof Office
     */
    contactInfo?: Array<ContactInfo> | null;
    /**
     * 
     * @type {Address}
     * @memberof Office
     */
    address?: Address;
}

export function OfficeFromJSON(json: any): Office {
    return OfficeFromJSONTyped(json, false);
}

export function OfficeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Office {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'jurisdictions': !exists(json, 'jurisdictions') ? undefined : (json['jurisdictions'] === null ? null : (json['jurisdictions'] as Array<any>).map(SupportedJurisdictionFromJSON)),
        'contactInfo': !exists(json, 'contactInfo') ? undefined : (json['contactInfo'] === null ? null : (json['contactInfo'] as Array<any>).map(ContactInfoFromJSON)),
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
    };
}

export function OfficeToJSON(value?: Office | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'jurisdictions': value.jurisdictions === undefined ? undefined : (value.jurisdictions === null ? null : (value.jurisdictions as Array<any>).map(SupportedJurisdictionToJSON)),
        'contactInfo': value.contactInfo === undefined ? undefined : (value.contactInfo === null ? null : (value.contactInfo as Array<any>).map(ContactInfoToJSON)),
        'address': AddressToJSON(value.address),
    };
}


