/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactInfo,
    ContactInfoFromJSON,
    ContactInfoFromJSONTyped,
    ContactInfoToJSON,
    ExternalPartnerConnection,
    ExternalPartnerConnectionFromJSON,
    ExternalPartnerConnectionFromJSONTyped,
    ExternalPartnerConnectionToJSON,
    Preferences,
    PreferencesFromJSON,
    PreferencesFromJSONTyped,
    PreferencesToJSON,
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firmId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    barNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isQuarantined?: boolean;
    /**
     * 
     * @type {Role}
     * @memberof User
     */
    role?: Role;
    /**
     * 
     * @type {UserType}
     * @memberof User
     */
    type?: UserType;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isInactive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastViewedAnnouncements?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    deactivationDate?: Date | null;
    /**
     * 
     * @type {Preferences}
     * @memberof User
     */
    preferences?: Preferences;
    /**
     * 
     * @type {ExternalPartnerConnection}
     * @memberof User
     */
    externalConnection?: ExternalPartnerConnection;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    documentCount?: number | null;
    /**
     * 
     * @type {Array<ContactInfo>}
     * @memberof User
     */
    contactInfo?: Array<ContactInfo> | null;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firmId': !exists(json, 'firmId') ? undefined : json['firmId'],
        'createdOn': !exists(json, 'createdOn') ? undefined : (new Date(json['createdOn'])),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'barNumber': !exists(json, 'barNumber') ? undefined : json['barNumber'],
        'isQuarantined': !exists(json, 'isQuarantined') ? undefined : json['isQuarantined'],
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'type': !exists(json, 'type') ? undefined : UserTypeFromJSON(json['type']),
        'isInactive': !exists(json, 'isInactive') ? undefined : json['isInactive'],
        'lastViewedAnnouncements': !exists(json, 'lastViewedAnnouncements') ? undefined : (json['lastViewedAnnouncements'] === null ? null : new Date(json['lastViewedAnnouncements'])),
        'deactivationDate': !exists(json, 'deactivationDate') ? undefined : (json['deactivationDate'] === null ? null : new Date(json['deactivationDate'])),
        'preferences': !exists(json, 'preferences') ? undefined : PreferencesFromJSON(json['preferences']),
        'externalConnection': !exists(json, 'externalConnection') ? undefined : ExternalPartnerConnectionFromJSON(json['externalConnection']),
        'documentCount': !exists(json, 'documentCount') ? undefined : json['documentCount'],
        'contactInfo': !exists(json, 'contactInfo') ? undefined : (json['contactInfo'] === null ? null : (json['contactInfo'] as Array<any>).map(ContactInfoFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firmId': value.firmId,
        'createdOn': value.createdOn === undefined ? undefined : (value.createdOn.toISOString()),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'barNumber': value.barNumber,
        'isQuarantined': value.isQuarantined,
        'role': RoleToJSON(value.role),
        'type': UserTypeToJSON(value.type),
        'isInactive': value.isInactive,
        'lastViewedAnnouncements': value.lastViewedAnnouncements === undefined ? undefined : (value.lastViewedAnnouncements === null ? null : value.lastViewedAnnouncements.toISOString()),
        'deactivationDate': value.deactivationDate === undefined ? undefined : (value.deactivationDate === null ? null : value.deactivationDate.toISOString()),
        'preferences': PreferencesToJSON(value.preferences),
        'externalConnection': ExternalPartnerConnectionToJSON(value.externalConnection),
        'documentCount': value.documentCount,
        'contactInfo': value.contactInfo === undefined ? undefined : (value.contactInfo === null ? null : (value.contactInfo as Array<any>).map(ContactInfoToJSON)),
    };
}


