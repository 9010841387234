/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Jurisdiction,
    JurisdictionFromJSON,
    JurisdictionToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    ServiceManner,
    ServiceMannerFromJSON,
    ServiceMannerToJSON,
} from '../models';

export interface JurisdictionGetDueDateRequest {
    id: string;
    serviceManner: ServiceManner;
    serviceDate?: string;
}

export interface JurisdictionGetJurisdictionRequest {
    jurisdictionId: string;
}

/**
 * 
 */
export class JurisdictionApi extends runtime.BaseAPI {

    /**
     */
    async jurisdictionGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Jurisdiction>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Jurisdiction`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JurisdictionFromJSON));
    }

    /**
     */
    async jurisdictionGet(initOverrides?: RequestInit): Promise<Array<Jurisdiction>> {
        const response = await this.jurisdictionGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async jurisdictionGetDueDateRaw(requestParameters: JurisdictionGetDueDateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling jurisdictionGetDueDate.');
        }

        if (requestParameters.serviceManner === null || requestParameters.serviceManner === undefined) {
            throw new runtime.RequiredError('serviceManner','Required parameter requestParameters.serviceManner was null or undefined when calling jurisdictionGetDueDate.');
        }

        const queryParameters: any = {};

        if (requestParameters.serviceDate !== undefined) {
            queryParameters['serviceDate'] = requestParameters.serviceDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Jurisdiction/{id}/due-date/{serviceManner}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"serviceManner"}}`, encodeURIComponent(String(requestParameters.serviceManner))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async jurisdictionGetDueDate(requestParameters: JurisdictionGetDueDateRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.jurisdictionGetDueDateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async jurisdictionGetJurisdictionRaw(requestParameters: JurisdictionGetJurisdictionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Jurisdiction>> {
        if (requestParameters.jurisdictionId === null || requestParameters.jurisdictionId === undefined) {
            throw new runtime.RequiredError('jurisdictionId','Required parameter requestParameters.jurisdictionId was null or undefined when calling jurisdictionGetJurisdiction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/{jurisdictionId}`.replace(`{${"jurisdictionId"}}`, encodeURIComponent(String(requestParameters.jurisdictionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JurisdictionFromJSON(jsonValue));
    }

    /**
     */
    async jurisdictionGetJurisdiction(requestParameters: JurisdictionGetJurisdictionRequest, initOverrides?: RequestInit): Promise<Jurisdiction> {
        const response = await this.jurisdictionGetJurisdictionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
