/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubscriptionPlan,
    SubscriptionPlanFromJSON,
    SubscriptionPlanFromJSONTyped,
    SubscriptionPlanToJSON,
} from './';

/**
 * 
 * @export
 * @interface SetSubscription
 */
export interface SetSubscription {
    /**
     * 
     * @type {SubscriptionPlan}
     * @memberof SetSubscription
     */
    subscriptionPlan?: SubscriptionPlan;
    /**
     * 
     * @type {string}
     * @memberof SetSubscription
     */
    subscriptionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetSubscription
     */
    customerId?: string | null;
}

export function SetSubscriptionFromJSON(json: any): SetSubscription {
    return SetSubscriptionFromJSONTyped(json, false);
}

export function SetSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscriptionPlan': !exists(json, 'subscriptionPlan') ? undefined : SubscriptionPlanFromJSON(json['subscriptionPlan']),
        'subscriptionId': !exists(json, 'subscriptionId') ? undefined : json['subscriptionId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
    };
}

export function SetSubscriptionToJSON(value?: SetSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscriptionPlan': SubscriptionPlanToJSON(value.subscriptionPlan),
        'subscriptionId': value.subscriptionId,
        'customerId': value.customerId,
    };
}


