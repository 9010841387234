/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommunicationInfo,
    CommunicationInfoFromJSON,
    CommunicationInfoFromJSONTyped,
    CommunicationInfoToJSON,
    CommunicationStatus,
    CommunicationStatusFromJSON,
    CommunicationStatusFromJSONTyped,
    CommunicationStatusToJSON,
    CommunicationType,
    CommunicationTypeFromJSON,
    CommunicationTypeFromJSONTyped,
    CommunicationTypeToJSON,
    ObjectTypeEnum,
    ObjectTypeEnumFromJSON,
    ObjectTypeEnumFromJSONTyped,
    ObjectTypeEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateClientCommunication
 */
export interface CreateClientCommunication {
    /**
     * 
     * @type {ObjectTypeEnum}
     * @memberof CreateClientCommunication
     */
    objectType?: ObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateClientCommunication
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientCommunication
     */
    caseId?: string;
    /**
     * 
     * @type {CommunicationType}
     * @memberof CreateClientCommunication
     */
    communicationType?: CommunicationType;
    /**
     * 
     * @type {CommunicationStatus}
     * @memberof CreateClientCommunication
     */
    status?: CommunicationStatus;
    /**
     * 
     * @type {ObjectTypeEnum}
     * @memberof CreateClientCommunication
     */
    sharedWithObjectType?: ObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateClientCommunication
     */
    sharedWithObjectId?: string | null;
    /**
     * 
     * @type {CommunicationInfo}
     * @memberof CreateClientCommunication
     */
    communicationInfo?: CommunicationInfo;
}

export function CreateClientCommunicationFromJSON(json: any): CreateClientCommunication {
    return CreateClientCommunicationFromJSONTyped(json, false);
}

export function CreateClientCommunicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateClientCommunication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectType': !exists(json, 'objectType') ? undefined : ObjectTypeEnumFromJSON(json['objectType']),
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'communicationType': !exists(json, 'communicationType') ? undefined : CommunicationTypeFromJSON(json['communicationType']),
        'status': !exists(json, 'status') ? undefined : CommunicationStatusFromJSON(json['status']),
        'sharedWithObjectType': !exists(json, 'sharedWithObjectType') ? undefined : ObjectTypeEnumFromJSON(json['sharedWithObjectType']),
        'sharedWithObjectId': !exists(json, 'sharedWithObjectId') ? undefined : json['sharedWithObjectId'],
        'communicationInfo': !exists(json, 'communicationInfo') ? undefined : CommunicationInfoFromJSON(json['communicationInfo']),
    };
}

export function CreateClientCommunicationToJSON(value?: CreateClientCommunication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectType': ObjectTypeEnumToJSON(value.objectType),
        'objectId': value.objectId,
        'caseId': value.caseId,
        'communicationType': CommunicationTypeToJSON(value.communicationType),
        'status': CommunicationStatusToJSON(value.status),
        'sharedWithObjectType': ObjectTypeEnumToJSON(value.sharedWithObjectType),
        'sharedWithObjectId': value.sharedWithObjectId,
        'communicationInfo': CommunicationInfoToJSON(value.communicationInfo),
    };
}


