/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountType,
    DiscountTypeFromJSON,
    DiscountTypeFromJSONTyped,
    DiscountTypeToJSON,
    SubscriptionPlan,
    SubscriptionPlanFromJSON,
    SubscriptionPlanFromJSONTyped,
    SubscriptionPlanToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubscriptionPrice
 */
export interface SubscriptionPrice {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPrice
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPrice
     */
    planId: string;
    /**
     * 
     * @type {SubscriptionPlan}
     * @memberof SubscriptionPrice
     */
    frequency: SubscriptionPlan;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPrice
     */
    originalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionPrice
     */
    discountedPrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPrice
     */
    discountCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionPrice
     */
    discountLength?: string | null;
    /**
     * 
     * @type {DiscountType}
     * @memberof SubscriptionPrice
     */
    discountType?: DiscountType;
}

export function SubscriptionPriceFromJSON(json: any): SubscriptionPrice {
    return SubscriptionPriceFromJSONTyped(json, false);
}

export function SubscriptionPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionPrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'planId': json['planId'],
        'frequency': SubscriptionPlanFromJSON(json['frequency']),
        'originalPrice': json['originalPrice'],
        'discountedPrice': !exists(json, 'discountedPrice') ? undefined : json['discountedPrice'],
        'discountCode': !exists(json, 'discountCode') ? undefined : json['discountCode'],
        'discountLength': !exists(json, 'discountLength') ? undefined : json['discountLength'],
        'discountType': !exists(json, 'discountType') ? undefined : DiscountTypeFromJSON(json['discountType']),
    };
}

export function SubscriptionPriceToJSON(value?: SubscriptionPrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'planId': value.planId,
        'frequency': SubscriptionPlanToJSON(value.frequency),
        'originalPrice': value.originalPrice,
        'discountedPrice': value.discountedPrice,
        'discountCode': value.discountCode,
        'discountLength': value.discountLength,
        'discountType': DiscountTypeToJSON(value.discountType),
    };
}


