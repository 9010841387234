import { useCallback, useEffect, useRef, useState } from 'react';
import { ObjectionVariant, Objection, ExtraUser, DocumentSubType } from 'briefpoint-client';
import { useObjectionApi } from './useApi';
import { DocumentDetailClient } from 'services/DocumentService';

export type UpdateVariantFunctionType = (
  objectionId: number,
  variantId: number,
  name: string,
  newContent: string,
  isDefault: boolean,
  additionalContent?: string,
  sharedWithFirmId?: string,
  refreshValues?: boolean,
  caseTypeId?: string | null,
  tagIds?: Array<number> | null,
  documentSubType?: DocumentSubType,
) => Promise<void>;

export type CreateVariantFunctionType =
  (objectionId: number, name: string, newContent: string, isDefault: boolean, additionalContent?: string, sharedWithFirmId?: string, refreshValues?: boolean, caseTypeId?: string | null, tagIds?: Array<number> | null,  documentSubType?: DocumentSubType) => Promise<ObjectionVariant>;

export type DeleteVariantFunctionType =
  (objectionId: number, variantId: number) => Promise<void>;

export type getDefaultObjectionType =
  (objectionId: number) => Promise<ObjectionVariant>;

type UseObjectionsType = [
  Objection[] | undefined,
  UpdateVariantFunctionType,
  // ObjectionTag[] | null,
  CreateVariantFunctionType,
  getDefaultObjectionType,
  DeleteVariantFunctionType,
  ExtraUser[] | undefined,
];

export default function useObjections(documentType: number | undefined = undefined, document?: DocumentDetailClient): UseObjectionsType {
  const objectionApi = useObjectionApi();
  const allObjections = useRef<Objection[] | undefined>();
  const currentDocId = useRef<string | undefined>();
  const [objections, setObjections] = useState<Objection[] | undefined>();
  const [extraUsers, setExtraUsers] = useState<ExtraUser[] | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  // const [tags, setTags] = useState<ObjectionTag[] | null>(null);

  const loadObjections = useCallback(async (clearCached: boolean = false) => {
    if(loading){
      return;
    }
    if (clearCached) {
      allObjections.current = undefined;
    }

    if (!loading && !allObjections.current?.length) {
      setLoading(true);
      const objections = await objectionApi.objectionGetList({
        documentType: documentType,
      });
      allObjections.current = objections;
      setLoading(false);
    }

    if (document?.id) {
      let currentObjections = allObjections.current;

      if (currentDocId.current !== document?.id) {
        currentDocId.current = document!.id;
        const documentSubType = document?.info?.documentSubType;
        setObjections(currentObjections?.filter(objection => objection.documentSubType === undefined || (!documentSubType || objection.documentSubType === documentSubType)));

        let missingVariants = [] as string[];
        for (let o = 0; o < allObjections.current!.length; o++) {
          const objection = allObjections.current![o];

          for (let i = 0; i < document!.interrogatories.length; i++) {
            const interrogatory = document!.interrogatories[i];

            const selected = interrogatory.response[objection.id];
            const selectedId = selected?.objectionVariantId.toString();
            if (selectedId && !missingVariants.find(x => x === selectedId) && !objection.variants.find(x => x.id === selected.objectionVariantId)) {
              missingVariants.push(selectedId);
            }
          }
        }

        if (missingVariants.length || document.caseId) {
          const supplemental = await objectionApi.objectionGetMissing({ documentType, caseId: document.caseId ?? undefined, missingVariants });
          setObjections(x => x?.map(o => {
            o.variants = [...o.variants, ...supplemental.variants?.filter(v => v.objectionId === o.id) ?? []];
            return o;
          }));
          setExtraUsers(supplemental.users ?? []);
        }
      }
    } else {
      setObjections(allObjections.current);
    }
  }, [loading, document, objectionApi, documentType]);

  // const loadTags = useCallback(async () => {
  //   const tags = await objectionApi.objectionGetTags();
  //   setTags(tags);
  // }, [objectionApi]);

  async function updateVariant(
    objectionId: number,
    variantId: number,
    name: string,
    newContent: string,
    isDefault: boolean,
    additionalContent?: string,
    sharedWithFirmId?: string,
    refreshValues = true,
    caseTypeId?: string | null,
    tagIds?: Array<number> | null,
    documentSubType?: DocumentSubType,
  ) {
    // set objections first to update interface more quickly.
    // in case of error we probably want to revert.
    setObjections(
      objections!.map((o) =>
        o.id === objectionId
          ? {
            ...o,
            variants: o.variants.map((v) =>
              v.id === variantId
                ? { ...v, name, content: newContent, isDefault, additionalContent }
                : { ...v, isDefault: isDefault ? false : v.isDefault }
            ),
          }
          : o
      )
    );
    await objectionApi.objectionUpdateVariant({
      objectionId,
      variantId,
      updateObjectionVariant: { name, content: newContent, isDefault, additionalContent, sharedWithFirmId, caseTypeId, tagIds, documentSubType},
    });
    if (refreshValues) {
      loadObjections(true);
    }
  }

  async function createVariant(objectionId: number, name: string, content: string, isDefault: boolean, additionalContent?: string, sharedWithFirmId?: string, refreshValues = true, caseTypeId?: string | null, tagIds?: Array<number> | null,  documentSubType?: DocumentSubType) {
    const variant = await objectionApi.objectionCreateVariant({
      objectionId,
      createObjectionVariant: { name, content, isDefault, objectionId, additionalContent, sharedWithFirmId, caseTypeId, tagIds, documentSubType},
    });
    setObjections(
      objections!.map((o) =>
        o.id === objectionId
          ? {
            ...o,
            variants: [...o.variants.map((v) => { return { ...v, isDefault: isDefault ? false : v.isDefault }; }), variant]
          }
          : o
      )
    );
    if (refreshValues) {
      loadObjections(true);
    }
    return variant;
  }

  async function getDefaultObjection(objectionId: number) {
    const defaultObj = await objectionApi.objectionGetDefault({
      objectionId,
    });
    return defaultObj;
  }

  async function deleteVariant(objectionId: number, variantId: number) {
    const variant = await objectionApi.objectionDeleteVariant({
      objectionId,
      variantId,
    });
    loadObjections(true);
    return variant;
  }

  useEffect(() => {
    loadObjections();
    // loadTags();
  }, [loadObjections]);

  return [objections, updateVariant, createVariant, getDefaultObjection, deleteVariant, extraUsers];
}
