/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentSubType,
    DocumentSubTypeFromJSON,
    DocumentSubTypeFromJSONTyped,
    DocumentSubTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateObjectionVariant
 */
export interface CreateObjectionVariant {
    /**
     * 
     * @type {number}
     * @memberof CreateObjectionVariant
     */
    objectionId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectionVariant
     */
    ownerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectionVariant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectionVariant
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectionVariant
     */
    additionalContent?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateObjectionVariant
     */
    isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectionVariant
     */
    sharedWithFirmId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateObjectionVariant
     */
    caseTypeId?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateObjectionVariant
     */
    tagIds?: Array<number> | null;
    /**
     * 
     * @type {DocumentSubType}
     * @memberof CreateObjectionVariant
     */
    documentSubType?: DocumentSubType;
}

export function CreateObjectionVariantFromJSON(json: any): CreateObjectionVariant {
    return CreateObjectionVariantFromJSONTyped(json, false);
}

export function CreateObjectionVariantFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateObjectionVariant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectionId': json['objectionId'],
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'name': json['name'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'additionalContent': !exists(json, 'additionalContent') ? undefined : json['additionalContent'],
        'isDefault': json['isDefault'],
        'sharedWithFirmId': !exists(json, 'sharedWithFirmId') ? undefined : json['sharedWithFirmId'],
        'caseTypeId': !exists(json, 'caseTypeId') ? undefined : json['caseTypeId'],
        'tagIds': !exists(json, 'tagIds') ? undefined : json['tagIds'],
        'documentSubType': !exists(json, 'documentSubType') ? undefined : DocumentSubTypeFromJSON(json['documentSubType']),
    };
}

export function CreateObjectionVariantToJSON(value?: CreateObjectionVariant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectionId': value.objectionId,
        'ownerId': value.ownerId,
        'name': value.name,
        'content': value.content,
        'additionalContent': value.additionalContent,
        'isDefault': value.isDefault,
        'sharedWithFirmId': value.sharedWithFirmId,
        'caseTypeId': value.caseTypeId,
        'tagIds': value.tagIds,
        'documentSubType': DocumentSubTypeToJSON(value.documentSubType),
    };
}


