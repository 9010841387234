/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommunicationInfo
 */
export interface CommunicationInfo {
    /**
     * 
     * @type {string}
     * @memberof CommunicationInfo
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommunicationInfo
     */
    caseTitle?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CommunicationInfo
     */
    requestedReviewIds?: Array<number> | null;
    /**
     * 
     * @type {Date}
     * @memberof CommunicationInfo
     */
    requestSentDate?: Date | null;
}

export function CommunicationInfoFromJSON(json: any): CommunicationInfo {
    return CommunicationInfoFromJSONTyped(json, false);
}

export function CommunicationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'caseTitle': !exists(json, 'caseTitle') ? undefined : json['caseTitle'],
        'requestedReviewIds': !exists(json, 'requestedReviewIds') ? undefined : json['requestedReviewIds'],
        'requestSentDate': !exists(json, 'requestSentDate') ? undefined : (json['requestSentDate'] === null ? null : new Date(json['requestSentDate'])),
    };
}

export function CommunicationInfoToJSON(value?: CommunicationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'caseTitle': value.caseTitle,
        'requestedReviewIds': value.requestedReviewIds,
        'requestSentDate': value.requestSentDate === undefined ? undefined : (value.requestSentDate === null ? null : value.requestSentDate.toISOString()),
    };
}


