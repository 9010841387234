/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SubscriptionPrice,
    SubscriptionPriceFromJSON,
    SubscriptionPriceToJSON,
} from '../models';

export interface StripeCheckCouponRequest {
    code?: string;
}

/**
 * 
 */
export class StripeApi extends runtime.BaseAPI {

    /**
     */
    async stripeCheckCouponRaw(requestParameters: StripeCheckCouponRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SubscriptionPrice>>> {
        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Stripe/check-coupon`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubscriptionPriceFromJSON));
    }

    /**
     */
    async stripeCheckCoupon(requestParameters: StripeCheckCouponRequest, initOverrides?: RequestInit): Promise<Array<SubscriptionPrice>> {
        const response = await this.stripeCheckCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async stripeGetPricesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SubscriptionPrice>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Stripe/get-prices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubscriptionPriceFromJSON));
    }

    /**
     */
    async stripeGetPrices(initOverrides?: RequestInit): Promise<Array<SubscriptionPrice>> {
        const response = await this.stripeGetPricesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async stripePortalSessionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Stripe/portal-session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async stripePortalSession(initOverrides?: RequestInit): Promise<string> {
        const response = await this.stripePortalSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async stripeSetupIntentRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Stripe/setup-intent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async stripeSetupIntent(initOverrides?: RequestInit): Promise<string> {
        const response = await this.stripeSetupIntentRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async stripeWebhookRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Stripe/webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async stripeWebhook(initOverrides?: RequestInit): Promise<void> {
        await this.stripeWebhookRaw(initOverrides);
    }

}
