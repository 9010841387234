import { DocumentSubType, MiscContent, MiscContentType, Role } from 'briefpoint-client';
import { FormModal } from 'components/Modals';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Alert, Button, CloseButton, Form, Toast, ToastContainer } from 'react-bootstrap';
import { InfoCircle } from 'react-bootstrap-icons';
import { Prompt } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { classes } from 'utils';

interface Props {
  intros: MiscContent[];
  onUpdate: (update: MiscContent) => Promise<MiscContent>;
}

const subTypes = {
  1: 'Interrogatories',
  2: 'Requests for Production',
  3: 'Requests for Admission',
};

export default function Intro({ intros, onUpdate }: Props) {
  const [content, setContent] = useState('');
  const { user, firm } = useAuth()!;
  const [isSharedWithFirm, setIsSharedWithFirm] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [showDirtyConfirm, setShowDirtyConfirm] = useState(false);
  const [nextSubType, setNextSubType] = useState<DocumentSubType | undefined>();
  const [toastMessage, setToastMessage] = useState('');
  const [selectedIntro, setSelectedIntro] = useState<MiscContent | null>();
  const [selectedIntroSubType, setSelectedIntroSubType] = useState<DocumentSubType | undefined>();

  const canSetFirmStandard = user?.role?.includes(Role.FirmAdmin);

  useEffect(() => {
    if (selectedIntro && selectedIntro.content) {
      setContent(selectedIntro.content);
      setIsDirty(false);
      setIsSharedWithFirm(!!selectedIntro.sharedWithFirmId);
      return;
    }

    if (!selectedIntroSubType) {
      setSelectedIntro(intros.find(x => !x.documentSubType));
    }
  }, [selectedIntro, intros, selectedIntroSubType]);

  function handleChangeContent(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setContent(event.target.value);
    checkIsDirty();
  }

  async function onSave() {
    if (selectedIntro) {
      const update = { ...selectedIntro, content: content, sharedWithFirmId: isSharedWithFirm ? firm!.id : undefined };
      const result = await onUpdate(update);
      setToastMessage('');
      setToastMessage('Intro Saved!');
      setIsDirty(false);
      setSelectedIntro(result);
    }
  }

  function handleChangeSelectedSubType(subType: DocumentSubType | undefined) {
    if (!isDirty) {
      handleSetSelectedIntroSubType(subType);
    } else {
      setNextSubType(subType);
      setShowDirtyConfirm(true);
    }
  }

  function handleSetSelectedIntroSubType(subType: DocumentSubType | undefined) {
    const next = subType ?? nextSubType;
    const intro = intros.find(x => x.documentSubType === (next));
    setSelectedIntro(intro);
    setSelectedIntroSubType(next);
    setShowDirtyConfirm(false);
    setNextSubType(undefined);
  }

  function handleCreateOverrideClick() {
    const defaultText = intros.find(x => !x.documentSubType);

    setSelectedIntro({ content: defaultText!.content, documentType: defaultText!.documentType, type: MiscContentType.PreliminaryStatement, documentSubType: selectedIntroSubType! });
  }

  function handleChangeCancel() {
    setContent(selectedIntro?.content || '');
    setIsSharedWithFirm(!!selectedIntro?.sharedWithFirmId);
    setIsDirty(false);
  }

  function handleFirmStandardClick(checked: boolean) {
    setIsSharedWithFirm(checked);
    checkIsDirty();
  }

  function checkIsDirty() {
    const dirty = content !== selectedIntro?.content || isSharedWithFirm !== !selectedIntro?.sharedWithFirmId;
    setIsDirty(dirty);
  }

  return (
    <div className="step-container" style={{ gap: 0 }}>
      <ToastContainer className="p-3" position="top-end">
        <Toast onClose={() => setToastMessage('')} show={!!toastMessage} delay={2500} bg="success" autohide>
          <Toast.Body>
            <div className="d-flex text-white">
              <div>{toastMessage}</div>
              <CloseButton
                className="btn-close btn-close-white me-2 m-auto"
                onClick={() => setToastMessage('')}
              />
            </div>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Prompt
        when={isDirty}
        message={location =>
          `You have unsaved changes, continue and lose them?`
        }
      />
      <div className="border-end" style={{ width: 250 }}>
        {
          <div
            key={'default'}
            onClick={() => {
              handleChangeSelectedSubType(undefined);
            }}
            className={classes('library-objection', {
              'library-objection-selected': !selectedIntroSubType,
            })}
          >
            Default
          </div>
        }
        {Object.keys(DocumentSubType)
          .map((o) => {
            const i = Number(o);
            const t = subTypes[i as keyof typeof subTypes];

            return t && (
              <div
                key={o}
                onClick={() => {
                  handleChangeSelectedSubType(i);
                }}
                className={classes('library-objection', {
                  'library-objection-selected': i === selectedIntroSubType,
                })}
              >
                {t}
              </div>);
          })}
      </div>
      <div className="flex-grow-1 ms-5">
        {selectedIntro ? (
          <>
            {!canSetFirmStandard && !!selectedIntro.sharedWithFirmId &&
              <Alert variant='info'>{'This Intro is set as your Firm\'s Standard, contact your Firm Admin if you need to make changes.'}</Alert>}

            <Form.Group className="mb-4">
              <Form.Label>Content</Form.Label>
              <Form.Control
                as="textarea"
                value={content}
                className="font-times mb-2"
                style={{ height: '300px', maxWidth: 700 }}
                onChange={handleChangeContent}
                required
                disabled={!canSetFirmStandard && !!selectedIntro.sharedWithFirmId}
              />
              {canSetFirmStandard && <Form.Check
                id={'sharedwithfirm'}
                type={'checkbox'}
                label="Use as Firm Standard"
                checked={isSharedWithFirm}
                onChange={(e) => handleFirmStandardClick(e.target.checked)}
              />}
              <FormModal
                title={'Abandon changes?'}
                cancelText="Cancel"
                confirmText="Yes, abandon"
                show={showDirtyConfirm}
                onClose={() => setShowDirtyConfirm(false)}
                onConfirm={() => handleSetSelectedIntroSubType(undefined)}
              >
                <div>Are you sure you want to abandon your saved changes?</div>
              </FormModal>
            </Form.Group>
            <p className={'placeholder-link'}><InfoCircle className="mr-2" style={{ marginRight: 10 }} /><Link to="/how-to/#using_placeholders" target={'_blank'}>Learn how to customize your content with Placeholders.</Link></p>
            <div className="d-flex">
              <Button variant="primary" onClick={onSave} disabled={!isDirty && !!selectedIntro.id}>
                Save
              </Button>
              <Button variant="link" onClick={handleChangeCancel} disabled={!isDirty && !!selectedIntro.id}>
                Cancel
              </Button>
            </div>
          </>) : <div>
          <p>This Document Type will use the Default Intro.</p>
          <Button onClick={handleCreateOverrideClick}>Create New</Button>
        </div>
        }
      </div>
    </div>
  );
}
