/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserInvite
 */
export interface UserInvite {
    /**
     * 
     * @type {string}
     * @memberof UserInvite
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvite
     */
    firmId?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserInvite
     */
    firstSent?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserInvite
     */
    lastResent?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UserInvite
     */
    pickedUp?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvite
     */
    invitedBy?: string;
    /**
     * 
     * @type {Role}
     * @memberof UserInvite
     */
    role?: Role;
    /**
     * 
     * @type {UserType}
     * @memberof UserInvite
     */
    userType?: UserType;
}

export function UserInviteFromJSON(json: any): UserInvite {
    return UserInviteFromJSONTyped(json, false);
}

export function UserInviteFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInvite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'firmId': !exists(json, 'firmId') ? undefined : json['firmId'],
        'firstSent': !exists(json, 'firstSent') ? undefined : (new Date(json['firstSent'])),
        'lastResent': !exists(json, 'lastResent') ? undefined : (json['lastResent'] === null ? null : new Date(json['lastResent'])),
        'pickedUp': !exists(json, 'pickedUp') ? undefined : (json['pickedUp'] === null ? null : new Date(json['pickedUp'])),
        'invitedBy': !exists(json, 'invitedBy') ? undefined : json['invitedBy'],
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
    };
}

export function UserInviteToJSON(value?: UserInvite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
        'firmId': value.firmId,
        'firstSent': value.firstSent === undefined ? undefined : (value.firstSent.toISOString()),
        'lastResent': value.lastResent === undefined ? undefined : (value.lastResent === null ? null : value.lastResent.toISOString()),
        'pickedUp': value.pickedUp === undefined ? undefined : (value.pickedUp === null ? null : value.pickedUp.toISOString()),
        'invitedBy': value.invitedBy,
        'role': RoleToJSON(value.role),
        'userType': UserTypeToJSON(value.userType),
    };
}


