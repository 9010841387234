import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { ReactComponent as Edit } from "images/icon-drag-edit.svg";
import { CollectionItem } from "briefpoint-client";
import styles from "./../ReviewPage.module.scss";

type GenericInfo = { [key: string]: string } | null | undefined;
type SetGenericInfo = React.Dispatch<React.SetStateAction<GenericInfo>>;
type SetCollection = React.Dispatch<React.SetStateAction<CollectionItem[] | null>>;

type PleadingFieldProps = {
  label: string;
  isTextArea?: boolean;
  pleadingValue: string | null | undefined;
  setField?: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  causesOfActionHandler?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  pleadingGenericInfoHandler?: ( key: string, newValue: string, obj: GenericInfo, setState: SetGenericInfo) => void;
  genericKey?: string;
  genericInfoObj?: GenericInfo;
  setGenericInfo?: SetGenericInfo;
  pleadingCollectionIndex?: number;
  pleadingCollection?: CollectionItem[];
  setCollection?: SetCollection;
  mappedFieldHandler?: ( index: number, newText: string, arr: CollectionItem[], stateFunc: SetCollection) => void;
};

const PleadingField: React.FC<PleadingFieldProps> = ({
  label,
  isTextArea = false,
  causesOfActionHandler,
  pleadingValue,
  pleadingCollectionIndex,
  pleadingCollection,
  genericInfoObj,
  genericKey,
  setField,
  setGenericInfo,
  setCollection,
  mappedFieldHandler,
  pleadingGenericInfoHandler,
}) => {
  const [isEditable, setIsEditable] = useState(false);
  // Keep the original value to be compared w/ the new value to revert back to incase a user hits 'Escape'
  const [oldCachedValue, setOldCachedValue] = useState<string | undefined>(pleadingValue ?? '');
  const [cacheNewValue, setCacheNewValue] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  const setNewValue = (newValue: string, event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCacheNewValue(newValue);
    if (setField) {
      setField(newValue);
    } else if (mappedFieldHandler && pleadingCollection && pleadingCollectionIndex !== undefined && setCollection) {
      mappedFieldHandler(pleadingCollectionIndex, newValue, pleadingCollection, setCollection);
    } else if (pleadingGenericInfoHandler && genericKey && setGenericInfo) {
      pleadingGenericInfoHandler(genericKey, newValue, genericInfoObj, setGenericInfo);
    } else if (causesOfActionHandler) {
      causesOfActionHandler(event!);
    }

  }

  const handleKeyDown = (event: React.KeyboardEvent) => {

    if (event.key === "Enter") {
      inputRef?.current?.blur();
    }

    if (event.key === 'Escape') {
      inputRef?.current?.blur();
      setNewValue(oldCachedValue ?? '');
    }

  };

  const toggleEditable = () => {
    setIsEditable(prev => !prev);
  };

  const handleBlur = () => {
    setIsEditable(false);
    if (cacheNewValue !== oldCachedValue) {
      setOldCachedValue(cacheNewValue);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setNewValue(newValue);
  };

  const renderEditableField = () => (
    <Form.Control
      ref={inputRef}
      className="mb-3"
      size="sm"
      value={pleadingValue ?? ""}
      onChange={handleChange}
      as={isTextArea ? 'textarea' : 'input'}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      {...(isTextArea && { rows: 5 })}
    />
  );

  return (
    <div className={styles.allegationField}>
      <label>
        {label}{' '}
        <span className={styles.allegationFieldEdit} onClick={toggleEditable}>
          <Edit />
        </span>
      </label>
      {isEditable ? renderEditableField() : <p>{pleadingValue || '-'}</p>}
    </div>
  );
};

export default PleadingField;
