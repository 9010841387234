import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { type Jurisdiction, DocumentSubType, ServiceManner} from 'briefpoint-client';
import { useJurisdictionApi } from 'hooks/useApi';
import { GetDocumentSubTypeName, ServiceMannerDisplay } from 'services/DocumentService';

// RequestType, Set/Starting Number, Date/Type of Service
function ReviewPageTwo({
  jurisdiction,
  documentSubType,
  setDocumentSubType,
  setNumber,
  setSetNumber,
  startingNumber,
  setStartingNumber,
  dateOfService,
  setDateOfService,
  methodOfService,
  setMethodOfService,
  dueDate,
  setDueDate
}: {
  jurisdiction: Jurisdiction | undefined;
  documentSubType: DocumentSubType;
  setDocumentSubType: React.Dispatch<React.SetStateAction<DocumentSubType>>;
  setNumber: number;
  setSetNumber: React.Dispatch<React.SetStateAction<number>>;
  startingNumber: number;
  setStartingNumber: React.Dispatch<React.SetStateAction<number>>;
  dateOfService: string | undefined;
  setDateOfService: React.Dispatch<React.SetStateAction<string | undefined>>;
  methodOfService: ServiceManner | undefined;
  setMethodOfService: React.Dispatch<React.SetStateAction<ServiceManner | undefined>>;
  dueDate: string | undefined;
  setDueDate: React.Dispatch<React.SetStateAction<string | undefined>>;

}) {
  const jurisdictionApi = useJurisdictionApi();
  const [showStartingNumber, setShowStartingNumber] = useState(false);
  useEffect(() => {
    async function effect() {
      if (dateOfService && methodOfService) {
        const newDueDate = await jurisdictionApi.jurisdictionGetDueDate({ id: jurisdiction?.id!, serviceManner: methodOfService, serviceDate: dateOfService });

        if (newDueDate) {
          setDueDate(newDueDate);
        }
      }
    }
    effect();
  }, [dateOfService, methodOfService, jurisdictionApi, jurisdiction, setDueDate]);

  useEffect(() => {
    if (setNumber > 1 || startingNumber > 1) {
      setShowStartingNumber(true);
    }
  }, [setNumber, startingNumber, showStartingNumber])

  const types = [
    DocumentSubType.SpecialInterrogatorries,
    DocumentSubType.RequestsForProduction,
    DocumentSubType.RequestsForAdmission,
  ];

  const serviceTypes = [
    ServiceManner.Electronic,
    ServiceManner.Other,
    ServiceManner.Regular,
    ServiceManner.InStateMail,
    ServiceManner.OutOfStateMail,
  ];
  return (
    <div >
      <div>
        <Form.Group>
          <label>Request Type</label>
          {documentSubType === DocumentSubType.FormInterrogatorries ? (
            <h4>Form Interrogatories</h4>
          ) : (
            <Form.Select
              required
              value={documentSubType === DocumentSubType.Unknown ? '' : documentSubType}
              size="sm"
              className="mb-3"
              onChange={(event) => setDocumentSubType(Number(event.currentTarget.value))}
            >
              <option disabled value="" selected>
                Choose one
              </option>
              {types.map((type, index) => (
                <option key={index} value={type}>
                  {GetDocumentSubTypeName(type, jurisdiction?.id ?? '')}
                </option>
              ))}
            </Form.Select>
          )}
        </Form.Group>
      </div>
        <div>
          <Form.Group>
            <label>Set Number</label>
            <Form.Control
              type="number"
              min="1"
              style={{ width: 80 }}
              className="mb-3"
              value={setNumber}
              size="sm"
              onChange={(event) => setSetNumber(parseInt(event.currentTarget.value))}
            />
          </Form.Group>
          {showStartingNumber && (
            <Form.Group>
              <label>Starting Number</label>
              <Form.Control
                type="number"
                min="1"
                style={{ width: 80 }}
                size="sm"
                value={startingNumber || ''}
                onChange={(event) => setStartingNumber(parseInt(event.currentTarget.value))}
              />
            </Form.Group>
          )}
        </div>
      {jurisdiction?.supportsDueDates && <>
        <div className="mb-3">
          <Form.Group>
            <label>Date of Service (Optional)</label>
            <Form.Control type='date' value={dateOfService}
              style={{ width: '200px' }} size="sm"
              onChange={(e) => setDateOfService(e.target.value)} />
          </Form.Group>
        </div>
        <div>
          <Form.Group>
            <label>Method of Service (Optional)</label>
            <Form.Select
              required
              value={methodOfService}
              onChange={(event) => setMethodOfService(Number(event.currentTarget.value))}
              size="sm"
              defaultValue={""}
            >
              <option disabled value={0}>Please Select</option>
              {serviceTypes?.map((s, index) => (
                <option key={index} value={s}>
                  {ServiceMannerDisplay[s]}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div></>}
    </div>
  );
}

export default ReviewPageTwo; 
