/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignedAttorney
 */
export interface AssignedAttorney {
    /**
     * 
     * @type {string}
     * @memberof AssignedAttorney
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof AssignedAttorney
     */
    order?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AssignedAttorney
     */
    isPrimary?: boolean;
}

export function AssignedAttorneyFromJSON(json: any): AssignedAttorney {
    return AssignedAttorneyFromJSONTyped(json, false);
}

export function AssignedAttorneyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignedAttorney {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'isPrimary': !exists(json, 'isPrimary') ? undefined : json['isPrimary'],
    };
}

export function AssignedAttorneyToJSON(value?: AssignedAttorney | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'order': value.order,
        'isPrimary': value.isPrimary,
    };
}


