/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessTokenPermission,
    AccessTokenPermissionFromJSON,
    AccessTokenPermissionFromJSONTyped,
    AccessTokenPermissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface DocumentAccessToken
 */
export interface DocumentAccessToken {
    /**
     * 
     * @type {string}
     * @memberof DocumentAccessToken
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentAccessToken
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentAccessToken
     */
    userId?: string;
    /**
     * 
     * @type {AccessTokenPermission}
     * @memberof DocumentAccessToken
     */
    permission?: AccessTokenPermission;
    /**
     * 
     * @type {Date}
     * @memberof DocumentAccessToken
     */
    created?: Date;
}

export function DocumentAccessTokenFromJSON(json: any): DocumentAccessToken {
    return DocumentAccessTokenFromJSONTyped(json, false);
}

export function DocumentAccessTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentAccessToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'permission': !exists(json, 'permission') ? undefined : AccessTokenPermissionFromJSON(json['permission']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
    };
}

export function DocumentAccessTokenToJSON(value?: DocumentAccessToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'documentId': value.documentId,
        'userId': value.userId,
        'permission': AccessTokenPermissionToJSON(value.permission),
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
    };
}


