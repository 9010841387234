import { useEffect, useRef, useCallback } from 'react';
import { Editor } from '@tiptap/react';

interface UseTiptapScrollAdjustmentOptions {
  editor: Editor | null;
  offset?: number;
  behavior?: ScrollBehavior;
  debounceDelay?: number;
}

const useTiptapScrollAdjustment = (
  options: UseTiptapScrollAdjustmentOptions
) => {
  const { 
    editor,
    offset = 100,
    behavior = 'smooth',
    debounceDelay = 100
  } = options;
  
  const timeoutRef = useRef<NodeJS.Timeout>();
  const isUserScrolling = useRef(false);
  const isInputting = useRef(false);

  const scrollIntoViewWithOffset = useCallback((element: HTMLElement, force = false) => {
    if (!force && isUserScrolling.current) return;

    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const isObscured = rect.bottom > (viewportHeight - offset);
    
    if (isObscured) {
      element.scrollIntoView({ behavior, block: 'center' });
    }

  }, [offset, behavior]);

  const debouncedScroll = useCallback((element: HTMLElement) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      scrollIntoViewWithOffset(element, true);
      isInputting.current = false;
    }, debounceDelay);
  }, [debounceDelay, scrollIntoViewWithOffset]);

  useEffect(() => {
    if (!editor?.view?.dom) return;

    const editorElement = editor.view.dom;

    const handleScroll = () => {
      if (!isInputting.current) {
        isUserScrolling.current = true;
      }
    };

    const handleTransaction = () => {
      isInputting.current = true;
      isUserScrolling.current = false;
      debouncedScroll(editorElement);
    };

    const handleFocus = () => {
      scrollIntoViewWithOffset(editorElement, true);
    };

    const handleSelectionUpdate = () => {
      if (isInputting.current) {
        debouncedScroll(editorElement);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (!entry.isIntersecting && 
              editor.isFocused && 
              isInputting.current) {
            scrollIntoViewWithOffset(editorElement, true);
          }
        });
      },
      {
        root: null,
        rootMargin: `-${offset}px 0px 0px 0px`,
        threshold: 1.0
      }
    );

    observer.observe(editorElement);

    window.addEventListener('scroll', handleScroll, { passive: true });
    editor.on('transaction', handleTransaction);
    editor.on('focus', handleFocus);
    editor.on('selectionUpdate', handleSelectionUpdate);
    
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      window.removeEventListener('scroll', handleScroll);
      observer.disconnect();
      editor.off('transaction', handleTransaction);
      editor.off('focus', handleFocus);
      editor.off('selectionUpdate', handleSelectionUpdate);
    };
  }, [editor, offset, debouncedScroll, scrollIntoViewWithOffset]);

};

export default useTiptapScrollAdjustment;
