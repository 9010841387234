/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InsightType {
    Unknown = 0,
    SuggestedObjections = 1,
    ObjectionText = 2,
    PlainEnglish = 3,
    CausesOfAction = 4,
    Data = 5,
    Allegations = 6,
    Timeline = 7
}

export function InsightTypeFromJSON(json: any): InsightType {
    return InsightTypeFromJSONTyped(json, false);
}

export function InsightTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightType {
    return json as InsightType;
}

export function InsightTypeToJSON(value?: InsightType | null): any {
    return value as any;
}

