import { Link } from 'react-router-dom';
import Error from './index';

export default function Error404() {
  return (
    <Error>
      <h1 className="mb-3 display-1">404</h1>
      <p className="text-muted">We seem to have misplaced that page.</p>
      <Link to="/" className="ml-1 btn btn-primary">
        Back to Home
      </Link>
    </Error>
  );
}
