import useMiscContent from 'hooks/useMiscContent';
import useObjections from 'hooks/useObjections';
import { Container, Nav, Tab } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import GeneralObjections from './GeneralObjections';
import ObjectionTable from './ObjectionTable';
import PreliminaryStatement from './PreliminaryStatement';
import Loading from 'components/Loading';
import { useEffect, useRef, useState } from 'react';
import { Objection, User } from 'briefpoint-client/models';
import { useAuth } from 'hooks/useAuth';
import { useUserApi } from 'hooks/useApi';
import Intro from './Intro';
import QuestionsTable from './QuestionsTable';
import Definitions from './Definitions';
import { InfoCircle } from 'react-bootstrap-icons';

export default function Library() {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth()!;
  const userApi = useUserApi()!;
  const tab = new URLSearchParams(location.search).get('tab');
  const type = new URLSearchParams(location.search).get('type');
  const [preliminaryStatements, generalObjections, intros, , questions, updateMiscContent] = useMiscContent();
  const [objections, , , deleteVariant] = useObjections();
  const [users, setUsers] = useState(new Map<string, User>());
  const queryRef = useRef<Objection[] | null>();



  useEffect(() => {
    const fetchData = async () => {
      if (queryRef.current && queryRef.current === objections) {
        return;
      }

      queryRef.current = objections;
      if (!users.has(user!.id!)) {
        setUsers(prev => new Map([...prev, [user!.id!, user!]]));
      }

      let variantOwnerIds = objections!.flatMap(x => x.variants).filter(x => x.ownerId && x.ownerId !== user!.id).map(x => x.ownerId!);
      variantOwnerIds = [...new Set(variantOwnerIds)]; // dedupe the users list

      for (const id of variantOwnerIds) {
        if (!users.has(id)) {
          let otherUser = await userApi.userGetUser({ userId: id });

          if (otherUser && queryRef.current === objections) {
            setUsers(prev => new Map([...prev, [id, otherUser!]]));
          }
        }
      }
    }

    if (objections && user) {
      fetchData();
    }

  }, [objections, user, userApi, setUsers, users]);

  function setUrlWithType(newType: string | null, newTab: string | null) {
    if (newType || newTab) {
      history.push({
        pathname: '/library',
        search: `?type=${newType ?? type}${newTab ? `&tab=${newTab}` : ''}`
      })
    }
  }

  return (
    <>
      <Container className="page-container library-container">
        <>
          <Tab.Container id="library-type" activeKey={type || 'responses'} onSelect={(k) => setUrlWithType(k, null)}>
            <Nav className="library-type-nav">
              <Nav.Item>
                <Nav.Link eventKey="responses">Discovery Responses</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="requests">Discovery Requests</Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="nav-border mb-2"></div>
            <Tab.Content className='type-content'>
              <Tab.Pane eventKey="responses">
                <h1>Discovery Responses</h1>
                <Tab.Container id="left-tabs-example" activeKey={tab || 'objections'} onSelect={(k) => setUrlWithType("responses", k)}>
                  <Nav className="library-nav">
                    <Nav.Item>
                      <Nav.Link eventKey="objections">Objections</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="responses">Responses</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="preliminary-statement">Preliminary Statements</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="general-objections">General Objections</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="nav-border mb-4"></div>
                  <Tab.Content>
                    <Tab.Pane eventKey="objections">
                      <Loading isLoading={!objections}>
                        <ObjectionTable objections={objections!} onDelete={deleteVariant} isResponses={false} users={users} />
                      </Loading>
                    </Tab.Pane>
                    <Tab.Pane eventKey="responses">
                      <Loading isLoading={!objections}>
                        <ObjectionTable objections={objections!} onDelete={deleteVariant} isResponses={true} users={users} />
                      </Loading>
                    </Tab.Pane>
                    <Tab.Pane eventKey="preliminary-statement" unmountOnExit>
                      <Loading isLoading={!preliminaryStatements}>
                        <PreliminaryStatement preliminaryStatements={preliminaryStatements!} onUpdate={updateMiscContent} />
                      </Loading>
                    </Tab.Pane>
                    <Tab.Pane eventKey="general-objections">
                      <Loading isLoading={!generalObjections}>
                        <GeneralObjections generalObjections={generalObjections!} onUpdate={updateMiscContent} />
                      </Loading>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Tab.Pane>
              <Tab.Pane eventKey="requests">
                <h1>Discovery Requests (Beta<span className='info-link'><Link to='/how-to#discovery_requests' title='Learn More About Briefpoint’s Propounding Discovery Beta' target='_blank'><InfoCircle /></Link></span>)</h1>
                <Tab.Container id="left-tabs-example" activeKey={tab || 'requests'} onSelect={(k) => setUrlWithType("requests", k)}>
                  <Nav className="library-nav">
                    <Nav.Item>
                      <Nav.Link eventKey="requests">Questions</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="intro">Intro</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                        <Nav.Link eventKey="instructions">Instructions</Nav.Link>
                      </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="definitions">Definitions</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="nav-border mb-4"></div>
                  <Tab.Content>
                    <Tab.Pane eventKey="intro" unmountOnExit>
                      <Loading isLoading={!intros}>
                        <Intro intros={intros!} onUpdate={updateMiscContent} />
                      </Loading>
                    </Tab.Pane>
                    <Tab.Pane eventKey="definitions">
                      <Definitions />
                    </Tab.Pane>
                    <Tab.Pane eventKey="requests">
                      <Loading isLoading={!questions}>
                        <QuestionsTable questions={questions!} onUpdate={updateMiscContent} users={users} />
                      </Loading>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </>
      </Container>
    </>
  );
}
