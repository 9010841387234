import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { DiscountType, SubscriptionPlan } from 'briefpoint-client';
import Button from 'components/Button';
import { useFirmApi, useStripeApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import { getPlanTypesForPrices, getPriceInDollars } from 'hooks/useStripe';
import useMixpanel from 'hooks/useMixpanel';
import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { SubscriptionPlanType, transformFirm } from 'services/FirmService';
import { formatShortDate } from 'utils/dateUtils';
import CardSection from './CardSection';
import './styles.scss';

export default function CardSetupForm({ plan, onFirmUpdated, attorneyQuantity, paralegalQuantity }: { plan: SubscriptionPlanType, onFirmUpdated: () => void, attorneyQuantity: number, paralegalQuantity: number }) {
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const { user, firm, setFirm } = useAuth()!;
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const stripeApi = useStripeApi();
  const firmApi = useFirmApi();
  const [coupon, setCoupon] = useState<string>();
  const [selectedPlan, setPlan] = useState(plan);
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [validCoupon, setValidCoupon] = useState(false);
  const [mixpanelTrack] = useMixpanel()!;
  // const [cardComplete, setCardComplete] = useState(false);
  // const updateSubscription = useCreateSubscription();
  const handleCouponChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setCoupon(event.target.value);
    setInvalidCoupon(false);
    setValidCoupon(false);
  }

  const handleCouponKey = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      validateCoupon();
    }
  }

  const validateCoupon = async () => {
    if (coupon) {
      const results = await stripeApi.stripeCheckCoupon({ code: coupon });
      const prices = getPlanTypesForPrices(results);
      if (results && results.length && prices[selectedPlan.plan] && prices[selectedPlan.plan].discountedPriceString) {
        setPlan(prices[selectedPlan.plan]);
        setInvalidCoupon(false);
        setValidCoupon(true);
      } else {
        setInvalidCoupon(true);
        setValidCoupon(false);
        setPlan(plan);
      }
    } else {
      setPlan(plan);
    }
  }

  useEffect(() => {
    async function fetch() {
      const secret = await stripeApi.stripeSetupIntent();
      setClientSecret(secret);
    }
    fetch();
  }, [stripeApi]);

  function stripeCardOnChange(event: StripeCardElementChangeEvent) {
    // setCardComplete(event.complete);
    setError(undefined);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    if (!user || !stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)!,
      },
    });

    if (result.error) {
      // Display result.error.message in your UI.
      setError(result.error.message);
      setLoading(false);
    } else {
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
      // var updatedFirm = await updateSubscription(plan.plan, result.setupIntent.payment_method!);
      var updatedFirm = await firmApi.firmCreateSubscription({
        createSubscription: {
          subscriptionPlan: selectedPlan.plan,
          paymentMethod: result.setupIntent.payment_method! as string,
          discountCode: selectedPlan.discountCode,
        },
      });
      if (updatedFirm) {
        setFirm(transformFirm(updatedFirm));
        mixpanelTrack("Subscribe", { "Plan": selectedPlan.title });
        // history.push('/?signedup=1');
        onFirmUpdated();
      }
    }
  };
  const inTrial = firm && firm.billingInfo.trialEnds && firm.billingInfo.trialEnds > new Date();
  const ENVIRONMENT: string = (window as any)._env_?.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT;
  return (
    <form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      <Row>
        <Col lg={{ span: 8 }}>
          <div className="mb-3">
            <strong>Add Payment Method</strong>
          </div>
          {ENVIRONMENT !== "Production" && <Alert variant="info">
            Payments are currently configured to use the test environment so you must use the test credit card
            number:
            <br /> 4111 1111 1111 1111
            <br />
            Use any future expiration date, any cvc, and any zip code
          </Alert>}
          <CardSection onChange={stripeCardOnChange} />
          <div className="mt-3 mb-3" style={{ fontSize: 12 }}>
            All payment information is secured using 256-bit encryption and processed by our payment provider{' '}
            <a target="_blank" href="https://www.stripe.com" rel="noreferrer">
              Stripe
            </a>
            . Briefpoint does not store payment data.
          </div>
        </Col>
        <Col lg={12}>
          <p>Have a coupon? <Form.Group className="d-inline">
            <Form.Control
              placeholder="Enter Code"
              style={{ width: 160 }}
              className="d-inline"
              onChange={handleCouponChanged}
              onKeyPress={handleCouponKey}
              isInvalid={invalidCoupon}
              isValid={validCoupon}
            />
            <Button variant='link' className="d-inline m-auto" onClick={validateCoupon}>
              Apply
            </Button></Form.Group></p>
          <div className="mb-3">
            <strong>Subscription Overview </strong>
          </div>

          <InfoRow left="Plan" right={selectedPlan.title} />
          <InfoRow left="Amount" right={PriceDisplay(selectedPlan, selectedPlan.plan === SubscriptionPlan.Monthly, attorneyQuantity)} />
          {inTrial && <InfoRow left="Trial end date" right={formatShortDate(firm!.billingInfo.trialEnds!)} />}
          <div className="mb-3">
            <InfoRow
              left={inTrial ? 'Total due at end of trial' : 'Total due now'}
              right={TotalDisplay(selectedPlan, attorneyQuantity, paralegalQuantity) ?? ''}
              summary
            />
          </div>
        </Col>
      </Row>
      <div className="text-center">
        <Button disabled={!stripe || loading} type="submit" className="px-5" loading={loading}>
          Sign up
        </Button>
      </div>
    </form>
  );
}

function PriceDisplay(plan: SubscriptionPlanType, showDiscountLength: boolean = false, quantity: number = 1) {
  if (!plan.discountedPriceString) {
    return (<>{`${plan.priceString}${plan.plan === SubscriptionPlan.Monthly || plan.plan === SubscriptionPlan.Yearly ? '/attorney' : plan.plan === SubscriptionPlan.YearlyBundle ? '/10 attorneys' : ''}`}</>);
  }

  return (
    <>
      <span className={'original-price-slashed'}><del>{plan.priceString}</del></span> {getPriceInDollars(plan.discountedPrice!)}{plan.plan === SubscriptionPlan.Monthly || plan.plan === SubscriptionPlan.Yearly ? '/attorney' : plan.plan === SubscriptionPlan.YearlyBundle ? '/10 attorneys' : ''}
      {(showDiscountLength && plan.discountLength) && <p className={'plan-discount-length'}> {plan.discountLength}</p>}
    </>
  );
}

function TotalDisplay(plan: SubscriptionPlanType, quantity: number = 1, paralegalQuantity: number = 0) {
  let originalCost = plan.price;
  if (plan.plan === SubscriptionPlan.Yearly || plan.plan === SubscriptionPlan.Monthly) {
    originalCost += (quantity - 1) * originalCost;
    const paralegals = paralegalQuantity - (plan.freeParalegals ?? 0);
    if (paralegals > 0) {
      originalCost += paralegals * (plan.paralegalPrice ?? 0);
    }
  }

  if (!plan.discountedPriceString) {
    return getPriceInDollars(originalCost);
  }

  let discounted = plan.discountedPrice!;
  if (plan.plan === SubscriptionPlan.Yearly || plan.plan === SubscriptionPlan.Monthly) {
    discounted += (quantity - 1) * discounted * (plan.discountType === DiscountType.Fixed ? 1 : plan.discountedPrice! / plan.price); //TODO: pull perAdditionalUser cost from Stripe
    const paralegals = paralegalQuantity - (plan.freeParalegals ?? 0);
    if (paralegals > 0) {
      discounted += paralegals * (plan.paralegalPrice ?? 0);
    }
  }
  return (
    <>
      <span className={'original-price-slashed'}><del>{getPriceInDollars(originalCost)}</del></span> {getPriceInDollars(discounted)}
    </>
  )
}

function InfoRow({ left, right, summary }: { left: string; right: string | JSX.Element; summary?: boolean }) {
  return (
    <>
      <hr />
      <Row>
        <Col sm={8} lg={9}>
          {summary ? <strong>{left}</strong> : left}
        </Col>
        <Col>{summary ? <strong>{right}</strong> : right}</Col>
      </Row>
    </>
  );
}
