import { useDocumentApi } from 'hooks/useApi';
import { useCallback, useEffect, useState } from 'react';
import api from 'api/Api';

interface Props {
  documentId: string;
  height: number;
  width: number;
}

export default function PdfViewer({ documentId, height }: Props) {
  // const [numPages, setNumPages] = useState<number | null>(null);
  // const [pageNumber, setPageNumber] = useState(1);
  const documentApi = useDocumentApi();
  const [itemUrl, setItemUrl] = useState<string>();

  // function changePage(offset: number) {
  //   setPageNumber((prevPageNumber) => prevPageNumber + offset);
  // }

  // function previousPage() {
  //   changePage(-1);
  // }

  // function nextPage() {
  //   changePage(1);
  // }

  const getPdfUrl = useCallback(
    async (checkLocalhost: boolean = false) => {
      if (checkLocalhost && api.API_URL.includes('localhost')) {
        // the word document previewer can't use localhost urls
        let url = await documentApi.documentGetPdfUrl({ documentId });
        return url;
      } else {
        let url = await documentApi.documentGetPdfUrl({ documentId });
        return url;
      }
    },
    [documentApi, documentId]
  );

  useEffect(() => {
    async function loadUrl() {
      let url = await getPdfUrl(true);
      setItemUrl(JSON.parse(url));
    }
    loadUrl();
  }, [getPdfUrl]);

  // function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
  //   setNumPages(numPages);
  // }

  return (
    <div>
      {itemUrl ? (
        <div>
          {/* <Document file={itemUrl} onLoadSuccess={onDocumentLoadSuccess}>
            <div style={{ height: height, width: testwidth * 0.35 }}>
              <Page height={height} width={testwidth * 0.35} pageNumber={pageNumber} />
            </div>
          </Document> */}
          <div style={{ height: height, width: '100%' }}>
            <iframe title="pdf" src={itemUrl} width="100%" height="100%" frameBorder="0">
              This browser does not support PDFs. Please download the PDF to view it: <a href={itemUrl}>Download PDF</a>
            </iframe>
          </div>
          {/* {numPages && (
            <div className="button-controls d-flex justify-content-end">
              <div className="page-number">
                {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
              </div>
              <button
                className="page-number-button border"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                &lt;
              </button>
              <button
                className="page-number-button border"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                &gt;
              </button>
            </div>
          )} */}
        </div>
      ) : (
        <div>Loading</div>
      )}
    </div>
  );
}
