import { useCallback, useMemo } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

interface OAuthProvider {
  isAuthenticated: boolean;
  logout: (redirect: string | undefined) => Promise<any>;
  getToken: (name?: string, scopes?: string[]) => Promise<string>;
}

// Provider hook that creates auth object and handles state
export function useProvideOAuth() {
  const { isAuthenticated, logout, getAccessTokenSilently } = useAuth0();

  const handleLogout = useCallback(async (redirect: string | undefined) => {
    return logout({ returnTo: redirect });
  }, [logout]
  );

  const handleIsAuthenticated = useMemo(() => {
    return isAuthenticated;
  }, [isAuthenticated]
  );

  const handleGetToken = useCallback(async (name?: string, scopes?: string[]) => {
    let token;
    const auth0scopes = "openid profile email";
    let result = await getAccessTokenSilently({
      scope: auth0scopes,
    });
    token = result;
    return token || '';
  }, [getAccessTokenSilently]);

  return {
    isAuthenticated: handleIsAuthenticated,
    logout: handleLogout,
    getToken: handleGetToken
  } as OAuthProvider;
}
