import Loading from 'components/Loading';
import useDocumentDetail from 'hooks/useDocumentDetail';
import { Button, Col, Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PdfViewer from '../../../components/Wizard/PdfViewer';
import InterrogatoriesList from './InterrogatoriesList';

export default function UpdateInterrogatories() {
  const { documentId } = useParams<{ documentId: string }>();
  const { interrogatoryId } = useParams<{ interrogatoryId: string }>();
  const [document, , , , addEditInterrogatory, deleteInterrogatory] = useDocumentDetail(documentId);
  const history = useHistory();

  function onConfirm() {
    history.goBack();
  }

  async function addNew(index: number, text: string) {
    await addEditInterrogatory(index + 1, text);
  }

  async function onEdit(id: number, index: number, text?: string) {
    await addEditInterrogatory(index, text, id);
  }

  return (
    <Loading className="mt-3" isLoading={!document}>
      <Container className="page-container">
        <div className="mb-4">
          <div className="d-flex">
            <Col xs="5">
              <div className="d-flex justify-content-between">
                <h2>Update Text</h2>
              </div>
              <div className="document-preview-container interrogatory-list-wrapper list-column">
                <InterrogatoriesList interrogatoriesList={document?.interrogatories || []} interrogatoryId={interrogatoryId} onAdd={addNew} onEdit={onEdit} onDelete={deleteInterrogatory} documentSubType={document?.info?.documentSubType} startingNumber={document?.info?.startingNumber || 1} />
              </div>
            </Col>
            <Col xs="7">
              <h2>Source Document</h2>
              <div className="document-preview-container">
                <PdfViewer documentId={documentId} height={800} width={365} />
              </div>
            </Col>
          </div>
        </div>
        <Button variant="primary" onClick={onConfirm}>
          Save and Go Back
        </Button>
      </Container>
    </Loading>
  );
}
