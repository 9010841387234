/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubscriptionPlan,
    SubscriptionPlanFromJSON,
    SubscriptionPlanFromJSONTyped,
    SubscriptionPlanToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateSubscription
 */
export interface CreateSubscription {
    /**
     * 
     * @type {SubscriptionPlan}
     * @memberof CreateSubscription
     */
    subscriptionPlan?: SubscriptionPlan;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscription
     */
    paymentMethod?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscription
     */
    discountCode?: string | null;
}

export function CreateSubscriptionFromJSON(json: any): CreateSubscription {
    return CreateSubscriptionFromJSONTyped(json, false);
}

export function CreateSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subscriptionPlan': !exists(json, 'subscriptionPlan') ? undefined : SubscriptionPlanFromJSON(json['subscriptionPlan']),
        'paymentMethod': !exists(json, 'paymentMethod') ? undefined : json['paymentMethod'],
        'discountCode': !exists(json, 'discountCode') ? undefined : json['discountCode'],
    };
}

export function CreateSubscriptionToJSON(value?: CreateSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subscriptionPlan': SubscriptionPlanToJSON(value.subscriptionPlan),
        'paymentMethod': value.paymentMethod,
        'discountCode': value.discountCode,
    };
}


