/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AlertType,
    AlertTypeFromJSON,
    AlertTypeFromJSONTyped,
    AlertTypeToJSON,
    DocumentInfo,
    DocumentInfoFromJSON,
    DocumentInfoFromJSONTyped,
    DocumentInfoToJSON,
    DocumentStatus,
    DocumentStatusFromJSON,
    DocumentStatusFromJSONTyped,
    DocumentStatusToJSON,
    Interrogatory,
    InterrogatoryFromJSON,
    InterrogatoryFromJSONTyped,
    InterrogatoryToJSON,
    Source,
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name?: string | null;
    /**
     * 
     * @type {DocumentStatus}
     * @memberof Document
     */
    status?: DocumentStatus;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    location?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    uploadDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    isArchived?: boolean;
    /**
     * 
     * @type {Array<Interrogatory>}
     * @memberof Document
     */
    interrogatories?: Array<Interrogatory> | null;
    /**
     * 
     * @type {DocumentInfo}
     * @memberof Document
     */
    info?: DocumentInfo;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    lastWordDownloadDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    fileSize?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    caseId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    clientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    uploadedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Document
     */
    caseIdConfirmed?: boolean;
    /**
     * 
     * @type {AlertType}
     * @memberof Document
     */
    alert?: AlertType;
    /**
     * 
     * @type {Source}
     * @memberof Document
     */
    source?: Source;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    parentDocumentId?: string | null;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status': !exists(json, 'status') ? undefined : DocumentStatusFromJSON(json['status']),
        'location': !exists(json, 'location') ? undefined : json['location'],
        'uploadDate': !exists(json, 'uploadDate') ? undefined : (new Date(json['uploadDate'])),
        'isArchived': !exists(json, 'isArchived') ? undefined : json['isArchived'],
        'interrogatories': !exists(json, 'interrogatories') ? undefined : (json['interrogatories'] === null ? null : (json['interrogatories'] as Array<any>).map(InterrogatoryFromJSON)),
        'info': !exists(json, 'info') ? undefined : DocumentInfoFromJSON(json['info']),
        'lastWordDownloadDate': !exists(json, 'lastWordDownloadDate') ? undefined : (json['lastWordDownloadDate'] === null ? null : new Date(json['lastWordDownloadDate'])),
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'uploadedBy': !exists(json, 'uploadedBy') ? undefined : json['uploadedBy'],
        'caseIdConfirmed': !exists(json, 'caseIdConfirmed') ? undefined : json['caseIdConfirmed'],
        'alert': !exists(json, 'alert') ? undefined : AlertTypeFromJSON(json['alert']),
        'source': !exists(json, 'source') ? undefined : SourceFromJSON(json['source']),
        'parentDocumentId': !exists(json, 'parentDocumentId') ? undefined : json['parentDocumentId'],
    };
}

export function DocumentToJSON(value?: Document | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'name': value.name,
        'status': DocumentStatusToJSON(value.status),
        'location': value.location,
        'uploadDate': value.uploadDate === undefined ? undefined : (value.uploadDate.toISOString()),
        'isArchived': value.isArchived,
        'interrogatories': value.interrogatories === undefined ? undefined : (value.interrogatories === null ? null : (value.interrogatories as Array<any>).map(InterrogatoryToJSON)),
        'info': DocumentInfoToJSON(value.info),
        'lastWordDownloadDate': value.lastWordDownloadDate === undefined ? undefined : (value.lastWordDownloadDate === null ? null : value.lastWordDownloadDate.toISOString()),
        'fileSize': value.fileSize,
        'caseId': value.caseId,
        'clientId': value.clientId,
        'uploadedBy': value.uploadedBy,
        'caseIdConfirmed': value.caseIdConfirmed,
        'alert': AlertTypeToJSON(value.alert),
        'source': SourceToJSON(value.source),
        'parentDocumentId': value.parentDocumentId,
    };
}


