import Loading from 'components/Loading';
import { useUserApi } from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

interface Props {
  isLoading: boolean;
  onAgree: () => void;
}

export default function TermsAndConditions({ isLoading, onAgree }: Props) {
  const [terms, setTerms] = useState<string>();
  const userApi = useUserApi();

  useEffect(() => {
    async function getTerms() {
      const tmpTerms = await userApi.userGetTermsAndConditions();
      setTerms(tmpTerms);
    }
    getTerms();
  }, [userApi]);

  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      <Loading isLoading={!terms}>
        <h1>Terms and conditions</h1>
        <div
          className="border p-3 overflow-auto"
          style={{ height: '70vh', maxWidth: 700 }}
          dangerouslySetInnerHTML={{ __html: terms! }}
        />
        <Loading className="mt-3" isLoading={isLoading}>
          <Button className="mt-3" onClick={onAgree}>
            Agree and Continue
          </Button>
        </Loading>
      </Loading>
    </div>
  );
}
