/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Representative {
    None = 'None',
    Individual = 'Individual',
    ClassAction = 'ClassAction',
    Paga = 'PAGA'
}

export function RepresentativeFromJSON(json: any): Representative {
    return RepresentativeFromJSONTyped(json, false);
}

export function RepresentativeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Representative {
    return json as Representative;
}

export function RepresentativeToJSON(value?: Representative | null): any {
    return value as any;
}

