import { Case, Client, Definition, DocumentType, DocumentSubType, Question, User, MiscContentType, MiscContent, CaseClass, CreateDiscoveryRequest, PropoundingParty, PartyPosition, Respondent, Representative, ContentGetMiscContentFilteredRequest, DocumentDetail } from 'briefpoint-client';
import { useCaseApi, useClientApi, useContentApi, useDefinitionsApi, useDocumentApi, useTagApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import useDocuments from 'hooks/useDocuments';
import { type RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Link, Prompt, useLocation, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import CaseInfo from 'components/DocumentGeneration/DiscoveryRequests/CaseInfo';
import Questions, { punctuationRegexForDefAliases } from 'components/DocumentGeneration/DiscoveryRequests/Questions';
import Definitions from 'components/DocumentGeneration/DiscoveryRequests/Definitions';
import './GenerateDiscoveryRequestPage.scss'
import CaseTypeSelect, { CaseTypeOption } from 'components/CaseManagement/CaseTypeSelect';
import { getRequestTypeSingular, replaceTokens } from 'screens/DocumentWizard/SpecialInterrogatoriesStep/Selections/utils';
import TagsSelect, { TagOption } from 'components/Select/TagsSelect';
import { ActionMeta, OptionsType } from 'react-select';
import api from 'api/Api';
import { ChevronDown, ChevronUp, InfoCircle } from 'react-bootstrap-icons';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import useMiscContent from 'hooks/useMiscContent';
import raygun4js from 'raygun4js';
import { SaveQueue } from 'utils/promiseQueue';

export function leadAttorneyName(_case: Case, client: Client, users: User[]) {
  if (_case && client) {
    let primary = _case.attorneys?.find(x => x.isPrimary) ?? client.attorneys?.find(x => x.isPrimary);

    if (!primary) {
      primary = _case.attorneys?.at(0) ?? client.attorneys?.at(0);
    }

    if (primary) {
      const attorney = users?.find(x => x.id === primary?.id);

      if (attorney) {
        return `${attorney.lastName}, ${attorney.firstName}`;
      }
    }
  }

  return '-';
}

type GenerateRequestParams = {
  clientId: string;
  id: string;
};

export interface ToastMsgItem {
  type: string;
  label: string;
}

export interface ToastMsgObj {
  item: ToastMsgItem[];
}

export interface DefinitionItem extends Definition {
  toDelete?: boolean;
  isDirty?: boolean;
}

export interface QuestionItem extends Question {
  interrogatoryId?: number;
  isChecked: boolean;
  displayText?: string;
  saveToLibrary?: boolean;
  order?: number;
  tagIds?: number[] | null;
}

export interface QuestionGroup {
  key: string;
  title: string;
  caseType?: string;
  tagIds?: number[];
  questions?: QuestionItem[];
  defaultQuestions?: MiscContent[];
}

const definitionRegex = new RegExp(/((?:[A-Z{}_]{3,}(?:\(S\))?(?: +[A-Z]{2,}(?:\(S\))?)*))/g);
const excludeFromDefinitions = ["SSD", "PDA", "NASA", "NATO", "SWAT", "UNICEF", "UNESCO", "WHO", "OPEC", "FBI", "CIA", "LASER", "RADAR", "SCUBA", "VIP", "PTSD", "MRI", "DNA", "CPU", "RAM", "LCD", "LED", "URL", "HTTP", "HTML", "DUI", "DWI", "IMF", "GPA", "IBM", "ABS", "DVD", "USB", "PPE", "HIPAA", "SUV", "JPEG", "GIF", "SARS", "SIM", "ATM", "VPN", "DSLR", "DOB", "BCE", "AKA", "CEO", "CFO", "CPR", "CSI", "DMV", "FAQ", "FBI", "GDP", "GMO", "GPS", "HDTV", "ICU", "ISP", "LCD", "MBA", "NBA", "NFL", "RSVP", "USDA", "ZIP", "RFP", "STEM", "UFO", "VCR", "WI-FI"];

const TOAST_TIMER = 5000;

function findWithin(text: string, searches?: string[]) {
  if (!text || !searches) return undefined;
  for (let i = 0; i < searches.length; i++) {
    const search = searches[i];

    if (text.includes(search)) {
      return search;
    }
  }

  return undefined;
}

function getDefinitions(text: string, existingDefinitions: DefinitionItem[], defs: Definition[], exclude: string[]) {
  const update = [...existingDefinitions];
  const missing = [];
  const definitionMatches = [...text.matchAll(definitionRegex)];
  const toExclude = excludeFromDefinitions.concat(exclude);

  if (!definitionMatches) return [];

  for (let m = 0; m < definitionMatches.length; m++) {
    let match = definitionMatches[m][0];

    //prevents matching on substitution strings
    if (match.startsWith("{") || toExclude.find(x => x === match)) continue;

    for (let d = 0; d < defs.length; d++) {
      const definition = defs[d];
      if (!definition.term || definition.term.length < 3) {
        continue;
      }
      const termMatch = [definition.term];

      const parens = definition.term.indexOf('(');
      if (parens > -1) {
        try {
          // Just TERM of TERM(S)
          const singular = definition.term.substring(0, parens);
          termMatch.push(singular);
          // TERMS from TERM(S)
          const plural = definition.term.substring(parens + 1, definition.term.length - 1);
          termMatch.push(singular.concat(plural));
        } catch (e) {
          console.log(e);
          raygun4js('send', { error: e, message: `Term was: ${definition.term}` });
        }
      }
      let allSearches: string[] = [];
      try {
        allSearches = termMatch.concat(definition.aliases?.split(punctuationRegexForDefAliases)?.map(x => x.trim()) ?? []).sort((a, b) => b.length - a.length);
      } catch (e) {
        console.log(e);
        console.log(`This alias failed: ${definition.aliases}`);
      }
      const searchFound = findWithin(match, allSearches);
      // TODO: do we need to do (S) filtering on aliases as above?
      if (searchFound) {
        const existing = update.findIndex(x => x.term === definition.term);
        // TODO: I can't figure out a clever way to not have duplicate code here
        if (existing === -1) {
          update.push(definition);
          //Need to potentially add definitions for terms inside other definitions
          const toAdd = getDefinitions(definition.content ?? '', update, defs, exclude) ?? [];
          toAdd.filter(a => !update.find(u => u.term === a.term)).forEach(a => update.push(a));
        } else if (!update[existing].id) {
          update[existing] = definition;
          //Need to potentially add definitions for terms inside other definitions
          const toAdd = getDefinitions(definition.content ?? '', update, defs, exclude) ?? [];
          toAdd.filter(a => !update.find(u => u.term === a.term)).forEach(a => update.push(a));
        }
        match = match.replace(searchFound, '').trim();

        if (match?.length < 3) {
          break;
        }
      }
    }

    if (match?.length >= 3) {
      missing.push({ term: match });
    }
  }

  return update.concat(missing);
}

export function buildQuestionKey(question: MiscContent) {
  let key = '-';

  if (question.caseTypeId) {
    key += question.caseTypeId;
  }

  if (question.tagIds?.length) {
    key += `tags${question.tagIds.sort().join('-')}`;
  }

  return key;
}

export function buildQuestionTitle(question: MiscContent, type: DocumentSubType, caseClasses: CaseClass[], allTags: Map<number, string>) {
  if (!question.caseTypeId && !question.tagIds?.length) {
    return `General ${getRequestTypeSingular(type, '')}`;
  }

  let caseTypeLabel = '';
  if (question.caseTypeId) {
    const caseClass = caseClasses.find(x => x.id === question.caseTypeId);

    if (caseClass) {
      caseTypeLabel = caseClass.name!;
    } else {
      const caseType = caseClasses.flatMap(x => x.subTypes).find(x => x?.id === question.caseTypeId);
      caseTypeLabel = caseType!.fullName!;
    }
  }

  let tagsLabel = '';
  if (question.tagIds?.length) {
    const tags: string[] = [];
    question.tagIds.sort().forEach(t => { tags.push(allTags.get(t)!) });

    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];

      if (!tagsLabel) {
        tagsLabel = tag;
      } else {
        tagsLabel += `, ${tag}`;
      }
    }
  } else {
    return caseTypeLabel;
  }

  if (!caseTypeLabel) {
    return tagsLabel;
  }

  return `${caseTypeLabel}: ${tagsLabel}`;
}

export function convertPartyPositionToPropoundingParty(position?: PartyPosition) {
  let ret = PropoundingParty.Unknown;

  if (!position) return ret;
  // need to do this jazz because UserService enums are being returned as strings not numbers like the DocumentService,
  // probably need to change UserService to behave like DocumentService but there are too many cases to handle right now
  const positionValues = position.split(',').map(item => item.trim());
  for (let i = 0; i < positionValues.length; i++) {
    const p = positionValues[i];

    if (p === PartyPosition.Plaintiff) {
      ret += PropoundingParty.Plaintiff;
    } else if (p === PartyPosition.Defendant) {
      ret += PropoundingParty.Defendant;
    } else if (p === PartyPosition.CrossComplainant) {
      ret += PropoundingParty.CrossComplainant;
    } else if (p === PartyPosition.CrossDefendant) {
      ret += PropoundingParty.CrossDefendant;
    }
  }

  return ret;
}

export default function GenerateDiscoveryRequestPage() {
  const { user, firm } = useAuth()!;
  const location = useLocation();
  const { clientId, id } = useParams<GenerateRequestParams>();
  const clientApi = useClientApi();
  const caseApi = useCaseApi();
  const [, , , ,] = useDocuments(false);
  const [client, setClient] = useState<Client>((location.state as any)?.client);
  const [_case, setCase] = useState<Case>((location.state as any)?.case);
  const [caseIsDirty, setCaseIsDirty] = useState(false);
  const [saving, setSaving] = useState<string>();
  const [, setErroredSave] = useState<() => Promise<void>>();
  const type = new URLSearchParams(location.search).get('type');
  const set = new URLSearchParams(location.search).get('set');
  const starting = new URLSearchParams(location.search).get('start');
  const partyId = new URLSearchParams(location.search).get('partyId');
  const documentId = new URLSearchParams(location.search).get('documentId');
  const [questions, setQuestions] = useState<QuestionGroup[]>();
  const [availableDefinitions, setAvailableDefinitions] = useState<Definition[]>();
  const [definitions, setDefinitions] = useState<DefinitionItem[]>();
  const [availableTags, setAvailableTags] = useState<Map<number, string> | undefined>();
  const [extraTagInfo, setExtraTagInfo] = useState<Map<number, number> | undefined>();
  const [setNumber, setSetNumber] = useState(set ? Number(set) : 1);
  const [startingNumber, setStartingNumber] = useState<number>(starting ? Number(starting) : 1);
  const [document, setDocument] = useState<DocumentDetail>();
  const [dirty, setIsDirty] = useState(false);
  const { jurisdictions } = useAuth()!;
  const jx = (jurisdictions ?? []).find(x => x.id === _case?.jurisdiction);
  const caseTypes = jx?.caseClasses?.filter(c => c.subTypes).flatMap(c => [c.subTypes!]).flat();
  const docType = document?.info?.documentSubType ?? (type ? Number(type) as DocumentSubType : undefined);
  const [caseDetailsOpen, setCaseDetailsOpen] = useState<boolean>(true);
  const definitionsApi = useDefinitionsApi();
  const [, , , , , , createMiscContent] = useMiscContent(false);
  const documentApi = useDocumentApi();
  const contentApi = useContentApi();
  const tagApi = useTagApi();
  const scrollToQuestion = useRef<HTMLDivElement>(null);
  const documentMatchedToQuestions = useRef(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastRequestOpen, setToastRequestOpen] = useState<boolean>(false);
  const [toastPromptType, setToastPromptType] = useState<string>();
  const [toastMsgValue, setToastMsgValue] = useState<string>();
  const [toastCurTagId, setToastCurTagId] = useState<number | undefined | null>(null);
  const [toastExistingTag, setToastExistingTag] = useState<boolean | null>(null);
  const party = _case ? _case.otherParties?.find(x => x.id === (document?.info?.otherPartyId ?? partyId)) : undefined;
  const updateQueue = useRef(new SaveQueue(setSaving, setErroredSave));

  useEffect(() => {
    setQuestions(undefined);
    setDefinitions(undefined);
    setDocument(undefined);
  }, [type])

  const loadClient = useCallback(async (user: User | undefined, client: Client | undefined) => {
    if (user && user.firmId && !client) {
      const client = await clientApi.clientGet({ firmId: user.firmId!, id: clientId });
      setClient(client);
    }
  }, [clientApi, clientId]);
  const requestType = docType && getRequestTypeSingular(docType, jx?.id ?? '')
  useEffect(() => {
    loadClient(user, client);
  }, [user, loadClient, client]);

  const loadCase = useCallback(async (user: User | undefined, _case: Case | undefined) => {
    if (user && user.firmId && !_case) {
      const c = await caseApi.caseGet({ firmId: user.firmId!, id });
      setCase(c);
    }
  }, [caseApi, id]);

  useEffect(() => {
    loadCase(user, _case);
  }, [user, loadCase, _case]);

  useEffect(() => {
    async function loadDefs() {
      const defs = await definitionsApi.definitionGetList({ caseId: id });
      defs.sort((a, b) => b.term!.length - a.term!.length);
      setAvailableDefinitions(defs);
    };

    if (!availableDefinitions?.length && id) {
      loadDefs();
    }

  }, [availableDefinitions, setAvailableDefinitions, definitionsApi, id]);

  const extractDefinitionsFromQuestions = useCallback(async (questions: QuestionItem[], existing?: DefinitionItem[]) => {
    if (!availableDefinitions || !client || !party) return;

    const update: DefinitionItem[] = existing ?? [];
    for (let i = 0; i < questions.length; i++) {
      const text = questions[i].displayText ?? questions[i].text;

      if (!text) continue;

      const toAdd = getDefinitions(text, update, availableDefinitions, [client?.name ?? '', party?.name ?? '']);

      for (let a = 0; a < toAdd.length; a++) {
        const element = toAdd[a];

        if (!update.find(x => x.term === element.term)) {
          update.push(element);
        }
      }
    }

    setDefinitions(d => [...d ?? [], ...update.filter(x => !d?.find(e => e.term === x.term))].sort((a, b) => (b.id ?? 0) - (a.id ?? 0)));
  }, [setDefinitions, availableDefinitions, client, party]);

  useEffect(() => {
    if (!definitions && questions && availableDefinitions) {
      extractDefinitionsFromQuestions(questions.flatMap(x => x.questions!));
    }

  }, [questions, definitions, availableDefinitions, extractDefinitionsFromQuestions]);

  useEffect(() => {
    async function loadDoc() {
      const doc = await documentApi.documentGet({ documentId: documentId! });
      setDocument(doc);
      setSetNumber(s => doc.info?.setNumber ?? s);
      setStartingNumber(s => doc.info?.startingNumber ?? s);
    };

    if (documentId && (!document || document.id !== documentId)) {
      loadDoc();
    }

  }, [documentId, document, documentApi]);

  function sortQuestionGroup(a: QuestionGroup, b: QuestionGroup) {
    if (a.key === '-1') {
      return 1;
    }

    if (b.key === '-1') {
      return -1;
    }

    return a.key.localeCompare(b.key);
  }

  useEffect(() => {
    if (questions && document && _case?.representativeType && !documentMatchedToQuestions.current) {
      const update = [...questions!];
      // match/replace saved misccontent with data from document

      update.forEach(g => g.questions?.filter(q => q.questionId).forEach(q => {
        const matching = document.interrogatories.find(x => x.sourceId === q.questionId);
        q.isChecked = !!matching;
        if (matching) {
          q.text = matching.text;
          q.displayText = replaceTokens(document.info, matching.text || '', true, _case?.representativeType)
          q.interrogatoryId = matching.id;
        }
      }));
      let group = update.find(x => x.key === '-1');

      for (let i = 0; i < document.interrogatories.length; i++) {
        const int = document.interrogatories[i];

        // no sourceId means it is not saved misccontent so should go in documentSpecific
        if (!int.sourceId) {
          if (!group) {
            group = { key: '-1', title: "Document Specific", questions: [] };
            update.push(group);
          }
          if (!group.questions?.find(x => x.interrogatoryId === int.id || x.text === int.text)) {
            group.questions?.push({
              isChecked: true,
              text: int.text,
              displayText: replaceTokens(document.info, int.text || '', true, _case?.representativeType),
              interrogatoryId: int.id
            });
          }
          let unsaved = group.questions?.find(x => !x.interrogatoryId && x.text === int.text);
          if (unsaved) {
            unsaved.interrogatoryId = int.id;
          }
        }
      }
      setQuestions(update.sort(sortQuestionGroup));
      return () => {
        documentMatchedToQuestions.current = true;
      }
    }

  }, [questions, document, _case?.representativeType]);

  useEffect(() => {
    if (_case?.caseType && docType && party) {
      const query: ContentGetMiscContentFilteredRequest = {
        type: MiscContentType.DiscoveryQuestion,
        documentType: DocumentType.DiscoveryRequest,
        caseType: _case.caseType ?? undefined,
        documentSubType: docType,
        skipTagFiltering: true,
        isForRepresentative: _case.representativeType === undefined || _case.representativeType === Representative.None ? false : _case.representativeType !== Representative.Individual,
        respondingParty: party?.position === undefined || party.position === PartyPosition.Unknown ? undefined : convertPartyPositionToPropoundingParty(party.position)
      };
      const extras = new Map<number, number>();
      Promise.all([contentApi.contentGetMiscContentFiltered(query), contentApi.contentGetDefaultMiscContentFiltered(query)])
        .then(([update, defaultQuestions]) => {
          update.flatMap(x => x.tagIds ?? []).forEach(x => {
            const found = extras.get(x);
            extras.set(x, found ? found + 1 : 1)
          });

          defaultQuestions.flatMap(x => x.tagIds ?? []).forEach(x => {
            const found = extras.get(x);
            extras.set(x, found ? found + 1 : 1)
          });
          setExtraTagInfo(extras);
        });
    }
  }, [_case, contentApi, docType, party]);

  const loadQuestions = useCallback(() => {
    const query: ContentGetMiscContentFilteredRequest = {
      type: MiscContentType.DiscoveryQuestion,
      documentType: DocumentType.DiscoveryRequest,
      documentSubType: docType,
      caseType: _case?.caseType ?? undefined,
      tagIds: !!_case.tagIds?.length || !!party?.tagIds?.length ? (_case?.tagIds ?? []).concat(party?.tagIds ?? []) : undefined,
      isForRepresentative: _case.representativeType === undefined || _case.representativeType === Representative.None ? false : _case.representativeType !== Representative.Individual,
      respondingParty: party?.position === undefined || party.position === PartyPosition.Unknown ? undefined : convertPartyPositionToPropoundingParty(party.position)
    };
    Promise.all([contentApi.contentGetMiscContentFiltered(query), contentApi.contentGetDefaultMiscContentFiltered(query)])
      .then(([update, defaultQuestions]) => {
        const questionCountForTags = new Map<number, number>();

        update.flatMap(x => x.tagIds ?? []).forEach(x => {
          const found = questionCountForTags.get(x);
          questionCountForTags.set(x, found ? found + 1 : 1)
        });

        defaultQuestions.flatMap(x => x.tagIds ?? []).forEach(x => {
          const found = questionCountForTags.get(x);
          questionCountForTags.set(x, found ? found + 1 : 1)
        });

        if (toastCurTagId) {
          if (!questionCountForTags.has(toastCurTagId as number)) {
            setToastExistingTag(false);
          } else {
            setToastExistingTag(true);
          }
        }

        setQuestions(existing => {
          const groups = [...existing ?? []];
          defaultQuestions.forEach((question) => {
            const key = buildQuestionKey(question);

            let group = groups.find(x => x.key === key);
            if (!group) {
              group = { key, title: buildQuestionTitle(question, docType!, jx!.caseClasses!, availableTags!), caseType: question.caseTypeId ?? undefined, tagIds: question.tagIds ?? [], questions: [], defaultQuestions: [question] };
              groups.push(group);
            } else if (!group.defaultQuestions?.find(x => x.id === question.id)) {
              group.defaultQuestions?.push(question);
            }
          });

          if (setNumber <= 1) {
            update.forEach((question) => {
              const key = buildQuestionKey(question);

              let group = groups.find(x => x.key === key);
              const q: QuestionItem = {
                ...question, questionId: question.id, order: question.order ?? undefined, isChecked: true, text: question.content, displayText: replaceTokens({
                  propoundingParty: convertPartyPositionToPropoundingParty(_case?.clientPosition),
                  documentType: DocumentType.DiscoveryRequest,
                  documentSubType: docType!,
                  respondent: Respondent.Custom,
                  respondentCustom: party?.name,
                  respondingPartyName: party?.name,
                  propoundingPartyName: client.name,
                  respondingParty: convertPartyPositionToPropoundingParty(party?.position)
                }, question.content || '', true, _case?.representativeType)
              };

              const matchTagId = q.tagIds?.includes(toastCurTagId as number);

              if (matchTagId) {
                if (q.text !== "") {
                  setToastExistingTag(true);
                } else {
                  setToastExistingTag(false);
                }
              }

              if (!group) {
                group = { key, title: buildQuestionTitle(question, docType!, jx!.caseClasses!, availableTags!), caseType: question.caseTypeId ?? undefined, tagIds: question.tagIds ?? [], questions: [q] };
                groups.push(group);
              } else if (!group.questions?.find(x => x.questionId === question.id)) {
                group.questions?.push(q);
              }
            });
          }
          const mapped = groups.sort(sortQuestionGroup);
          return mapped;
        });

        if (toastMsgValue && caseIsDirty) {
          setShowToast(true);
        }

      });
  }, [_case, caseIsDirty, contentApi, docType, availableTags, jx, client, party, setNumber, toastCurTagId, toastMsgValue]);

  useEffect(() => {
    if (_case && client && availableTags && jx?.caseClasses && docType && party) {
      loadQuestions();
      documentMatchedToQuestions.current = false;
    }

  }, [_case, contentApi, docType, availableTags, jx, client, party, loadQuestions]);

  useEffect(() => {
    async function loadTags() {
      const tagsList = new Map<number, string>();

      const loadedTags = await tagApi.tagFindTags({});

      loadedTags.forEach(t => tagsList.set(t.id, t.name));
      setAvailableTags(tagsList);
    };
    loadTags();
  }, [tagApi]);

  const caseTags = availableTags && new Map<number, string>(Array.from(availableTags).filter(([index]) => !party || !party.tagIds?.includes(index)));

  const resetToastParams = (): void => {
    setShowToast(false);
    setToastExistingTag(null);
  };

  useEffect(() => {

    if (showToast) {
      function handleToastVisibility() {
        const timeoutMsg = setTimeout(() => {
          resetToastParams();
        }, TOAST_TIMER);

        return () => clearTimeout(timeoutMsg);
      }

      handleToastVisibility();
    }

  }, [showToast])

  function handleCaseChange(value: any, field: keyof Case) {
    setCaseIsDirty(true);
    setCase(c => { let update = { ...c }; update[field] = value; return update; });
  }

  function handleDefinitionChange(value: string, index: number, field: string) {
    setDefinitions(ds => {
      const update = [...ds!];
      const item = update[index] as any;
      item[field] = value;
      return update;
    });
  };

  // TODO: retries/error handling
  const handleDefinitionBlur = useCallback(async (index: number, deleting: boolean = false) => {
    let definition = definitions![index];
    // New or edited definitions are always for the Case
    // Update
    if (definition.id && definition.caseId === id) {
      definition.isDeleted = deleting;
      await definitionsApi.definitionUpdate({ definition });
    } else { // Create
      definition.caseId = id;
      definition.id = undefined;
      definition.isInternal = true;
      definition.content = definition.content ?? '';
      definition.isDeleted = deleting;
      const created = await definitionsApi.definitionCreate({ definition });
      if (created.id) {
        setDefinitions(ds => {
          const update = [...ds!];
          update[index] = created;
          return update;
        });
      };
    };
  }, [definitions, definitionsApi, id]);

  function handleDefinitionRemove(index: number) {
    setDefinitions(ds => {
      const update = [...ds!];
      update[index].toDelete = true;
      return update;
    });
  };

  const canSaveCase = useMemo(() => {
    if (_case?.clientPosition === PartyPosition.Unknown) {
      return false;
    }

    return true;
  }, [_case]);

  const handleProcessRequests = useCallback(async (openInWord: boolean) => {
    //setSaving(openInWord ? 'Word' : 'Save');
    if (caseIsDirty) {
      await caseApi.casePut({ id, firmId: user!.firmId!, _case });
      setCaseIsDirty(false);
    }

    for (let i = 0; i < definitions!.length; i++) {
      const d = definitions![i];

      // any existing definitions to be deleted
      // OR New definitions that didn't previously get saved (empty)
      if ((d.id && d.caseId && d.toDelete) || !d.id) {
        await handleDefinitionBlur(i, d.toDelete);
      }
    }

    const allQuestions = [...questions!];
    let questionsToSave: { q: QuestionItem, toSave: MiscContent }[] = [];
    for (let i = 0; i < allQuestions.length; i++) {
      const g = allQuestions[i];

      if (g.key !== '-1') {
        for (let j = 0; j < g.questions!.length; j++) {
          const q = g.questions![j];
          if (q.isChecked && q.saveToLibrary) {
            questionsToSave.push({
              q, toSave: {
                caseTypeId: g.caseType,
                content: q.text,
                type: MiscContentType.DiscoveryQuestion,
                documentType: DocumentType.DiscoveryRequest,
                documentSubType: docType,
                isForRepresentative: (_case?.representativeType === undefined || _case.representativeType === Representative.None ? undefined : _case.representativeType !== Representative.Individual),
                tagIds: g.tagIds
              }
            });
          }
        }
      }
    }

    let results: void[] = [];
    const limit = 6;
    for (let start = 0; start < questionsToSave.length; start += limit) {
      const end = start + limit > questionsToSave.length ? questionsToSave.length : start + limit;

      const slicedResults = await Promise.all(questionsToSave.slice(start, end).map((x) => createMiscContent(x.toSave).then(val => {
        x.q.saveToLibrary = false;
        x.q.questionId = val.id;
      })));

      results = [
        ...results,
        ...slicedResults,
      ]
    }

    if (results.length) {
      setQuestions(allQuestions);
    }

    const createDiscoveryRequest: CreateDiscoveryRequest = {
      caseId: _case.id,
      clientId: client.id,
      setNumber: setNumber,
      startingNumber: startingNumber,
      documentSubType: docType,
      respondingId: party?.id,
      questions: allQuestions?.flatMap(x => x.questions).filter(x => x?.isChecked)
        .map<Question>((q, index) => {
          return {
            questionId: q?.questionId,
            text: q?.text,
            number: index + (startingNumber ?? 1),
            interrogatoryId: q?.interrogatoryId
          }
        }),
    };

    let docId = document?.id;
    if (!docId) {
      const created = await documentApi.documentCreateDiscoveryRequest({ createDiscoveryRequest });
      setDocument(created);
      docId = created.id;
    } else {
      await documentApi.documentPutDiscoveryRequest({ documentId: docId, createDiscoveryRequest })
    }

    if (openInWord) {
      const token = await documentApi.documentGenerateAccessToken({ documentId: docId });
      const url = api.getUrl(`document/generate-doc-accesstoken`, { documentId: docId, tokenId: token.id, t: Date.now() });

      window.open(url, "_blank");
    }
    setSaving(undefined);
    setIsDirty(false);
  }, [_case, caseApi, caseIsDirty, client?.id, createMiscContent, definitions, docType, document?.id, documentApi, handleDefinitionBlur, id, party?.id, questions, setNumber, startingNumber, user]);

  useEffect(() => {
    if (dirty) {
      updateQueue.current.addSave(() => handleProcessRequests(false), 'Save');

      setIsDirty(false);
    }
  }, [dirty, handleProcessRequests]);

  const options: CaseTypeOption[] = useMemo(
    () => {
      if (!caseTypes || !jx?.caseClasses) {
        return [];
      }
      let vs = caseTypes?.flatMap((v) =>
        [
          {
            value: v.id!,
            label: v.shortName!,
            key: jx.caseClasses!.find(x => x.id === v.caseClassId)?.name ?? ""
          },
        ]
      );
      return vs;
    },
    [caseTypes, jx?.caseClasses]
  );

  // pass an element's ref (via useRef) to scroll to a specific element on a page
  function handleScrollToElement(ref: RefObject<HTMLDivElement>): void {
    setToastRequestOpen(true);
    // Add a small delay to make sure the form is visible first after clicking 'add now' so that the ref exists before scrolling to it.
    setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: 'smooth'
      })
    }, 100);
    resetToastParams();
  }

  function handleCaseTypeChange(caseType: string) {
    setCaseIsDirty(true);
    setCase(c => { return { ...c, caseType: caseType } });
  }

  function handleTagChange(value: OptionsType<TagOption>, _: ActionMeta<TagOption>) {

    // If showToast is true before choosing a new tag, set it back to false again so we dont wind up with the message blinking/flashing with the new selected tag...
    if (showToast) {
      resetToastParams();
    }

    setCaseIsDirty(true);
    setCase(c => { return { ...c, tagIds: value?.map(x => x.value!) ?? [] } });

    if (_.action === 'select-option') {
      const cleanedLabel = _.option?.label.replace(/\s*\[\d+\]/g, '').trim();

      setToastMsgValue(cleanedLabel);
      setToastCurTagId(_.option?.value)
      setToastPromptType('add')
    } else {
      resetToastParams();
      setCaseIsDirty(false);
    }

  }

  function handleChangeSetNumber(value: string): void {
    setSetNumber(Number(value));

    if (setNumber > 1 && value === "1") {
      setStartingNumber(1);
    }
  }

  function filterTags(tags: Map<number, string> | undefined, ids: number[]) {
    const filtered = new Map<number, string>();

    if (!tags) return filtered;

    ids.forEach(x => {
      const t = tags.get(x);
      if (t) {
        filtered.set(x, t);
      }
    })

    return filtered;
  }

  function handleCaseHeaderClick(e: AccordionEventKey): void {
    setCaseDetailsOpen(o => !o);
  }

  return (
    <>
      <Prompt
        when={dirty || caseIsDirty}
        message={() =>
          `You have unsaved changes, continue and lose them?`
        }
      />
      <Container className="page-container generate-request-container">
        <Loading isLoading={user === undefined || firm === undefined || _case === undefined || client === undefined || jurisdictions === undefined || questions === undefined || definitions === undefined}>
          <div className="header-container">
            <h1 className="request-header">Draft {requestType} (Beta<span className='info-link'><Link to='/how-to#discovery_requests' title='Learn More About Briefpoint’s Propounding Discovery Beta' target='_blank'><InfoCircle /></Link></span>)</h1>
            <span className="header-span"><Link to={`/clients/${client?.id}`}>{client?.name}</Link></span>&nbsp;/&nbsp;<span className="header-span"><Link to={`/clients/${client?.id}/cases/${_case?.id}`}>{_case?.shortTitle || _case?.title}</Link></span>
          </div>
          <Accordion defaultActiveKey="case-details" className="accordion-main" onSelect={(e) => handleCaseHeaderClick(e)}>

            {/*** BEGIN CASE INFORMATION ***/}
            <Accordion.Item eventKey="case-details">
              <Accordion.Header className="section-header">
                <span>Case Details</span>
                <span className="arrow-span">{caseDetailsOpen ? <ChevronUp /> : <ChevronDown />}</span>
              </Accordion.Header>
              <Accordion.Body className="accordion-body-container section-body">
                <CaseInfo _case={_case} jurisdiction={jx} onChange={handleCaseChange} />
              </Accordion.Body>
            </Accordion.Item>

            <h2 className="section-header">Request Details</h2>
            <div className='section-body'>
              {/*** BEGIN REQUESTS ***/}
              <Row>
                <Col className='col-4'>
                  <label htmlFor='case-type'>Case Type</label>
                  <CaseTypeSelect
                    className='select'
                    options={options}
                    onChange={handleCaseTypeChange}
                    selected={_case?.caseType ?? ''}
                  />
                </Col>
                <Col className='col-6'>
                  <TagsSelect id='case-tags' label="Case Tags" availableTags={caseTags} extraInfo={extraTagInfo} selected={_case?.tagIds ?? undefined} onChange={handleTagChange} useToast={true} caseExist={toastExistingTag} showToast={showToast} scrollTo={() => { handleScrollToElement(scrollToQuestion) }} toastMsgValue={toastMsgValue} toastPromptType={toastPromptType} closeToast={resetToastParams} />
                </Col>
                <Col className='col-1'>
                  <label htmlFor='set-number'>Set No.</label>
                  <Form.Control id='set-number'
                    type="number"
                    min="1"
                    style={{ width: 80 }}
                    value={setNumber}
                    onChange={(e) => handleChangeSetNumber(e.target.value)} />
                </Col>
                {setNumber > 1 && (
                  <Col className='col-1'>
                    <label htmlFor='starting-number'>Starting No.</label>
                    <Form.Control id='starting-number'
                      type="number"
                      min="1"
                      style={{ width: 80 }}
                      value={startingNumber}
                      onChange={(event) => setStartingNumber(parseInt(event.currentTarget.value))}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col className='col-4'>
                  <label htmlFor='party-select' className='padding form-label'>Responding Party</label>
                  <p>{`${party?.position} ${party?.name}${!!party?.tagIds?.length ? `: ${party.tagIds.map(x => availableTags?.get(x)).join(', ')}` : ''}`}</p>
                </Col>
              </Row>
              <Questions
                _case={_case}
                user={user}
                client={client}
                otherParty={party}
                startingNumber={startingNumber}
                jurisdiction={jx}
                questions={questions}
                docType={docType}
                checkForDefinitionUpdate={extractDefinitionsFromQuestions}
                definitions={definitions}
                availableTags={filterTags(availableTags, (_case?.tagIds ?? []).concat(party?.tagIds ?? []))}
                setQuestions={setQuestions}
                setIsDirty={setIsDirty}
                toastRequestOpen={toastRequestOpen}
                setToastRequestOpen={setToastRequestOpen}
                scrollRef={scrollToQuestion}
                setToastCurTagId={setToastCurTagId}
                toastCurTagId={toastCurTagId}
              />
            </div>
            {/*** BEGIN DEFINITIONS ***/}
            <h2 className="section-header">Definitions</h2>
            <div className='section-body'>
              <Definitions _case={_case} definitions={definitions} onChange={handleDefinitionChange} onBlur={handleDefinitionBlur} onRemove={handleDefinitionRemove} />
            </div>
          </Accordion>
          <Row className='actions-bar'>
            <Col className='col-8 col-link'>
              <Link to={`/clients/${client?.id}/cases/${_case?.id}`}>{`< Back to Case`}</Link>
            </Col>
            <Col className='action-buttons' style={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button variant='outline-primary' onClick={() => updateQueue.current.addSave(() => handleProcessRequests(false), 'Save')} disabled={!canSaveCase || !!saving || (!dirty && !caseIsDirty)}>{saving === 'Save' ? <><Spinner size="sm" /> Saving...</> : 'Save'}</Button>

              <Button variant='primary' onClick={() => updateQueue.current.addSave(() => handleProcessRequests(true), 'Save')} disabled={!canSaveCase || !!saving}>{saving === 'Word' ? <><Spinner size="sm" /> Generating...</> : 'Open in Word'}</Button>
            </Col>
          </Row>
        </Loading>
      </Container>
    </>
  );
}
