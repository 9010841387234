import { Case, DocumentStatus } from 'briefpoint-client';
import Button from 'components/Button';
import { Modal } from 'components/Modals';
import { generatePath } from 'react-router-dom';
import { DocumentClient, GetDocumentSubTypeShortName } from 'services/DocumentService';
import { buildShareUrl, isInternetExplorer, SHARE_SITES } from 'utils/dateUtils';
import './AddToCalendarModal.scss';
import AppleLogo from 'images/AppleLogo.svg';
import GoogleLogo from 'images/GoogleLogo.svg';
import OutlookLogo from 'images/OutlookLogo.svg';

const URL: string = (window as any)._env_?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL;

interface Props {
  isShow: boolean;
  doc: DocumentClient;
  _case?: Case;
  onClose: () => void;
}

function CalendarButton({ onClick, text, icon }: { onClick: () => void; text: string; icon: string }) {
  return (
    <Button variant="outline-primary" onClick={onClick} className="calendar-button">
      <div className="button-content-container">
        <img width="14" src={icon} alt={`Icon for ${text}`} />
        <div>{text}</div>
      </div>
    </Button>
  );
}

export default function AddToCalendarModal({ isShow, doc, _case, onClose }: Props) {
  const rawDocumentUrl = `${URL.split(';').find(x => !x.includes("bridge"))?.replace(/\/$/, '')}/#${generatePath('/wizard/:id', { id: doc.id })}`;

  const htmlEventDescription = `<a href="${rawDocumentUrl}">Finish or Export your Response on Briefpoint</a>`;
  const rawEventDescription = `Finish or Export your Response on Briefpoint: ${rawDocumentUrl}`;

  let eventTitle = `${GetDocumentSubTypeShortName(doc.info!.documentSubType, doc.info!.jurisdiction ?? '')} Response Due`;
  if (_case?.title) {
    eventTitle += ` - ${_case.title}`;
  } else if (doc.info?.plaintiffs && doc.info?.defendants) {
    eventTitle += ` - ${doc.info!.plaintiffs} v. ${doc.info!.defendants}`;
  } else if (doc.status! >= DocumentStatus.InfoConfirmed && doc.info?.caseNumber) {
    eventTitle += ` - Case No. ${doc.info?.caseNumber}`;
  }

  function handleCalendarButtonClick(url: string) {
    const filename = doc.name?.split('.')[0];
    console.log(url);

    if (url.startsWith('BEGIN')) {
      const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

      if (isInternetExplorer()) {
        (window.navigator as any).msSaveOrOpenBlob(blob, `${filename}.ics`);
      } else {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `${filename}.ics`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      window.open(url, '_blank');
    }
  }

  return (
    <Modal
      show={isShow}
      onClose={onClose}
      onConfirm={onClose}
      showCancelButton={false}
      showConfirmButton={false}
      title="Add to Your Calendar"
    >
      <div className="button-container">
        <CalendarButton
          text="Apple"
          icon={AppleLogo}
          onClick={() =>
            handleCalendarButtonClick(
              buildShareUrl(
                rawEventDescription,
                doc.info?.dueDateOverridden || doc.info?.dueDate,
                eventTitle,
                SHARE_SITES.ICAL,
                rawDocumentUrl
              )
            )
          }
        />
        <CalendarButton
          text="Google Calendar"
          icon={GoogleLogo}
          onClick={() =>
            handleCalendarButtonClick(
              buildShareUrl(
                htmlEventDescription,
                doc.info?.dueDateOverridden || doc.info?.dueDate,
                eventTitle,
                SHARE_SITES.GOOGLE
              )
            )
          }
        />
        <CalendarButton
          text="Outlook"
          icon={OutlookLogo}
          onClick={() =>
            handleCalendarButtonClick(
              buildShareUrl(
                rawEventDescription,
                doc.info?.dueDateOverridden || doc.info?.dueDate,
                eventTitle,
                SHARE_SITES.OUTLOOK,
                rawDocumentUrl
              )
            )
          }
        />
      </div>
    </Modal>
  );
}
