import React from "react";
// UI COMPONENTS
import Loading from "components/Loading";
// UTILITIES
import Button from "components/Button";
import { Tree } from "antd";
import { renderSwitcherIcon } from "utils/ExternalPartner/utils";
// STYLING
import styles from "./ExternalFileExport.module.scss";
// INTERFACES/TYPES
import type { TreeDataNode } from "antd";
import { Case, ExternalDocument, ExternalFolder } from "briefpoint-client";
import { DirectoryTreeProps } from "antd/lib/tree";
import { Spinner } from "react-bootstrap";

const { DirectoryTree } = Tree;

type ExternalWrapperProps = {
  children: React.ReactNode;
  directoryTitle: string;
  treeData: TreeDataNode[];
  files?: ExternalDocument[];
  destination?: ExternalFolder;
  isLoading: boolean;
  isSubmitted?: boolean;
  selectedCase?: Case;
  multiple?: boolean;
  submitActionText: string;
  additionalActionText?: string;
  onSelect: DirectoryTreeProps["onSelect"];
  closeModal: () => void;
  additionalAction?: () => void;
  submitAction: () => {};
};

function ExternalDirectoryWrapper(
  {
    children,
    directoryTitle,
    treeData,
    files,
    destination,
    isLoading,
    isSubmitted,
    selectedCase,
    multiple,
    closeModal,
    additionalAction,
    additionalActionText,
    onSelect,
    submitAction,
    submitActionText,
  }: ExternalWrapperProps,
) {
  return (
    <>
      <div className={styles.fileWindowContainer}>
        <h2>{directoryTitle} </h2>
        <p className={styles.fileCase}>{selectedCase?.shortTitle}</p>
        {children}
        <div className={styles.fileWindow}>
          {selectedCase
            ? (
              <div className={styles.fileTree}>
                <Loading isLoading={isLoading}>
                  <div>
                    <DirectoryTree
                      showLine
                      expandAction="doubleClick"
                      multiple={multiple ? true : false}
                      className={styles.fileDirectory}
                      onSelect={onSelect}
                      defaultExpandAll
                      switcherIcon={renderSwitcherIcon}
                      treeData={treeData}
                    />
                  </div>
                </Loading>
              </div>
            )
            : <div>Please select a case to view files</div>}
        </div>
        {!directoryTitle.includes('Add to') && <small>Hold Ctrl(Windows) / Command(Mac) to select multiple files.</small>}        
        <div className={styles.fileWindowBtnWrapper}>
          <div className={styles.uploadCreateFolder}>
            {additionalAction && (
              <Button
                variant="outline-primary"
                onClick={additionalAction}
                disabled={destination && !destination?.id}
              >
                {additionalActionText}
              </Button>
            )}
          </div>
          <div className={styles.uploadActions}>
            <Button variant="text" onClick={closeModal}>Cancel</Button>
            <Button
              variant="primary"
              onClick={submitAction}
              disabled={(isSubmitted) || (files && !files?.length) ||
                (destination && !destination?.id)}
            >
              {isSubmitted ? <><Spinner size="sm"/> Adding...</> : submitActionText}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExternalDirectoryWrapper;
