import {
  AccountApi,
  CaseApi,
  ClientApi,
  Configuration,
  ContentApi,
  DocumentApi,
  FirmApi,
  ObjectionApi,
  StripeApi,
  UserApi,
  FormApi,
  AnnouncementApi,
  TagApi,
  DefinitionApi,
  CommunicationApi,
  CredentialApi
} from 'briefpoint-client';
import { JurisdictionApi } from 'briefpoint-client/apis/JurisdictionApi';
import { useMemo } from 'react';
import { OnBehalfOfHeaderKey, OnBehalfOfSessionKey } from './useAuth';
import { useSessionStorage } from 'usehooks-ts';
import { useProvideOAuth } from './useOAuth';

export function useApiConfig() {
  const { getToken } = useProvideOAuth();
  const basePath = (window as any)._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;
  const [onbehalfof] = useSessionStorage(OnBehalfOfSessionKey, undefined);

  const config = useMemo(() => {
    const headers = onbehalfof ? { [OnBehalfOfHeaderKey]: onbehalfof } : undefined;
    return new Configuration({ basePath, accessToken: getToken, headers });
  }, [basePath, getToken, onbehalfof]);
  return config;
}

export function useAccountApi() {
  const config = useApiConfig();
  return useMemo(() => new AccountApi(config), [config]);
}

export function useAnnouncementApi() {
  const config = useApiConfig();
  return useMemo(() => new AnnouncementApi(config), [config]);
}

export function useContentApi() {
  const config = useApiConfig();
  return useMemo(() => new ContentApi(config), [config]);
}

export function useCommunicationApi() {
  const config = useApiConfig();
  return useMemo(() => new CommunicationApi(config), [config]);
}

export function useCredentialApi() {
  const config = useApiConfig();
  return useMemo(() => new CredentialApi(config), [config]);
}

export function useDefinitionsApi() {
  const config = useApiConfig();
  return useMemo(() => new DefinitionApi(config), [config]);
}

export function useDocumentApi() {
  const config = useApiConfig();
  return useMemo(() => new DocumentApi(config), [config]);
}

export function useFirmApi() {
  const config = useApiConfig();
  return useMemo(() => new FirmApi(config), [config]);
}

export function useClientApi() {
  const config = useApiConfig();
  return useMemo(() => new ClientApi(config), [config]);
}

export function useCaseApi() {
  const config = useApiConfig();
  return useMemo(() => new CaseApi(config), [config]);
}

export function useObjectionApi() {
  const config = useApiConfig();
  return useMemo(() => new ObjectionApi(config), [config]);
}

export function useUserApi() {
  const config = useApiConfig();
  return useMemo(() => new UserApi(config), [config]);
}

export function useJurisdictionApi() {
  const config = useApiConfig();
  return useMemo(() => new JurisdictionApi(config), [config]);
}

export function useStripeApi() {
  const config = useApiConfig();
  return useMemo(() => new StripeApi(config), [config]);
}

export function useTagApi() {
  const config = useApiConfig();
  return useMemo(() => new TagApi(config), [config]);
}

export function useFormApi() {
  const config = useApiConfig();
  return useMemo(() => new FormApi(config), [config]);
}