/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CommunicationType {
    Unknown = 'Unknown',
    Review = 'Review',
    Verification = 'Verification'
}

export function CommunicationTypeFromJSON(json: any): CommunicationType {
    return CommunicationTypeFromJSONTyped(json, false);
}

export function CommunicationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationType {
    return json as CommunicationType;
}

export function CommunicationTypeToJSON(value?: CommunicationType | null): any {
    return value as any;
}

