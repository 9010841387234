/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormIntQuestion
 */
export interface FormIntQuestion {
    /**
     * 
     * @type {number}
     * @memberof FormIntQuestion
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof FormIntQuestion
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormIntQuestion
     */
    text?: string | null;
}

export function FormIntQuestionFromJSON(json: any): FormIntQuestion {
    return FormIntQuestionFromJSONTyped(json, false);
}

export function FormIntQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormIntQuestion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': !exists(json, 'order') ? undefined : json['order'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function FormIntQuestionToJSON(value?: FormIntQuestion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': value.order,
        'number': value.number,
        'text': value.text,
    };
}


