import { FunctionComponent } from 'react';

interface Props {}

const Error: FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <div className="text-center">{children}</div>
    </div>
  );
};

export default Error;
