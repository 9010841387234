import { Button as BootrapButton, ButtonProps } from 'react-bootstrap';
import Loading from './Loading';

interface Props extends ButtonProps {
  text?: string;
  loading?: boolean;
}

export default function Button(props: Props) {
  const { loading, ...rest } = props;

  return (
    <BootrapButton disabled={loading} {...rest}>
      <Loading isLoading={loading || false} text={props.text} color="text-white">
        {props.children}
      </Loading>
    </BootrapButton>
  );
}
