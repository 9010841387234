/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Jurisdictions {
    Unknown = 0,
    Superior_Court_CA = 1,
    AL = 3,
    AK = 4,
    AZ = 5,
    AR = 6,
    CO = 7,
    CT = 8,
    DE = 9,
    DC = 10,
    FL = 11,
    GA = 12,
    HI = 13,
    ID = 14,
    IN = 15,
    IA = 16,
    KS = 17,
    KY = 18,
    LA = 19,
    ME = 20,
    MD = 21,
    MA = 22,
    MI = 23,
    MN = 24,
    MS = 25,
    MO = 26,
    MT = 27,
    NE = 28,
    NV = 29,
    NJ = 30,
    NY = 31,
    NC = 32,
    ND = 33,
    OH = 34,
    OK = 35,
    OR = 36,
    PA = 37,
    RI = 38,
    SC = 40,
    SD = 41,
    TN = 42,
    TX = 43,
    UT = 44,
    VT = 45,
    VA = 46,
    WA = 47,
    WV = 48,
    WI = 49,
    WY = 50,
    NH = 51,
    NM = 52,
    IL = 53
}

export function JurisdictionsFromJSON(json: any): Jurisdictions {
    return JurisdictionsFromJSONTyped(json, false);
}

export function JurisdictionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Jurisdictions {
    return json as Jurisdictions;
}

export function JurisdictionsToJSON(value?: Jurisdictions | null): any {
    return value as any;
}

