import classNames from 'classnames';
import { Check2 } from 'react-bootstrap-icons';

export interface DocumentOutlineProps {
  activeSection: string;
  confirmedSections?: string[];
  setActiveRef: React.Dispatch<React.SetStateAction<HTMLDivElement | undefined>>;
  setActiveLineRef: React.Dispatch<React.SetStateAction<HTMLSpanElement | undefined>>;
}

export interface SectionProps extends DocumentOutlineProps {
  section: string;
}

function Section({
  section,
  activeSection,
  confirmedSections = [],
  setActiveRef,
  setActiveLineRef,
}: SectionProps) {
  var isActive = activeSection === section;
  var isConfirmed = confirmedSections.includes(section);
  return (
    <div className="position-relative">
      <div
        className={classNames('section', section, { active: isActive, confirmed: isConfirmed })}
        ref={(ref) => (isActive && ref ? setActiveRef(ref) : null)}
      >
        <div>{isConfirmed && <Check2 />}</div>
        {isActive && (
          <span
            className="position-absolute active-line"
            ref={(ref) => (ref ? setActiveLineRef(ref) : null)}
          />
        )}
      </div>
    </div>
  );
}

export default function DocumentOutline({
  activeSection,
  confirmedSections = [],
  setActiveRef,
  setActiveLineRef,
}: DocumentOutlineProps) {
  const params = {
    activeSection,
    confirmedSections,
    setActiveRef,
    setActiveLineRef,
  };
  return (
    <div className="document-outline-wrapper">
      <div className="document-outline">
        <Section section="info" {...params} />
        {/* <div className="position-relative">
          <div
            className={classNames('section info', { active: activeSection === 'info' })}
            ref={(ref) => (activeSection === 'info' ? setActiveRef(ref) : null)}
          >
            <div></div>
            <span
              className="position-absolute active-line"
              ref={(ref) => (activeSection === 'info' ? setActiveLineRef(ref) : null)}
            ></span>
          </div>
        </div> */}
        <div className="section info-bottom">
          <div></div>
        </div>
        <Section section="venue" {...params} />
        <div className="d-flex">
          <div className="flex-grow-1">
            <div className="section plaintiff-name">
              <div></div>
            </div>
            <div className="section plaintiff">
              <div></div>
            </div>
            <div className="section defendant-name">
              <div></div>
            </div>
            <div className="section defendant">
              <div></div>
            </div>
          </div>
          <div className="flex-grow-1">
            <Section section="case" {...params} />
            <Section section="type" {...params} />
            <Section section="subtype" {...params} />
          </div>
        </div>
      </div>
    </div>
  );
}
