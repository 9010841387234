/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Source {
    Unknown = 0,
    Internal = 1,
    External = 2,
    Uploaded = 4,
    InternalUploaded = 5,
    ExternalUploaded = 6,
    Generated = 8,
    InternalGenerated = 9,
    ExternalGenerated = 10
}

export function SourceFromJSON(json: any): Source {
    return SourceFromJSONTyped(json, false);
}

export function SourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Source {
    return json as Source;
}

export function SourceToJSON(value?: Source | null): any {
    return value as any;
}

