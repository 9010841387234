import React, { RefObject, useEffect, useRef, useState } from "react";
import { Extension, mergeAttributes } from "@tiptap/core";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import { ReactComponent as BoldIcon } from './../../images/editor-bold.svg';
import { ReactComponent as ItalicIcon } from './../../images/editor-italic.svg';
import { ReactComponent as UnderlineIcon } from './../../images/editor-underline.svg';
import { ReactComponent as BulletedListIcon } from './../../images/editor-bullet.svg';
import { ReactComponent as NumberedListIcon } from './../../images/editor-numbered.svg';
import "./styles.scss";
// NOTE: Enable these imports to test the formatting functionality or once backend supports HTML formatting.
import Underline from "@tiptap/extension-underline";
import Paragraph from "@tiptap/extension-paragraph";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import { FlattenAndFilterOnPaste } from "./CustomPasteFilterFlattener";
import useTiptapScrollAdjustment from "hooks/useScrollHiddenTiptap";


type MenuBarProps = {
  editor: ReturnType<typeof useEditor>;
  menuBarRef: React.LegacyRef<HTMLDivElement>
  showMenuOptions?: boolean;
};

// Custom extensions
const BlurOnEnter = Extension.create({
  name: "blurOnEnter",
  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        editor.view.dom.blur();
        return true;
      },
    };
  },
});

const BlurOnShiftEnter = Extension.create({
  name: "blurOnShiftEnter",
  addKeyboardShortcuts() {
    return {
      'Shift-Enter': ({ editor }) => {
        editor.view.dom.blur();
        return true;
      },
    };
  },
});

const TabIndent = Extension.create({
  name: 'tabIndent',
  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        editor.chain().focus().insertContent('\t').run();
        return true;
      }
    }
  }
})

const MenuBar = ({ editor, menuBarRef, showMenuOptions }: MenuBarProps) => {

  if (!editor) {
    return null;
  }

  const createButton = (
    action: () => void,
    isActive: boolean,
    icon: JSX.Element,
  ) => (
    <button onClick={action} className={isActive ? "is-active" : ""}>
      {icon}
    </button>
  );

  return (
    <div className="menubar-container" ref={menuBarRef}>
      {createButton(
        () => editor.chain().toggleBold().run(),
        editor.isActive("bold"),
        <BoldIcon />,
      )}
      {createButton(
        () => editor.chain().focus().toggleItalic().run(),
        editor.isActive("italic"),
        <ItalicIcon />,
      )}
      {createButton(
        () => editor.chain().focus().toggleUnderline().run(),
        editor.isActive("underline"),
        <UnderlineIcon />,
      )}
      {showMenuOptions && (
        <>
          {createButton(
            () => editor.chain().focus().toggleBulletList().run(),
            editor.isActive("bulletList"),
            <BulletedListIcon />,
          )}
          {createButton(
            () => editor.chain().focus().toggleOrderedList().run(),
            editor.isActive("orderedList"),
            <NumberedListIcon />,
          )}
        </>
      )}
    </div>
  );
};

const CustomEditor = ({
  handleChange,
  handleBlur,
  handleKeyDown,
  editorContent,
  placeholderText,
  editingRef,
  shouldBlurOnEnter,
  shouldBlurOnShiftEnter,
  isDisabled = false,
  isFullWidth = false,
  showMenu = false,
  showAdvancedOptions = false,
  forceFocus = false
}: {
  handleChange: (val: string) => void;
  handleBlur?: () => void;
  handleKeyDown?: (e: React.KeyboardEvent) => void;
  isDisabled?: boolean;
  editorContent: string | undefined;
  placeholderText?: string | undefined;
  editingRef?: RefObject<any>;
  forceFocus?: boolean;
  shouldBlurOnEnter?: boolean;
  shouldBlurOnShiftEnter?: boolean;
  isFullWidth?: boolean;
  showMenu?: boolean;
  showAdvancedOptions?: boolean;
}): JSX.Element => {

  const [isMenuVisible, setisMenuVisible] = useState<boolean>(false);
  const menuBarRef = useRef<HTMLDivElement>(null);
  const placeholderConfig = Placeholder.configure({ placeholder: placeholderText ?? "" });
  const paragraphConfig = Paragraph.extend({
    parseHTML() {
      return [{ tag: showAdvancedOptions ? 'div' : 'p' }]
    },
    renderHTML({ HTMLAttributes }) {
      return [showAdvancedOptions ? 'div' : 'p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },
  });
  // NOTE: Remove the next two lines once we support HTML for these fields on the backend.
  const extensions = [showMenu ? StarterKit : StarterKit.configure({ bold: false, italic: false, }), placeholderConfig, Underline, paragraphConfig];
  const additionalExtensions = [
    showMenu ? StarterKit : StarterKit.configure({ bold: false, italic: false, }), placeholderConfig, Underline, OrderedList, ListItem, paragraphConfig, TabIndent, FlattenAndFilterOnPaste];
  let determineExtensions = showAdvancedOptions ? additionalExtensions : extensions;

  // NOTE: Enable the bottom 'extensions' set up once backend supports HTML. It will reenable all necessary formatting functionality and shortcuts.
  // If you want to test the 'showMenu' bar with all formatting functionality, uncomment the below line and comment the top 'kitConfiguration' variable and 'extenstions' array.
  // const extensions = [StarterKit, placeholderConfig, Underline, OrderedList, ListItem];

  // If this prop gets passed, if enter gets pressed, the editor is unselected and we keep the updated value intact.
  if (shouldBlurOnEnter) {
    extensions.push(BlurOnEnter);
  }

  if (shouldBlurOnShiftEnter) {
    extensions.push(BlurOnShiftEnter);
  }

  // Editor set up
  const editor = useEditor({
    extensions: determineExtensions,
    editable: !isDisabled,
    onFocus: function () {
      if (showMenu) {
        setisMenuVisible(true);
      }
    },
    onBlur: function ({ event }) {
      // Fire off a blur function if needed
      if (handleBlur) {
        // Checks if the editor options are in the menu bar, ignores the passed 'handleBlur' function to avoid the menubar from saving any info in the editor when interacted with.
        if (menuBarRef.current && menuBarRef.current.contains(event.relatedTarget as Element | null)) {
          event.stopPropagation();
          return;
        } else {
          handleBlur();
        }
      }

      if (showMenu) {
        if (menuBarRef.current && menuBarRef.current.contains(event.relatedTarget as Element | null)) {
          event.stopPropagation();
          return;
        } else {
          setisMenuVisible(false);
        }
      }
    },
    onUpdate: function ({ editor }) {
      if (handleChange) {
        handleChange(showMenu ? editor.getHTML() : editor.getText());
      }
    },
    content: "",
  });

  useTiptapScrollAdjustment({
    editor,
    offset: 100,
    behavior: "smooth",
    debounceDelay: 50
  })

  useEffect(() => {
    if (editor) {
      const currentContent = showMenu ? editor.getHTML() : editor.getText();
      if (currentContent !== editorContent) {
        editor.commands.setContent(editorContent!, false);
      }

      // Forces the editor to be focused, useful in cases where we want to focus the editor immediately in a component when immediately loaded to the UI 
      if (forceFocus) {
        editor.commands.focus();
      }
    }
  }, [editor, forceFocus, editorContent, showMenu]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(!isDisabled);
    }
  }, [editor, isDisabled]);

  return (
    /*If we pass fullwidth, the editor just basically stretches to 100% width of its parent container*/
    <div className={`editorContainer ${isFullWidth ? "fw" : ""}`}>
      {(showMenu && isMenuVisible) && <MenuBar menuBarRef={menuBarRef} editor={editor} showMenuOptions={showAdvancedOptions} />}
      <EditorContent
        className={`${showMenu && isMenuVisible ? 'menuOn' : ""}`}
        ref={editingRef}
        onKeyDown={handleKeyDown}
        editor={editor}
      />
    </div>
  );
};

export default CustomEditor;
