/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubscriptionPlan,
    SubscriptionPlanFromJSON,
    SubscriptionPlanFromJSONTyped,
    SubscriptionPlanToJSON,
    SubscriptionStatus,
    SubscriptionStatusFromJSON,
    SubscriptionStatusFromJSONTyped,
    SubscriptionStatusToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserPlusFirmInfo
 */
export interface UserPlusFirmInfo {
    /**
     * 
     * @type {User}
     * @memberof UserPlusFirmInfo
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof UserPlusFirmInfo
     */
    firmName?: string | null;
    /**
     * 
     * @type {SubscriptionPlan}
     * @memberof UserPlusFirmInfo
     */
    firmSubscription?: SubscriptionPlan;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof UserPlusFirmInfo
     */
    firmStatus?: SubscriptionStatus;
}

export function UserPlusFirmInfoFromJSON(json: any): UserPlusFirmInfo {
    return UserPlusFirmInfoFromJSONTyped(json, false);
}

export function UserPlusFirmInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPlusFirmInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'firmName': !exists(json, 'firmName') ? undefined : json['firmName'],
        'firmSubscription': !exists(json, 'firmSubscription') ? undefined : SubscriptionPlanFromJSON(json['firmSubscription']),
        'firmStatus': !exists(json, 'firmStatus') ? undefined : SubscriptionStatusFromJSON(json['firmStatus']),
    };
}

export function UserPlusFirmInfoToJSON(value?: UserPlusFirmInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'firmName': value.firmName,
        'firmSubscription': SubscriptionPlanToJSON(value.firmSubscription),
        'firmStatus': SubscriptionStatusToJSON(value.firmStatus),
    };
}


