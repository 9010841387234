/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InviteUserToFirm
 */
export interface InviteUserToFirm {
    /**
     * 
     * @type {string}
     * @memberof InviteUserToFirm
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {Role}
     * @memberof InviteUserToFirm
     */
    role?: Role;
    /**
     * 
     * @type {UserType}
     * @memberof InviteUserToFirm
     */
    userType?: UserType;
}

export function InviteUserToFirmFromJSON(json: any): InviteUserToFirm {
    return InviteUserToFirmFromJSONTyped(json, false);
}

export function InviteUserToFirmFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteUserToFirm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
    };
}

export function InviteUserToFirmToJSON(value?: InviteUserToFirm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailAddress': value.emailAddress,
        'role': RoleToJSON(value.role),
        'userType': UserTypeToJSON(value.userType),
    };
}


