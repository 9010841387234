/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentSubType,
    DocumentSubTypeFromJSON,
    DocumentSubTypeFromJSONTyped,
    DocumentSubTypeToJSON,
    DocumentType,
    DocumentTypeFromJSON,
    DocumentTypeFromJSONTyped,
    DocumentTypeToJSON,
    ObjectionType,
    ObjectionTypeFromJSON,
    ObjectionTypeFromJSONTyped,
    ObjectionTypeToJSON,
    ObjectionVariant,
    ObjectionVariantFromJSON,
    ObjectionVariantFromJSONTyped,
    ObjectionVariantToJSON,
} from './';

/**
 * 
 * @export
 * @interface Objection
 */
export interface Objection {
    /**
     * 
     * @type {number}
     * @memberof Objection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Objection
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Objection
     */
    order?: number | null;
    /**
     * 
     * @type {ObjectionType}
     * @memberof Objection
     */
    objectionType: ObjectionType;
    /**
     * 
     * @type {DocumentType}
     * @memberof Objection
     */
    documentType: DocumentType;
    /**
     * 
     * @type {DocumentSubType}
     * @memberof Objection
     */
    documentSubType?: DocumentSubType;
    /**
     * 
     * @type {Array<ObjectionVariant>}
     * @memberof Objection
     */
    variants: Array<ObjectionVariant>;
}

export function ObjectionFromJSON(json: any): Objection {
    return ObjectionFromJSONTyped(json, false);
}

export function ObjectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Objection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'objectionType': ObjectionTypeFromJSON(json['objectionType']),
        'documentType': DocumentTypeFromJSON(json['documentType']),
        'documentSubType': !exists(json, 'documentSubType') ? undefined : DocumentSubTypeFromJSON(json['documentSubType']),
        'variants': ((json['variants'] as Array<any>).map(ObjectionVariantFromJSON)),
    };
}

export function ObjectionToJSON(value?: Objection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'order': value.order,
        'objectionType': ObjectionTypeToJSON(value.objectionType),
        'documentType': DocumentTypeToJSON(value.documentType),
        'documentSubType': DocumentSubTypeToJSON(value.documentSubType),
        'variants': ((value.variants as Array<any>).map(ObjectionVariantToJSON)),
    };
}


