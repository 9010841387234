import {
  Document,
  DocumentDetail,
  DocumentType,
  DocumentSubType,
  InterrogatoryResponseItem,
  Respondent,
  ServiceManner,
  DocumentStatus,
  Case
} from 'briefpoint-client';
import { CaliforniaId } from 'components/CaseManagement/AddEditCaseModal';

export type InterrogatoryResponse = { [key: string]: InterrogatoryResponseItem };
export type InterrogatoryResponseMultiple = { [key: string]: InterrogatoryResponseItem[] };

export const DocumentStatusDisplay: {
  [key in DocumentStatus]: { title: string };
} = {
  0: {
    title: 'Unknown'
  },
  100: {
    title: 'Uploaded'
  },
  200: {
    title: 'Processing'
  },
  300: {
    title: 'Processed'
  },
  400: {
    title: 'In Progress'
  },
  500: {
    title: 'Complete'
  },
  600: {
    title: 'Deleted'
  },
  1000: {
    title: 'Error'
  }
}

export const DocumentTypeDisplay: {
  [key in DocumentType]: { name: string; request: string; response: string };
} = {
  [DocumentType.Unknown]: { name: 'Unknown', request: '', response: '' },
  [DocumentType.DiscoveryRequest]: {
    name: 'Discovery Request',
    request: 'Discovery Request',
    response: 'Discovery Request',
  },
  [DocumentType.Pleading]: { name: 'Complaint', request: '', response: 'Answer' },
  [DocumentType.DiscoveryResponse]: {
    name: 'Discovery Response',
    request: 'Discovery Response',
    response: 'Discovery Response',
  },
};

//TODO: there's probably a more generic way to handle these 4 methods
export function GetDocumentSubTypeName(subType: DocumentSubType, jurisdiction: string) {
  let name = DocumentSubTypeDisplay[subType].name;

  if (jurisdiction !== CaliforniaId) {
    name = name.replace('Special ', '');
    name = name.replace('S', '');
  }

  return name;
}

export function GetDocumentSubTypeShortName(subType: DocumentSubType, jurisdiction: string) {
  let name = DocumentSubTypeDisplay[subType].short;

  if (jurisdiction !== CaliforniaId) {
    name = name.replace('Special ', '');
    name = name.replace('S', '');
  }

  return name;
}

export function GetDocumentSubTypeRequestName(subType: DocumentSubType, jurisdiction: string) {
  let name = DocumentSubTypeDisplay[subType].request;

  if (jurisdiction !== CaliforniaId) {
    name = name.replace('Special ', '');
    name = name.replace('S', '');
  }

  return name;
}

export function GetDocumentSubTypeResponseName(subType: DocumentSubType, jurisdiction: string) {
  let name = DocumentSubTypeDisplay[subType].response;

  if (jurisdiction !== CaliforniaId) {
    name = name.replace('Special ', '');
    name = name.replace('S', '');
  }

  return name;
}

const DocumentSubTypeDisplay: {
  [key in DocumentSubType]: { name: string; request: string; response: string; short: string };
} = {
  [DocumentSubType.Unknown]: { name: 'Unknown', request: '', response: '', short: '' },
  [DocumentSubType.SpecialInterrogatorries]: {
    name: 'Special Interrogatories',
    request: 'Special Interrogatory',
    response: 'Interrogatory',
    short: 'SROG'
  },
  [DocumentSubType.RequestsForProduction]: {
    name: 'Requests for Production',
    request: 'Request for Production',
    response: 'Request for Production',
    short: 'RFP'
  },
  [DocumentSubType.RequestsForAdmission]: {
    name: 'Requests for Admission',
    request: 'Request for Admission',
    response: 'Request for Admission',
    short: 'RFA'
  },
  [DocumentSubType.Complaint]: { name: 'Complaint', request: '', response: 'Answer', short: 'Complaint' },
  [DocumentSubType.Petition]: { name: 'Petition', request: '', response: 'Answer', short: 'Petition' },
  [DocumentSubType.FormInterrogatorries]: { name: 'Form Interrogatories', request: 'Form Interrogatory', response: 'Interrogatory', short: 'Form Rog' },
};

export const RespondentDisplay: { [key in Respondent]: string } = {
  [Respondent.Respondent]: 'Respondent',
  [Respondent.PlaintiffOrDefendant]: 'Plaintiff/Defendant',
  [Respondent.Custom]: 'Custom',
  [Respondent.RespondingParty]: 'Responding Party',
};

export const ServiceMannerDisplay: { [key in ServiceManner]: string } = {
  [ServiceManner.Regular]: 'Regular',
  [ServiceManner.InStateMail]: 'In-State Mail',
  [ServiceManner.OutOfStateMail]: 'Out-of-State Mail',
  [ServiceManner.Electronic]: 'Electronic',
  [ServiceManner.Other]: 'Fax, Express Mail, or Overnight Delivery',
  [ServiceManner.Unknown]: 'Unknown',
}

export interface DocumentClient extends Document {
  uploadDate: Date;
  _case?: Case;
}

export interface DocumentDetailClient extends DocumentDetail {
  uploadDate: Date;
}

export function transformDocument(document: any): DocumentClient | DocumentDetailClient {
  return {
    ...document,
    uploadDate: new Date(document.uploadDate)
  };
}

const acceptableFileTypes = [
  'application/pdf',
  // 'application/msword',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export function sanitizeFiles(files: File[]) {
  return files.filter((file) => acceptableFileTypes.includes(file.type));
  // TODO: check files for ??
}
