/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentSubType,
    DocumentSubTypeFromJSON,
    DocumentSubTypeFromJSONTyped,
    DocumentSubTypeToJSON,
    Question,
    QuestionFromJSON,
    QuestionFromJSONTyped,
    QuestionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateDiscoveryRequest
 */
export interface CreateDiscoveryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDiscoveryRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscoveryRequest
     */
    caseId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscoveryRequest
     */
    clientId?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDiscoveryRequest
     */
    setNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateDiscoveryRequest
     */
    startingNumber?: number | null;
    /**
     * 
     * @type {DocumentSubType}
     * @memberof CreateDiscoveryRequest
     */
    documentSubType?: DocumentSubType;
    /**
     * 
     * @type {Array<Question>}
     * @memberof CreateDiscoveryRequest
     */
    questions?: Array<Question> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscoveryRequest
     */
    respondingId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDiscoveryRequest
     */
    parentDocumentId?: string | null;
}

export function CreateDiscoveryRequestFromJSON(json: any): CreateDiscoveryRequest {
    return CreateDiscoveryRequestFromJSONTyped(json, false);
}

export function CreateDiscoveryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDiscoveryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'setNumber': !exists(json, 'setNumber') ? undefined : json['setNumber'],
        'startingNumber': !exists(json, 'startingNumber') ? undefined : json['startingNumber'],
        'documentSubType': !exists(json, 'documentSubType') ? undefined : DocumentSubTypeFromJSON(json['documentSubType']),
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] === null ? null : (json['questions'] as Array<any>).map(QuestionFromJSON)),
        'respondingId': !exists(json, 'respondingId') ? undefined : json['respondingId'],
        'parentDocumentId': !exists(json, 'parentDocumentId') ? undefined : json['parentDocumentId'],
    };
}

export function CreateDiscoveryRequestToJSON(value?: CreateDiscoveryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'caseId': value.caseId,
        'clientId': value.clientId,
        'setNumber': value.setNumber,
        'startingNumber': value.startingNumber,
        'documentSubType': DocumentSubTypeToJSON(value.documentSubType),
        'questions': value.questions === undefined ? undefined : (value.questions === null ? null : (value.questions as Array<any>).map(QuestionToJSON)),
        'respondingId': value.respondingId,
        'parentDocumentId': value.parentDocumentId,
    };
}


