/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PropoundingParty {
    Unknown = 0,
    Plaintiff = 1,
    Defendant = 2,
    CrossDefendant = 4,
    CrossComplainant = 8
}

export function PropoundingPartyFromJSON(json: any): PropoundingParty {
    return PropoundingPartyFromJSONTyped(json, false);
}

export function PropoundingPartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropoundingParty {
    return json as PropoundingParty;
}

export function PropoundingPartyToJSON(value?: PropoundingParty | null): any {
    return value as any;
}

