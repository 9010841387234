import { Case, ExternalCase, ExternalClientIEnumerableExternalPartnerResponse, ExternalDocument } from "briefpoint-client";
import { Typeahead } from "react-bootstrap-typeahead";
import type { TreeDataNode } from "antd";
import type { Option } from "react-bootstrap-typeahead/types/types";
import { RenderMenuProps } from "react-bootstrap-typeahead/types/components/Typeahead";
import { DirectoryTreeProps } from "antd/lib/tree";
import { useCaseApi, useDocumentApi, useFirmApi } from "hooks/useApi";
import { useAuth } from "hooks/useAuth";
import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import ExternalDirectoryWrapper from "./ExternalDirectoryWrapper";
import { CombinedCaseSearch, mergeCases } from "components/ConfirmInfo/ReviewPageOne";
import { Alert } from "react-bootstrap";
import { delayDocumentRefresh, externalRequestWrapper, handleExternalReqResCodes, transformDataExternalToBp } from "utils/ExternalPartner/utils";
import useReInitiateAuth from "hooks/useExternalAuthenticate";
import styles from './../../components/Modals/QuickActionModal.module.scss';
import { NoSuggestCustomMenuExternalUpload } from "components/ConfirmInfo/utils";
import { debounce } from "lodash";

const GENERAL_ERROR_MSG = "An error occurred. Please try again later.";

export default function ExternalFileBrowser({
  cases,
  isExternalUploading,
  setIsExternalUploading,
  loadDocuments,
  onFinish,
}: {
  cases?: Case[];
  isExternalUploading: boolean;
  setIsExternalUploading: React.Dispatch<SetStateAction<boolean>>;
  loadDocuments: () => Promise<void>;
  onFinish: () => void;
}) {
  const { user, firm } = useAuth()!;
  const { reInitiateAuth, errorAuthenticateMsg } = useReInitiateAuth();
  const caseApi = useCaseApi();
  const firmApi = useFirmApi();
  const documentApi = useDocumentApi();
  const [externalLoading, setExternalLoading] = useState(false);
  const [selectedCase, setSelectedCase] = useState<Case>();
  const [caseFiles, setCaseFiles] = useState<ExternalDocument[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<any[]>([]);
  const [firmCases, setFirmCases] = useState<ExternalCase[]>();
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  useEffect(() => {
    async function load() {
      function exCaseFetchError() {
        setErrorMsg(GENERAL_ERROR_MSG);
        throw new Error(GENERAL_ERROR_MSG);
      }

      if (!!user?.externalConnection?.isActive) {
        try {
          const res = await externalRequestWrapper(caseApi.caseGetExternalCaseFiles({ firmId: user?.firmId!, externalCaseId: selectedCase?.integration?.identifier! }), 3);
          function exCaseFetchSuccess(res: ExternalClientIEnumerableExternalPartnerResponse) {
            setCaseFiles(res.data!);
            if (errorMsg) {
              setErrorMsg(undefined);
            }
          }

          handleExternalReqResCodes(res, exCaseFetchSuccess, exCaseFetchError, reInitiateAuth)
        } catch (error) {
          setErrorMsg(GENERAL_ERROR_MSG);
          return;
        }
      }
    }

    async function getCases() {
      try {
        if (user) {
          const getExternalCases = await caseApi.caseGetExternal({
            firmId: user.firmId!,
          });
          setFirmCases(getExternalCases.data ?? undefined);
        }
      } catch (error) {
        setErrorMsg(GENERAL_ERROR_MSG);
      }
    }
    getCases();

    if (selectedCase) {
      load();
    }
  }, [user, selectedCase, errorMsg, caseApi, firmApi, reInitiateAuth]);

  const handleFilterChange = useCallback(async (filter?: string) => {
    if (user?.externalConnection?.isActive) {
      setExternalLoading(true);
      const getExternalCases = await caseApi.caseGetExternal({
        firmId: user.firmId!, filter
      });
      setFirmCases(getExternalCases.data ?? undefined);
      setExternalLoading(false);
    }
  }, [caseApi, user]);

  const debouncedFilter = useMemo(() => debounce(async (filter?: string) => {
    await handleFilterChange(filter);
  }, 400), [handleFilterChange]);

  if (!user || !firm) {
    return null;
  }

  if (!user.externalConnection?.isActive) {
    return <div>No external connection... you shouldn't really be here.</div>;
  }

  const [treeData, dataMap] = transformDataExternalToBp(caseFiles);

  const onSelect: DirectoryTreeProps["onSelect"] = (selectedKeys) => {
    const files = selectedKeys
      .map((key: any) =>
        (dataMap as Map<string, ExternalDocument>).get(key.toString())
      )
      .filter((file: any): file is ExternalDocument => !!file);

    setFilesToUpload(files);
  };

  const doFileUpload = async () => {
    setIsExternalUploading(true);
    const getUploadLinks = await caseApi.caseGetExternalCaseFileDownloadLinks({
      firmId: firm.id,
      externalCaseId: selectedCase?.integration?.identifier!,
      externalDocument: filesToUpload,
    });

    if (
      getUploadLinks && getUploadLinks.responseCode === 200 &&
      getUploadLinks.data?.length
    ) {
      // Create a new case linked to BP where the external case is does NOT have an internal ID with us (IE: Not linked to BP).
      if (!selectedCase?.id) {
        const newCase = await caseApi.casePost({
          firmId: user!.firmId!,
          _case: selectedCase,
        });
        await caseApi.caseExternalIntegration({
          firmId: user!.firmId!,
          id: newCase.id!,
          externalIntegration: newCase?.integration,
        });
        await documentApi.documentExternalFiles({
          caseId: newCase?.id,
          externalFile: getUploadLinks.data.map((d) => {
            return {
              location: d.location!,
              size: d.size!,
              name: d.name?.endsWith(d.type ?? '') ? d.name : `${d.name}${d.type}`,
            };
          }),
        });
      } else {
        // If the selected external case already exists with us however, we just use the associated case Id linked w/ it and upload...
        await documentApi.documentExternalFiles({
          caseId: selectedCase?.id,
          externalFile: getUploadLinks.data.map((d) => {
            return {
              location: d.location!,
              size: d.size!,
              name: d.name?.endsWith(d.type ?? '') ? d.name : `${d.name}${d.type}`,
            };
          }),
        });
      }
    } else {
      setErrorMsg(GENERAL_ERROR_MSG);
      setIsExternalUploading(false);
    }

    delayDocumentRefresh(2750, loadDocuments, setIsExternalUploading, onFinish);

  };

  const mergedCases = mergeCases(cases, firmCases);

  function handleCaseChange(e: Option[]): void {
    const val = e[0];

    // TODO: Possibly refactor this so this modal isn't dependant on a merged case list and should only use the firmCases list (Which has external cases only)?
    // We'd have to reformat the _case structure below obviously, or we can make this and the SDR into a utility function these two components could use...

    if (val) {
      const c = val as CombinedCaseSearch;
      let _case = cases?.find((x) =>
        x.id === c.internalId && x.integration?.identifier === c.externalId
      );

      if (!_case) {
        const integration = c.externalId
          ? { partner: c.partner!, identifier: c.externalId! }
          : undefined;
        _case = { title: c.title, shortTitle: c.title, integration };
      }
      setSelectedCase(_case);
    } else {
      setSelectedCase(undefined);
    }
  }

  return (
    <>
      <ExternalDirectoryWrapper
        multiple
        isSubmitted={isExternalUploading}
        selectedCase={selectedCase}
        onSelect={onSelect}
        files={filesToUpload}
        isLoading={!caseFiles.length}
        directoryTitle="Upload Document"
        closeModal={onFinish}
        submitActionText="Upload"
        submitAction={doFileUpload}
        treeData={treeData as TreeDataNode[]}
      >
        <div className="mb-3">
          {(errorMsg || errorAuthenticateMsg) && <Alert variant="danger">{errorMsg || errorAuthenticateMsg}</Alert>}
          {firmCases
            ? (
              <>
                <Typeahead
                  className={
                    user.externalConnection.isActive && selectedCase?.integration?.partner
                      ? styles[`external-partner-${selectedCase.integration.partner}`]
                      : ''
                  }
                  onChange={(e) => handleCaseChange(e)}
                  selected={(selectedCase && [selectedCase]) || []}
                  isInvalid={!selectedCase}
                  id="case"
                  emptyLabel="No matches found"
                  highlightOnlyResult
                  options={mergedCases.filter((c) => c.partner)}
                  renderMenu={(results: any, menuProps: RenderMenuProps, state: any) => (
                    <NoSuggestCustomMenuExternalUpload results={results} menuProps={menuProps} state={state} showSourceLogos={!!user.externalConnection?.isActive} />
                  )}
                  placeholder="Find a case..."
                  labelKey="title"
                  onInputChange={(f) => debouncedFilter(f)}
                  isLoading={externalLoading}
                />
              </>
            )
            : <div>Loading cases...</div>}
        </div>
      </ExternalDirectoryWrapper>
    </>
  );
}
