/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CredentialAudit,
    CredentialAuditFromJSON,
    CredentialAuditFromJSONTyped,
    CredentialAuditToJSON,
    ObjectTypeEnum,
    ObjectTypeEnumFromJSON,
    ObjectTypeEnumFromJSONTyped,
    ObjectTypeEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface Credential
 */
export interface Credential {
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    login?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    firmCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    passcode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Credential
     */
    isDisabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Credential
     */
    isLockedOut?: boolean;
    /**
     * 
     * @type {ObjectTypeEnum}
     * @memberof Credential
     */
    objectType?: ObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof Credential
     */
    firmId?: string;
    /**
     * 
     * @type {Array<CredentialAudit>}
     * @memberof Credential
     */
    audits?: Array<CredentialAudit> | null;
}

export function CredentialFromJSON(json: any): Credential {
    return CredentialFromJSONTyped(json, false);
}

export function CredentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): Credential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'login': !exists(json, 'login') ? undefined : json['login'],
        'firmCode': !exists(json, 'firmCode') ? undefined : json['firmCode'],
        'passcode': !exists(json, 'passcode') ? undefined : json['passcode'],
        'isDisabled': !exists(json, 'isDisabled') ? undefined : json['isDisabled'],
        'isLockedOut': !exists(json, 'isLockedOut') ? undefined : json['isLockedOut'],
        'objectType': !exists(json, 'objectType') ? undefined : ObjectTypeEnumFromJSON(json['objectType']),
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'firmId': !exists(json, 'firmId') ? undefined : json['firmId'],
        'audits': !exists(json, 'audits') ? undefined : (json['audits'] === null ? null : (json['audits'] as Array<any>).map(CredentialAuditFromJSON)),
    };
}

export function CredentialToJSON(value?: Credential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'login': value.login,
        'firmCode': value.firmCode,
        'passcode': value.passcode,
        'isDisabled': value.isDisabled,
        'isLockedOut': value.isLockedOut,
        'objectType': ObjectTypeEnumToJSON(value.objectType),
        'objectId': value.objectId,
        'firmId': value.firmId,
        'audits': value.audits === undefined ? undefined : (value.audits === null ? null : (value.audits as Array<any>).map(CredentialAuditToJSON)),
    };
}


