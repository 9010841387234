/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalPartners,
    ExternalPartnersFromJSON,
    ExternalPartnersFromJSONTyped,
    ExternalPartnersToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalPartnerConnection
 */
export interface ExternalPartnerConnection {
    /**
     * 
     * @type {ExternalPartners}
     * @memberof ExternalPartnerConnection
     */
    partner?: ExternalPartners;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalPartnerConnection
     */
    isActive?: boolean;
}

export function ExternalPartnerConnectionFromJSON(json: any): ExternalPartnerConnection {
    return ExternalPartnerConnectionFromJSONTyped(json, false);
}

export function ExternalPartnerConnectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalPartnerConnection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partner': !exists(json, 'partner') ? undefined : ExternalPartnersFromJSON(json['partner']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function ExternalPartnerConnectionToJSON(value?: ExternalPartnerConnection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partner': ExternalPartnersToJSON(value.partner),
        'isActive': value.isActive,
    };
}


