/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CaseType,
    CaseTypeFromJSON,
    CaseTypeFromJSONTyped,
    CaseTypeToJSON,
    CollectionItem,
    CollectionItemFromJSON,
    CollectionItemFromJSONTyped,
    CollectionItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface CaseMetadata
 */
export interface CaseMetadata {
    /**
     * 
     * @type {string}
     * @memberof CaseMetadata
     */
    damages?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMetadata
     */
    timeline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMetadata
     */
    incidentDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMetadata
     */
    incidentTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMetadata
     */
    incidentLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseMetadata
     */
    incidentDescription?: string | null;
    /**
     * 
     * @type {CaseType}
     * @memberof CaseMetadata
     */
    caseType?: CaseType;
    /**
     * 
     * @type {Array<CollectionItem>}
     * @memberof CaseMetadata
     */
    injuries?: Array<CollectionItem> | null;
    /**
     * 
     * @type {Array<CollectionItem>}
     * @memberof CaseMetadata
     */
    agreements?: Array<CollectionItem> | null;
    /**
     * 
     * @type {Array<CollectionItem>}
     * @memberof CaseMetadata
     */
    allegations?: Array<CollectionItem> | null;
    /**
     * 
     * @type {Array<CollectionItem>}
     * @memberof CaseMetadata
     */
    plaintiffs?: Array<CollectionItem> | null;
    /**
     * 
     * @type {Array<CollectionItem>}
     * @memberof CaseMetadata
     */
    defendants?: Array<CollectionItem> | null;
    /**
     * 
     * @type {Array<CollectionItem>}
     * @memberof CaseMetadata
     */
    causesOfAction?: Array<CollectionItem> | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CaseMetadata
     */
    genericInfo?: { [key: string]: string; } | null;
}

export function CaseMetadataFromJSON(json: any): CaseMetadata {
    return CaseMetadataFromJSONTyped(json, false);
}

export function CaseMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaseMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'damages': !exists(json, 'damages') ? undefined : json['damages'],
        'timeline': !exists(json, 'timeline') ? undefined : json['timeline'],
        'incidentDate': !exists(json, 'incidentDate') ? undefined : json['incidentDate'],
        'incidentTime': !exists(json, 'incidentTime') ? undefined : json['incidentTime'],
        'incidentLocation': !exists(json, 'incidentLocation') ? undefined : json['incidentLocation'],
        'incidentDescription': !exists(json, 'incidentDescription') ? undefined : json['incidentDescription'],
        'caseType': !exists(json, 'caseType') ? undefined : CaseTypeFromJSON(json['caseType']),
        'injuries': !exists(json, 'injuries') ? undefined : (json['injuries'] === null ? null : (json['injuries'] as Array<any>).map(CollectionItemFromJSON)),
        'agreements': !exists(json, 'agreements') ? undefined : (json['agreements'] === null ? null : (json['agreements'] as Array<any>).map(CollectionItemFromJSON)),
        'allegations': !exists(json, 'allegations') ? undefined : (json['allegations'] === null ? null : (json['allegations'] as Array<any>).map(CollectionItemFromJSON)),
        'plaintiffs': !exists(json, 'plaintiffs') ? undefined : (json['plaintiffs'] === null ? null : (json['plaintiffs'] as Array<any>).map(CollectionItemFromJSON)),
        'defendants': !exists(json, 'defendants') ? undefined : (json['defendants'] === null ? null : (json['defendants'] as Array<any>).map(CollectionItemFromJSON)),
        'causesOfAction': !exists(json, 'causesOfAction') ? undefined : (json['causesOfAction'] === null ? null : (json['causesOfAction'] as Array<any>).map(CollectionItemFromJSON)),
        'genericInfo': !exists(json, 'genericInfo') ? undefined : json['genericInfo'],
    };
}

export function CaseMetadataToJSON(value?: CaseMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'damages': value.damages,
        'timeline': value.timeline,
        'incidentDate': value.incidentDate,
        'incidentTime': value.incidentTime,
        'incidentLocation': value.incidentLocation,
        'incidentDescription': value.incidentDescription,
        'caseType': CaseTypeToJSON(value.caseType),
        'injuries': value.injuries === undefined ? undefined : (value.injuries === null ? null : (value.injuries as Array<any>).map(CollectionItemToJSON)),
        'agreements': value.agreements === undefined ? undefined : (value.agreements === null ? null : (value.agreements as Array<any>).map(CollectionItemToJSON)),
        'allegations': value.allegations === undefined ? undefined : (value.allegations === null ? null : (value.allegations as Array<any>).map(CollectionItemToJSON)),
        'plaintiffs': value.plaintiffs === undefined ? undefined : (value.plaintiffs === null ? null : (value.plaintiffs as Array<any>).map(CollectionItemToJSON)),
        'defendants': value.defendants === undefined ? undefined : (value.defendants === null ? null : (value.defendants as Array<any>).map(CollectionItemToJSON)),
        'causesOfAction': value.causesOfAction === undefined ? undefined : (value.causesOfAction === null ? null : (value.causesOfAction as Array<any>).map(CollectionItemToJSON)),
        'genericInfo': value.genericInfo,
    };
}


