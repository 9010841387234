/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InterrogatoryResponseItem,
    InterrogatoryResponseItemFromJSON,
    InterrogatoryResponseItemFromJSONTyped,
    InterrogatoryResponseItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateInterrogatory
 */
export interface UpdateInterrogatory {
    /**
     * 
     * @type {string}
     * @memberof UpdateInterrogatory
     */
    text?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateInterrogatory
     */
    number?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInterrogatory
     */
    complete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateInterrogatory
     */
    sourceId?: string | null;
    /**
     * 
     * @type {{ [key: string]: InterrogatoryResponseItem; }}
     * @memberof UpdateInterrogatory
     */
    response?: { [key: string]: InterrogatoryResponseItem; } | null;
    /**
     * 
     * @type {{ [key: string]: Array<InterrogatoryResponseItem>; }}
     * @memberof UpdateInterrogatory
     */
    responseList?: { [key: string]: Array<InterrogatoryResponseItem>; } | null;
}

export function UpdateInterrogatoryFromJSON(json: any): UpdateInterrogatory {
    return UpdateInterrogatoryFromJSONTyped(json, false);
}

export function UpdateInterrogatoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInterrogatory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'complete': !exists(json, 'complete') ? undefined : json['complete'],
        'sourceId': !exists(json, 'sourceId') ? undefined : json['sourceId'],
        'response': !exists(json, 'response') ? undefined : (json['response'] === null ? null : mapValues(json['response'], InterrogatoryResponseItemFromJSON)),
        'responseList': !exists(json, 'responseList') ? undefined : json['responseList'],
    };
}

export function UpdateInterrogatoryToJSON(value?: UpdateInterrogatory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'number': value.number,
        'complete': value.complete,
        'sourceId': value.sourceId,
        'response': value.response === undefined ? undefined : (value.response === null ? null : mapValues(value.response, InterrogatoryResponseItemToJSON)),
        'responseList': value.responseList,
    };
}


