/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InterrogatoryResponseItem
 */
export interface InterrogatoryResponseItem {
    /**
     * 
     * @type {number}
     * @memberof InterrogatoryResponseItem
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof InterrogatoryResponseItem
     */
    interrogatoryId: number;
    /**
     * 
     * @type {number}
     * @memberof InterrogatoryResponseItem
     */
    objectionId: number;
    /**
     * 
     * @type {number}
     * @memberof InterrogatoryResponseItem
     */
    objectionVariantId: number;
    /**
     * 
     * @type {number}
     * @memberof InterrogatoryResponseItem
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof InterrogatoryResponseItem
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InterrogatoryResponseItem
     */
    additionalText?: string | null;
}

export function InterrogatoryResponseItemFromJSON(json: any): InterrogatoryResponseItem {
    return InterrogatoryResponseItemFromJSONTyped(json, false);
}

export function InterrogatoryResponseItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterrogatoryResponseItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'interrogatoryId': json['interrogatoryId'],
        'objectionId': json['objectionId'],
        'objectionVariantId': json['objectionVariantId'],
        'position': json['position'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'additionalText': !exists(json, 'additionalText') ? undefined : json['additionalText'],
    };
}

export function InterrogatoryResponseItemToJSON(value?: InterrogatoryResponseItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'interrogatoryId': value.interrogatoryId,
        'objectionId': value.objectionId,
        'objectionVariantId': value.objectionVariantId,
        'position': value.position,
        'text': value.text,
        'additionalText': value.additionalText,
    };
}


