/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalFolder,
    ExternalFolderFromJSON,
    ExternalFolderFromJSONTyped,
    ExternalFolderToJSON,
    ExternalPartners,
    ExternalPartnersFromJSON,
    ExternalPartnersFromJSONTyped,
    ExternalPartnersToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalFolderIEnumerableExternalPartnerResponse
 */
export interface ExternalFolderIEnumerableExternalPartnerResponse {
    /**
     * 
     * @type {ExternalPartners}
     * @memberof ExternalFolderIEnumerableExternalPartnerResponse
     */
    partner?: ExternalPartners;
    /**
     * 
     * @type {number}
     * @memberof ExternalFolderIEnumerableExternalPartnerResponse
     */
    responseCode?: number;
    /**
     * 
     * @type {Array<ExternalFolder>}
     * @memberof ExternalFolderIEnumerableExternalPartnerResponse
     */
    data?: Array<ExternalFolder> | null;
}

export function ExternalFolderIEnumerableExternalPartnerResponseFromJSON(json: any): ExternalFolderIEnumerableExternalPartnerResponse {
    return ExternalFolderIEnumerableExternalPartnerResponseFromJSONTyped(json, false);
}

export function ExternalFolderIEnumerableExternalPartnerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalFolderIEnumerableExternalPartnerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partner': !exists(json, 'partner') ? undefined : ExternalPartnersFromJSON(json['partner']),
        'responseCode': !exists(json, 'responseCode') ? undefined : json['responseCode'],
        'data': !exists(json, 'data') ? undefined : (json['data'] === null ? null : (json['data'] as Array<any>).map(ExternalFolderFromJSON)),
    };
}

export function ExternalFolderIEnumerableExternalPartnerResponseToJSON(value?: ExternalFolderIEnumerableExternalPartnerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partner': ExternalPartnersToJSON(value.partner),
        'responseCode': value.responseCode,
        'data': value.data === undefined ? undefined : (value.data === null ? null : (value.data as Array<any>).map(ExternalFolderToJSON)),
    };
}


