/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FormIntQuestion,
    FormIntQuestionFromJSON,
    FormIntQuestionFromJSONTyped,
    FormIntQuestionToJSON,
} from './';

/**
 * 
 * @export
 * @interface FormInt
 */
export interface FormInt {
    /**
     * 
     * @type {string}
     * @memberof FormInt
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FormInt
     */
    form?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FormInt
     */
    section?: number;
    /**
     * 
     * @type {number}
     * @memberof FormInt
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof FormInt
     */
    readonly name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormInt
     */
    readonly displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FormInt
     */
    text?: string | null;
    /**
     * 
     * @type {Array<FormIntQuestion>}
     * @memberof FormInt
     */
    questions?: Array<FormIntQuestion> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormInt
     */
    canAddAdditonal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormInt
     */
    itemName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormInt
     */
    conditionalAnswerDirection?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FormInt
     */
    conditionalOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FormInt
     */
    conditionalSkipsEntireSection?: boolean;
}

export function FormIntFromJSON(json: any): FormInt {
    return FormIntFromJSONTyped(json, false);
}

export function FormIntFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormInt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'form': !exists(json, 'form') ? undefined : json['form'],
        'section': !exists(json, 'section') ? undefined : json['section'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] === null ? null : (json['questions'] as Array<any>).map(FormIntQuestionFromJSON)),
        'canAddAdditonal': !exists(json, 'canAddAdditonal') ? undefined : json['canAddAdditonal'],
        'itemName': !exists(json, 'itemName') ? undefined : json['itemName'],
        'conditionalAnswerDirection': !exists(json, 'conditionalAnswerDirection') ? undefined : json['conditionalAnswerDirection'],
        'conditionalOnly': !exists(json, 'conditionalOnly') ? undefined : json['conditionalOnly'],
        'conditionalSkipsEntireSection': !exists(json, 'conditionalSkipsEntireSection') ? undefined : json['conditionalSkipsEntireSection'],
    };
}

export function FormIntToJSON(value?: FormInt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'form': value.form,
        'section': value.section,
        'number': value.number,
        'text': value.text,
        'questions': value.questions === undefined ? undefined : (value.questions === null ? null : (value.questions as Array<any>).map(FormIntQuestionToJSON)),
        'canAddAdditonal': value.canAddAdditonal,
        'itemName': value.itemName,
        'conditionalAnswerDirection': value.conditionalAnswerDirection,
        'conditionalOnly': value.conditionalOnly,
        'conditionalSkipsEntireSection': value.conditionalSkipsEntireSection,
    };
}


