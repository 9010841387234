/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FirmRevenueLineItem
 */
export interface FirmRevenueLineItem {
    /**
     * 
     * @type {string}
     * @memberof FirmRevenueLineItem
     */
    firmId?: string;
    /**
     * 
     * @type {number}
     * @memberof FirmRevenueLineItem
     */
    monthlyRevenue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FirmRevenueLineItem
     */
    isMonthly?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FirmRevenueLineItem
     */
    attorneys?: number;
    /**
     * 
     * @type {number}
     * @memberof FirmRevenueLineItem
     */
    paralegals?: number;
    /**
     * 
     * @type {string}
     * @memberof FirmRevenueLineItem
     */
    subscriptionDate?: string | null;
}

export function FirmRevenueLineItemFromJSON(json: any): FirmRevenueLineItem {
    return FirmRevenueLineItemFromJSONTyped(json, false);
}

export function FirmRevenueLineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirmRevenueLineItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firmId': !exists(json, 'firmId') ? undefined : json['firmId'],
        'monthlyRevenue': !exists(json, 'monthlyRevenue') ? undefined : json['monthlyRevenue'],
        'isMonthly': !exists(json, 'isMonthly') ? undefined : json['isMonthly'],
        'attorneys': !exists(json, 'attorneys') ? undefined : json['attorneys'],
        'paralegals': !exists(json, 'paralegals') ? undefined : json['paralegals'],
        'subscriptionDate': !exists(json, 'subscriptionDate') ? undefined : json['subscriptionDate'],
    };
}

export function FirmRevenueLineItemToJSON(value?: FirmRevenueLineItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firmId': value.firmId,
        'monthlyRevenue': value.monthlyRevenue,
        'isMonthly': value.isMonthly,
        'attorneys': value.attorneys,
        'paralegals': value.paralegals,
        'subscriptionDate': value.subscriptionDate,
    };
}


