import { type LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import { Case, Client, Definition, DocumentSubType, DocumentType, Jurisdiction, MiscContent, MiscContentType, Party, PartyPosition, Representative, Respondent, Role, User } from 'briefpoint-client';
import { DefinitionItem, QuestionGroup, QuestionItem, convertPartyPositionToPropoundingParty } from 'screens/DocumentGeneration/GenerateDiscoveryRequestPage';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import TagsSelect, { TagOption } from 'components/Select/TagsSelect';
import { OptionsType } from 'react-select';
import { buildQuestionKey, buildQuestionTitle } from 'screens/DocumentGeneration/GenerateDiscoveryRequestPage'
import useMiscContent from 'hooks/useMiscContent';
import reactStringReplace from 'react-string-replace';
import { replaceTokens } from 'screens/DocumentWizard/SpecialInterrogatoriesStep/Selections/utils';
import Question from '../Question';

export const punctuationRegexForDefAliases = new RegExp(/[,;:|]/g);
function definitionHighlight(term: string, key: string, definition: DefinitionItem, _case: Case, client: Client, docType: DocumentSubType, party: Party) {
  return (
    <OverlayTrigger
      key={key}
      placement={'top'}
      flip={true}
      overlay={<Tooltip className='definition-tooltip' id={`tooltip-${key}`}>{definitionContent(definition, _case, client, docType, party)}</Tooltip>}
    >
      <span className='definition-match'>{term}</span>
    </OverlayTrigger>
  )
}

function definitionContent(def: Definition, _case: Case, client: Client, docType: DocumentSubType, party: Party) {
  if (!def.content) {
    return <>undefined</>
  }

  return (
    <>
      "<b>{def.term}</b>"{definitionAliasDisplay(def.aliases ?? undefined)} {replaceTokens({
        propoundingParty: convertPartyPositionToPropoundingParty(_case?.clientPosition),
        documentType: DocumentType.DiscoveryRequest,
        documentSubType: docType,
        respondent: Respondent.Custom,
        respondentCustom: party.name,
        respondingPartyName: party.name,
        propoundingPartyName: client.name,
        respondingParty: convertPartyPositionToPropoundingParty(party?.position)
      }, def.content || '', true, _case?.representativeType)}
    </>
  )
}

function definitionAliasDisplay(aliases?: string) {
  if (!aliases) {
    return <></>
  }

  const split = aliases.split(punctuationRegexForDefAliases).map<React.ReactNode>(x => <>"<b>{x.trim()}</b>"</>);
  return <>{split.length === 1 ? ' and ' : ', '}{split.length === 1 ? split[0] : split.reduce((prev, curr, index) => [prev, index === split.length - 1 ? ' and ' : ', ', curr])}</>
}



export function buildCaseTypeOptions(caseType: string, jurisdiction: Jurisdiction | undefined, selected: string | undefined, handleChange: (value?: string) => void) {
  let typeClass = jurisdiction?.caseClasses?.find(x => x.id === caseType);

  if (typeClass) {
    return <Form.Check id='new-casetype-class' type='radio' label={typeClass.name} inline value={typeClass.id} checked={selected === typeClass.id} onChange={_ => handleChange(typeClass?.id)} />
  }

  typeClass = jurisdiction?.caseClasses?.find(x => x.subTypes?.find(s => s.id === caseType));

  let type = typeClass!.subTypes?.find(x => x.id === caseType);

  return (
    <>
      <Form.Check id='new-casetype-class' type='radio' inline label={typeClass!.name} value={typeClass!.id} checked={selected === typeClass!.id} onChange={_ => handleChange(typeClass?.id)} />
      <Form.Check id='new-casetype-type' type='radio' inline label={type!.shortName} value={type!.id} checked={selected === type!.id} onChange={_ => handleChange(type?.id)} />
    </>
  )
}

interface Props {
  availableTags: Map<number, string>;
  user?: User;
  _case?: Case;
  client: Client;
  startingNumber: number;
  jurisdiction?: Jurisdiction;
  questions: QuestionGroup[] | undefined;
  docType?: DocumentSubType;
  definitions?: DefinitionItem[];
  otherParty?: Party;
  checkForDefinitionUpdate: (questions: QuestionItem[], existing?: DefinitionItem[]) => Promise<void>
  setQuestions: React.Dispatch<React.SetStateAction<QuestionGroup[] | undefined>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  toastRequestOpen?: boolean;
  setToastRequestOpen: React.Dispatch<React.SetStateAction<boolean>>;
  scrollRef?: LegacyRef<HTMLDivElement>;
  setToastCurTagId: React.Dispatch<React.SetStateAction<number | null | undefined>>;
  toastCurTagId?: number | null;
}

export default function Questions({ _case, user, client, startingNumber, jurisdiction, availableTags, questions, docType, definitions, otherParty, checkForDefinitionUpdate, setQuestions, setIsDirty, toastRequestOpen, setToastRequestOpen, scrollRef, setToastCurTagId, toastCurTagId }: Props) {
  const [isAdding, setIsAdding] = useState(false);
  const [newRequestText, setNewRequestText] = useState('');
  const newRef = useRef<HTMLTextAreaElement>(null);
  const [selectedTags, setSelectedTags] = useState<number[]>();
  const [selectedCaseType, setSelectedCaseType] = useState<string>();
  const [saveToFirm, setSaveToFirm] = useState(false);
  const [selectedRespondentType, setSelectedRespondentType] = useState<PartyPosition>();
  const [selectedRepresentative, setSelectedRepresentative] = useState<boolean>();
  const [editQuestionText, setEditQuestionText] = useState('');
  const [editQuestionGroup, setEditQuestionGroup] = useState<number>();
  const [editQuestionNumber, setEditQuestionNumber] = useState<number>();
  const [saveQuestionToLibrary, setSaveQuestionToLibrary] = useState<boolean>(false);
  const [editActive, setEditActive] = useState<boolean>(false);
  const [defaultsAdded, setDefaultsAdded] = useState<string[]>([]);
  const [, , , , , , createMiscContent] = useMiscContent(false);
  const [startIdx, setStartIdx] = useState<number | null>(null);
  const [isDragChecked, setIsDragChecked] = useState<boolean>(false);

  const handleInputChange = (content: string) => {
    setNewRequestText(content);
  };

  useEffect(() => {
    if (!questions && defaultsAdded.length) {
      setDefaultsAdded([]);
    }
  }, [questions, defaultsAdded]);

  useEffect(() => {
    if (isAdding && newRef.current) {
      newRef.current.focus();
    }
  }, [isAdding, newRef]);


  // Update the questions toggle if the 'Add now' button is clicked 
  useEffect(() => {

    if (toastRequestOpen) {
      setIsAdding(true);
      setSaveQuestionToLibrary(true);

      if (toastCurTagId) {
        if (!selectedTags?.length) {
          setSelectedTags([toastCurTagId]);
        }
        // Clear the tag ID carried by the toast message to ensure it doesnt get re-added when this runs again. The tag only gets carried over after clicking 'add now'. 
        setToastCurTagId(null);
      }
    }

  }, [toastRequestOpen, toastCurTagId, selectedTags, setToastCurTagId])

  function handleMouseDown(groupIndex: number, questionIndex: number) {
    setIsDragChecked((prevIsDragChecked) => !prevIsDragChecked);
    setQuestions((q) => {
      const updatedCheckboxes = [...q ?? []];
      const checkboxToUpdate = updatedCheckboxes[groupIndex]?.questions?.[questionIndex];

      if (checkboxToUpdate) {
        checkboxToUpdate.isChecked = !checkboxToUpdate.isChecked;
      }

      return updatedCheckboxes;
    });
    setStartIdx(questionIndex);
    setIsDirty(true);
  };

  function handleMouseEnter(groupIndex: number, questionIndex: number) {
    if (startIdx !== null) {

      setQuestions((q) => {
        const updatedCheckboxes = [...q ?? []];
        const checkboxToUpdate = updatedCheckboxes[groupIndex]?.questions?.[questionIndex];

        if (checkboxToUpdate) {
          checkboxToUpdate.isChecked = !isDragChecked;
        }

        return updatedCheckboxes;
      });

    }
  };

  function handleMouseUp() {
    setStartIdx(null);
  };

  function handleEditingQuestion(original: string, groupIndex: number, questionIndex: number) {
    setEditActive(true);
    setEditQuestionText(original);
    setEditQuestionGroup(groupIndex);
    setEditQuestionNumber(questionIndex);
  }

  function handleOnKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Escape') {
      setEditQuestionText('');
      setEditQuestionGroup(undefined);
      setEditQuestionNumber(undefined);
      setEditActive(false);
    }
  }

  function handleEditingBlur() {
    const previous = questions && questions[editQuestionGroup!]?.questions && questions[editQuestionGroup!].questions![editQuestionNumber!]?.displayText;
    if (editQuestionText && editQuestionText !== previous) {
      const displayText = replaceTokens({
        propoundingParty: convertPartyPositionToPropoundingParty(_case?.clientPosition),
        documentType: DocumentType.DiscoveryRequest,
        documentSubType: docType!,
        respondent: Respondent.Custom,
        respondentCustom: otherParty?.name,
        respondingPartyName: otherParty?.name,
        propoundingPartyName: client.name,
        respondingParty: convertPartyPositionToPropoundingParty(otherParty?.position)
      }, editQuestionText || '', true, _case?.representativeType);
      setQuestions(q => {
        const update = [...q ?? []];
        const group = update[editQuestionGroup!];

        group.questions![editQuestionNumber!].text = editQuestionText;
        group.questions![editQuestionNumber!].displayText = displayText;

        return update;
      });
      checkForDefinitionUpdate([{ text: displayText, isChecked: true }], definitions)
    }
    setEditQuestionText('');
    setEditQuestionGroup(undefined);
    setEditQuestionNumber(undefined);
    setEditActive(false);
    setIsDirty(true);
  }

  async function handleNewSave() {
    if (newRequestText) {
      const newQuestion: QuestionItem = {
        isChecked: true,
        text: newRequestText,
      };
      // questions added without "Add to library for future use" go in their own group
      // TODO: could probably combine these 2 branches w/ some smarter logic
      if (!saveQuestionToLibrary) {
        const groupId = "-1";

        setQuestions(q => {
          const update = [...q ?? []];

          let group = update.find(x => x.key === groupId);

          if (!group) {
            group = { key: '-1', title: "Document Specific", questions: [] };
            update.push(group);
          }

          group.questions?.push(newQuestion);

          return update;
        });
      } else {
        const toSave: MiscContent = {
          caseTypeId: selectedCaseType,
          content: newRequestText,
          type: MiscContentType.DiscoveryQuestion,
          documentType: DocumentType.DiscoveryRequest,
          documentSubType: docType,
          isForRepresentative: selectedRepresentative,
          tagIds: selectedTags,
          sharedWithFirmId: saveToFirm ? user?.firmId : null,
          respondentType: selectedRespondentType === undefined ? undefined : convertPartyPositionToPropoundingParty(selectedRespondentType)
        };
        const groupId = buildQuestionKey(toSave);

        const questionGroup = questions?.find(x => x.key === groupId);

        if (questionGroup) {
          const maxOrder = questionGroup.questions?.reduce<number | undefined>((prev, current) => { return !prev ? current.order ?? undefined : (current.order && current.order > prev) ? current.order : prev }, undefined);
          toSave.order = (maxOrder ?? 0) + 1;
        }

        const miscContent = await createMiscContent(toSave);

        newQuestion.questionId = miscContent.id;

        setQuestions(q => {
          const update = [...q ?? []];

          let group = update.find(x => x.key === groupId);

          if (!group) {
            group = { key: groupId, title: buildQuestionTitle(miscContent, docType!, jurisdiction!.caseClasses!, availableTags), questions: [] };
            update.push(group);
          }

          group.questions?.push(newQuestion);

          return update;
        });
      }

      checkForDefinitionUpdate([newQuestion], definitions);
    }
    setIsDirty(true);
    closeAddNew();
  };

  function closeAddNew(clear: boolean = false) {
    if (clear) {
      setSelectedTags([]);
      setSelectedCaseType(undefined);
      setSelectedRepresentative(undefined);
      setSelectedRespondentType(undefined);
    }
    setNewRequestText('');
    setIsAdding(false);
    setToastRequestOpen(false);
  }

  function handleLocalTagChange(value: OptionsType<TagOption>) {
    const mapped = value.map((v) => v.value!);
    setSelectedTags(mapped);
  }

  function handleLocalCaseTypeChange(value?: string) {
    setSelectedCaseType(value);
  }

  function handleAddToLibrary() {
    setSelectedCaseType(_case?.caseType ?? undefined);
    setSaveQuestionToLibrary(!saveQuestionToLibrary);
  }

  function addDefaults(group: QuestionGroup): void {
    if (!group.defaultQuestions?.length) return;

    const toAdd: QuestionItem[] = group.defaultQuestions?.map(question => {
      return {
        ...question, order: question.order ?? undefined, saveToLibrary: true, isChecked: true, text: question.content, displayText: replaceTokens({
          propoundingParty: convertPartyPositionToPropoundingParty(_case?.clientPosition),
          documentType: DocumentType.DiscoveryRequest,
          documentSubType: docType!,
          respondent: Respondent.Custom,
          respondentCustom: otherParty?.name,
          respondingPartyName: otherParty?.name,
          propoundingPartyName: client.name,
          respondingParty: convertPartyPositionToPropoundingParty(otherParty?.position)
        }, question.content || '', true, _case?.representativeType)
      }
    });
    setQuestions(q => {
      const update = [...q ?? []];
      const g = update.find(x => x.key === group.key);

      if (g?.questions) {
        // g.questions.push(...toAdd) results in all items being duplicated for reasons that are beyond me, feel free to fix if you know
        toAdd.filter(x => !g.questions?.find(gq => gq.text === x.text)).forEach(x => g.questions?.push(x));
      }
      return update;
    });
    setDefaultsAdded(d => [...d, group.key]);
    checkForDefinitionUpdate(toAdd, definitions);
    setIsDirty(true);
  }

  const highlightDefinitions = useCallback((text: string, key: string) => {
    const tempText = `${text}`;
    let ret;
    for (let i = 0; i < definitions!.length; i++) {
      const def = definitions![i];
      if (def.toDelete || def.isDeleted) continue;

      const term = def.term!;

      let termMatch = [term];

      const parens = term.indexOf('(');
      if (parens > -1) {
        // Just TERM of TERM(S)
        const singular = term.substring(0, parens);
        termMatch.push(singular);
        // TERMS from TERM(S)
        const plural = term.substring(parens + 1, term.length - 1);
        termMatch.push(singular.concat(plural));
      }

      const aliases = def.aliases?.split(punctuationRegexForDefAliases).map(x => x.trim().toUpperCase());

      if (aliases?.length) {
        termMatch.push(...aliases);
      }

      termMatch = termMatch.sort((a, b) => b.length - a.length);
      for (let i = 0; i < termMatch.length; i++) {
        const t = termMatch[i].replace('(', "\\(").replace(')', "\\)");

        const regex = new RegExp(`( ?\\b(?<!>)${t}[\\b ]?)`, 'g');
        ret = reactStringReplace(ret ?? tempText, regex, (match, i) => {
          const spaceAfter = match[match.length - 1] === ' ';
          return <>{match[0] === ' ' ? <>&thinsp;</> : null}{definitionHighlight(match.trim(), `${key}-${match.trim().toLowerCase()}-${i}`, def, _case!, client, docType!, otherParty!)}{spaceAfter ? <>&thinsp;</> : null}</>;
        });
      }
    }

    return ret ?? text;
  }, [definitions, _case, client, docType, otherParty]);

  let questionCount = 0;
  let checkedQuestionCount = 0;
  return (
    <div key='questions' className='questions'>
      {!!questions?.length && questions.map((group, groupIndex) => {
        return (
          <div key={`questiongroup-${groupIndex}`}>
            <div key={`question-title-${groupIndex}`} className="group-title-container">
              <h3>{group.title}{!!group.defaultQuestions?.length && (defaultsAdded.find(x => x === group.key) ? <span className='bp-defaults-added'>{`Added Briefpoint Defaults (${group.defaultQuestions.length})`}</span> : <Button variant='link' onClick={() => addDefaults(group)}>{`Add Briefpoint Defaults (${group.defaultQuestions.length})`}</Button>)}</h3>
            </div>
            {group.questions?.sort((a, b) => (a.order ?? Number.MAX_SAFE_INTEGER) - (b.order ?? Number.MAX_SAFE_INTEGER)).map((q, questionIndex) => {
              if (q.isChecked) {
                checkedQuestionCount++;
              }
              return <Question key={`question-${questionCount++}`} q={q}
                startingNumber={startingNumber}
                questionIndex={questionIndex}
                groupIndex={groupIndex}
                questionCount={questionCount}
                checkedQuestionCount={checkedQuestionCount}
                editQuestionGroup={editQuestionGroup}
                editQuestionNumber={editQuestionNumber}
                editQuestionText={editQuestionText}
                setEditQuestionText={setEditQuestionText}
                editActive={editActive}
                highlightDefinitions={highlightDefinitions}
                handleEditingBlur={handleEditingBlur}
                handleEditingQuestion={handleEditingQuestion}
                handleOnKeyDown={handleOnKeyDown}
                handleMouseDown={handleMouseDown}
                handleMouseEnter={handleMouseEnter}
                handleMouseUp={handleMouseUp}
              />
            }
            )
            }
          </div>
        )
      })
      }
      {!!isAdding &&
        <div className="input-wrapper" ref={scrollRef}>
          <label htmlFor='new-request-text' className="accordion-item-header">Add New Request</label>
          <textarea
            id={'new-request-text'}
            className="form-control fs-7 request-fac-text"
            title='new-request-text'
            ref={newRef}
            onChange={(e) => handleInputChange(e.target.value)}
          />
          <div className="lib-checkbox-container">
            <Form.Check
              id={'library-add-check'}
              type={'checkbox'}
              label="Add to library for future use"
              checked={saveQuestionToLibrary}
              onChange={handleAddToLibrary}
            />
          </div>
          {saveQuestionToLibrary &&
            <>
              {!!user?.role?.includes(Role.FirmAdmin) &&
                <Form.Group id='new-firm-select' className='indent-radio'>
                  <Form.Check id='new-firm-select-personal' type='radio' inline label='Personal Library' checked={!saveToFirm} onChange={_ => setSaveToFirm(false)} />
                  <Form.Check id='new-firm-select-firm' type='radio' inline label='Firm Library' checked={saveToFirm} onChange={_ => setSaveToFirm(true)} />
                </Form.Group>
              }
              <Row>
                {!!_case?.caseType && <Col md={5}>
                  <label>Case Type</label>
                  <Form.Group id='new-casetype' className='indent-radio'>
                    <Form.Check id='new-casetype-any' type='radio' inline label='Any' value={undefined} checked={selectedCaseType === undefined} onChange={_ => handleLocalCaseTypeChange(undefined)} />
                    {buildCaseTypeOptions(_case.caseType, jurisdiction, selectedCaseType, handleLocalCaseTypeChange)}
                  </Form.Group>
                </Col>}
                <Col md={4}>
                  <label>Respondent Position</label>
                  <Form.Group id='new-position-select' className='indent-radio'>
                    <Form.Check id='new-position-select-any' type='radio' inline label='Any' checked={!selectedRespondentType} onChange={_ => setSelectedRespondentType(undefined)} />
                    <Form.Check id='new-position-select-party' type='radio' inline label={otherParty?.position} checked={!!selectedRespondentType} onChange={_ => setSelectedRespondentType(otherParty?.position)} />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <label>Representative Action</label>
                  <Form.Group id='new-representative-select' className='indent-radio'>
                    <Form.Check id='new-representative-select-any' type='radio' inline label='Any' checked={selectedRepresentative === undefined} onChange={_ => setSelectedRepresentative(undefined)} />
                    <Form.Check id='new-representative-select-party' type='radio' inline label={_case?.representativeType === Representative.ClassAction || _case?.representativeType === Representative.Paga ? "Representative (PAGA, Class Action, etc.)" : "Individual"} checked={selectedRepresentative !== undefined} onChange={_ => setSelectedRepresentative(_case?.representativeType === Representative.ClassAction || _case?.representativeType === Representative.Paga)} />
                  </Form.Group>
                </Col>
              </Row>
              <TagsSelect id='case-tags' label="Tags" availableTags={availableTags} selected={selectedTags} onChange={handleLocalTagChange} />
            </>}
        </div>
      }
      <div key={'add-request-wrapper'} className="add-request-button-wrapper">
        {isAdding ?
          <>
            <Button variant={"primary"} style={{ padding: '0px 20px' }} className="btn-sm" onClick={handleNewSave}>
              Save
            </Button>
            <div className="close-link-container">
              <Button variant={"link"} className="btn-sm" onClick={() => closeAddNew(true)}>
                Cancel
              </Button>
            </div>
          </>
          :
          <Button variant={"outline-primary"} className="btn-sm" onClick={() => setIsAdding(true)}>
            + Add Another Request
          </Button>
        }
      </div>
    </div>
  );
};
