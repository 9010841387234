
import { Modal } from 'components/Modals';

interface Props {
  isShow: boolean;
  onClose: (goBack: boolean) => void;
  onConfirm: () => void;
}

export default function ConfirmCodeResetModal({ isShow, onClose, onConfirm }: Props) {

  function handleClose(goBack?:boolean) {
    if(goBack){
      onClose(true);
    }
    onClose(false);
  }

  function ModalContent() {
    return (
      <div>
        If you generate a new code, the previous code will no longer work.
      </div>
    );
  }

  return (
    <Modal
      show={isShow}
      onClose={handleClose}
      onConfirm={onConfirm}
      onSecondaryAction={()=>{handleClose(true)}}
      title={`Are you sure?`}
      showCancelButton={false}
      showConfirmButton={true}
      showSecondaryButton = {true}
      secondaryText = 'No, Go Back'
      confirmText = "Yes, I'm Sure"
    >
      {ModalContent()}
    </Modal>
  );
}
