/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Account,
    AccountFromJSON,
    AccountToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface AccountGetRequest {
    id: string;
}

export interface AccountGetUserByEmailRequest {
    email?: string;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     */
    async accountGetRaw(requestParameters: AccountGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Account>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Account/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     */
    async accountGet(requestParameters: AccountGetRequest, initOverrides?: RequestInit): Promise<Account> {
        const response = await this.accountGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async accountGetUserByEmailRaw(requestParameters: AccountGetUserByEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Account>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Account/user-by-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountFromJSON(jsonValue));
    }

    /**
     */
    async accountGetUserByEmail(requestParameters: AccountGetUserByEmailRequest, initOverrides?: RequestInit): Promise<Account> {
        const response = await this.accountGetUserByEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
