import { EmojiFrownFill } from "react-bootstrap-icons";
import colors from "../../css/_shared.module.scss";

export default function SignupHeader({
  showAdminContent,
  userIsInactive,
}: {
  showAdminContent: boolean;
  userIsInactive: boolean;
}) {

  function renderNonAdminMsg(): JSX.Element {
    return (
      <>
        <div className="text-center mb-3">
          <EmojiFrownFill color={colors.primary} size={58} />
        </div>
        <div className="mb-2 text-center">
          <p>Looks like you’re trying to use a feature that requires a subscription.</p>
          <p>
            {`Talk to your administrator about ${
              userIsInactive ? "reactivating your account" : "signing up for Briefpoint"
            } to unlock all features and put some vacation days back on your calendar :)`}
          </p>
          <p>You can find more information on Briefpoint’s subscriptions <a href="https://briefpoint.ai/pricing/" target="_blank" rel="noreferrer">here.</a></p>
          <p>Once you’re subscribed, you’ll never see this message again!</p>
        </div>
      </>
    );
  };

  function renderAdminMsg(): JSX.Element {
    return (
      <>
        <h1 className="mb-4 text-center d-flex justify-content-center">
          <div className="mb-2">
            Start putting vacation days back on your calendar.
          </div>
        </h1>
      </>
    );
  };

  return (
    <>
      {!showAdminContent ? renderNonAdminMsg() : renderAdminMsg()}
    </>
  );
}
