/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Case,
    CaseFromJSON,
    CaseToJSON,
    CreateExternalFolder,
    CreateExternalFolderFromJSON,
    CreateExternalFolderToJSON,
    ExternalCaseIEnumerableExternalPartnerResponse,
    ExternalCaseIEnumerableExternalPartnerResponseFromJSON,
    ExternalCaseIEnumerableExternalPartnerResponseToJSON,
    ExternalDocument,
    ExternalDocumentFromJSON,
    ExternalDocumentToJSON,
    ExternalDocumentExternalPartnerResponse,
    ExternalDocumentExternalPartnerResponseFromJSON,
    ExternalDocumentExternalPartnerResponseToJSON,
    ExternalDocumentIEnumerableExternalPartnerResponse,
    ExternalDocumentIEnumerableExternalPartnerResponseFromJSON,
    ExternalDocumentIEnumerableExternalPartnerResponseToJSON,
    ExternalFolderExternalPartnerResponse,
    ExternalFolderExternalPartnerResponseFromJSON,
    ExternalFolderExternalPartnerResponseToJSON,
    ExternalFolderIEnumerableExternalPartnerResponse,
    ExternalFolderIEnumerableExternalPartnerResponseFromJSON,
    ExternalFolderIEnumerableExternalPartnerResponseToJSON,
    ExternalIntegration,
    ExternalIntegrationFromJSON,
    ExternalIntegrationToJSON,
    Party,
    PartyFromJSON,
    PartyToJSON,
} from '../models';

export interface CaseAddClientRequest {
    firmId: string;
    id: string;
    body?: string;
}

export interface CaseCreateExternalCaseFolderRequest {
    externalCaseId: string;
    firmId: string;
    createExternalFolder?: CreateExternalFolder;
}

export interface CaseCreatePartyRequest {
    firmId: string;
    id: string;
    party?: Party;
}

export interface CaseDeleteRequest {
    firmId: string;
    id: string;
    deleteDocumentData?: boolean;
}

export interface CaseExternalCaseFileAfterUploadRequest {
    externalCaseId: string;
    firmId: string;
    externalDocument?: ExternalDocument;
}

export interface CaseExternalIntegrationRequest {
    firmId: string;
    id: string;
    externalIntegration?: ExternalIntegration;
}

export interface CaseGetRequest {
    firmId: string;
    id: string;
}

export interface CaseGetAllRequest {
    firmId: string;
    clientId: string;
    usersCasesOnly?: boolean;
    includeArchived?: boolean;
}

export interface CaseGetAll0Request {
    firmId: string;
    usersCasesOnly?: boolean;
    includeArchived?: boolean;
    clientId?: string;
}

export interface CaseGetExternalRequest {
    firmId: string;
    filter?: string;
}

export interface CaseGetExternalCaseFileDownloadLinksRequest {
    externalCaseId: string;
    firmId: string;
    externalDocument: Array<ExternalDocument>;
}

export interface CaseGetExternalCaseFileUploadLinkRequest {
    externalCaseId: string;
    firmId: string;
    externalDocument?: ExternalDocument;
}

export interface CaseGetExternalCaseFilesRequest {
    externalCaseId: string;
    firmId: string;
}

export interface CaseGetExternalCaseFoldersRequest {
    externalCaseId: string;
    firmId: string;
}

export interface CasePostRequest {
    firmId: string;
    clientId?: string;
    _case?: Case;
}

export interface CasePutRequest {
    firmId: string;
    id: string;
    _case?: Case;
}

export interface CasePutPartyRequest {
    firmId: string;
    id: string;
    partyId: string;
    party?: Party;
}

/**
 * 
 */
export class CaseApi extends runtime.BaseAPI {

    /**
     */
    async caseAddClientRaw(requestParameters: CaseAddClientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseAddClient.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling caseAddClient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/{id}/add-client`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async caseAddClient(requestParameters: CaseAddClientRequest, initOverrides?: RequestInit): Promise<void> {
        await this.caseAddClientRaw(requestParameters, initOverrides);
    }

    /**
     */
    async caseCreateExternalCaseFolderRaw(requestParameters: CaseCreateExternalCaseFolderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalFolderExternalPartnerResponse>> {
        if (requestParameters.externalCaseId === null || requestParameters.externalCaseId === undefined) {
            throw new runtime.RequiredError('externalCaseId','Required parameter requestParameters.externalCaseId was null or undefined when calling caseCreateExternalCaseFolder.');
        }

        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseCreateExternalCaseFolder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/external/{externalCaseId}/folders`.replace(`{${"externalCaseId"}}`, encodeURIComponent(String(requestParameters.externalCaseId))).replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExternalFolderToJSON(requestParameters.createExternalFolder),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalFolderExternalPartnerResponseFromJSON(jsonValue));
    }

    /**
     */
    async caseCreateExternalCaseFolder(requestParameters: CaseCreateExternalCaseFolderRequest, initOverrides?: RequestInit): Promise<ExternalFolderExternalPartnerResponse> {
        const response = await this.caseCreateExternalCaseFolderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async caseCreatePartyRaw(requestParameters: CaseCreatePartyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Party>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseCreateParty.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling caseCreateParty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/{id}/Party`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PartyToJSON(requestParameters.party),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartyFromJSON(jsonValue));
    }

    /**
     */
    async caseCreateParty(requestParameters: CaseCreatePartyRequest, initOverrides?: RequestInit): Promise<Party> {
        const response = await this.caseCreatePartyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async caseDeleteRaw(requestParameters: CaseDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseDelete.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling caseDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.deleteDocumentData !== undefined) {
            queryParameters['deleteDocumentData'] = requestParameters.deleteDocumentData;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/{id}`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async caseDelete(requestParameters: CaseDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.caseDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async caseExternalCaseFileAfterUploadRaw(requestParameters: CaseExternalCaseFileAfterUploadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalDocumentExternalPartnerResponse>> {
        if (requestParameters.externalCaseId === null || requestParameters.externalCaseId === undefined) {
            throw new runtime.RequiredError('externalCaseId','Required parameter requestParameters.externalCaseId was null or undefined when calling caseExternalCaseFileAfterUpload.');
        }

        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseExternalCaseFileAfterUpload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/external/{externalCaseId}/files/after-upload`.replace(`{${"externalCaseId"}}`, encodeURIComponent(String(requestParameters.externalCaseId))).replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalDocumentToJSON(requestParameters.externalDocument),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalDocumentExternalPartnerResponseFromJSON(jsonValue));
    }

    /**
     */
    async caseExternalCaseFileAfterUpload(requestParameters: CaseExternalCaseFileAfterUploadRequest, initOverrides?: RequestInit): Promise<ExternalDocumentExternalPartnerResponse> {
        const response = await this.caseExternalCaseFileAfterUploadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async caseExternalIntegrationRaw(requestParameters: CaseExternalIntegrationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseExternalIntegration.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling caseExternalIntegration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/{id}/external-integration`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalIntegrationToJSON(requestParameters.externalIntegration),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async caseExternalIntegration(requestParameters: CaseExternalIntegrationRequest, initOverrides?: RequestInit): Promise<void> {
        await this.caseExternalIntegrationRaw(requestParameters, initOverrides);
    }

    /**
     */
    async caseGetRaw(requestParameters: CaseGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Case>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseGet.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling caseGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/{id}`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseFromJSON(jsonValue));
    }

    /**
     */
    async caseGet(requestParameters: CaseGetRequest, initOverrides?: RequestInit): Promise<Case> {
        const response = await this.caseGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async caseGetAllRaw(requestParameters: CaseGetAllRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Case>>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseGetAll.');
        }

        if (requestParameters.clientId === null || requestParameters.clientId === undefined) {
            throw new runtime.RequiredError('clientId','Required parameter requestParameters.clientId was null or undefined when calling caseGetAll.');
        }

        const queryParameters: any = {};

        if (requestParameters.usersCasesOnly !== undefined) {
            queryParameters['usersCasesOnly'] = requestParameters.usersCasesOnly;
        }

        if (requestParameters.includeArchived !== undefined) {
            queryParameters['includeArchived'] = requestParameters.includeArchived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Client/{clientId}/Case`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters.clientId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CaseFromJSON));
    }

    /**
     */
    async caseGetAll(requestParameters: CaseGetAllRequest, initOverrides?: RequestInit): Promise<Array<Case>> {
        const response = await this.caseGetAllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async caseGetAll_1Raw(requestParameters: CaseGetAll0Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Case>>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseGetAll_1.');
        }

        const queryParameters: any = {};

        if (requestParameters.usersCasesOnly !== undefined) {
            queryParameters['usersCasesOnly'] = requestParameters.usersCasesOnly;
        }

        if (requestParameters.includeArchived !== undefined) {
            queryParameters['includeArchived'] = requestParameters.includeArchived;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CaseFromJSON));
    }

    /**
     */
    async caseGetAll_1(requestParameters: CaseGetAll0Request, initOverrides?: RequestInit): Promise<Array<Case>> {
        const response = await this.caseGetAll_1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async caseGetExternalRaw(requestParameters: CaseGetExternalRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalCaseIEnumerableExternalPartnerResponse>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseGetExternal.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/external`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalCaseIEnumerableExternalPartnerResponseFromJSON(jsonValue));
    }

    /**
     */
    async caseGetExternal(requestParameters: CaseGetExternalRequest, initOverrides?: RequestInit): Promise<ExternalCaseIEnumerableExternalPartnerResponse> {
        const response = await this.caseGetExternalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async caseGetExternalCaseFileDownloadLinksRaw(requestParameters: CaseGetExternalCaseFileDownloadLinksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalDocumentIEnumerableExternalPartnerResponse>> {
        if (requestParameters.externalCaseId === null || requestParameters.externalCaseId === undefined) {
            throw new runtime.RequiredError('externalCaseId','Required parameter requestParameters.externalCaseId was null or undefined when calling caseGetExternalCaseFileDownloadLinks.');
        }

        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseGetExternalCaseFileDownloadLinks.');
        }

        if (requestParameters.externalDocument === null || requestParameters.externalDocument === undefined) {
            throw new runtime.RequiredError('externalDocument','Required parameter requestParameters.externalDocument was null or undefined when calling caseGetExternalCaseFileDownloadLinks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/external/{externalCaseId}/files/download`.replace(`{${"externalCaseId"}}`, encodeURIComponent(String(requestParameters.externalCaseId))).replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.externalDocument.map(ExternalDocumentToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalDocumentIEnumerableExternalPartnerResponseFromJSON(jsonValue));
    }

    /**
     */
    async caseGetExternalCaseFileDownloadLinks(requestParameters: CaseGetExternalCaseFileDownloadLinksRequest, initOverrides?: RequestInit): Promise<ExternalDocumentIEnumerableExternalPartnerResponse> {
        const response = await this.caseGetExternalCaseFileDownloadLinksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async caseGetExternalCaseFileUploadLinkRaw(requestParameters: CaseGetExternalCaseFileUploadLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalDocumentExternalPartnerResponse>> {
        if (requestParameters.externalCaseId === null || requestParameters.externalCaseId === undefined) {
            throw new runtime.RequiredError('externalCaseId','Required parameter requestParameters.externalCaseId was null or undefined when calling caseGetExternalCaseFileUploadLink.');
        }

        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseGetExternalCaseFileUploadLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/external/{externalCaseId}/files/upload`.replace(`{${"externalCaseId"}}`, encodeURIComponent(String(requestParameters.externalCaseId))).replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalDocumentToJSON(requestParameters.externalDocument),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalDocumentExternalPartnerResponseFromJSON(jsonValue));
    }

    /**
     */
    async caseGetExternalCaseFileUploadLink(requestParameters: CaseGetExternalCaseFileUploadLinkRequest, initOverrides?: RequestInit): Promise<ExternalDocumentExternalPartnerResponse> {
        const response = await this.caseGetExternalCaseFileUploadLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async caseGetExternalCaseFilesRaw(requestParameters: CaseGetExternalCaseFilesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalDocumentIEnumerableExternalPartnerResponse>> {
        if (requestParameters.externalCaseId === null || requestParameters.externalCaseId === undefined) {
            throw new runtime.RequiredError('externalCaseId','Required parameter requestParameters.externalCaseId was null or undefined when calling caseGetExternalCaseFiles.');
        }

        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseGetExternalCaseFiles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/external/{externalCaseId}/files`.replace(`{${"externalCaseId"}}`, encodeURIComponent(String(requestParameters.externalCaseId))).replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalDocumentIEnumerableExternalPartnerResponseFromJSON(jsonValue));
    }

    /**
     */
    async caseGetExternalCaseFiles(requestParameters: CaseGetExternalCaseFilesRequest, initOverrides?: RequestInit): Promise<ExternalDocumentIEnumerableExternalPartnerResponse> {
        const response = await this.caseGetExternalCaseFilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async caseGetExternalCaseFoldersRaw(requestParameters: CaseGetExternalCaseFoldersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalFolderIEnumerableExternalPartnerResponse>> {
        if (requestParameters.externalCaseId === null || requestParameters.externalCaseId === undefined) {
            throw new runtime.RequiredError('externalCaseId','Required parameter requestParameters.externalCaseId was null or undefined when calling caseGetExternalCaseFolders.');
        }

        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling caseGetExternalCaseFolders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/external/{externalCaseId}/folders`.replace(`{${"externalCaseId"}}`, encodeURIComponent(String(requestParameters.externalCaseId))).replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalFolderIEnumerableExternalPartnerResponseFromJSON(jsonValue));
    }

    /**
     */
    async caseGetExternalCaseFolders(requestParameters: CaseGetExternalCaseFoldersRequest, initOverrides?: RequestInit): Promise<ExternalFolderIEnumerableExternalPartnerResponse> {
        const response = await this.caseGetExternalCaseFoldersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async casePostRaw(requestParameters: CasePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Case>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling casePost.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CaseToJSON(requestParameters._case),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CaseFromJSON(jsonValue));
    }

    /**
     */
    async casePost(requestParameters: CasePostRequest, initOverrides?: RequestInit): Promise<Case> {
        const response = await this.casePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async casePutRaw(requestParameters: CasePutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling casePut.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling casePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/{id}`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CaseToJSON(requestParameters._case),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async casePut(requestParameters: CasePutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.casePutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async casePutPartyRaw(requestParameters: CasePutPartyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Party>> {
        if (requestParameters.firmId === null || requestParameters.firmId === undefined) {
            throw new runtime.RequiredError('firmId','Required parameter requestParameters.firmId was null or undefined when calling casePutParty.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling casePutParty.');
        }

        if (requestParameters.partyId === null || requestParameters.partyId === undefined) {
            throw new runtime.RequiredError('partyId','Required parameter requestParameters.partyId was null or undefined when calling casePutParty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Firm/{firmId}/Case/{id}/Party/{partyId}`.replace(`{${"firmId"}}`, encodeURIComponent(String(requestParameters.firmId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"partyId"}}`, encodeURIComponent(String(requestParameters.partyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartyToJSON(requestParameters.party),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartyFromJSON(jsonValue));
    }

    /**
     */
    async casePutParty(requestParameters: CasePutPartyRequest, initOverrides?: RequestInit): Promise<Party> {
        const response = await this.casePutPartyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
