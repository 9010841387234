/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CommunicationStatus {
    Unknown = 'Unknown',
    Pending = 'Pending',
    Sent = 'Sent',
    Open = 'Open',
    InProgress = 'InProgress',
    ChangesRequested = 'ChangesRequested',
    Complete = 'Complete',
    Closed = 'Closed',
    Deleted = 'Deleted'
}

export function CommunicationStatusFromJSON(json: any): CommunicationStatus {
    return CommunicationStatusFromJSONTyped(json, false);
}

export function CommunicationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommunicationStatus {
    return json as CommunicationStatus;
}

export function CommunicationStatusToJSON(value?: CommunicationStatus | null): any {
    return value as any;
}

