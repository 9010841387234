/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsightTarget
 */
export interface InsightTarget {
    /**
     * 
     * @type {number}
     * @memberof InsightTarget
     */
    interrogatoryId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsightTarget
     */
    objectionId?: number | null;
}

export function InsightTargetFromJSON(json: any): InsightTarget {
    return InsightTargetFromJSONTyped(json, false);
}

export function InsightTargetFromJSONTyped(json: any, ignoreDiscriminator: boolean): InsightTarget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interrogatoryId': !exists(json, 'interrogatoryId') ? undefined : json['interrogatoryId'],
        'objectionId': !exists(json, 'objectionId') ? undefined : json['objectionId'],
    };
}

export function InsightTargetToJSON(value?: InsightTarget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interrogatoryId': value.interrogatoryId,
        'objectionId': value.objectionId,
    };
}


