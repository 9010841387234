/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PartyPosition,
    PartyPositionFromJSON,
    PartyPositionFromJSONTyped,
    PartyPositionToJSON,
} from './';

/**
 * 
 * @export
 * @interface Party
 */
export interface Party {
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Party
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof Party
     */
    tagIds?: Array<number> | null;
    /**
     * 
     * @type {PartyPosition}
     * @memberof Party
     */
    position?: PartyPosition;
}

export function PartyFromJSON(json: any): Party {
    return PartyFromJSONTyped(json, false);
}

export function PartyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Party {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'tagIds': !exists(json, 'tagIds') ? undefined : json['tagIds'],
        'position': !exists(json, 'position') ? undefined : PartyPositionFromJSON(json['position']),
    };
}

export function PartyToJSON(value?: Party | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'isDeleted': value.isDeleted,
        'tagIds': value.tagIds,
        'position': PartyPositionToJSON(value.position),
    };
}


