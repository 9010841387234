import useConfig, { COMPLAINTS } from "hooks/useConfig";
import { Link } from "react-router-dom";
import { ReactComponent as GsPropound } from "../../images/getstarted-propound.svg";
import { ReactComponent as GsDiscovery } from "../../images/getstarted-discovery.svg";
import { ReactComponent as GsClients } from "../../images/getstarted-clients.svg";
import { ReactComponent as GsWorkprod } from "../../images/getstarted-workproduct.svg";

type TopRibbonProps = {
  handleQuickActionModal: () => void;
  handleQuickActionUpload: () => void;
  handlePropoundIntroModal: () => void;
}

export function TopRibbon({ handleQuickActionModal, handleQuickActionUpload, handlePropoundIntroModal }: TopRibbonProps): JSX.Element {

  const [, featureFlags] = useConfig();
  const useComplaints = featureFlags()[COMPLAINTS] ? featureFlags()[COMPLAINTS](undefined, undefined) : false;

  return (
    <div className="quickstart-wrapper mb-3">
      <h2>What do you want to do?</h2>
      <div className="quickstart-container">
        {!useComplaints ? (
          <div onClick={handleQuickActionModal}>
            <GsPropound /> <p>Propound Discovery</p>
          </div>
        ) : (
          <div className="new-feature" onClick={handlePropoundIntroModal}>
            <GsPropound /> <p>Propound Discovery</p>
            <span>New</span>
          </div>
        )}
        <div onClick={handleQuickActionUpload}>
          <GsDiscovery /> <p>Respond to Discovery</p>
        </div>
        <Link to="/clients">
          <div>
            <GsClients /> <p>View Clients</p>
          </div>
        </Link>
        <Link to="/library?tab=objections">
          <div>
            <GsWorkprod /> <p>Add/Edit Workproduct</p>
          </div>
        </Link>
      </div>
    </div>
  );
}
