import { Col, Button } from 'react-bootstrap';
import InterrogatoriesList, { IntLi } from './CommunicationsList';
import React, { useState } from 'react';
import InterrogatoriesSelector from './CommunicationsSelector';

export default function CommunicationsSelect({
  intData,
  setFormIntData,
  clientName,
  questionType,
  confirmClientInfoClick,
  readOnly
}: {
  intData: IntLi[];
  setFormIntData: React.Dispatch<React.SetStateAction<IntLi[]>>;
  clientName: string;
  questionType: string;
  confirmClientInfoClick: () => void;
  readOnly: boolean;
}) {

  const onUpdateInterrogatory = async (updatedInterrogatory: IntLi) => {
    const updatedData = intData.map(item =>
      item.id === updatedInterrogatory.id ? updatedInterrogatory : item
    );
    setFormIntData(updatedData);
  };

  const [linkRef, setLinkRefs] = useState<{ [key: string]: React.RefObject<HTMLDivElement>; }>({});
  const [refsDone, setRefsDone] = useState<boolean>(false);

  const updateLinkRefs = (newRefs: { [key: string]: React.RefObject<HTMLDivElement> }) => {
    setLinkRefs(newRefs);
    setRefsDone(true);
  };

  const scrollToView = (target: string) => {
    const ref = linkRef[target];
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const onClick = (target: string) => {
    scrollToView(target);
  };

  return (
    <div className="mb-4">
      <h1 className='feedback-header'>Request Client Response</h1>
      {!readOnly ? <p>Select the questions you'd like to send to your client for response.</p> :
        <p>The communication has been opened by the client. Please start over to begin another communication for this document.</p>}
      <div className="d-flex">
        <Col xs="6" sm="6" m="4" lg="3" xl="3" xxl="3">
          <div className="d-flex flex-column">
            <h3><b style={{ fontSize: "18px" }}>Responses</b></h3>
            <InterrogatoriesSelector
              intData={intData}
              onClick={onClick}
              refsDone={refsDone} />
          </div>
        </Col>
        <Col xs="6" sm="6" m="6" lg="6" xl="7" xxl="8">
          <div className="d-flex flex-column">
            <h3><b style={{ fontSize: "18px" }}>{clientName}</b></h3>
          </div>
          <div className='frogs-bounds'>
            <div className="document-preview-container list-column frogs-list" style={{ paddingRight: "0px" }}>
              <InterrogatoriesList
                interrogatoriesList={intData}
                onUpdateInterrogatory={onUpdateInterrogatory}
                setLinkRefs={updateLinkRefs}
                questionType={questionType}
                readOnly={readOnly}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-2">
            <Button
              onClick={confirmClientInfoClick}>
              Next
            </Button>
          </div>
        </Col>
      </div>
    </div>
  );
}
