import React, { useEffect, useRef, useState } from 'react';
import { sanitizeHtmlString } from 'utils';
import './styles.scss';

export default function FormInterrogatoriesList({
  interrogatoriesList,
  onUpdateInterrogatory,
  setLinkRefs,
}: {
  interrogatoriesList: FormIntLi[],
  onUpdateInterrogatory: (updatedInterrogatory: FormIntLi) => void;
  setLinkRefs: (newRefs: {
    [key: string]: React.RefObject<HTMLDivElement>;
}) => void
}) {

  const refs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  useEffect(() => {
    interrogatoriesList.forEach(item => {
      if (!refs.current[`${item.section}.${item.number}`]) {
        refs.current[`${item.section}.${item.number}`] = React.createRef();
      }
    });
    setLinkRefs(refs.current);
  }, [interrogatoriesList, setLinkRefs]);

  return (
      <div className="interrogatories-edit mt-2">
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {interrogatoriesList.map((interrogatory) => (
            <li key={interrogatory.id} className="selection-item">
              <FormInterrogatoryLI 
              interrogatory={interrogatory} 
              onUpdateInterrogatory={onUpdateInterrogatory}
              liRef={refs.current[`${interrogatory.section}.${interrogatory.number}`]} />
            </li>
          ))}
        </ul>
      </div>
  );
}

export function FormInterrogatoryLI({
  interrogatory,
  onUpdateInterrogatory,
  liRef
}: {
  interrogatory: FormIntLi;
  onUpdateInterrogatory: (updatedInterrogatory: FormIntLi) => void;
  liRef :  React.RefObject<HTMLDivElement>
}) {

  const[cbState, setCBState] = useState<boolean>(interrogatory.selected ?? false);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedInterrogatory = {
      ...interrogatory,
      selected: e.target.checked,
      action: 'cb'
    };
    setCBState(e.target.checked)
    onUpdateInterrogatory(updatedInterrogatory);
  };

  const handleConfirm = () => {
    const updatedInterrogatory = {
      ...interrogatory,
      highlighted: false,
      action: 'confirm'
    };
    onUpdateInterrogatory(updatedInterrogatory);
  };

  const handleSpanClick = () => {
    const updatedInterrogatory = {
      ...interrogatory,
      selected: !cbState,
      action: 'cb'
    };
    setCBState(!cbState);
    onUpdateInterrogatory(updatedInterrogatory);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter') {
      handleSpanClick();
    }
  };

  return (
    <div
      key={interrogatory.id}
      ref={liRef}
    >
      {interrogatory.highlighted ? 
      (
        <div className='action-border'>
          <div className='action-required flat-bottem'>
          <span>Please confirm that this interrogatory's selection matches the document.</span>
          <button 
            className='btn btn-primary btn-sm frogs-btn'
            onClick={handleConfirm}
          >Confirm</button>
          </div>
          <div className="interrogatory-div">
          <input
            type="checkbox"
            className="interrogatory-cb"
            checked={cbState}
            onChange={handleCheckboxChange}
            onKeyDownCapture={handleKeyPress}
          />
          <span className={'sp-click'} onClick ={handleSpanClick} dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(`<b> ${interrogatory.section}.${interrogatory.number}</b> ${interrogatory.text}`) }} />
          </div>
        </div>
      )
      :
      (
      <div>
        <div className="interrogatory-div">
          <input
            type="checkbox"
            className="interrogatory-cb"
            checked={cbState}
            onChange={handleCheckboxChange}
            onKeyDownCapture={handleKeyPress}
          />
          <span className={'sp-click'} onClick ={handleSpanClick} dangerouslySetInnerHTML={{ __html: sanitizeHtmlString(`<b> ${interrogatory.section}.${interrogatory.number}</b> ${interrogatory.text}`) }} />
        </div>
        <hr />
      </div>)
      } 
    </div>
  );
}

export interface FormIntLi {
  id?: string;
  section?: number;
  number?: number;
  text?: string | null;
  selected?: boolean;
  highlighted?: boolean;
  action?: string;
}