import { useEffect, useState } from 'react';
import { Role, SubscriptionPlan, User } from 'briefpoint-client';
import { useAuth } from 'hooks/useAuth';
import { Alert } from 'react-bootstrap';
import { FirmClient, hasCanceledSubscription, isInTrial } from 'services/FirmService';
import Button from './Button';
import { ReactComponent as Restore } from 'images/Restore.svg';
import './TrialEndsBanner.scss';

export const GetTrialBannerMessage = (user?: User, firm?: FirmClient) => {
  const now = new Date();
  const expiry = firm?.billingInfo?.creditsExpireDate;
  const docsLeft = firm?.billingInfo.creditsAvailable ?? 0;

  function calculateTimeLeft(a: Date | null | undefined, b: Date | null | undefined) {
    const difference = a && b ? a.getTime() - b.getTime() : null;
    let calc;
    if (difference && difference > 0) {
      calc = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return calc
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(expiry, now));

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(expiry, now));
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  if (user?.isInactive) {
    return <div>Your account has been deactivated.</div>
  }
  if (firm) {
    if (isInTrial(firm) && firm.billingInfo.subscriptionPlan === SubscriptionPlan.None) {
      if (firm?.billingInfo.trialEnds) {
        const trialEnds = firm?.billingInfo.trialEnds!;
        const differenceInTime: any = trialEnds && now ? calculateTimeLeft(trialEnds, now) : null;
        if (differenceInTime.days <= 0 && differenceInTime.hours <= 0) {
          return <div>Your trial is over.</div>;
        } else {
          if (differenceInTime.days === 0 && differenceInTime.hours > 0) {
            return (
              <div>
                Your trial ends <strong>today</strong>.
              </div>
            );
          } else {
            return (
              <div>
                Your trial ends in <strong>{differenceInTime.days}</strong> day{differenceInTime.days !== 1 ? 's' : ''}.
              </div>
            );
          }
        }
      } else if (firm?.billingInfo?.creditsExpireDate) {
        return (
          <div className="expiry-message-container">
            <div className="restore-wrapper">
              <Restore />
            </div>
            <strong>Expires Soon!&nbsp;</strong>
            Your&nbsp;<strong>{docsLeft}</strong>
            &nbsp;free document{docsLeft !== 1 ? 's are' : ' is'} only available for&nbsp;

            <div className="time-box"><strong>{timeLeft?.days}&nbsp;</strong></div>
            <strong>Days</strong>&nbsp;

            <div className="time-box"> <strong>{timeLeft?.hours}&nbsp;</strong></div>
            <strong>Hours</strong>&nbsp;

            <div className="time-box"><strong>{timeLeft?.minutes}&nbsp;</strong></div>
            <strong> Minutes</strong>.
          </div>
        );
      } else {
        return (
          <div>
            You have <strong>{docsLeft}</strong>&nbsp;free document{docsLeft !== 1 ? 's' : ''} left.
          </div>
        );
      }
    } else if (hasCanceledSubscription(firm)) {
      return <div>Your subscription is currently canceled.</div>;
    }
  }
}

export default function TrialEndsBanner({ onClickSignUp, message, showCountdownStyle }: { onClickSignUp?: () => void, message: JSX.Element | undefined, showCountdownStyle: boolean }) {
  const { user } = useAuth()!;
  const isAdmin = user && user.role && user.role.includes(Role.FirmAdmin);
  return message ? (
    <div className={showCountdownStyle ? "banner-wrapper" : ""}>
      <Alert variant="info" className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
          {message}{' '}
          {!!onClickSignUp && isAdmin ? <Button onClick={onClickSignUp}>Sign up now!</Button> : `Ask your firm admin to ${user?.isInactive ? 'reactivate your account' : 'sign up'}!`}
        </div>
      </Alert>
    </div>
  ) : (
    null
  );
}
