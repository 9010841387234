/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BooleanExternalPartnerResponse,
    BooleanExternalPartnerResponseFromJSON,
    BooleanExternalPartnerResponseToJSON,
    ExternalPartners,
    ExternalPartnersFromJSON,
    ExternalPartnersToJSON,
    Preferences,
    PreferencesFromJSON,
    PreferencesToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RoleUpdate,
    RoleUpdateFromJSON,
    RoleUpdateToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserPage,
    UserPageFromJSON,
    UserPageToJSON,
} from '../models';

export interface UserExternalPartnerAuthRequest {
    userId: string;
    code?: string;
    partner?: ExternalPartners;
}

export interface UserGetAllPagedRequest {
    itemsPerPage?: number;
    pageNumber?: number;
    search?: string;
    searchFirmId?: string;
}

export interface UserGetUserRequest {
    userId: string;
}

export interface UserMoveUserAccountRequest {
    userId: string;
    newUserId: string;
}

export interface UserMoveUserFirmRequest {
    userId: string;
    newFirmId: string;
}

export interface UserPostRequest {
    useDefaultJurisdiction?: boolean;
    partner?: ExternalPartners;
}

export interface UserPutRequest {
    user?: User;
}

export interface UserQuarantineRequest {
    userId: string;
}

export interface UserUpdatePreferencesRequest {
    preferences?: Preferences;
}

export interface UserUpdateRoleRequest {
    userId: string;
    roleUpdate?: RoleUpdate;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async userExternalPartnerAuthRaw(requestParameters: UserExternalPartnerAuthRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BooleanExternalPartnerResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userExternalPartnerAuth.');
        }

        const queryParameters: any = {};

        if (requestParameters.code !== undefined) {
            queryParameters['code'] = requestParameters.code;
        }

        if (requestParameters.partner !== undefined) {
            queryParameters['partner'] = requestParameters.partner;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/{userId}/external-partner-auth`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BooleanExternalPartnerResponseFromJSON(jsonValue));
    }

    /**
     */
    async userExternalPartnerAuth(requestParameters: UserExternalPartnerAuthRequest, initOverrides?: RequestInit): Promise<BooleanExternalPartnerResponse> {
        const response = await this.userExternalPartnerAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async userGet(initOverrides?: RequestInit): Promise<User> {
        const response = await this.userGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userGetAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async userGetAll(initOverrides?: RequestInit): Promise<Array<User>> {
        const response = await this.userGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userGetAllPagedRaw(requestParameters: UserGetAllPagedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserPage>> {
        const queryParameters: any = {};

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.searchFirmId !== undefined) {
            queryParameters['searchFirmId'] = requestParameters.searchFirmId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/all/paged`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPageFromJSON(jsonValue));
    }

    /**
     */
    async userGetAllPaged(requestParameters: UserGetAllPagedRequest, initOverrides?: RequestInit): Promise<UserPage> {
        const response = await this.userGetAllPagedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userGetTermsAndConditionsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/terms-and-conditions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async userGetTermsAndConditions(initOverrides?: RequestInit): Promise<string> {
        const response = await this.userGetTermsAndConditionsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userGetUserRaw(requestParameters: UserGetUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userGetUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async userGetUser(requestParameters: UserGetUserRequest, initOverrides?: RequestInit): Promise<User> {
        const response = await this.userGetUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userLocationRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/location`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async userLocation(initOverrides?: RequestInit): Promise<string> {
        const response = await this.userLocationRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async userMoveUserAccountRaw(requestParameters: UserMoveUserAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userMoveUserAccount.');
        }

        if (requestParameters.newUserId === null || requestParameters.newUserId === undefined) {
            throw new runtime.RequiredError('newUserId','Required parameter requestParameters.newUserId was null or undefined when calling userMoveUserAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/{userId}/change-account/{newUserId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"newUserId"}}`, encodeURIComponent(String(requestParameters.newUserId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userMoveUserAccount(requestParameters: UserMoveUserAccountRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userMoveUserAccountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userMoveUserFirmRaw(requestParameters: UserMoveUserFirmRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userMoveUserFirm.');
        }

        if (requestParameters.newFirmId === null || requestParameters.newFirmId === undefined) {
            throw new runtime.RequiredError('newFirmId','Required parameter requestParameters.newFirmId was null or undefined when calling userMoveUserFirm.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/{userId}/move-to-firm/{newFirmId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"newFirmId"}}`, encodeURIComponent(String(requestParameters.newFirmId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userMoveUserFirm(requestParameters: UserMoveUserFirmRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userMoveUserFirmRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userPostRaw(requestParameters: UserPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        if (requestParameters.useDefaultJurisdiction !== undefined) {
            queryParameters['useDefaultJurisdiction'] = requestParameters.useDefaultJurisdiction;
        }

        if (requestParameters.partner !== undefined) {
            queryParameters['partner'] = requestParameters.partner;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async userPost(requestParameters: UserPostRequest, initOverrides?: RequestInit): Promise<User> {
        const response = await this.userPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userPutRaw(requestParameters: UserPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async userPut(requestParameters: UserPutRequest, initOverrides?: RequestInit): Promise<User> {
        const response = await this.userPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async userQuarantineRaw(requestParameters: UserQuarantineRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userQuarantine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/{userId}/quarantine`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userQuarantine(requestParameters: UserQuarantineRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userQuarantineRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userUpdatePreferencesRaw(requestParameters: UserUpdatePreferencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/update-preferences`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PreferencesToJSON(requestParameters.preferences),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userUpdatePreferences(requestParameters: UserUpdatePreferencesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userUpdatePreferencesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userUpdateRoleRaw(requestParameters: UserUpdateRoleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userUpdateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/{userId}/update-role`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleUpdateToJSON(requestParameters.roleUpdate),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userUpdateRole(requestParameters: UserUpdateRoleRequest, initOverrides?: RequestInit): Promise<void> {
        await this.userUpdateRoleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async userViewedAnnouncementsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/User/viewed-announcements`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async userViewedAnnouncements(initOverrides?: RequestInit): Promise<void> {
        await this.userViewedAnnouncementsRaw(initOverrides);
    }

}
