import { Button } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

export default function Logout() {
  const { logout } = useAuth0();
  const history = useHistory();

  const handleLogout = async () => {
    await logout({ returnTo: 'https://www.google.com/' })
    history.push('/login');
  };

  return (
    <Container className="page-container">
      <h1>Logout</h1>
      <Button onClick={handleLogout}>Logout</Button>
    </Container>
  );
}
