/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalDocument,
    ExternalDocumentFromJSON,
    ExternalDocumentFromJSONTyped,
    ExternalDocumentToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalFolder
 */
export interface ExternalFolder {
    /**
     * 
     * @type {string}
     * @memberof ExternalFolder
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalFolder
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalFolder
     */
    path?: string | null;
    /**
     * 
     * @type {Array<ExternalFolder>}
     * @memberof ExternalFolder
     */
    folders?: Array<ExternalFolder> | null;
    /**
     * 
     * @type {Array<ExternalDocument>}
     * @memberof ExternalFolder
     */
    documents?: Array<ExternalDocument> | null;
}

export function ExternalFolderFromJSON(json: any): ExternalFolder {
    return ExternalFolderFromJSONTyped(json, false);
}

export function ExternalFolderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalFolder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'folders': !exists(json, 'folders') ? undefined : (json['folders'] === null ? null : (json['folders'] as Array<any>).map(ExternalFolderFromJSON)),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(ExternalDocumentFromJSON)),
    };
}

export function ExternalFolderToJSON(value?: ExternalFolder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'path': value.path,
        'folders': value.folders === undefined ? undefined : (value.folders === null ? null : (value.folders as Array<any>).map(ExternalFolderToJSON)),
        'documents': value.documents === undefined ? undefined : (value.documents === null ? null : (value.documents as Array<any>).map(ExternalDocumentToJSON)),
    };
}


