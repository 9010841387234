/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Definition
 */
export interface Definition {
    /**
     * 
     * @type {number}
     * @memberof Definition
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Definition
     */
    term?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Definition
     */
    aliases?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Definition
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Definition
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Definition
     */
    firmStandardId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Definition
     */
    caseId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Definition
     */
    documentId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Definition
     */
    isInternal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Definition
     */
    isDeleted?: boolean;
}

export function DefinitionFromJSON(json: any): Definition {
    return DefinitionFromJSONTyped(json, false);
}

export function DefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Definition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'term': !exists(json, 'term') ? undefined : json['term'],
        'aliases': !exists(json, 'aliases') ? undefined : json['aliases'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'firmStandardId': !exists(json, 'firmStandardId') ? undefined : json['firmStandardId'],
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'documentId': !exists(json, 'documentId') ? undefined : json['documentId'],
        'isInternal': !exists(json, 'isInternal') ? undefined : json['isInternal'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
    };
}

export function DefinitionToJSON(value?: Definition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'term': value.term,
        'aliases': value.aliases,
        'content': value.content,
        'userId': value.userId,
        'firmStandardId': value.firmStandardId,
        'caseId': value.caseId,
        'documentId': value.documentId,
        'isInternal': value.isInternal,
        'isDeleted': value.isDeleted,
    };
}


