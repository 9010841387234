import { Check2 } from 'react-bootstrap-icons';
import { IntLi } from './CommunicationsList';

export default function InterrogatoriesSelector({
  intData,
  onClick,
  refsDone
}: {
  intData: IntLi[];
  onClick: (target: string) => void;
  refsDone: boolean;
}) {

  const ResponseGrid = () => {
    if (!refsDone) {
      return <></>;
    }
    const circles = [];
    let count = 0;
    for (let rowNum = 0; rowNum < (intData.length / 5); rowNum++) {
      const row = [];
      for (let j = 0; j < 5; j++) {
        let btnIdx = count;
        row.push(
          <div key={count} className="col-2" style={{ marginLeft: "3px" }}>
            <button
              className={
                intData[btnIdx]?.selected ?
                  "circle-button-sl text-center" : "circle-button text-center"
              }
              onClick={() => { onClick(intData[btnIdx]?.number?.toString() ?? "") }}>
              {intData[count]?.number}
            </button>
            {intData[btnIdx]?.selected && <Check2 className='circle-ck' />}
          </div>
        );
        count++;
        if (count >= intData.length) {
          break;
        }
      }
      if ((rowNum + 1) * 5 < intData.length) {
        circles.push(
          <div key={rowNum} className="row">
            {row}
            <hr style={{ marginBottom: "8px" }} />
          </div>
        );
      }
      else {
        circles.push(
          <div key={rowNum} className="row">
            {row}
          </div>
        );
      }
    }
    return <div className="container">{circles}</div>;
  };

  return (
    <div className="interrogatory-response-box">
      {ResponseGrid()}
    </div>
  );

}