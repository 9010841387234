/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CredentialAudit
 */
export interface CredentialAudit {
    /**
     * 
     * @type {string}
     * @memberof CredentialAudit
     */
    credentialId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CredentialAudit
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof CredentialAudit
     */
    ip?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CredentialAudit
     */
    isSuccess?: boolean;
}

export function CredentialAuditFromJSON(json: any): CredentialAudit {
    return CredentialAuditFromJSONTyped(json, false);
}

export function CredentialAuditFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialAudit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'credentialId': !exists(json, 'credentialId') ? undefined : json['credentialId'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
        'isSuccess': !exists(json, 'isSuccess') ? undefined : json['isSuccess'],
    };
}

export function CredentialAuditToJSON(value?: CredentialAudit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'credentialId': value.credentialId,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'ip': value.ip,
        'isSuccess': value.isSuccess,
    };
}


