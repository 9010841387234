import { SubscriptionPlan } from 'briefpoint-client';
import { Check, X } from 'react-bootstrap-icons';
import Button from 'components/Button';
import { SubscriptionPlanType } from 'services/FirmService';
import { Form } from 'react-bootstrap';

function UnlimitedPlanCard(plan: SubscriptionPlanType, onClick: (plan: SubscriptionPlanType) => void, intervalSwitch: React.Dispatch<React.SetStateAction<boolean>>, compareToPlan?: SubscriptionPlanType | null) {
  const hasDiscount = false;

  return (
    <div className={`p-4 plan-card mb-4 highlight`}>
      <span className={'callout'}>{plan.plan !== SubscriptionPlan.YearlyBundle ? "Popular" : "Best Value"}</span>
      <div className="p-2">
        <h2 className="mb-3 title">
          {`${plan.plan !== SubscriptionPlan.YearlyBundle ? 'Unlimited Rainmaker' : 'High Volume'}`}
        </h2>
        {plan.plan !== SubscriptionPlan.YearlyBundle ?
          <div className='interval-switch'><label className={plan.plan === SubscriptionPlan.Monthly ? '' : 'selected'} htmlFor='yearly-toggle'>Yearly Pricing</label><Form.Check type='switch' id='yearly-toggle' checked={plan.plan === SubscriptionPlan.Monthly} onChange={(e) => intervalSwitch(e.currentTarget.checked)} /><label className={plan.plan !== SubscriptionPlan.Monthly ? '' : 'selected'} htmlFor='yearly-toggle'>Monthly Pricing</label></div>
          : <div>&nbsp;</div>}
        {plan.plan === SubscriptionPlan.YearlyBundle ?
          <>
            <div className='price'>
              <div className="mt-2 mb-0">
                {hasDiscount ? (
                  <>
                    <span className="original-price-slashed"><del>{compareToPlan!.priceString}</del></span><br />
                    <strong>{plan.monthlyPrice || plan.priceString}</strong>
                  </>
                ) : (
                  <span>{plan.monthlyPrice || plan.priceString}</span>
                )}
              </div>
              <div className="mb-2 text-secondary">For 10 attorneys/year</div>
            </div>
          </> : <>
            <div className='prices'>
              <div className='price-split left'>
                <div>{plan.monthlyPrice || plan.priceString}</div>
                <p className="text-secondary">Per attorney/&#10;&#13;month</p>
              </div>
              <div className='slash'></div>
              <div className='right price-split' >
                <div>{plan.plan === SubscriptionPlan.Monthly ? '$59' : '$49'}</div>
                <p className="text-secondary">Per paralegal/&#10;&#13;month</p>
              </div>
            </div>
          </>
        }
        <div className='benefit'><Check size='20' className='check' />Unlimited Uploads</div>
        <div className='benefit'><Check size='20' className='check' />Work Product Library</div>
        <div className='benefit'><Check size='20' className='check' />Unlimited Documents</div>
        <div className='benefit'><Check size='20' className='check' />24/7 Support</div>
        {plan.plan === SubscriptionPlan.YearlyBundle ? (
          <>
            <div className='benefit'><Check size='20' className='check' />3 free paralegal accounts</div>
            <div className='benefit'><Check size='20' className='check' />Free Upgrades</div>
            <div className='benefit'><Check size='20' className='check' />Dedicated Support</div>
          </>
        ) : (
          <>
            <div className='benefit'><span style={{ height: 20, marginBottom: '2%', verticalAlign: 'middle' }}>&nbsp;</span></div>
            <div className='benefit'><span style={{ height: 20, marginBottom: '2%', verticalAlign: 'middle' }}>&nbsp;</span></div>
            <div className='benefit'><span style={{ height: 20, marginBottom: '2%', verticalAlign: 'middle' }}>&nbsp;</span></div>
          </>
        )}
        <br />
        <Button
          onClick={() => {
            onClick(plan);
          }}
        >
          Sign up now!
        </Button>
      </div>
    </div>
  );
}

function AlaCartePlanCard(plan: SubscriptionPlanType, onClick: (plan: SubscriptionPlanType) => void, compareToPlan?: SubscriptionPlanType | null) {
  return (
    <div className={`p-4 plan-card mb-4`}>
      <div className="p-2">
        <h2 className="mb-3 title">
          {`${plan.title}`}
        </h2>
        <div>&nbsp;</div>
        <div className="mt-2 mb-0 price">
          $55
        </div>
        <div className="mb-1 text-secondary">Per document</div>
        <h2 className='mb-0'>&nbsp;</h2>
        <div className='benefit'><Check size='20' className='check' />Unlimited Uploads</div>
        <div className='benefit'><Check size='20' className='check' />Work Product Library</div>
        <div className='benefit'><X size='20' className='x' />Unlimited Documents</div>
        <div className='benefit'><span style={{ height: 20, marginBottom: '2%', verticalAlign: 'middle' }}>&nbsp;</span></div>
        <div className='benefit'><span style={{ height: 20, marginBottom: '2%', verticalAlign: 'middle' }}>&nbsp;</span></div>
        <div className='benefit'><span style={{ height: 20, marginBottom: '2%', verticalAlign: 'middle' }}>&nbsp;</span></div>
        <div className='benefit'><span style={{ height: 20, marginBottom: '2%', verticalAlign: 'middle' }}>&nbsp;</span></div>
        <br />
        <Button
          onClick={() => {
            onClick(plan);
          }}
        >
          Sign up now!
        </Button>
      </div>
    </div>
  );
}

export default function PlanCard({
  plan,
  compareToPlan,
  onClick,
  intervalSwitch
}: {
  plan: SubscriptionPlanType;
  compareToPlan?: SubscriptionPlanType | null;
  onClick: (plan: SubscriptionPlanType) => void;
  intervalSwitch: React.Dispatch<React.SetStateAction<boolean>>
}) {
  return plan.isUnlimited ? UnlimitedPlanCard(plan, onClick, intervalSwitch, compareToPlan) : AlaCartePlanCard(plan, onClick, compareToPlan);
}
