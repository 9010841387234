import { useMemo } from 'react';
import ReactSelect, { ActionMeta, OptionsType } from 'react-select';
import { ExclamationTriangleFill, CheckCircleFill, XLg as CloseIcon } from 'react-bootstrap-icons';
import styles from "./TagSelect.module.scss";

export interface TagOption {
  label: string;
  value: number | null;
}

interface Props {
  availableTags?: Map<number, string>;
  extraInfo?: Map<number, any>;
  className?: string;
  selected?: number[];
  onChange: (value: OptionsType<TagOption>, action: ActionMeta<TagOption>) => void;
  id: string;
  label: string;
  disabled?: boolean;
  // Props used for toast UI implementation. Can be refactored at a later time if we want to make it more dynamic/flexible in the future.
  useToast?: boolean;
  caseExist?: boolean | null;
  showToast?: boolean;
  scrollTo?: () => void; 
  closeToast?: () => void; 
  toastPromptType?: string;
  toastMsgValue?: string;
}

export default function Select({
  availableTags,
  extraInfo,
  className,
  selected,
  onChange,
  id,
  label,
  disabled,
  useToast,
  caseExist,
  showToast,
  scrollTo,
  closeToast,
  toastMsgValue,
}: Props) {
  function buildItem(index: number, label: string, ex?: Map<number, any>) {
    const extra = ex && ex.get(index);
    return { value: index, label: `${label}${extra ? ` [${extra}]` : ''}` }
  }

  const transformedOptions = useMemo(() => {
    const options = availableTags && Array.from(availableTags.entries()).map(([index, t]) => { return buildItem(index, t, extraInfo) });

    return options?.sort((a, b) => {
      const aHasExtra = a.label.endsWith(']');
      const bHasExtra = b.label.endsWith(']');

      if (aHasExtra && !bHasExtra) {
        return -1;
      }

      if (bHasExtra && !aHasExtra) {
        return 1;
      }

      return a.label.localeCompare(b.label);
    });
  }, [availableTags, extraInfo]);

  const selectedOptions = useMemo(() => {
    if (availableTags) {
      const ts = selected?.map(s => {
        const tag = availableTags.get(s);
          // Ensure a label with a pair value exists; if not, don't return an undefined label/value. This indicates the selected label/value doesn't exist among the available filtered tags.
          if (!tag || !s) {
            return null as any;
        } else {
          return { label: tag, value: s } as TagOption;
        }
      }
      );
      return ts ?? [];
    }
    return [];
  }, [availableTags, selected]);

  const generateToastMessage = () => {

      if (!caseExist) {
        return (
          <div
            className={`${styles.toast}  ${styles.warning}`}
          >
            <ExclamationTriangleFill className={styles.iconType} /> No {toastMsgValue} content for this case type found.  <span onClick={scrollTo}>Add Now.</span>
            <CloseIcon onClick={closeToast} className={styles.closeIcon} />
          </div>
        )
      } else {
        return (
          <div
            className={`${styles.toast}  ${styles.success}`}
          >
            <CheckCircleFill className={styles.iconType} />
            {toastMsgValue} content added.
          </div>
        )
      }

  };

  const customStyles = useMemo(() => {
    return {
      control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        borderColor: '#808788',
        boxShadow: !state.isFocused ? '0' : '0 0 0 0.25rem rgba(4, 65, 84, 0.25)',
        "&:hover": {
          borderColor: 'rgb(128, 135, 136)'
        }
      }),
      dropdownIndicator: (baseStyles: any, state: any) => ({
        ...baseStyles,
        color: '#4D5758',
        "&:hover": {
          color: '#4D5758',
          cursor: 'pointer'
        }
      }),
      clearIndicator: (baseStyles: any, state: any) => ({
        ...baseStyles,
        color: '#4D5758',
        "&:hover": {
          color: '#4D5758',
          cursor: 'pointer'
        }
      }),
      indicatorSeparator: (baseStyles: any, state: any) => ({
        ...baseStyles,
        color: '#4D5758',
        "&:hover": {
          color: '#4D5758',
        }
      }),
      multiValueRemove: (baseStyles: any, state: any) => ({
        ...baseStyles,
        ':hover': {
          backgroundColor: '#CCCFCF',
          cursor: 'pointer'
        },
      }),
    };
  }, []);

  return (
    <>
      <div className={styles.tagContainer}>
        <label htmlFor={id} className='form-label padding' >{label}</label>
        <ReactSelect
          id={id}
          isDisabled={disabled}
          isLoading={!availableTags}
          className={className}
          classNamePrefix="briefpoint-tag-select"
          options={transformedOptions}
          value={selectedOptions}
          styles={customStyles}
          onChange={onChange}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#074f6b',
              primary25: '#efefea',
            },
          })}
          //menuIsOpen={true}
          menuPlacement='auto'
          isMulti={true}
        ></ReactSelect>
        {useToast && showToast && (
          <div className={styles.toastContainer}>
            {generateToastMessage()}
          </div>
        )}
      </div>
    </>
  );
}
