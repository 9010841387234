import {
  type ReactNode,
  type Dispatch,
  useContext,
  createContext,
  useState,
  SetStateAction,
} from "react";
import { Firm, User } from "briefpoint-client";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class Dictionary<T> {
  [index: string]: T;
}

type ConfigContextProps = {
  config: Dictionary<string> | undefined;
  setConfig: Dispatch<SetStateAction<Dictionary<string> | undefined>>;
  featureFlags: Dictionary<(user?: User, firm?: Firm) => boolean> | undefined;
  setFeatureFlags: Dispatch<SetStateAction<Dictionary<(user?: User, firm?: Firm) => boolean> | undefined>>;
};

type ConfigProviderProps = {
  children: ReactNode;
};

const ConfigContext = createContext<ConfigContextProps | null>(null);

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [config, setConfig] = useState<Dictionary<string> | undefined>();
  const [featureFlags, setFeatureFlags] = useState<Dictionary<(user?: User, firm?: Firm) => boolean> | undefined>();

  const ctxValues: ConfigContextProps = {
    config,
    setConfig,
    featureFlags,
    setFeatureFlags
  };

  return (
    <ConfigContext.Provider value={ctxValues}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => {
  const configCtx = useContext(ConfigContext);

  if (configCtx === null) {
    throw new Error("Config context is null");
  }

  return configCtx;

}

export default ConfigContext
