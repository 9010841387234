import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ArchiveFill } from 'react-bootstrap-icons';
import { DocumentClient } from 'services/DocumentService';

export default function ArchiveIcon({
  document,
  archiveDocument,
}: {
  document: DocumentClient;
  archiveDocument: (document: DocumentClient) => Promise<void>;
}) {
  return (
    <div className="px-2 text-end">
      <OverlayTrigger overlay={<Tooltip className="text-capitalize">Archive</Tooltip>}>
        <ArchiveFill className="archive-icon" onClick={() => archiveDocument(document)} />
      </OverlayTrigger>
    </div>
  );
}