/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalPartners,
    ExternalPartnersFromJSON,
    ExternalPartnersFromJSONTyped,
    ExternalPartnersToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalClient
 */
export interface ExternalClient {
    /**
     * 
     * @type {string}
     * @memberof ExternalClient
     */
    id?: string | null;
    /**
     * 
     * @type {ExternalPartners}
     * @memberof ExternalClient
     */
    partner?: ExternalPartners;
    /**
     * 
     * @type {string}
     * @memberof ExternalClient
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalClient
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalClient
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalClient
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalClient
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalClient
     */
    fax?: string | null;
}

export function ExternalClientFromJSON(json: any): ExternalClient {
    return ExternalClientFromJSONTyped(json, false);
}

export function ExternalClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'partner': !exists(json, 'partner') ? undefined : ExternalPartnersFromJSON(json['partner']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
    };
}

export function ExternalClientToJSON(value?: ExternalClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'partner': ExternalPartnersToJSON(value.partner),
        'name': value.name,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'phone': value.phone,
        'fax': value.fax,
    };
}


