/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ObjectTypeEnum {
    Unknown = 'Unknown',
    MiscContent = 'MiscContent',
    Case = 'Case',
    ObjectionVariant = 'ObjectionVariant',
    Document = 'Document',
    Client = 'Client',
    Interrogatory = 'Interrogatory'
}

export function ObjectTypeEnumFromJSON(json: any): ObjectTypeEnum {
    return ObjectTypeEnumFromJSONTyped(json, false);
}

export function ObjectTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObjectTypeEnum {
    return json as ObjectTypeEnum;
}

export function ObjectTypeEnumToJSON(value?: ObjectTypeEnum | null): any {
    return value as any;
}

