import { useCallback, useState } from "react";
import { GetExternalPartnerAuthUrl } from "components/ExternalPartner/ExternalPartnerConnection";
import { PopupNavigator } from "components/ExternalPartner/AuthPopup/PopupNavigator";
import { UrlUtils } from "components/ExternalPartner/AuthPopup/UrlUtils";
import { useAuth } from "./useAuth";
import { useUserApi } from "./useApi";
import useConfig from "./useConfig";

export default function useReInitiateAuth() {

  const [errorAuthenticateMsg, setAuthenticateErrorMsg] = useState<string>();
  const { user, setUser, firm } = useAuth()!;
  const userApi = useUserApi();
  const [config] = useConfig();

  const reInitiateAuth = useCallback(async () => {
    if (!user || !firm) {
      return null;
    }
    const firmProviders = firm.preferences?.enabledExternalProviders?.values();
    let enabledPartners = firmProviders ? Array.from(firmProviders) : null;

    if (!enabledPartners?.length) {
      return null;
    }

    const partner = enabledPartners[0];
    const authUrl = GetExternalPartnerAuthUrl(partner, user?.id!, config);
    const popupNavigator = new PopupNavigator();
    const handle = await popupNavigator.prepare({});

    try {
      const result = await handle.navigate({
        url: authUrl!,
      });

      const params = UrlUtils.readParams(result.url);
      const code = params.get("code");
      if (code) {
        const tokenResult = await userApi.userExternalPartnerAuth({
          userId: user.id!,
          code,
          partner: partner,
        });
        if (tokenResult.responseCode !== 200) {
          throw new Error(`Unable to authenticate to ${partner}. Status Code: ${tokenResult.responseCode}`);
        }

        setUser((u) => {
          const newUser = { ...u };
          newUser.externalConnection = { partner: partner, isActive: true };
          return newUser;
        });
      } else {
        throw new Error(`Unexpected response from auth: ${params}`);
      }
    } catch (_e) {
      let message = "";
      if (typeof _e === "string") {
        message = _e;
      } else if (_e instanceof Error) {
        message = _e.message;
      }

      if (message.includes("closed by user")) {
        setAuthenticateErrorMsg(`Authentication cancelled. Please login to ${partner}`);
        throw new Error("Authentication Cancelled");
      }
    }
  }, [firm, setUser, user, userApi, config]);

  return { reInitiateAuth, errorAuthenticateMsg };

};
