import { Case, ExternalClientIEnumerableExternalPartnerResponse, ExternalDocument } from "briefpoint-client";
import type { TreeDataNode } from "antd";
import { DirectoryTreeProps } from "antd/lib/tree";
import { useCaseApi, useDocumentApi, useFirmApi } from "hooks/useApi";
import { useAuth } from "hooks/useAuth";
import React, {SetStateAction, useEffect, useState } from "react";
import ExternalDirectoryWrapper from "./ExternalDirectoryWrapper";
import { delayDocumentRefresh, externalRequestWrapper, handleExternalReqResCodes, transformDataExternalToBp } from "utils/ExternalPartner/utils";
import useReInitiateAuth from "hooks/useExternalAuthenticate";

const GENERAL_ERROR_MSG = "An error occurred. Please try again later.";

export default function ExternalFileBrowser({
  _case,
  isExternalUploading,
  setIsExternalUploading,
  loadDocuments,
  onFinish,
}: {
  _case?: Case;
  isExternalUploading: boolean;
  setIsExternalUploading: React.Dispatch<SetStateAction<boolean>>;
  loadDocuments: () => Promise<void>;
  onFinish: () => void;
}) {
  const { user, firm } = useAuth()!;
  const { reInitiateAuth, errorAuthenticateMsg } = useReInitiateAuth();
  const caseApi = useCaseApi();
  const firmApi = useFirmApi();
  const documentApi = useDocumentApi();
  const [caseFiles, setCaseFiles] = useState<ExternalDocument[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<any[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  
  useEffect(() => {
    async function load() {
      function exCaseFetchError(){
        setErrorMsg(GENERAL_ERROR_MSG);
        throw new Error(GENERAL_ERROR_MSG);
      }

      if (!!user?.externalConnection?.isActive) {
        try {
          const res = await externalRequestWrapper(caseApi.caseGetExternalCaseFiles({ firmId: user?.firmId!, externalCaseId: _case?.integration?.identifier!}), 3); 
          function exCaseFetchSuccess(res: ExternalClientIEnumerableExternalPartnerResponse){
            setCaseFiles(res.data!);
            if (errorMsg) {
              setErrorMsg(undefined);
            }
          }
          handleExternalReqResCodes(res, exCaseFetchSuccess, exCaseFetchError, reInitiateAuth);
        } catch (error) {
          setErrorMsg(GENERAL_ERROR_MSG);
          return;
        }
      }
    }

    if (_case) {
      load();
    }
  }, [user, _case, errorMsg, caseApi, firmApi, reInitiateAuth]);

  if (!user || !firm) {
    return null;
  }

  if (!user.externalConnection?.isActive) {
    return <div>No external connection... you shouldn't really be here.</div>;
  }

  const [treeData, dataMap] = transformDataExternalToBp(caseFiles);

  const onSelect: DirectoryTreeProps["onSelect"] = (selectedKeys) => {
    const files = selectedKeys
      .map((key: any) =>
        (dataMap as Map<string, ExternalDocument>).get(key.toString())
      )
      .filter((file: any): file is ExternalDocument => !!file);

    setFilesToUpload(files);
  };

  const doFileUpload = async () => {
    setIsExternalUploading(true);
    const getUploadLinks = await caseApi.caseGetExternalCaseFileDownloadLinks({
      firmId: firm.id,
      externalCaseId: _case?.integration?.identifier!,
      externalDocument: filesToUpload,
    });

    if (
      getUploadLinks && getUploadLinks.responseCode === 200 &&
      getUploadLinks.data?.length
    ) {
      await documentApi.documentExternalFiles({
        caseId: _case?.id,
        externalFile: getUploadLinks.data.map((d) => {
          return {
            location: d.location!,
            size: d.size!,
            name: `${d.name}${d.type}`,
          };
        }),
      });
    } else {
      setErrorMsg(GENERAL_ERROR_MSG);
      setIsExternalUploading(false);
    }

    delayDocumentRefresh(2750, loadDocuments, setIsExternalUploading, onFinish);

  };

  return (
    <>
      <ExternalDirectoryWrapper
        multiple
        isSubmitted={isExternalUploading}
        selectedCase={_case}
        onSelect={onSelect}
        files={filesToUpload}
        isLoading={!caseFiles.length}
        directoryTitle="Upload Document"
        closeModal={onFinish}
        submitActionText="Upload"
        submitAction={doFileUpload}
        treeData={treeData as TreeDataNode[]}
      >
        {errorAuthenticateMsg || errorMsg}
      </ExternalDirectoryWrapper>
    </>
  );
}
