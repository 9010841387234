import { Objection, ObjectionType, ObjectionVariant, Role, User } from 'briefpoint-client';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { BriefcaseFill, ClipboardPlus, PencilFill } from 'react-bootstrap-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { classes } from 'utils';
import { FormModal } from 'components/Modals/Modal';
import { useAuth } from 'hooks/useAuth';
import { DropDownFilterList, getCaseTypeDisplay, getCases, getMiscDisplay, getSortedCases, getSortedTags, getTagsDisplay } from 'components/DropDownFilters';
import { useTagApi } from 'hooks/useApi';
import { tagsList } from './QuestionsTable';
import { CaliforniaId } from 'components/CaseManagement/AddEditCaseModal';

interface Props {
  objections: Objection[];
  onDelete: (objectionId: number, variantId: number) => void;
  isResponses: boolean;
  users: Map<string, User>;
}

export default function ObjectionTable({ objections, onDelete, isResponses, users }: Props) {
  const [search, setSearch] = useState('');
  const { user, defaultJurisdiction } = useAuth()!;
  const [selectedObjection, setSelectedObjection] = useState<Objection>();
  const [allVariants, setAllVariants] = useState<ObjectionVariant[]>();
  const [allObjectionsSelected, setAllObjectionsSelected] = useState<boolean>(!isResponses);
  const [confirmRemove, setConfirmRemove] = useState<number | null>(null);
  const [availableTags, setAvailableTags] = useState<Map<number, string> | undefined>();
  const [tagFilter, setTagFilter] = useState<any[]>([]);
  const [caseFilter, setCaseFilter] = useState<any[]>([]);
  const [documentFilter, setDocumentFilter] = useState<any[]>([]);
  const history = useHistory();
  const location = useLocation();
  const tagApi = useTagApi();
  const selection = new URLSearchParams(location.search).get('selection');
  
  const subTypes = [
    {id: 1, name:'Special Interrogatories'},
    {id: 2, name:'Requests for Production'},
    {id: 3, name:'Requests for Admission'},
    {id: 5, name:'Form Interrogatories'}
  ];

  useEffect(() => {
    async function loadTags() {
      const tagsList = new Map<number, string>();
      const loadedTags = await tagApi.tagFindTags({});

      loadedTags.forEach(t => tagsList.set(t.id, t.name));
      setAvailableTags(tagsList);
    };
    loadTags();
  }, [tagApi]);

  useEffect(() => {
    if (objections && objections.length) {
      if (selectedObjection) {
        setSelectedObjection(objections.find((o) => o.id === selectedObjection.id));
      } else {
        setSelectedObjection(
          objections.filter(
            (objection) =>
              (!isResponses &&
                objection.objectionType !== ObjectionType.Response &&
                objection.objectionType !== ObjectionType.NoResponse) ||
              (isResponses &&
                (objection.objectionType === ObjectionType.Response ||
                  objection.objectionType === ObjectionType.NoResponse))
          )[0]
        );
      }
      var allTheVariants: ObjectionVariant[] = [];
      for (const objection of objections) {
        for (const variant of objection.variants) {
          allTheVariants.push(variant);
        }
      }
      setAllVariants(allTheVariants);
    }
  }, [isResponses, objections, selectedObjection]);

  useEffect(() => {
    if (objections && selection) {
      for (const objection of objections.filter(
        (objection) =>
          (!isResponses &&
            objection.objectionType !== ObjectionType.Response &&
            objection.objectionType !== ObjectionType.NoResponse) ||
          (isResponses &&
            (objection.objectionType === ObjectionType.Response ||
              objection.objectionType === ObjectionType.NoResponse))
      )) {
        if (objection.id === parseInt(selection)) {
          setAllObjectionsSelected(false);
          setSelectedObjection(objection);
        }
      }
    }
  }, [isResponses, objections, selection]);

  function setSelectionUrl(newUrl: number | null) {
    if (newUrl === null) {
      history.push({
        pathname: '/library',
        search: `?tab=${isResponses ? 'responses' : 'objections'}`,
      });
    } else {
      history.push({
        pathname: '/library',
        search: `?tab=${isResponses ? 'responses' : 'objections'}&selection=${newUrl}`,
      });
    }
  }

  async function handleConfirmDelete() {
    if (!selectedObjection) {
      return;
    }
    if (confirmRemove) {
      try {
        await onDelete(selectedObjection.id, confirmRemove);
        setConfirmRemove(null);
      } catch (error) {
        alert('Error deleting objection. It is probably in use.');
      }
    }
  }

  function getVariantsCaseType(variant: ObjectionVariant) {
    const cases =  getCases(defaultJurisdiction);

    if (variant.caseTypeId) {
      return cases.find(c => c.id === variant.caseTypeId)?.name
    }
    return 'All';
  }

  function getVariantsObjections(variant: ObjectionVariant) {
    if (objections) {
      for (const objection of objections) {
        if (variant.objectionId === objection.id) {
          return objection.name;
        }
      }
    }
    return '';
  }

  function getVariantsDocumentType(variant: ObjectionVariant) {

    let subTypes: { [key: number]: string } = {
      1: 'Interrogatories',
      2: 'Requests for Production',
      3: 'Requests for Admission',
    };
  
    if(CaliforniaId === defaultJurisdiction?.id){
      subTypes = {
        1: 'Special Interrogatories',
        2: 'Requests for Production',
        3: 'Requests for Admission',
        5: 'Form Interrogatories'
      };
    }
  
    if (variant?.documentSubType) {
      const subType = subTypes[variant.documentSubType];
      return subType ?? 'All';
    }
    return 'All';
  }
  

  function addVariant() {
    if (!allObjectionsSelected && selectedObjection) {
      history.push({
        pathname: `/library/addedit`,
        search: `?type=${selectedObjection.id}`,
      });
    } else {
      history.push({
        pathname: `/library/addedit`,
      });
    }
  }

  function editVariant(variant: ObjectionVariant, copy: boolean = false) {
    history.push({
      pathname: `/library/addedit`,
      search: `${copy ? '?copy=true' : ''}`,
      state: variant,
    });
  }

  function handleClearEvent() {
    setTagFilter([]);
    setCaseFilter([]);
    setDocumentFilter([]);
  };

  return (
    <div className="step-container" style={{ gap: 0 }}>
      <div className="border-end" style={{ width: 250 }}>
        {!isResponses && (
          <div
            key={'all'}
            onClick={() => {
              setAllObjectionsSelected(true);
              setSelectedObjection(undefined);
              setSelectionUrl(null);
            }}
            className={classes('library-objection', {
              'library-objection-selected': allObjectionsSelected,
            })}
          >
            All Objections
          </div>
        )}
        {objections
          ?.filter(
            (objection) =>
              (!isResponses &&
                objection.objectionType !== ObjectionType.Response &&
                objection.objectionType !== ObjectionType.NoResponse) ||
              (isResponses &&
                (objection.objectionType === ObjectionType.Response ||
                  objection.objectionType === ObjectionType.NoResponse))
          )
          .map((o) => (
            <div
              key={o.id}
              onClick={() => {
                setAllObjectionsSelected(false);
                setSelectedObjection(o);
                setSelectionUrl(o.id);
              }}
              className={classes('library-objection', {
                'library-objection-selected': selectedObjection === o && !allObjectionsSelected,
              })}
            >
              {o.name}
            </div>
          ))}
      </div>
      <div className="flex-grow-1 ms-5">
        <Row className="mb-4">
          <Col key='search-actions' className='col-12 col-md-3 '>
            <div className="input-group input-group-search mb-1">
                <div className="input-group-prepend">
                  <div className="input-group-text">⌕</div>
                </div>
                <Form.Control
                  value={search}
                  className="form-control defense-search-input"
                  placeholder="Search title"
                  onChange={(e) => setSearch(e.target.value.toLowerCase())}
                />
              </div>
            </Col>
            <Col key='filters-actions' className='col-auto col-lg-7 filters'>
            <b>Filter By:</b>
            {!!documentFilter.length && <label className='filter-label'>Document Type</label>}
            <DropDownFilterList key='document-filter' 
                  text={!documentFilter.length ? 'Document Type' : getMiscDisplay(documentFilter, subTypes)}
                  itemsList={subTypes}
                  activeFilter={documentFilter}
                  updateFilter={setDocumentFilter} />
            {!!caseFilter.length && <label className='filter-label'>Case Type</label>}
            <DropDownFilterList key='case-filter' 
                  text={!caseFilter.length ? 'Case Type' : getCaseTypeDisplay(caseFilter, defaultJurisdiction)}
                  itemsList={getSortedCases(allVariants ?? [], defaultJurisdiction, caseFilter)}
                  activeFilter={caseFilter}
                  updateFilter={setCaseFilter} />
            {!!tagFilter.length && <label className='filter-label'>Tags</label>}
            <DropDownFilterList key='tags-filter' 
                  text={!tagFilter.length ? 'Tags' : getTagsDisplay(tagFilter, availableTags)}
                  itemsList={getSortedTags(allVariants ?? [], availableTags, tagFilter)}
                  activeFilter={tagFilter}
                  updateFilter={setTagFilter} />
            {(!!caseFilter.length || !!tagFilter.length || !!documentFilter.length) && <Button className='clear-btn' variant='link' onClick={handleClearEvent}>Clear All</Button>}      
            </Col>
          <Col key='button-actions' className='col-auto col-md-2 justify-content-end'>
            <Button variant="primary" onClick={addVariant}>
                Create New
            </Button>
          </Col>
        </Row>
        <Row>
          <Table striped hover>
            <thead>
              <tr className="table-header">
                <th></th>
                <th className="table-header-cell" scope="">
                  Variant Title
                </th>
                <th className="table-header-cell" scope="col">
                  Objection Type
                </th>
                <th scope="col" className="table-header-cell">Document Type</th>
                <th scope="col" className="table-header-cell">Case Type</th>
                <th scope="col" className="table-header-cell">Tags</th>
                <th className="table-header-cell" scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {(allObjectionsSelected ? allVariants : selectedObjection?.variants)
                ?.filter((variant) => variant?.name.toLowerCase().includes(search) && !variant?.isDeleted)
                ?.filter((v) => 
                {
                  if(documentFilter.length > 0 ){
                    return !!documentFilter.find(df => df === v.documentSubType && v.documentSubType !== null)
                  }
                  return true;
                })?.filter((x) => 
                {
                  if(caseFilter.length > 0 ){
                    return !!caseFilter.find(cf => cf === x.caseTypeId && x.caseTypeId !== null)
                  }
                  return true;
                })?.filter((y) => 
                {
                  if(tagFilter.length > 0 ){
                    return !!tagFilter.find(tf => !!y.tagIds && !!y.tagIds.find(id => id === tf) )
                  }
                  return true;
                })
                .map((variant) => (
                  <tr key={variant.id} className="table-row">
                    <td style={{ paddingLeft: 10 }}>{variant.sharedWithFirmId ? <BriefcaseFill title="Firm Standard" /> : ' '}</td>
                    <td className="table-cell">{variant.name}</td>
                    <td className="table-cell">{getVariantsObjections(variant)}</td>
                    <td className="table-cell">{getVariantsDocumentType(variant)}</td>
                    <td className="table-cell">{getVariantsCaseType(variant)}</td>
                    <td className="table-cell">{tagsList(variant?.tagIds, availableTags)}</td>
                    <td className="table-cell actions-cell">
                      {(user?.role?.includes(Role.FirmAdmin) || !variant.sharedWithFirmId) &&
                        <>
                          <PencilFill
                            onClick={() => editVariant(variant)}
                            className="table-edit-item"
                            title='Edit'
                          /></>}
                      <ClipboardPlus
                        onClick={() => editVariant(variant, true)}
                        className="table-edit-item"
                        title='Copy' />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Row>
      </div>
      <FormModal
        title={'Are you sure?'}
        cancelText="Cancel"
        confirmText="Yes, remove"
        show={confirmRemove != null}
        onClose={() => setConfirmRemove(null)}
        onConfirm={handleConfirmDelete}
      >
        <div>Are you sure you want to remove this variant? This cannot be undone.</div>
      </FormModal>
    </div>
  );
}
