/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsightTarget,
    InsightTargetFromJSON,
    InsightTargetFromJSONTyped,
    InsightTargetToJSON,
    InsightType,
    InsightTypeFromJSON,
    InsightTypeFromJSONTyped,
    InsightTypeToJSON,
    ReactionType,
    ReactionTypeFromJSON,
    ReactionTypeFromJSONTyped,
    ReactionTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface GeneratedInsightBase
 */
export interface GeneratedInsightBase {
    /**
     * 
     * @type {string}
     * @memberof GeneratedInsightBase
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneratedInsightBase
     */
    entityId?: string;
    /**
     * 
     * @type {InsightType}
     * @memberof GeneratedInsightBase
     */
    insightType?: InsightType;
    /**
     * 
     * @type {InsightTarget}
     * @memberof GeneratedInsightBase
     */
    target?: InsightTarget;
    /**
     * 
     * @type {string}
     * @memberof GeneratedInsightBase
     */
    userId?: string;
    /**
     * 
     * @type {Date}
     * @memberof GeneratedInsightBase
     */
    generatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof GeneratedInsightBase
     */
    response?: string | null;
    /**
     * 
     * @type {ReactionType}
     * @memberof GeneratedInsightBase
     */
    reaction?: ReactionType;
}

export function GeneratedInsightBaseFromJSON(json: any): GeneratedInsightBase {
    return GeneratedInsightBaseFromJSONTyped(json, false);
}

export function GeneratedInsightBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratedInsightBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'insightType': !exists(json, 'insightType') ? undefined : InsightTypeFromJSON(json['insightType']),
        'target': !exists(json, 'target') ? undefined : InsightTargetFromJSON(json['target']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'generatedAt': !exists(json, 'generatedAt') ? undefined : (new Date(json['generatedAt'])),
        'response': !exists(json, 'response') ? undefined : json['response'],
        'reaction': !exists(json, 'reaction') ? undefined : ReactionTypeFromJSON(json['reaction']),
    };
}

export function GeneratedInsightBaseToJSON(value?: GeneratedInsightBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'entityId': value.entityId,
        'insightType': InsightTypeToJSON(value.insightType),
        'target': InsightTargetToJSON(value.target),
        'userId': value.userId,
        'generatedAt': value.generatedAt === undefined ? undefined : (value.generatedAt.toISOString()),
        'response': value.response,
        'reaction': ReactionTypeToJSON(value.reaction),
    };
}


