import { ExternalPartners } from "briefpoint-client";
import Toggle from "components/Toggle";
import { useAuth } from "hooks/useAuth";
import { useState } from "react";
import { PopupNavigator } from "./AuthPopup/PopupNavigator";
import { ReactComponent as ClioLogo } from '../../images/Glyph_Clio Glyph Blue.svg';
import { ReactComponent as SmokeballLogo } from '../../images/Smokeball_logo_mark.svg';
import { ReactComponent as MyCaseLogo } from '../../images/MyCase.svg';
import { UrlUtils } from "./AuthPopup/UrlUtils";
import { useUserApi } from "hooks/useApi";
import useConfig, { ExternalPartnerConfig } from "hooks/useConfig";

const REACT_APP_BASE_URL: string = (window as any)._env_?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL;

// This should probably be derived from configuration, but Smokeball and Clio only have a prod environment so ... :shrug:
export const GetExternalPartnerAuthUrl = (partner: ExternalPartners, userId: string, configStore: (partner: ExternalPartners) => ExternalPartnerConfig) => {
  const state = { 'userId': userId, 'partner': partner };
  const returnAuthority = REACT_APP_BASE_URL.split(";")[0];
  const returnUrl = `${returnAuthority}`;

  switch (partner) {
    case ExternalPartners.Smokeball:
      return `https://auth.smokeball.com/oauth2/authorize?response_type=code&client_id=25192rib3rkb067a4isvd7ko1i&redirect_uri=${encodeURIComponent(returnUrl)}&state=${encodeURIComponent(btoa(JSON.stringify(state)))}`;
    case ExternalPartners.Clio:
      const clioConfig = configStore(ExternalPartners.Clio);
      return `https://app.clio.com/oauth/authorize?response_type=code&client_id=${clioConfig.clientId}&redirect_uri=${encodeURIComponent(returnUrl)}&state=${encodeURIComponent(btoa(JSON.stringify(state)))}`;
    case ExternalPartners.MyCase:
      const config = configStore(ExternalPartners.MyCase);
      return `${config.authBase}/login_sessions/new?response_type=code&client_id=${config.clientId}&redirect_uri=${encodeURIComponent(returnUrl)}&state=${encodeURIComponent(btoa(JSON.stringify(state)))}`;
    default:
      return null;
  }
}

export default function ExternalPartnerConnection() {
  const { user, setUser, firm } = useAuth()!;
  const [connecting, setConnecting] = useState(false);
  const [alert, setAlert] = useState<string | undefined>();
  const userApi = useUserApi();
  const [config] = useConfig();

  if (!user || !firm) {
    return null;
  }
  const firmProviders = firm.preferences?.enabledExternalProviders?.values();
  let enabledPartners = firmProviders ? Array.from(firmProviders) : null;

  if (!enabledPartners?.length) {
    return null;
  }

  const connectionIsActive = user.externalConnection?.isActive ?? false;
  // at some point we'll need to enable a user to select from a given set of providers enabled for their Firm
  // but for now there will only be one manually enabled
  const partner = enabledPartners[0];

  const onToggle = async () => {
    setConnecting(true);
    setAlert(undefined);
    const authUrl = GetExternalPartnerAuthUrl(partner, user.id!, config);

    if (!user?.externalConnection?.isActive) {
      const popupNavigator = new PopupNavigator();
      const handle = await popupNavigator.prepare({});

      try {
        const result = await handle.navigate({
          url: authUrl!
        });

        const params = UrlUtils.readParams(result.url);
        const code = params.get("code");
        if (code) {
          const tokenResult = await userApi.userExternalPartnerAuth({ userId: user.id!, code, partner: partner });
          if (tokenResult.responseCode !== 200) {
            throw new Error(`Unable to authenticate to ${partner}. Status Code: ${tokenResult.responseCode}`);
          }

          setUser(u => {
            const newUser = { ...u };
            newUser.externalConnection = { partner: partner, isActive: true };
            return newUser;
          });
        } else {
          throw new Error(`Unexpected response from auth: ${params}`);
        }

      } catch (_e) {
        let message = '';
        if (typeof _e === "string") {
          message = _e;
        } else if (_e instanceof Error) {
          message = _e.message;
        }

        if (message.includes("closed by user")) {
          setAlert("Authentication cancelled")
        }
      }
    } else {
      setUser(u => {
        const curUser = { ...u };
        curUser.externalConnection = {};
        return curUser;
      });
    }

    setConnecting(false);
  }

  function getPartnerLogo(partner?: ExternalPartners) {
    if (!partner) {
      return null;
    }

    switch (partner!) {
      case ExternalPartners.Clio:
        return <ClioLogo title="Clio" />;
      case ExternalPartners.Smokeball:
        return <SmokeballLogo title="Smokeball" />;
      case ExternalPartners.MyCase:
        return <MyCaseLogo title="MyCase" />;
      default:
        return null;
    }
  }

  let label: string | JSX.Element = connectionIsActive
    ? (
      <>
        {"Connected to "}
        <span>
          {getPartnerLogo(user.externalConnection?.partner)}
        </span>
        {` ${user.externalConnection?.partner}`}
      </>
    )
    : (
      <>
        {"Connect to "}
        <span>
          {getPartnerLogo(partner)}
        </span>
        {` ${partner}`}
      </>
    );

  if (connecting) {
    label = "Connecting..."
  }

  return (
    <Toggle
      checked={connectionIsActive}
      checkedName=""
      unCheckedName=""
      label={label}
      alert={alert}
      onToggle={onToggle}
      disabled={connecting}
    />
  )

}
