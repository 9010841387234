/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentSubType,
    DocumentSubTypeFromJSON,
    DocumentSubTypeFromJSONTyped,
    DocumentSubTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateObjectionVariant
 */
export interface UpdateObjectionVariant {
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectionVariant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectionVariant
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectionVariant
     */
    additionalContent?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateObjectionVariant
     */
    isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectionVariant
     */
    sharedWithFirmId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateObjectionVariant
     */
    caseTypeId?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateObjectionVariant
     */
    tagIds?: Array<number> | null;
    /**
     * 
     * @type {DocumentSubType}
     * @memberof UpdateObjectionVariant
     */
    documentSubType?: DocumentSubType;
}

export function UpdateObjectionVariantFromJSON(json: any): UpdateObjectionVariant {
    return UpdateObjectionVariantFromJSONTyped(json, false);
}

export function UpdateObjectionVariantFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateObjectionVariant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'additionalContent': !exists(json, 'additionalContent') ? undefined : json['additionalContent'],
        'isDefault': json['isDefault'],
        'sharedWithFirmId': !exists(json, 'sharedWithFirmId') ? undefined : json['sharedWithFirmId'],
        'caseTypeId': !exists(json, 'caseTypeId') ? undefined : json['caseTypeId'],
        'tagIds': !exists(json, 'tagIds') ? undefined : json['tagIds'],
        'documentSubType': !exists(json, 'documentSubType') ? undefined : DocumentSubTypeFromJSON(json['documentSubType']),
    };
}

export function UpdateObjectionVariantToJSON(value?: UpdateObjectionVariant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'content': value.content,
        'additionalContent': value.additionalContent,
        'isDefault': value.isDefault,
        'sharedWithFirmId': value.sharedWithFirmId,
        'caseTypeId': value.caseTypeId,
        'tagIds': value.tagIds,
        'documentSubType': DocumentSubTypeToJSON(value.documentSubType),
    };
}


