const API_URL: string = (window as any)._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

/**
 * Function to generate a url
 * @param endpoint base url to be used
 * @param params Dictionary of parameters to be appended to the url
 * @returns {string} Constructed url
 */
function getUrl(endpoint: string, params: any): string {
  let url = new URL(API_URL + '/api/' + endpoint);
  if (params) {
    Object.keys(params).forEach((key) => {
      if (params[key] != null) {
        if (Array.isArray(params[key])) {
          params[key].forEach((element: any) => {
            url.searchParams.append(key, element);
          });
        } else {
          url.searchParams.append(key, params[key]);
        }
      }
    });
  }
  return url.toString();
}

const api = {
  API_URL,
  getUrl,
};

export default api;
