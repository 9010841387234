import { Announcement, User } from "briefpoint-client";
import { useAnnouncementApi, useUserApi } from "hooks/useApi";
import { useEffect, useRef, useState } from "react";
import { BellFill } from "react-bootstrap-icons";
import { formatShortDate } from "utils/dateUtils";

interface Props {
  user: User
}

export default function Notifications({ user }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const announcementsApi = useAnnouncementApi();
  const userApi = useUserApi();
  const [announcements, setAnnouncements] = useState<Announcement[]>();
  const [show, setShow] = useState(false);
  const [lastSeen, setLastSeen] = useState<Date | null | undefined>(user.lastViewedAnnouncements);

  useEffect(() => {
    const get = async () => {
      var result = await announcementsApi.announcementGet({});
      setAnnouncements(result);
    };
    get();
  }, [announcementsApi]);

  useEffect(() => {
    const handleClickOutside = (event: { target: any; }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setShow]);

  async function handleClick() {
    setShow(s => !s);

    if (!show) {
      setLastSeen(new Date());
      await userApi.userViewedAnnouncements();
    }
  }

  const highlightBell = user && announcements?.length &&
    (!lastSeen || announcements.find(x => x.start && x.start > lastSeen));

  return (
    <div ref={ref} className="notifications">
      <span className={`nav-link ${highlightBell ? 'highlight' : ''}`} onClick={handleClick}><BellFill /></span>
      <div className={`content ${show ? 'show' : ''}`}>
        {!!announcements?.length ?
          <>
            <h3>Updates</h3>
            {announcements?.map(AnnouncementLine)}
          </>
          :
          <div className="item blank">
            <h4>You're Up-to-date!</h4>
            <p className="text">No new updates or notifications.</p>
          </div>
        }
      </div>
    </div>
  );
}

function AnnouncementLine(announcement: Announcement) {
  return (
    <div className="item" key={announcement.id}>
      <p className="date">{formatShortDate(announcement.start!)}</p>
      <h4>{announcement.title}</h4>
      <p className="text">{announcement.content}{announcement.link &&
        <> <a href={announcement.link} rel="noreferrer" target="_blank">Learn more</a></>}
      </p>
    </div>
  );
}