import { ContentGetMiscContentFilteredRequest, DocumentType, DocumentSubType, Jurisdiction, MiscContent, MiscContentType, PropoundingParty, Role, User } from 'briefpoint-client';
import Loading from 'components/Loading';
import { useContentApi, useTagApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { BriefcaseFill, ChevronDown, ChevronUp, ClipboardPlus, PencilFill, PeopleFill, PersonFill } from 'react-bootstrap-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { getPartyDisplay } from 'screens/DocumentWizard/SpecialInterrogatoriesStep/Selections/utils';
import { classes } from 'utils';
import './QuestionsTable.scss'
import { DropDownFilterList, DropDownRadio, getCaseTypeDisplay, getSortedCases, getSortedTags, getTagsDisplay } from 'components/DropDownFilters';
import { ReactComponent as ReactLogo } from '../../images/logo.svg';
import { useSessionStorage } from 'usehooks-ts';
const NoCaseText = "All";

interface Props {
  questions: MiscContent[];
  onUpdate: (update: MiscContent) => Promise<MiscContent>;
  users: Map<string, User>;
}

const subTypes = {
  1: 'Interrogatories',
  2: 'Requests for Production',
  3: 'Requests for Admission',
};

export function tagsList(tagIds: number[] | null | undefined, availableTags?: Map<number, string>, questionId?: string, startTrimingTagsAfter = 3) {
  if (!tagIds?.length) {
    return null;
  }

  if (tagIds.length > startTrimingTagsAfter) {
    const tagParens = new RegExp(/\(([A-Z]*)\)/);
    return (tagIds?.map(t => {
      const tag = availableTags && availableTags.get(t);
      const match = tag && tagParens.exec(tag);

      return (<span key={`${questionId}-tag-${t}`} className='tag-pill'>{match ? match[1] : tag}</span>);
    }))
  }

  return (tagIds?.map(t => { return (<span key={`${questionId}-tag-${t}`} className='tag-pill'>{availableTags && availableTags.get(t)}</span>) }))
}

export function getTagsKeys(tagIds: number[] | null | undefined, availableTags?: Map<number, string>) {
  if (!tagIds?.length) {
    return null;
  }

  return (tagIds?.map(t => availableTags ? availableTags.get(t) : '').join('-'));
}

export function caseTypeDisplay(caseTypeId: string | null | undefined, jurisdiction: Jurisdiction | undefined, includeClass: boolean, noCaseText = NoCaseText) {
  if (!caseTypeId) {
    return noCaseText;
  }
  let typeClass = jurisdiction?.caseClasses?.find(x => x.id === caseTypeId);

  if (typeClass) {
    return typeClass.name;
  }

  typeClass = jurisdiction?.caseClasses?.find(x => x.subTypes?.find(s => s.id === caseTypeId));

  if (!typeClass) {
    return "Unknown";
  }

  let type = typeClass.subTypes?.find(x => x.id === caseTypeId);

  return `${(includeClass && `${typeClass.name} - `)}${type?.shortName}`;
}

export default function QuestionsTable({ questions, onUpdate, users }: Props) {
  const { user } = useAuth()!;
  const location = useLocation();
  const tab = new URLSearchParams(location.search).get('document');
  const [nextSubType, setNextSubType] = useState<DocumentSubType | undefined>();
  const [selectedQuestionSubType, setSelectedQuestionSubType] = useState<DocumentSubType>(tab ? Number(tab) : 1);
  const [availableTags, setAvailableTags] = useState<Map<number, string> | undefined>();
  const [showDefaults, setShowDefaults] = useSessionStorage("library-show-bp-defaults", false);
  const [showQuestions, setShowQuestions] = useState<string[]>([]);
  const [expandAll, setExpandAll] = useState(false);
  const [defaultQuestions, setDefaultQuestions] = useState<MiscContent[]>();
  const tagApi = useTagApi();
  const contentApi = useContentApi();
  const { jurisdictions } = useAuth()!;
  const ca_superior_court = (jurisdictions ?? [])[0];
  const history = useHistory();
  const [tagFilter, setTagFilter] = useState<any[]>([]);
  const [caseFilter, setCaseFilter] = useState<any[]>([]);
  const [classFilter, setClassFilter] = useState<any[]>(['Any']);
  const [positionFilter, setPositionFilter] = useState<any[]>(['Any']);

  useEffect(() => {
    async function loadTags() {
      const tagsList = new Map<number, string>();

      const loadedTags = await tagApi.tagFindTags({});

      loadedTags.forEach(t => tagsList.set(t.id, t.name));
      setAvailableTags(tagsList);
    };
    loadTags();
  }, [tagApi]);

  useEffect(() => {
    async function loadDefaults() {
      const query: ContentGetMiscContentFilteredRequest = {
        type: MiscContentType.DiscoveryQuestion,
        documentType: DocumentType.DiscoveryRequest,
        documentSubType: selectedQuestionSubType,
        skipTagFiltering: true
      };
      const defaults = await contentApi.contentGetDefaultMiscContentFiltered(query);
      setDefaultQuestions(defaults);
      setShowQuestions([]);
      handleClearEvent();
    };
    loadDefaults();
  }, [contentApi, selectedQuestionSubType]);

  const groupedQuestions = {} as GroupedContent;
  const allQuestions = showDefaults && defaultQuestions ? questions.concat(defaultQuestions) : questions;
  let typeQuestions = allQuestions.filter(x => x.documentSubType === selectedQuestionSubType);

  if (caseFilter.length > 0) {
    typeQuestions = typeQuestions.filter(tq => caseFilter.some(cf => tq.caseTypeId === cf));
  }

  if (tagFilter.length > 0) {
    typeQuestions = typeQuestions.filter(tq => tagFilter.some(tf => tq.tagIds?.some(tgId => tgId === tf)));
  }

  //filter id is set independently of the enums
  if (positionFilter.length > 0) {
    typeQuestions = typeQuestions.filter(tq => positionFilter.some(pf => {
      if (pf === 'Any') {
        return true;
      }
      else if (pf === 'Plaintiff' && tq.respondentType === PropoundingParty.Defendant) {
        return true;
      }
      else if (pf === 'Defendant' && tq.respondentType === PropoundingParty.Plaintiff) {
        return true;
      }
      else {
        return false;
      }
    }));
  }

  if (classFilter.length > 0) {
    typeQuestions = typeQuestions.filter(tq => classFilter.some(cf => {
      if (cf === 'Any') {
        return true;
      }
      else if (cf === 'Individual' && tq.isForRepresentative === false) {
        return true;
      }
      else if (cf === 'Representative' && tq.isForRepresentative === true) {
        return true;
      }
      else {
        return false;
      }
    }));
  }

  typeQuestions.forEach(q => {
    const key = `${caseTypeDisplay(q.caseTypeId, ca_superior_court, true, NoCaseText)}${getTagsKeys(q.tagIds, availableTags)}${q.isForRepresentative !== undefined ? `-${q.isForRepresentative}` : ''}${q.respondentType !== undefined ? `-${q.respondentType}` : ''}${!!q.sharedWithFirmId && '-firm'}${!q.ownerId && '-bp'}`;
    if (!(key in groupedQuestions)) {
      groupedQuestions[key] = [];
    }
    groupedQuestions[key].push(q);
  });

  function handleChangeSelectedSubType(subType: DocumentSubType) {
    handleSetSelectedQuestionSubType(subType);
  }

  function handleSetSelectedQuestionSubType(subType: DocumentSubType) {
    const next = subType ?? nextSubType;
    setSelectedQuestionSubType(next);
    setNextSubType(undefined);
    addQuery("document", subType.toString());
  }

  function handleClearEvent() {
    setTagFilter([]);
    setCaseFilter([]);
    setClassFilter(['Any']);
    setPositionFilter(['Any']);;
  };

  const addQuery = (key: string, value: string) => {
    let pathname = location.pathname;
    // returns path: '/app/books'
    let searchParams = new URLSearchParams(location.search);
    // returns the existing query string: '?type=fiction&author=fahid'
    searchParams.set(key, value);
    history.replace({
      pathname: pathname,
      search: searchParams.toString()
    });
  };

  function addQuestion() {
    history.push({
      pathname: `/library/addeditquestion`,
      search: `?type=${selectedQuestionSubType}`,
    });
  }

  function editQuestion(content: MiscContent[], copy: boolean = false) {
    history.push({
      pathname: `/library/addeditquestion`,
      search: `${copy ? '?copy=true' : ''}`,
      state: content,
    });
  }

  interface GroupedContent {
    [key: string]: MiscContent[]
  }

  function openOrCloseQuestion(key: string) {
    setShowQuestions(q => {
      const update = [...q];

      const existing = update.indexOf(key);
      if (existing > -1) {
        update.splice(existing, 1)
      } else {
        update.push(key);
      }

      return update;
    })
  }

  function handleExpandAllClick() {
    if (expandAll) {
      setExpandAll(false);
      setShowQuestions([]);
    } else {
      setExpandAll(true);
      setShowQuestions(Object.keys(groupedQuestions));
    }
  }

  return (
    <div key='step-container' className="step-container" style={{ gap: 0 }}>
      <div key='docsubtypes' className="border-end" style={{ width: 250 }}>
        {Object.keys(DocumentSubType)
          .map((o) => {
            const i = Number(o);
            const t = subTypes[i as keyof typeof subTypes];

            return t && (
              <div
                key={`docsubtyp-${o}`}
                onClick={() => {
                  handleChangeSelectedSubType(i);
                }}
                className={classes('library-objection', {
                  'library-objection-selected': i === selectedQuestionSubType,
                })}
              >
                {t}
              </div>);
          })}
      </div>
      <div key='content' className="flex-grow-1 ms-5">
        <Loading isLoading={!ca_superior_court || !availableTags}>
          <Row key='filters-row'>
            <Col key='filters-actions' className='col-8 filters'>
              <span className='filter-title'>Filter By:</span>
              {!!caseFilter.length && <label className='filter-label'>Case Type</label>}
              <DropDownFilterList key='case-type-filter' text={!caseFilter.length ? 'Case Type' : getCaseTypeDisplay(caseFilter, ca_superior_court)}
                itemsList={getSortedCases(allQuestions, ca_superior_court, caseFilter)}
                activeFilter={caseFilter}
                updateFilter={setCaseFilter} />
              {!!tagFilter.length && <label className='filter-label'>Tags</label>}
              <DropDownFilterList key='tags-filter' text={!tagFilter.length ? 'Tags' : getTagsDisplay(tagFilter, availableTags)}
                itemsList={getSortedTags(allQuestions, availableTags, tagFilter)}
                activeFilter={tagFilter}
                updateFilter={setTagFilter} />
              {!!(positionFilter.length && positionFilter.some(x => x !== 'Any')) && <label className='filter-label'>Your Position</label>}
              {<DropDownRadio key='position-filter' text={!(positionFilter.length && positionFilter.some(x => x !== 'Any')) ? 'Your Position' : positionFilter[0]}
                itemsList={[{ name: "Any", id: "Any", default: true }, { name: "Plaintiff", id: "Plaintiff" }, { name: "Defendant", id: "Defendant" }]}
                activeFilter={positionFilter}
                updateFilter={setPositionFilter} />}
              {!!(classFilter.length && classFilter.some(x => x !== 'Any')) && <label className='filter-label'>Individual/Class</label>}
              <DropDownRadio key='class-filter' text={!(classFilter.length && classFilter.some(x => x !== 'Any')) ? 'Individual/Class' : classFilter[0]}
                itemsList={[{ name: "Any", id: "Any", default: true }, { name: "Individual", id: "Individual" }, { name: "Representative", id: "Representative" }]}
                activeFilter={classFilter}
                updateFilter={setClassFilter} />
              {(caseFilter.length || tagFilter.length || (positionFilter.length && positionFilter.some(x => x !== 'Any')) || (classFilter.length && classFilter.some(x => x !== 'Any'))) && <Button className='clear-btn' variant='link' onClick={handleClearEvent}>Clear All</Button>}
            </Col>
            <Col key='filters-new' className='col-4'>
              <Form.Check id='include-defaults-check' style={{ fontSize: "14px", display: "inline-block" }} checked={showDefaults} onChange={(e) => setShowDefaults(e.target.checked)} label='Show Briefpoint Library' />
              &nbsp;&nbsp;
              <Button variant="primary" onClick={addQuestion}>
                Create New
              </Button>
            </Col >
          </Row>
          <Row className='mt-2'>
            <Table style={{ width: "100%", maxWidth: "998px", overflow: "auto" }}>
              <thead>
                <tr className="table-header">
                  <th></th>
                  <th className="table-header-cell" scope="">
                    Case Type
                  </th>
                  <th className="table-header-cell" scope="col">
                    Tags
                  </th>
                  <th className="table-header-cell" scope="col">
                    Resp. Position
                  </th>
                  <th className="table-header-cell" scope="col">
                    Representative
                  </th>
                  <th className="table-header-cell" scope="col">
                    # of Questions
                  </th>
                  <th className="table-header-cell" style={{ textAlign: "end" }} scope="col">
                    <Button className='expand-all p-0 m-0' variant='link' onClick={handleExpandAllClick}>{!expandAll ? "Expand All" : "Collapse All"}</Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(groupedQuestions)
                  .sort((a, b) => a.localeCompare(b))
                  .map((k) => {
                    const group = groupedQuestions[k];
                    const question = group[0];
                    return (
                      <>
                        <tr className="table-row-main" key={k}>
                          <td className='question-from'>{question.sharedWithFirmId ? <BriefcaseFill title="Firm Standard" /> : (question.ownerId ? <>&nbsp;</> : <ReactLogo title='Briefpoint Default' />)}</td>
                          <td className="table-cell">{caseTypeDisplay(question.caseTypeId, ca_superior_court, true)}</td>
                          <td className="table-cell">{tagsList(question.tagIds, availableTags, k)}</td>
                          <td className="table-cell">{question.respondentType === undefined ? '' : getPartyDisplay(question.respondentType, false)}</td>
                          <td className="table-cell" style={{ textAlign: "center" }}>{question.isForRepresentative === undefined ? '' : (question.isForRepresentative === true ? <PeopleFill title='Representative' /> : <PersonFill title='Individual' />)}</td>
                          <td className="table-cell" style={{ textAlign: "end" }}>{group.length}</td>
                          <td className="table-cell actions-cell">
                            {(question.ownerId && (user?.role?.includes(Role.FirmAdmin) || !question.sharedWithFirmId)) &&
                              <PencilFill
                                onClick={() => editQuestion(group)}
                                className="table-edit-item"
                                title='Edit'
                              />}
                            <ClipboardPlus
                              onClick={() => editQuestion(group, true)}
                              className="table-edit-item"
                              title='Copy' />
                            {showQuestions.includes(k) ? <ChevronUp title='Hide Questions' onClick={() => openOrCloseQuestion(k)} /> : <ChevronDown title='Show Questions' onClick={() => openOrCloseQuestion(k)} />}
                          </td>
                        </tr>
                        <tr className="table-row" key={`${k}-content`}>
                          {(showQuestions.includes(k)) &&
                            <>
                              <td style={{ width: "15px" }}>&nbsp;</td>
                              <td className="table-cell p-0" colSpan={6}>
                                <ol>
                                  {group.map(q =>
                                    <li key={q.id}><span>{q.content}</span></li>)}
                                </ol>
                              </td>
                            </>}
                        </tr>
                      </>
                    )
                  })}
              </tbody>
            </Table>
          </Row>
        </Loading>
      </div>
    </div>
  );
}
