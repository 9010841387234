import { DocumentSubType, DocumentType, IncludedDocuments, PropoundingParty, ReactionType } from 'briefpoint-client';
import { useCallback, useEffect, useState } from 'react';
import { DocumentClient, transformDocument, GetDocumentSubTypeShortName } from 'services/DocumentService';
import { useDocumentApi, useFormApi } from './useApi';
import useMixpanel from './useMixpanel';


type UseDocumentsType = [
  DocumentClient[] | null,
  () => Promise<void>,
  (files: File[], caseId?: string, isPleadingUpload?: boolean) => Promise<void>,
  (document: DocumentClient) => Promise<void>,
  (document: DocumentClient) => Promise<void>,
  (document: DocumentClient) => Promise<void>,
  (document: DocumentClient, formId: string, formTypeName: string) => Promise<void>,
  (document: DocumentClient, caseId?: string) => Promise<void>,
  (document: DocumentClient) => Promise<void>,
];
export default function useDocuments(loadDocs: boolean = true, caseId?: string): UseDocumentsType {
  const [documents, setDocuments] = useState<DocumentClient[] | null>(null);
  const documentApi = useDocumentApi();
  const formApi = useFormApi();
  const [mixpanelTrack] = useMixpanel()!;

  const loadDocuments = useCallback(async () => {
    const documents = !caseId ? await documentApi.documentGetList({ includedDocuments: IncludedDocuments.NonArchived }) : await documentApi.documentGetCaseDocuments({ caseId, includedDocuments: IncludedDocuments.NonArchived });
    setDocuments(documents.map((d: any) => transformDocument(d)));
  }, [documentApi, caseId]);

  const archiveDocument = useCallback(
    async (doc: DocumentClient) => {
      doc.isArchived = true;
      await documentApi.documentUpdateDocument({ documentId: doc.id, document: doc });
      setDocuments([...documents!]);
    },
    [documentApi, documents]
  );

  const restoreDocument = useCallback(
    async (doc: DocumentClient) => {
      doc.isArchived = false;
      await documentApi.documentUpdateDocument({ documentId: doc.id, document: doc }); // Call update document endpoint
    }, [documentApi]);

  const updateDocument = useCallback(
    async (doc: DocumentClient) => {
      await documentApi.documentUpdateDocument({ documentId: doc.id, document: doc });
      setDocuments([...documents!]);
    },
    [documentApi, documents]
  );

  const reUploadDocument = useCallback(
    async (doc: DocumentClient, caseId?: string) => {
      await documentApi.documentReuploadDocument({ documentId: doc.id, caseId });
    },
    [documentApi]
  );

  const downloadForm = useCallback(
    async (doc: DocumentClient, formId: string, formTypeName: string) => {
      const result = await formApi.formDownloadFormDataWithDoc({ formId: formId, caseId: doc.caseId ?? '', documentId: doc.id });
      //save result to memory
      const url = window.URL.createObjectURL(result);
      //create a new a tag so we can set the download prptery = filename
      //this is work around because navigating directly to the url gives a guid as the filename
      const link = window.document.createElement('a');
      link.href = url;
      link.download = `${doc.info?.respondingPartyName} Response to ${doc.info?.propoundingPartyName}'s ${GetDocumentSubTypeShortName(doc.info?.documentSubType ?? 0, doc.info?.jurisdiction ?? '')} - Set ${doc.info?.setNumber ?? 1} ${formTypeName}.docx`;
      link.click();

      //free memory of object
      window.URL.revokeObjectURL(url);
    },
    [formApi]
  );

  const downloadRequest = useCallback(
    async (doc: DocumentClient) => {
      const result = await documentApi.documentGenerateDocumentDownload({ documentId: doc.id });
      //save result to memory
      const url = window.URL.createObjectURL(result);
      //create a new a tag so we can set the download prptery = filename
      //this is work around because navigating directly to the url gives a guid as the filename
      const link = window.document.createElement('a');
      link.href = url;
      link.download = `${doc.info?.propoundingPartyName} ${GetDocumentSubTypeShortName(doc.info?.documentSubType ?? 0, doc.info?.jurisdiction ?? '')} to ${doc.info?.respondingPartyName} - Set ${doc.info?.setNumber ?? 1}.docx`;
      link.click();

      //free memory of object
      window.URL.revokeObjectURL(url);
    },
    [documentApi]
  );

  async function uploadDocuments(files: File[], caseId?: string, isPleadingUpload?: boolean) {
    try {
      const uploaded = await documentApi.documentPost({ files, caseId, ...(isPleadingUpload ? { docSubType: DocumentSubType.Complaint, docType: DocumentType.Pleading } : {})
      });
      if (uploaded && documents) {
        uploaded.forEach((doc) => mixpanelTrack("DocumentUpload", { "DocumentName": doc.name, "DocumentId": doc.id }));
        setDocuments(documents.concat(uploaded.map((d: any) => transformDocument(d))));
      }
    } catch (error) { }
  }

  useEffect(() => {
    if (loadDocs) loadDocuments();
  }, [loadDocuments, loadDocs]);

  return [documents, loadDocuments, uploadDocuments, archiveDocument, restoreDocument, updateDocument, downloadForm, reUploadDocument, downloadRequest];
}

const isPowerOfTwo = (x: number): boolean => {
  return x !== 0 && (x & (x - 1)) === 0;
};

function getEnumFlags<
  O extends object,
  K extends O[keyof O] = O[keyof O]
>(obj: O): K[] {
  const isFlag = (arg: string | number | K): arg is K => {
    const nArg = Number(arg);
    const isNumber = !Number.isNaN(nArg);
    return isNumber && isPowerOfTwo(nArg);
  };

  const enumFlags: K[] = [];

  Object.keys(obj).forEach(key => {
    const nKey = Number(key);
    if (isFlag(nKey)) {
      enumFlags.push(nKey);
    }
  });

  return enumFlags;
}

export function GetEnumValues(values: number) {
  const flags = getEnumFlags(PropoundingParty); //should be able to get this to be generic, but idk how to typescript

  const enums: string[] = [];

  flags.forEach(ef => {
    if ((ef & values) !== 0) {
      enums.push(PropoundingParty[ef]);
    }
  })

  return enums;
}

export function GetReactionTypeEnumValues(values: number) {
  const flags = getEnumFlags(ReactionType); //should be able to get this to be generic, but idk how to typescript

  const enums: string[] = [];

  flags.forEach(ef => {
    if ((ef & values) !== 0) {
      enums.push(ReactionType[ef]);
    }
  })

  return enums;
}
