import Loading from 'components/Loading';
import useDocumentDetail, { useDocumentFormInterrogatories } from 'hooks/useDocumentDetail';
import { Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import FormInterrogatoriesSelect from './FormInterrogatoriesSelect';
import { FormIntLi } from './FormInterrogatoriesList';
import { useEffect, useState } from 'react';

export default function UpdateFormInterrogatories() {
  const { documentId } = useParams<{ documentId: string }>();
  const [document, , , , , , , ,clearMissingFormInterrogatory] = useDocumentDetail(documentId);
  const history = useHistory();
  const formInterrogatories = useDocumentFormInterrogatories(document?.info?.documentSubType);

  const [formIntData, setFormIntData] = useState<FormIntLi[]>([]);

  //set selected and highlighted values for form int data 
  useEffect(() => {
    const relevantQuestions = (formInterrogatories?.filter(frog => frog.form === document?.info?.formIntType) ?? []).map(rq => {
      let idx = document?.interrogatories?.findIndex(rog => rog.text === rq.id)
      if (idx !== -1) {
        (rq as FormIntLi).selected = true;
      }
      idx = document?.info?.missingFormInts?.findIndex(mFint => mFint === rq.id)
      if (idx !== -1 && document?.info?.missingFormInts) {
        (rq as FormIntLi).highlighted = true;
      }
      return rq;
    });
    setFormIntData(relevantQuestions);
  }, [document, formInterrogatories, setFormIntData]);

  //clear missing form ints if no highlighted values remain
  useEffect(() => {
    if(formIntData.filter(q => (q as FormIntLi).highlighted).length <=0 && formIntData.length > 0){
      async function clearMissingFrogs() {
        await clearMissingFormInterrogatory();
      }
      clearMissingFrogs();
    }
  }, [clearMissingFormInterrogatory, formIntData]);

  //go to document wizzard if no missing form ints
  useEffect(() => {
    if(document && (document?.info?.missingFormInts?.length ?? 0) <= 0){
      history.push(`/wizard/${document?.id}/0`);
    }
  }, [document, history]);

  return (
    <Loading className="mt-3" isLoading={!document}>
      <Container className="page-container">
        <FormInterrogatoriesSelect 
          formIntData={formIntData}
          setFormIntData={setFormIntData}
        />
      </Container>
    </Loading>
  );
}
