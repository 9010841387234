import ArchiveDocumentList from 'components/DocumentList/ArchiveDocumentList'
import useDocuments from 'hooks/useDocuments';
import { useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import '../../screens/DocumentList.scss'
import { Link } from "react-router-dom";

export default function ArchiveListScreen() {
  const [error] = useState('');
  const [, , , , restoreDocument] = useDocuments(false);

  return (
    <Container className="page-container">
      {error && <Alert variant="danger">{error}</Alert>}
      <p><Link to='/documents'>Documents</Link> {'>'} Archived</p>
      <header className="d-flex justify-content-between mb-3">
        <h1>Archived Document List</h1>
      </header>
      <div>
        <ArchiveDocumentList restoreDocument={restoreDocument} />
      </div>
    </Container>
  );
}