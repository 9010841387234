import { Modal } from 'components/Modals';
import uploadImage from '../../images/tutorial-upload-doc.png';
import getStarted from '../../images/tutorial-get-started.png';
import objectRespond from '../../images/tutorial-object-respond.png';
import download from '../../images/tutorial-download.png';
import { Button, Carousel } from 'react-bootstrap';
import { useState } from 'react';

interface Props {
  isShow: boolean;
  supportsFrogs: boolean;
  onClose: () => void;
  fileUpload: (e: React.MouseEvent) => void;
}

export default function IntroTutorialModal({ isShow, supportsFrogs, onClose, fileUpload }: Props) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  const handleUploadClick = (e: React.MouseEvent) => {
    fileUpload(e);
    onClose();
  }
  function modalContent() {
    return (
      <>
        <Carousel className='tutorial' activeIndex={index} onSelect={handleSelect} interval={null} controls={false} indicators={false} wrap={false}>
          <Carousel.Item>
            <h3>Welcome to Briefpoint!</h3>
            <p className='lead'>Briefpoint takes your opposing counsel’s discovery request PDFs and<br /> turns them into servable responses within minutes.</p>
            <p className='lead'>You’ll start by uploading your own document or use our sample for a test run.</p>
            <img
              className="d-block"
              src={uploadImage}
              alt="First slide"
              height={222}
            />
          </Carousel.Item>
          <Carousel.Item>
            <h3>Next, Confirm Details</h3>
            <p className='lead'>This process allows you to verify some of the information that's been extracted.</p>
            <img
              className="d-block roundedimg"
              src={getStarted}
              alt="Second slide"
              height={298}
            />
          </Carousel.Item>
          <Carousel.Item>
            <h3>Object and Respond</h3>
            <p className='lead'>Next, you’ll respond to your discovery request by selecting the objections and responses you’d like to add.</p>
            <img
              className="d-block"
              src={objectRespond}
              alt="Third slide"
              height={179}
            />
            <p className='lead' style={{ marginTop: '20px' }}>Briefpoint provides default content to get you started, however, all content can be customized and made your own - and we encourage you to do so.</p>
          </Carousel.Item>
          <Carousel.Item>
            <h3>Download, Finalize and Serve!</h3>
            <p className='lead'>Once you’re satisfied with your response document, click “Open in Word”, and<br /> a .docx file of your response will be downloaded to your computer.</p>
            <img
              className="d-block"
              src={download}
              alt="Fourth slide"
              height={277}
            />
          </Carousel.Item>
          <Carousel.Item>
            <h3>Let’s get the ball rolling!</h3>
            <p className='lead'>Get started by uploading your first document.</p>
            <p>
              Briefpoint currently accepts the following state court documents:
            </p>
            <ul style={{ paddingLeft: '70px', paddingRight: '60px' }}>
              <li>Requests for Admission</li>
              <li>Requests for Production</li>
              <li>{supportsFrogs && 'Special '}Interrogatories</li>
              {supportsFrogs &&
                <li>Form Interrogatories</li>}
              <li>Notices of Depositions with RFPs Attached</li>
              {supportsFrogs &&
                <li>Third Party Depo Subpoenas (SUBP-010)</li>}
            </ul>
            <Button onClick={(e) => handleUploadClick(e)} style={{ marginTop: '4px' }}>Upload Document</Button>
            <p className='lead' style={{ marginTop: '20px', marginBottom: '0px' }}>Want to schedule a live Briefpoint demo or training session?</p>
            <p>You can use the following calendar link to find the best time for you and/or your team to see a demo of Briefpoint or get trained on the system: <a href='https://calendly.com/briefpointceo/demo' target='_blank' rel='noreferrer' title='Schedule Demo/Training'>Briefpoint Demo/Training Calendar</a>.</p>
          </Carousel.Item>
        </Carousel>
        <div className='d-flex justify-content-between align-items-center' style={{ marginTop: '20px' }}>
          <span>{index + 1} of 5</span>
          <div>
            {index > 0 && <Button variant="outline-primary" onClick={() => handleSelect(index - 1)}>
              Back
            </Button>}
            {index < 4 &&
              <Button onClick={() => handleSelect(index + 1)} style={{ marginLeft: '5px' }}>
                Next
              </Button>}
          </div>
        </div>
        <div style={{ marginTop: '10px', marginBottom: '-20px', textAlign: 'center', fontSize: 'smaller', fontWeight: 'bold' }}>Briefpoint access limited to licensed attorneys and their agents.<br />All content available on Briefpoint is strictly illustrative and does not constitute legal advice.</div>
      </>
    );
  }

  return (
    <Modal
      show={isShow}
      onClose={onClose}
      onConfirm={onClose}
      size="lg"
      showCancelButton={false}
      showConfirmButton={false}
      confirmText={'Get Started!'}
      preventBackgroundClick
    >
      {modalContent()}
    </Modal>
  );
}
