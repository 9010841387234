import Loading from 'components/Loading';
import { useEffect, useMemo, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { InfoCircle, TrashFill } from 'react-bootstrap-icons';
import { DocumentSubType, DocumentType, MiscContent, MiscContentType, PropoundingParty, Role } from 'briefpoint-client';
import { useAuth } from 'hooks/useAuth';
import ReactSelect, { OptionsType } from 'react-select';
import { useTagApi } from 'hooks/useApi';
import { TagOption } from 'components/Select/TagsSelect';
import useMiscContent from 'hooks/useMiscContent';
import { caseTypeDisplay } from './QuestionsTable';
import { GetDocumentSubTypeName } from 'services/DocumentService';
import Button from 'components/Button';
import SelectCreate from 'components/Select/TagsSelectCreate';
// import useConfig, { RICHTEXT_FF } from 'hooks/useConfig';
import CustomEditor from 'components/Editor/CustomEditor';

function sortMiscContentByOrder(a: MiscContent, b: MiscContent) {
  const aOrder = a.order;
  const bOrder = b.order;

  if (aOrder && !bOrder) {
    return -1;
  }

  if (bOrder && !aOrder) {
    return 1;
  }

  if (!bOrder && !aOrder) {
    return 0;
  }

  return aOrder! - bOrder!;
}

export default function AddEditQuestion() {
  const location = useLocation();
  const initialType = new URLSearchParams(location.search).get('type');
  const isCopying = new URLSearchParams(location.search).get('copy');
  const [, , , , , updateMiscContent, , createMiscContentMultiple] = useMiscContent();
  const incoming = location.state as MiscContent[];
  const { user } = useAuth()!;
  const [isDirty, setIsDirty] = useState<Set<number>>(new Set<number>());
  const [saving, setSaving] = useState(false);
  const [questions, setQuestions] = useState<MiscContent[]>();
  const [availableTags, setAvailableTags] = useState<Map<number, string> | undefined>();
  const [addingTags, setAddingTags] = useState(new Map<number, string>());
  const { jurisdictions } = useAuth()!;
  const tagApi = useTagApi();
  // const [, featureFlags] = useConfig();

  // const useRichText = featureFlags()[RICHTEXT_FF] ?? false;
  // rich text doesn't play well with requests for now
  const useRichText = false;
  const ca_superior_court = (jurisdictions ?? [])[0];

  const history = useHistory();
  const documentSubType = initialType ? Number(initialType) as DocumentSubType : (incoming ? incoming[0].documentSubType : undefined);

  const caseType = questions && questions[0] && questions[0].caseTypeId;
  const position = questions && questions[0] && questions[0].respondentType;
  const isRepresentative = questions && questions[0] && questions[0].isForRepresentative;
  const canChange = !incoming?.length || isCopying;

  useEffect(() => {
    async function initQuestions() {
      if (!incoming?.length) {
        setQuestions([{ documentType: DocumentType.DiscoveryRequest, documentSubType: Number(initialType), type: MiscContentType.DiscoveryQuestion }]);
      } else {
        if (isCopying) {
          const copied = [...incoming ?? []];
          copied.forEach(x => { x.id = undefined; x.ownerId = null; x.sharedWithFirmId = undefined; });
          setQuestions(copied.sort(sortMiscContentByOrder));
        } else {
          setQuestions(incoming.sort(sortMiscContentByOrder));
        }
      }
    };
    if (!questions) {
      initQuestions();
    }
  }, [initialType, questions, isCopying, incoming]);

  useEffect(() => {
    async function loadTags() {
      const tagsList = new Map<number, string>();

      const loadedTags = await tagApi.tagFindTags({});

      loadedTags.forEach(t => tagsList.set(t.id, t.name));
      setAvailableTags(tagsList);
    };
    loadTags();
  }, [tagApi]);

  async function onSave() {
    setSaving(true);
    const addedTags = new Map<number, number>();
    const tagsToAdd = Array.from(addingTags.entries());
    for (let i = 0; i < tagsToAdd.length; i++) {
      const t = tagsToAdd[i];
      const newTag = await tagApi.tagCreateTag({ tagCreateRequest: { tagName: t[1] } });
      if (newTag) {
        addedTags.set(t[0], newTag.id);
      }
      setAvailableTags(q => {
        const update = q ? new Map([...q]) : new Map<number, string>();
        update.set(newTag.id, newTag.name);

        return update;
      });
    }
    setAddingTags(new Map<number, string>());
    const toAdd: MiscContent[] = [];
    for (let i = 0; i < questions!.length; i++) {
      const element = questions![i];
      if (!element.content) {
        continue;
      }
      addedTags.forEach((_new, old) => {
        const added = element.tagIds?.findIndex(x => x === old);

        if (added !== undefined && added > -1) {
          element.tagIds![added] = _new;
        }
      });

      if (element.id && (element.isDeleted || isDirty.has(i) || isDirty.has(-1))) {
        await updateMiscContent(element);
      } else {
        toAdd.push(element);
      }
    }

    if (toAdd.length) {
      await createMiscContentMultiple(toAdd);
    }

    setIsDirty(new Set<number>());
    setSaving(false);
    history.goBack();
  }

  const transformedOptions = useMemo(() => {
    const opts: any[] = []

    opts.push({ value: null, label: 'All' });
    ca_superior_court?.caseClasses?.forEach(c => {
      opts.push({ value: c.id, label: c.name });
      c.subTypes?.forEach(s => {
        opts.push({ value: s.id, label: `└${s.shortName}` });
      });
    });

    return opts;
  }, [ca_superior_court]);

  function handleAddNew() {
    const lastQuestion = questions!.sort(sortMiscContentByOrder).at(-1);
    const toAdd: MiscContent = {
      type: MiscContentType.DiscoveryQuestion,
      documentType: DocumentType.DiscoveryRequest,
      documentSubType: lastQuestion?.documentSubType,
      tagIds: lastQuestion?.tagIds,
      caseTypeId: caseType,
      isForRepresentative: isRepresentative,
      respondentType: position,
      order: (lastQuestion?.order ?? 0) + 1
    };
    setQuestions(q => {
      const update = [...q ?? []];
      update.push(toAdd);
      return update;
    });
  }

  function addToIsDirty(index: number) {
    setIsDirty(d => {
      const update = new Set<number>(d);

      update.add(index);

      return update;
    });
  }

  function handleChangeType(value?: string) {
    setQuestions(qs => {
      const update = [...qs!];
      update.forEach(x => x.caseTypeId = value);
      return update;
    });
    addToIsDirty(-1)
  }

  function handleChangeContent(index: number, value: string) {
    setQuestions(qs => {
      const update = [...qs!];
      update[index].content = value;
      return update;
    });
    addToIsDirty(index);
  }

  function handleChangeTags(index: number, value: OptionsType<TagOption>) {
    let updatedQuestions: MiscContent[];
    setQuestions(qs => {
      updatedQuestions = [...qs!];
      updatedQuestions[index].tagIds = value?.map(x => x.value!) ?? [];
      return updatedQuestions;
    });

    setAddingTags(q => {
      const update = new Map([...q].filter(a => updatedQuestions.find(v => v.tagIds?.includes(a[0]))));

      return update;
    });
    addToIsDirty(index);
  }

  function handleChangeShared(index: number, value: boolean) {
    setQuestions(qs => {
      const update = [...qs!];
      update[index].sharedWithFirmId = value ? user?.firmId : undefined;
      return update;
    });
    addToIsDirty(index);
  }

  function handleRemoveIem(index: number) {
    setQuestions(qs => {
      const update = [...qs!];
      const item = update[index];
      if (item.id) {
        item.isDeleted = true;
      } else {
        update.splice(index, 1);
      }
      return update;
    });
  }

  function handleRestoreItem(index: number) {
    setQuestions(qs => {
      const update = [...qs!];
      const item = update[index];
      item.isDeleted = false;
      return update;
    });
  }

  function handlePositionChange(value?: PropoundingParty) {
    setQuestions(qs => {
      const update = [...qs!];
      update.forEach(x => x.respondentType = value);
      return update;
    });
    addToIsDirty(-1);
  }

  function handleRepresentativeChange(value?: boolean) {
    setQuestions(qs => {
      const update = [...qs!];
      update.forEach(x => x.isForRepresentative = value);
      return update;
    });
    addToIsDirty(-1);
  }

  if (!documentSubType) {
    history.push({
      pathname: '/library',
      search: `?type=requests`
    });
    return null;
  }

  return (
    <>
      <Prompt
        when={!!isDirty.size}
        message={() =>
          `You have unsaved changes, continue and lose them?`
        }
      />
      <Container className="page-container library-container">
        <Loading isLoading={!ca_superior_court}>

          <div className="mb-4 bread-crumbs">
            <Link to={'/library'}>Library</Link>{' > '}<Link to={'/library?type=requests'}>Discovery Requests</Link>{' > '}<Link to={`/library?type=requests&document=${documentSubType}`}>{GetDocumentSubTypeName(documentSubType, '')}</Link>{' > '}
            {!incoming?.length ? 'Create' : (isCopying ? 'Copy' : 'Edit')}
          </div>
          <h2>
            {!incoming?.length ? 'Add' : (isCopying ? 'Copy' : 'Edit')} {GetDocumentSubTypeName(documentSubType, '')}
          </h2>
          <div>
            <label htmlFor={`type-select`} className='form-label'>Case Type (optional)</label>
            <ReactSelect
              onChange={(e) => handleChangeType(e?.value ?? undefined)}
              id={`type-select`}
              className='question-select'
              isDisabled={!canChange}
              options={transformedOptions}
              value={{ value: caseType, label: caseTypeDisplay(caseType, ca_superior_court, true) }} />
            <label className='form-label'>Respondent Position</label>
            <Form.Group id='position-select'>
              <Form.Check inline disabled={!canChange} type='radio' id='position-select-any' label='Any' value={undefined} checked={position === undefined} onChange={() => handlePositionChange(undefined)} />
              <Form.Check inline disabled={!canChange} type='radio' id='position-select-plaintiff' label='Plaintiff' value={PropoundingParty.Plaintiff} checked={position === PropoundingParty.Plaintiff} onChange={() => handlePositionChange(PropoundingParty.Plaintiff)} />
              <Form.Check inline disabled={!canChange} type='radio' id='position-select-defendant' label='Defendant' value={PropoundingParty.Defendant} checked={position === PropoundingParty.Defendant} onChange={() => handlePositionChange(PropoundingParty.Defendant)} />
            </Form.Group>
            <label className='form-label'>Representative Action</label>
            <Form.Group id='representative-action'>
              <Form.Check inline disabled={!canChange} type='radio' id='representative-select-any' label='Any' value={undefined} checked={isRepresentative === undefined} onChange={() => handleRepresentativeChange(undefined)} />
              <Form.Check inline disabled={!canChange} type='radio' id='representative-select-ind' label='Individual' value={0} checked={isRepresentative === false} onChange={() => handleRepresentativeChange(false)} />
              <Form.Check inline disabled={!canChange} type='radio' id='representative-select-rep' label='Representative (PAGA, Class Action, etc.)' value={1} checked={isRepresentative === true} onChange={() => handleRepresentativeChange(true)} />
            </Form.Group>
          </div>
          {questions?.map((ques, index) => {
            return (
              <div className='mb-3 question-item' key={`content-${index}`}>
                {!ques.isDeleted ? <>
                  <TrashFill
                    onClick={() => handleRemoveIem(index)}
                    className="remove-item"
                    title={!ques.id ? "Remove" : "Delete"}
                  />
                  <Form.Group>
                    <Form.Label>Request</Form.Label>
                    <CustomEditor handleChange={(val) => handleChangeContent(index, val)} editorContent={ques.content ?? ''} showMenu={useRichText} />
                  </Form.Group>
                  {/* Too much text for now, maybe revist <p key={`question-description-${index}`} className='question-description'>{`Included on ${caseTypeDisplay(caseType, ca_superior_court, false)}${ques.isForRepresentative !== undefined ? ` ${ques.isForRepresentative === false ? 'non-' : ''}Representative` : ''} ${DocumentSubTypeDisplay[documentSubType].short}s${ques.respondentType !== undefined ? ` directed at the ${getPartyDisplay(ques.respondentType, false)}` : ''}`}{!!ques.tagIds?.length && <> where the combination of Tags on the Case and Responding party includes {ques.tagIds.map<React.ReactNode>(t => <i>{availableTags?.get(t)}</i>).reduce((prev, curr) => [prev, ' AND ', curr])}</>}.</p> */}
                  <SelectCreate className='question-select' id={`question-tags-${index}`} label="Tags" availableTags={availableTags} addingTags={addingTags} setAddingTags={setAddingTags} selected={ques.tagIds ?? []} onChange={(value) => handleChangeTags(index, value)} />
                  {!!user?.role?.includes(Role.FirmAdmin) && (
                    <>
                      <span className='form-label'>Add to</span>
                      <Form.Group>
                        <Form.Check
                          id="existing-share-with-firm-no"
                          inline
                          type="radio"
                          label="Personal Library"
                          onChange={(e) => handleChangeShared(index, !e.target.checked)}
                          checked={!ques.sharedWithFirmId}
                        />
                        <Form.Check
                          id="existing-share-with-firm-yes"
                          type="radio"
                          inline
                          label="Firm Library"
                          onChange={(e) => handleChangeShared(index, e.target.checked)}
                          checked={!!ques.sharedWithFirmId}
                        />
                      </Form.Group>
                    </>
                  )}</> : <div className='undo-button'>Request will be deleted on Save, this cannot be undone! <Button variant='link' onClick={() => handleRestoreItem(index)}>Undo</Button></div>}
              </div>
            )
          })}
          <Button variant='outline-primary' size='sm' onClick={handleAddNew}>Add Another Question</Button>
          <p className={'placeholder-link'}><InfoCircle className="mr-2" style={{ marginRight: 10 }} /><Link to="/how-to/#using_placeholders" target={'_blank'}>Learn how to customize your content with Placeholders.</Link></p>
          <div className="d-flex mt-5">
            <Button variant="primary" onClick={onSave} loading={saving} text='Saving...'>
              {!isCopying ? 'Save' : 'Copy and Save'}
            </Button>
            <Button variant="link" onClick={() => history.goBack()}>
              Cancel
            </Button>
            {/* {question && isDeletable(question, allVariants, user!) && (
              <Button onClick={() => setDeletingVariant(true)} className="delete-button" variant="link">
                Delete
              </Button>
            )} */}
          </div>
        </Loading>
      </Container>
    </>
  );
}
