import { useMemo } from 'react';
import ReactSelect from 'react-select';


export interface CaseTypeOption {
  label: string;
  value: string | null;
  key: string;
}

interface Props {
  options: CaseTypeOption[];
  className?: string;
  selected?: string;
  onChange: (value: string) => void;
}

export default function Select({
  options,
  className,
  selected,
  onChange,
}: Props) {
  const customStyles = useMemo(() => {
    return {
      control: (baseStyles: any, state: any) => ({
        ...baseStyles,
        borderColor: 'rgb(128, 135, 136)',
        boxShadow: !state.isFocused ? '0' : '0 0 0 0.25rem rgba(4, 65, 84, 0.25)',
        "&:hover": {
          borderColor: 'rgb(128, 135, 136)'
        },
        fontSize: '16px'
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        padding: '2px 8px'
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
      }),
      group: (provided: any, state: any) => ({
        ...provided,
        padding: '0px',
      }),
      groupHeading: (provided: any, state: any) => {
        if (!state.data.key) {
          return provided;
        }

        if (!state.id.includes('group-0')) {

          return {
            ...provided,
            fontWeight: '700',
            borderBottom: '1pt solid #cccccc',
            borderTop: '1pt solid #cccccc',
          }
        } else {
          return {
            ...provided,
            fontWeight: '700',
            borderBottom: '1pt solid #cccccc',
          }
        }
      },
    };
  }, []);

  const selectedOption = useMemo(() => {
    return options.find((o) => o.value === selected);
  }, [selected, options]);

  const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    }, {} as Record<K, T[]>);

  const transformedOptions = useMemo(() => {
    const group = groupBy(options, i => i.key);
    const opts = []

    for (let k in group) {
      opts.push({ key: k, options: group[k].map(v => { return { label: v.label, value: v.value }; }) });
    }

    return opts;
  }, [options]);

  const formatGroupLabel = (option: any) => {
    return (
      <div>{option.key}</div>
    );
  };

  const handleChange = (option: any) => {
    onChange(option.value);
  };

  return (
    <ReactSelect
      className='select'
      id="case-type"
      //classNamePrefix="brief-point-select"
      options={transformedOptions}
      styles={customStyles}
      value={selectedOption?.label ? { label: selectedOption?.label, value: selectedOption?.value } : undefined}
      onChange={handleChange}
      formatGroupLabel={formatGroupLabel}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#074f6b',
          primary25: '#efefea',
        },
      })}
      //menuIsOpen={true}
      placeholder='Required please select...'
      menuPlacement='auto'
    ></ReactSelect>
  );
}
