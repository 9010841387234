/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CaseType
 */
export interface CaseType {
    /**
     * 
     * @type {string}
     * @memberof CaseType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CaseType
     */
    fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseType
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CaseType
     */
    caseClassId?: string;
}

export function CaseTypeFromJSON(json: any): CaseType {
    return CaseTypeFromJSONTyped(json, false);
}

export function CaseTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CaseType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'caseClassId': !exists(json, 'caseClassId') ? undefined : json['caseClassId'],
    };
}

export function CaseTypeToJSON(value?: CaseType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fullName': value.fullName,
        'shortName': value.shortName,
        'caseClassId': value.caseClassId,
    };
}


