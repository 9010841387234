/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateDiscoveryRequest,
    CreateDiscoveryRequestFromJSON,
    CreateDiscoveryRequestToJSON,
    Document,
    DocumentFromJSON,
    DocumentToJSON,
    DocumentAccessToken,
    DocumentAccessTokenFromJSON,
    DocumentAccessTokenToJSON,
    DocumentAction,
    DocumentActionFromJSON,
    DocumentActionToJSON,
    DocumentDetail,
    DocumentDetailFromJSON,
    DocumentDetailToJSON,
    DocumentItemPage,
    DocumentItemPageFromJSON,
    DocumentItemPageToJSON,
    DocumentSubType,
    DocumentSubTypeFromJSON,
    DocumentSubTypeToJSON,
    DocumentType,
    DocumentTypeFromJSON,
    DocumentTypeToJSON,
    ExternalFile,
    ExternalFileFromJSON,
    ExternalFileToJSON,
    GeneratedInsightBase,
    GeneratedInsightBaseFromJSON,
    GeneratedInsightBaseToJSON,
    IncludedDocuments,
    IncludedDocumentsFromJSON,
    IncludedDocumentsToJSON,
    Languages,
    LanguagesFromJSON,
    LanguagesToJSON,
    ParsePayload,
    ParsePayloadFromJSON,
    ParsePayloadToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    Source,
    SourceFromJSON,
    SourceToJSON,
    UpdateInterrogatory,
    UpdateInterrogatoryFromJSON,
    UpdateInterrogatoryToJSON,
} from '../models';

export interface DocumentAddInterrogatoryRequest {
    documentId: string;
    updateInterrogatory?: UpdateInterrogatory;
}

export interface DocumentClearMissingFormInterrogatoryRequest {
    documentId: string;
}

export interface DocumentCreateDiscoveryRequestRequest {
    createDiscoveryRequest?: CreateDiscoveryRequest;
}

export interface DocumentDeleteInterrogatoryRequest {
    documentId: string;
    interrogatoryId: number;
}

export interface DocumentDismissAlertRequest {
    documentId: string;
}

export interface DocumentDismissMismatchWarningRequest {
    documentId: string;
}

export interface DocumentExternalFilesRequest {
    externalFile: Array<ExternalFile>;
    caseId?: string;
    clientId?: string;
}

export interface DocumentGenerateAccessTokenRequest {
    documentId: string;
}

export interface DocumentGenerateDocumentRequest {
    documentId: string;
    upload?: boolean;
    uploadLocation?: string;
}

export interface DocumentGenerateDocumentDownloadRequest {
    documentId: string;
}

export interface DocumentGenerateDocumentFromAccessTokenRequest {
    documentId?: string;
    tokenId?: string;
    upload?: boolean;
}

export interface DocumentGetRequest {
    documentId: string;
}

export interface DocumentGetAllPagedRequest {
    itemsPerPage?: number;
    pageNumber?: number;
    source?: Source;
}

export interface DocumentGetCaseDocumentsRequest {
    caseId: string;
    includedDocuments?: IncludedDocuments;
    source?: Source;
}

export interface DocumentGetListRequest {
    userId?: string;
    includedDocuments?: IncludedDocuments;
    source?: Source;
    parentDocumentId?: string;
}

export interface DocumentGetPdfUrlRequest {
    documentId: string;
}

export interface DocumentGetSuggestionsRequest {
    documentId: string;
}

export interface DocumentGetUsageCountRequest {
    userId: string;
}

export interface DocumentParseRequest {
    parsePayload?: ParsePayload;
}

export interface DocumentPostRequest {
    caseId?: string;
    docType?: DocumentType;
    docSubType?: DocumentSubType;
    files?: Array<Blob>;
}

export interface DocumentPutDiscoveryRequestRequest {
    documentId: string;
    createDiscoveryRequest?: CreateDiscoveryRequest;
}

export interface DocumentRateObjectionTextRequest {
    documentId: string;
    interrogatoryId: number;
    objectionId: number;
    body?: number;
}

export interface DocumentReuploadDocumentRequest {
    documentId: string;
    caseId?: string;
}

export interface DocumentStartGenerateRequestsRequest {
    createDiscoveryRequest?: CreateDiscoveryRequest;
}

export interface DocumentSuggestObjectionTextRequest {
    documentId: string;
    interrogatoryId: number;
    objectionId: number;
}

export interface DocumentSuggestObjectionsForInterrogatoryRequest {
    documentId: string;
    interrogatoryId: number;
}

export interface DocumentTranslateToPlainRequest {
    documentId: string;
    interrogatoryId: number;
    language?: Languages;
}

export interface DocumentTriggerActionRequest {
    documentId: string;
    todo: DocumentAction;
}

export interface DocumentTriggerParseRequest {
    documentId: string;
    startAt?: number;
    endAt?: number;
}

export interface DocumentUpdateDocumentRequest {
    documentId: string;
    document?: Document;
}

export interface DocumentUpdateDocumentViewedRequest {
    documentId: string;
}

export interface DocumentUpdateInterrogatoryRequest {
    documentId: string;
    interrogatoryId: number;
    updateInterrogatory?: UpdateInterrogatory;
}

/**
 * 
 */
export class DocumentApi extends runtime.BaseAPI {

    /**
     */
    async documentAddInterrogatoryRaw(requestParameters: DocumentAddInterrogatoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentAddInterrogatory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/interrogatory`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInterrogatoryToJSON(requestParameters.updateInterrogatory),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentAddInterrogatory(requestParameters: DocumentAddInterrogatoryRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentAddInterrogatoryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentClearMissingFormInterrogatoryRaw(requestParameters: DocumentClearMissingFormInterrogatoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentClearMissingFormInterrogatory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/interrogatory/ClearMissingFormInterrogatory`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentClearMissingFormInterrogatory(requestParameters: DocumentClearMissingFormInterrogatoryRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentClearMissingFormInterrogatoryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentCreateDiscoveryRequestRaw(requestParameters: DocumentCreateDiscoveryRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DocumentDetail>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/DiscoveryRequest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDiscoveryRequestToJSON(requestParameters.createDiscoveryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentDetailFromJSON(jsonValue));
    }

    /**
     */
    async documentCreateDiscoveryRequest(requestParameters: DocumentCreateDiscoveryRequestRequest, initOverrides?: RequestInit): Promise<DocumentDetail> {
        const response = await this.documentCreateDiscoveryRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentDeleteInterrogatoryRaw(requestParameters: DocumentDeleteInterrogatoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentDeleteInterrogatory.');
        }

        if (requestParameters.interrogatoryId === null || requestParameters.interrogatoryId === undefined) {
            throw new runtime.RequiredError('interrogatoryId','Required parameter requestParameters.interrogatoryId was null or undefined when calling documentDeleteInterrogatory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/interrogatory/{interrogatoryId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"interrogatoryId"}}`, encodeURIComponent(String(requestParameters.interrogatoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentDeleteInterrogatory(requestParameters: DocumentDeleteInterrogatoryRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentDeleteInterrogatoryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentDismissAlertRaw(requestParameters: DocumentDismissAlertRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentDismissAlert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/dismiss-alert`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentDismissAlert(requestParameters: DocumentDismissAlertRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentDismissAlertRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentDismissMismatchWarningRaw(requestParameters: DocumentDismissMismatchWarningRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentDismissMismatchWarning.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/dismiss-mismatch-warning`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentDismissMismatchWarning(requestParameters: DocumentDismissMismatchWarningRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentDismissMismatchWarningRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentExternalFilesRaw(requestParameters: DocumentExternalFilesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.externalFile === null || requestParameters.externalFile === undefined) {
            throw new runtime.RequiredError('externalFile','Required parameter requestParameters.externalFile was null or undefined when calling documentExternalFiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.caseId !== undefined) {
            queryParameters['caseId'] = requestParameters.caseId;
        }

        if (requestParameters.clientId !== undefined) {
            queryParameters['clientId'] = requestParameters.clientId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/external-files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.externalFile.map(ExternalFileToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentExternalFiles(requestParameters: DocumentExternalFilesRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentExternalFilesRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentGenerateAccessTokenRaw(requestParameters: DocumentGenerateAccessTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DocumentAccessToken>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentGenerateAccessToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/generate-access-token`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentAccessTokenFromJSON(jsonValue));
    }

    /**
     */
    async documentGenerateAccessToken(requestParameters: DocumentGenerateAccessTokenRequest, initOverrides?: RequestInit): Promise<DocumentAccessToken> {
        const response = await this.documentGenerateAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentGenerateDocumentRaw(requestParameters: DocumentGenerateDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentGenerateDocument.');
        }

        const queryParameters: any = {};

        if (requestParameters.upload !== undefined) {
            queryParameters['upload'] = requestParameters.upload;
        }

        if (requestParameters.uploadLocation !== undefined) {
            queryParameters['uploadLocation'] = requestParameters.uploadLocation;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/generate-doc`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async documentGenerateDocument(requestParameters: DocumentGenerateDocumentRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.documentGenerateDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentGenerateDocumentDownloadRaw(requestParameters: DocumentGenerateDocumentDownloadRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentGenerateDocumentDownload.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/generate-doc-download`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async documentGenerateDocumentDownload(requestParameters: DocumentGenerateDocumentDownloadRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.documentGenerateDocumentDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentGenerateDocumentFromAccessTokenRaw(requestParameters: DocumentGenerateDocumentFromAccessTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.documentId !== undefined) {
            queryParameters['documentId'] = requestParameters.documentId;
        }

        if (requestParameters.tokenId !== undefined) {
            queryParameters['tokenId'] = requestParameters.tokenId;
        }

        if (requestParameters.upload !== undefined) {
            queryParameters['upload'] = requestParameters.upload;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/generate-doc-accesstoken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async documentGenerateDocumentFromAccessToken(requestParameters: DocumentGenerateDocumentFromAccessTokenRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.documentGenerateDocumentFromAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentGetRaw(requestParameters: DocumentGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DocumentDetail>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentDetailFromJSON(jsonValue));
    }

    /**
     */
    async documentGet(requestParameters: DocumentGetRequest, initOverrides?: RequestInit): Promise<DocumentDetail> {
        const response = await this.documentGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentGetAllPagedRaw(requestParameters: DocumentGetAllPagedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DocumentItemPage>> {
        const queryParameters: any = {};

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/all/paged`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentItemPageFromJSON(jsonValue));
    }

    /**
     */
    async documentGetAllPaged(requestParameters: DocumentGetAllPagedRequest, initOverrides?: RequestInit): Promise<DocumentItemPage> {
        const response = await this.documentGetAllPagedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentGetCaseDocumentsRaw(requestParameters: DocumentGetCaseDocumentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Document>>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling documentGetCaseDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.includedDocuments !== undefined) {
            queryParameters['includedDocuments'] = requestParameters.includedDocuments;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/case/{caseId}`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentFromJSON));
    }

    /**
     */
    async documentGetCaseDocuments(requestParameters: DocumentGetCaseDocumentsRequest, initOverrides?: RequestInit): Promise<Array<Document>> {
        const response = await this.documentGetCaseDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentGetListRaw(requestParameters: DocumentGetListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Document>>> {
        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.includedDocuments !== undefined) {
            queryParameters['includedDocuments'] = requestParameters.includedDocuments;
        }

        if (requestParameters.source !== undefined) {
            queryParameters['source'] = requestParameters.source;
        }

        if (requestParameters.parentDocumentId !== undefined) {
            queryParameters['parentDocumentId'] = requestParameters.parentDocumentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentFromJSON));
    }

    /**
     */
    async documentGetList(requestParameters: DocumentGetListRequest, initOverrides?: RequestInit): Promise<Array<Document>> {
        const response = await this.documentGetListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentGetPdfUrlRaw(requestParameters: DocumentGetPdfUrlRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentGetPdfUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/getpdfurl`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async documentGetPdfUrl(requestParameters: DocumentGetPdfUrlRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.documentGetPdfUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentGetSuggestionsRaw(requestParameters: DocumentGetSuggestionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<GeneratedInsightBase>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentGetSuggestions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/suggestions`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GeneratedInsightBaseFromJSON));
    }

    /**
     */
    async documentGetSuggestions(requestParameters: DocumentGetSuggestionsRequest, initOverrides?: RequestInit): Promise<Array<GeneratedInsightBase>> {
        const response = await this.documentGetSuggestionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentGetUsageCountRaw(requestParameters: DocumentGetUsageCountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling documentGetUsageCount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/usage-count/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async documentGetUsageCount(requestParameters: DocumentGetUsageCountRequest, initOverrides?: RequestInit): Promise<number> {
        const response = await this.documentGetUsageCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentParseRaw(requestParameters: DocumentParseRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/parse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParsePayloadToJSON(requestParameters.parsePayload),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentParse(requestParameters: DocumentParseRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentParseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentPostRaw(requestParameters: DocumentPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Document>>> {
        const queryParameters: any = {};

        if (requestParameters.caseId !== undefined) {
            queryParameters['caseId'] = requestParameters.caseId;
        }

        if (requestParameters.docType !== undefined) {
            queryParameters['docType'] = requestParameters.docType;
        }

        if (requestParameters.docSubType !== undefined) {
            queryParameters['docSubType'] = requestParameters.docSubType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.files) {
            requestParameters.files.forEach((element) => {
                formParams.append('files', element as any);
            })
        }

        const response = await this.request({
            path: `/api/Document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentFromJSON));
    }

    /**
     */
    async documentPost(requestParameters: DocumentPostRequest, initOverrides?: RequestInit): Promise<Array<Document>> {
        const response = await this.documentPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentPutDiscoveryRequestRaw(requestParameters: DocumentPutDiscoveryRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DocumentDetail>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentPutDiscoveryRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/DiscoveryRequest`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDiscoveryRequestToJSON(requestParameters.createDiscoveryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentDetailFromJSON(jsonValue));
    }

    /**
     */
    async documentPutDiscoveryRequest(requestParameters: DocumentPutDiscoveryRequestRequest, initOverrides?: RequestInit): Promise<DocumentDetail> {
        const response = await this.documentPutDiscoveryRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentRateObjectionTextRaw(requestParameters: DocumentRateObjectionTextRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentRateObjectionText.');
        }

        if (requestParameters.interrogatoryId === null || requestParameters.interrogatoryId === undefined) {
            throw new runtime.RequiredError('interrogatoryId','Required parameter requestParameters.interrogatoryId was null or undefined when calling documentRateObjectionText.');
        }

        if (requestParameters.objectionId === null || requestParameters.objectionId === undefined) {
            throw new runtime.RequiredError('objectionId','Required parameter requestParameters.objectionId was null or undefined when calling documentRateObjectionText.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/interrogatory/{interrogatoryId}/suggest-objection-text/{objectionId}/rate`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"interrogatoryId"}}`, encodeURIComponent(String(requestParameters.interrogatoryId))).replace(`{${"objectionId"}}`, encodeURIComponent(String(requestParameters.objectionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentRateObjectionText(requestParameters: DocumentRateObjectionTextRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentRateObjectionTextRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentReuploadDocumentRaw(requestParameters: DocumentReuploadDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentReuploadDocument.');
        }

        const queryParameters: any = {};

        if (requestParameters.caseId !== undefined) {
            queryParameters['caseId'] = requestParameters.caseId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/re-upload`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentReuploadDocument(requestParameters: DocumentReuploadDocumentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentReuploadDocumentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentStartGenerateRequestsRaw(requestParameters: DocumentStartGenerateRequestsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/start-generate-requests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDiscoveryRequestToJSON(requestParameters.createDiscoveryRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentStartGenerateRequests(requestParameters: DocumentStartGenerateRequestsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentStartGenerateRequestsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentSuggestObjectionTextRaw(requestParameters: DocumentSuggestObjectionTextRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentSuggestObjectionText.');
        }

        if (requestParameters.interrogatoryId === null || requestParameters.interrogatoryId === undefined) {
            throw new runtime.RequiredError('interrogatoryId','Required parameter requestParameters.interrogatoryId was null or undefined when calling documentSuggestObjectionText.');
        }

        if (requestParameters.objectionId === null || requestParameters.objectionId === undefined) {
            throw new runtime.RequiredError('objectionId','Required parameter requestParameters.objectionId was null or undefined when calling documentSuggestObjectionText.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/interrogatory/{interrogatoryId}/suggest-objection-text/{objectionId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"interrogatoryId"}}`, encodeURIComponent(String(requestParameters.interrogatoryId))).replace(`{${"objectionId"}}`, encodeURIComponent(String(requestParameters.objectionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async documentSuggestObjectionText(requestParameters: DocumentSuggestObjectionTextRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.documentSuggestObjectionTextRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentSuggestObjectionsForInterrogatoryRaw(requestParameters: DocumentSuggestObjectionsForInterrogatoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentSuggestObjectionsForInterrogatory.');
        }

        if (requestParameters.interrogatoryId === null || requestParameters.interrogatoryId === undefined) {
            throw new runtime.RequiredError('interrogatoryId','Required parameter requestParameters.interrogatoryId was null or undefined when calling documentSuggestObjectionsForInterrogatory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/interrogatory/{interrogatoryId}/suggested-objections`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"interrogatoryId"}}`, encodeURIComponent(String(requestParameters.interrogatoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async documentSuggestObjectionsForInterrogatory(requestParameters: DocumentSuggestObjectionsForInterrogatoryRequest, initOverrides?: RequestInit): Promise<Array<number>> {
        const response = await this.documentSuggestObjectionsForInterrogatoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentTranslateToPlainRaw(requestParameters: DocumentTranslateToPlainRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentTranslateToPlain.');
        }

        if (requestParameters.interrogatoryId === null || requestParameters.interrogatoryId === undefined) {
            throw new runtime.RequiredError('interrogatoryId','Required parameter requestParameters.interrogatoryId was null or undefined when calling documentTranslateToPlain.');
        }

        const queryParameters: any = {};

        if (requestParameters.language !== undefined) {
            queryParameters['language'] = requestParameters.language;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/interrogatory/{interrogatoryId}/plain`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"interrogatoryId"}}`, encodeURIComponent(String(requestParameters.interrogatoryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async documentTranslateToPlain(requestParameters: DocumentTranslateToPlainRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.documentTranslateToPlainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async documentTriggerActionRaw(requestParameters: DocumentTriggerActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentTriggerAction.');
        }

        if (requestParameters.todo === null || requestParameters.todo === undefined) {
            throw new runtime.RequiredError('todo','Required parameter requestParameters.todo was null or undefined when calling documentTriggerAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/trigger-action/{todo}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"todo"}}`, encodeURIComponent(String(requestParameters.todo))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentTriggerAction(requestParameters: DocumentTriggerActionRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentTriggerActionRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentTriggerParseRaw(requestParameters: DocumentTriggerParseRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentTriggerParse.');
        }

        const queryParameters: any = {};

        if (requestParameters.startAt !== undefined) {
            queryParameters['startAt'] = requestParameters.startAt;
        }

        if (requestParameters.endAt !== undefined) {
            queryParameters['endAt'] = requestParameters.endAt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/parse`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentTriggerParse(requestParameters: DocumentTriggerParseRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentTriggerParseRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentUpdateDocumentRaw(requestParameters: DocumentUpdateDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentUpdateDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DocumentToJSON(requestParameters.document),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentUpdateDocument(requestParameters: DocumentUpdateDocumentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentUpdateDocumentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentUpdateDocumentViewedRaw(requestParameters: DocumentUpdateDocumentViewedRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentUpdateDocumentViewed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/viewed`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentUpdateDocumentViewed(requestParameters: DocumentUpdateDocumentViewedRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentUpdateDocumentViewedRaw(requestParameters, initOverrides);
    }

    /**
     */
    async documentUpdateInterrogatoryRaw(requestParameters: DocumentUpdateInterrogatoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentUpdateInterrogatory.');
        }

        if (requestParameters.interrogatoryId === null || requestParameters.interrogatoryId === undefined) {
            throw new runtime.RequiredError('interrogatoryId','Required parameter requestParameters.interrogatoryId was null or undefined when calling documentUpdateInterrogatory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Document/{documentId}/interrogatory/{interrogatoryId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))).replace(`{${"interrogatoryId"}}`, encodeURIComponent(String(requestParameters.interrogatoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInterrogatoryToJSON(requestParameters.updateInterrogatory),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async documentUpdateInterrogatory(requestParameters: DocumentUpdateInterrogatoryRequest, initOverrides?: RequestInit): Promise<void> {
        await this.documentUpdateInterrogatoryRaw(requestParameters, initOverrides);
    }

}
