import { Case, Role, User } from 'briefpoint-client';
import Loading from 'components/Loading';
import { useCaseApi, useTagApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import useFirm from 'hooks/useFirm';
import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import './styles.scss';
import AddEditCaseModal from 'components/CaseManagement/AddEditCaseModal';
import CaseTableMulti from 'components/CaseManagement/CaseTableMulti';

export default function Cases() {
  const { user, firm } = useAuth()!;
  const caseApi = useCaseApi();
  const tagApi = useTagApi();
  const [users, , , ,] = useFirm(firm?.id, false);
  const [cases, setCases] = useState<Case[] | undefined>();
  const [search, setSearch] = useState('');
  const [tags, setTags] = useState<Map<number, string> | undefined>();
  const { jurisdictions } = useAuth()!;
  const [showAddCase, setShowAddCase] = useState(false);

  async function AddCase(_case: Case) {
    const newCase = await caseApi.casePost({ firmId: user?.firmId!, _case });
    if (newCase) {
      if (cases) {
        setCases(c => [...c!, newCase]);
      } else {
        setCases([newCase]);
      }
    }
    closeAddCase();
  }

  function closeAddCase() {
    setShowAddCase(false);
  }

  const loadCases = useCallback(async (user: User | undefined) => {
    if (user && user.firmId) {
      const cases = await caseApi.caseGetAll_1({ firmId: user.firmId!, usersCasesOnly: !user?.role?.includes(Role.FirmAdmin) });
      setCases(cases);
    }
  }, [caseApi]);

  useEffect(() => {
    loadCases(user);
  }, [user, loadCases]);

  useEffect(() => {
    async function loadTags() {
      const tagsList = new Map<number, string>();

      const loadedTags = await tagApi.tagFindTags({});

      loadedTags.forEach(t => tagsList.set(t.id, t.name));
      setTags(tagsList);
    };
    if (!tags) {
      loadTags();
    }
  }, [tagApi, tags]);


  function getVenue(_case: Case) {
    const j = jurisdictions?.find(x => x.id === _case.jurisdiction);
    return j?.venues?.find(x => x.id === _case.venue)?.shortName?.toLocaleLowerCase() ?? '';
  }


  const filteredCases = cases?.filter(x => {
    if (!search) return true;

    const title = x.title?.toLocaleLowerCase() ?? "";
    if (title.includes(search.toLowerCase())) {
      return true;
    }
    const shortTitle = x.shortTitle?.toLocaleLowerCase() ?? "";
    if (shortTitle.includes(search.toLowerCase())) {
      return true;
    }
    const caseNum = x.caseNumber?.toLocaleLowerCase() ?? "";
    if (caseNum.includes(search.toLowerCase())) {
      return true;
    }
    const venue = getVenue(x);
    if (venue.includes(search.toLowerCase())) {
      return true;
    }

    return false;
  });

  return (
    <>
      <Container className="page-container-multi">
        <Loading isLoading={cases === undefined && jurisdictions === undefined && users === undefined}>
          <Row>
            <Col>
              <h1 style={{ marginTop: "2rem", marginBottom: "1rem" }}>Cases</h1>
            </Col>
            <Col className='actions-cell' style={{ marginTop: "2rem" }}>
              <AddEditCaseModal availableTags={tags} setAvailableTags={setTags} title='Add Case' confirm='Add' isShow={showAddCase} onClose={() => setShowAddCase(false)} onConfirm={AddCase} users={users} jurisdictions={jurisdictions!} firm={firm!} />
              <Button variant="primary" onClick={() => { setShowAddCase(true) }}>
                Add New Case
              </Button>
            </Col>
          </Row>
          <Row style={{ marginBottom: "1rem" }}>
            <Col xs="4">
              <div id='search-box' className="input-group input-group-search mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">⌕</div>
                </div>
                <Form.Control
                  value={search}
                  className="form-control defense-search-input"
                  placeholder="Search cases"
                  onChange={(e) => setSearch(e.target.value.toLowerCase())}
                />
              </div>
            </Col>
          </Row>
          {((filteredCases?.length ?? 0) > 5) &&
            (
              <div>
                <h2 style={{ margin: '0px', marginBottom: "8px" }}>Recent Cases</h2>
                <CaseTableMulti cases={filteredCases?.slice().sort((a, b) => {
                  if (a.lastActivity && b.lastActivity) {
                    return a.lastActivity > b.lastActivity ? -1 : 1;
                  }
                  else if (a.lastActivity) {
                    return -1;
                  }
                  else if (b.lastActivity) {
                    return 1;
                  }
                  else {
                    return 0;
                  }
                }).slice(0, 5)} addCase={() => { }} users={users} jurisdictions={jurisdictions} showIfEmpty={false} />
                <span>&nbsp;&nbsp;</span>
                <h2 style={{ margin: '0px', marginBottom: "8px" }}>All Cases</h2>
              </div>)}
          {((filteredCases?.length ?? 0) <= 0 && !!search?.length) ?
            (<div>No results found.</div>) :
            (<CaseTableMulti cases={filteredCases?.slice().sort((a, b) => {
              if (a.shortTitle && b.shortTitle) {
                return a.shortTitle.localeCompare(b.shortTitle);
              }
              else if (a.shortTitle) {
                return -1;
              }
              else if (b.shortTitle) {
                return 1;
              }
              else {
                return 0;
              }
            })} addCase={() => { setShowAddCase(true) }} users={users} jurisdictions={jurisdictions} showIfEmpty={true} />
            )

          }
        </Loading>
      </Container>
    </>
  );
}
