import { User, UserType } from "briefpoint-client";
import Loading from "components/Loading";
import { useFirmApi } from "hooks/useApi";
import { OnBehalfOfSessionKey, useAuth } from "hooks/useAuth";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CheckLg } from "react-bootstrap-icons";
import InitialsAvatar from 'react-initials-avatar';
import { useSessionStorage } from "usehooks-ts";
import "./OnBehalfOfSelect.scss";

export default function OnBehalfOfSelect() {
  const { user, firm, baseUser } = useAuth()!;

  const firmApi = useFirmApi();
  const [users, setUsers] = useState<User[] | undefined>();
  const [selected, setSelected] = useState<string | undefined>();
  const [, setOnBehalfOf] = useSessionStorage<string | undefined>(OnBehalfOfSessionKey, undefined);

  useEffect(() => {
    async function load() {
      setUsers(await firmApi.firmGetUsers({ id: firm!.id }));
    }
    if (!users) {
      load();
    }
  }, [firmApi, firm, users]);

  function select(id: string) {
    setSelected(id);

    setTimeout(() => setOnBehalfOf(id), 500);
  }

  return (
    <Container className="page-container">
      <h2>Welcome to Briefpoint, {user?.firstName}</h2>
      {baseUser?.isInactive ?
        <div>Your account has been deactivated, please contact your Firm Admin for more information.</div>
        :
        <>
          <p>Who will you be working on behalf of today?</p>
          <Loading isLoading={!users}>
            <Row>
              {users?.filter(u => !u.isInactive && u.type === UserType.Attorney).map(u => {
                const name = `${u?.firstName} ${u?.lastName}`;
                return (
                  <Col className="col-4 onbehalfof-tile" key={u.id}>
                    <div className="onbehalfof-tile-content" onClick={() => select(u.id!)}>
                      <InitialsAvatar name={name} />
                      {name}
                      {selected === u.id && <span className="selected-icon">
                        <CheckLg />
                      </span>}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Loading>
        </>
      }
    </Container>
  );
}
