/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Definition,
    DefinitionFromJSON,
    DefinitionToJSON,
} from '../models';

export interface DefinitionCreateRequest {
    definition?: Definition;
}

export interface DefinitionGetCaseListRequest {
    caseId: string;
}

export interface DefinitionGetListRequest {
    caseId?: string;
}

export interface DefinitionUpdateRequest {
    definition?: Definition;
}

/**
 * 
 */
export class DefinitionApi extends runtime.BaseAPI {

    /**
     */
    async definitionCreateRaw(requestParameters: DefinitionCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Definition>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Definition`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DefinitionToJSON(requestParameters.definition),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefinitionFromJSON(jsonValue));
    }

    /**
     */
    async definitionCreate(requestParameters: DefinitionCreateRequest, initOverrides?: RequestInit): Promise<Definition> {
        const response = await this.definitionCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async definitionGetAllRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Definition>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Definition/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DefinitionFromJSON));
    }

    /**
     */
    async definitionGetAll(initOverrides?: RequestInit): Promise<Array<Definition>> {
        const response = await this.definitionGetAllRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async definitionGetCaseListRaw(requestParameters: DefinitionGetCaseListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Definition>>> {
        if (requestParameters.caseId === null || requestParameters.caseId === undefined) {
            throw new runtime.RequiredError('caseId','Required parameter requestParameters.caseId was null or undefined when calling definitionGetCaseList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Definition/case/{caseId}`.replace(`{${"caseId"}}`, encodeURIComponent(String(requestParameters.caseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DefinitionFromJSON));
    }

    /**
     */
    async definitionGetCaseList(requestParameters: DefinitionGetCaseListRequest, initOverrides?: RequestInit): Promise<Array<Definition>> {
        const response = await this.definitionGetCaseListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async definitionGetListRaw(requestParameters: DefinitionGetListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Definition>>> {
        const queryParameters: any = {};

        if (requestParameters.caseId !== undefined) {
            queryParameters['caseId'] = requestParameters.caseId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Definition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DefinitionFromJSON));
    }

    /**
     */
    async definitionGetList(requestParameters: DefinitionGetListRequest, initOverrides?: RequestInit): Promise<Array<Definition>> {
        const response = await this.definitionGetListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async definitionUpdateRaw(requestParameters: DefinitionUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Definition`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DefinitionToJSON(requestParameters.definition),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async definitionUpdate(requestParameters: DefinitionUpdateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.definitionUpdateRaw(requestParameters, initOverrides);
    }

}
