import { Form } from "react-bootstrap";
import { PencilFill } from "react-bootstrap-icons";
import { QuestionItem } from "screens/DocumentGeneration/GenerateDiscoveryRequestPage";

interface Props {
  groupIndex: number,
  questionIndex: number,
  questionCount: number,
  checkedQuestionCount: number,
  editQuestionGroup?: number,
  editQuestionNumber?: number,
  editQuestionText?: string,
  setEditQuestionText: React.Dispatch<React.SetStateAction<string>>,
  q: QuestionItem,
  startingNumber: number,
  editActive: boolean,
  highlightDefinitions: (text: string, key: string) => string | React.ReactNode[],
  handleEditingBlur: () => void,
  handleEditingQuestion: (original: string, groupIndex: number, questionIndex: number) => void,
  handleOnKeyDown(e: React.KeyboardEvent): void
  handleMouseDown: (groupIndex: number, questionIndex: number) => void,
  handleMouseEnter: (groupIndex: number, questionndex: number) => void,
  handleMouseUp: () => void,
}

export default function Question({ groupIndex,
  questionIndex,
  questionCount,
  checkedQuestionCount,
  editQuestionGroup,
  editQuestionNumber,
  editQuestionText,
  setEditQuestionText,
  q,
  startingNumber,
  editActive,
  highlightDefinitions,
  handleEditingBlur,
  handleEditingQuestion,
  handleOnKeyDown,
  handleMouseDown,
  handleMouseEnter,
  handleMouseUp
}: Props) {
  return (
    <div
      key={`question-wrapper-${questionCount++}`}
      className={`${q.isChecked ? 'selected' : ''} question-item`}
      onMouseDown={() => handleMouseDown(groupIndex, questionIndex)}
      onMouseEnter={() => handleMouseEnter(groupIndex, questionIndex)}
      onMouseUp={handleMouseUp}
    >

      <div
        key={`question-check-wrapper-${questionCount}`}
        className="question-item-check-wrapper"
      >
        <Form.Check
          id={`question-check-${questionCount}`}
          key={`question-check-${questionCount}`}
          type={'checkbox'}
          label=""
          checked={q.isChecked}
        />
      </div>
      <div key={`question-item-number-${questionCount}`} className="question-item-number">{q.isChecked && `${startingNumber + checkedQuestionCount - 1}.`}</div>
      <div
        key={`question-content-${questionCount}`}
        className={`question-item-content${editActive ? ' nohov' : ''}`}
      >
        {(editQuestionGroup === groupIndex && editQuestionNumber === questionIndex) ? (
          <div className="edit-input-container">
            <Form.Control
              id={`edit-${questionCount}`}
              as="textarea"
              autoFocus
              value={editQuestionText}
              style={{ height: '100px' }}
              onChange={e => setEditQuestionText(e.target.value)}
              onBlur={handleEditingBlur}
              onKeyDown={handleOnKeyDown}
              required
            />
          </div>
        ) : (
          <div key={`question-text-${questionCount}`} className="question-text">{highlightDefinitions(q.displayText ?? q.text ?? '', `question-highlight-${questionCount}`)}</div>
        )}
        {!(editQuestionGroup === groupIndex && editQuestionNumber === questionIndex) ?
          <PencilFill key={`edit-pencil-${questionCount}`} className="edit-selection-item" onClick={() => handleEditingQuestion(q.displayText ?? q.text ?? '', groupIndex, questionIndex)} /> : null}
      </div>
    </div>

  )
}
