/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SubscriptionPlan,
    SubscriptionPlanFromJSON,
    SubscriptionPlanFromJSONTyped,
    SubscriptionPlanToJSON,
    SubscriptionStatus,
    SubscriptionStatusFromJSON,
    SubscriptionStatusFromJSONTyped,
    SubscriptionStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface FirmBillingInfo
 */
export interface FirmBillingInfo {
    /**
     * 
     * @type {string}
     * @memberof FirmBillingInfo
     */
    customerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FirmBillingInfo
     */
    subscriptionId?: string | null;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof FirmBillingInfo
     */
    subscriptionStatus?: SubscriptionStatus;
    /**
     * 
     * @type {SubscriptionPlan}
     * @memberof FirmBillingInfo
     */
    subscriptionPlan?: SubscriptionPlan;
    /**
     * 
     * @type {number}
     * @memberof FirmBillingInfo
     */
    creditsAvailable?: number;
    /**
     * 
     * @type {string}
     * @memberof FirmBillingInfo
     */
    availableCreditBlock?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FirmBillingInfo
     */
    creditsExpireDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FirmBillingInfo
     */
    trialEnds?: Date | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof FirmBillingInfo
     */
    usersByInvoicePeriod?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof FirmBillingInfo
     */
    paralegalUsersByInvoicePeriod?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {number}
     * @memberof FirmBillingInfo
     */
    attorneyPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FirmBillingInfo
     */
    paralegalPrice?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof FirmBillingInfo
     */
    subscriptionDate?: Date | null;
}

export function FirmBillingInfoFromJSON(json: any): FirmBillingInfo {
    return FirmBillingInfoFromJSONTyped(json, false);
}

export function FirmBillingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirmBillingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'subscriptionId': !exists(json, 'subscriptionId') ? undefined : json['subscriptionId'],
        'subscriptionStatus': !exists(json, 'subscriptionStatus') ? undefined : SubscriptionStatusFromJSON(json['subscriptionStatus']),
        'subscriptionPlan': !exists(json, 'subscriptionPlan') ? undefined : SubscriptionPlanFromJSON(json['subscriptionPlan']),
        'creditsAvailable': !exists(json, 'creditsAvailable') ? undefined : json['creditsAvailable'],
        'availableCreditBlock': !exists(json, 'availableCreditBlock') ? undefined : json['availableCreditBlock'],
        'creditsExpireDate': !exists(json, 'creditsExpireDate') ? undefined : (json['creditsExpireDate'] === null ? null : new Date(json['creditsExpireDate'])),
        'trialEnds': !exists(json, 'trialEnds') ? undefined : (json['trialEnds'] === null ? null : new Date(json['trialEnds'])),
        'usersByInvoicePeriod': !exists(json, 'usersByInvoicePeriod') ? undefined : json['usersByInvoicePeriod'],
        'paralegalUsersByInvoicePeriod': !exists(json, 'paralegalUsersByInvoicePeriod') ? undefined : json['paralegalUsersByInvoicePeriod'],
        'attorneyPrice': !exists(json, 'attorneyPrice') ? undefined : json['attorneyPrice'],
        'paralegalPrice': !exists(json, 'paralegalPrice') ? undefined : json['paralegalPrice'],
        'subscriptionDate': !exists(json, 'subscriptionDate') ? undefined : (json['subscriptionDate'] === null ? null : new Date(json['subscriptionDate'])),
    };
}

export function FirmBillingInfoToJSON(value?: FirmBillingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'subscriptionId': value.subscriptionId,
        'subscriptionStatus': SubscriptionStatusToJSON(value.subscriptionStatus),
        'subscriptionPlan': SubscriptionPlanToJSON(value.subscriptionPlan),
        'creditsAvailable': value.creditsAvailable,
        'availableCreditBlock': value.availableCreditBlock,
        'creditsExpireDate': value.creditsExpireDate === undefined ? undefined : (value.creditsExpireDate === null ? null : value.creditsExpireDate.toISOString()),
        'trialEnds': value.trialEnds === undefined ? undefined : (value.trialEnds === null ? null : value.trialEnds.toISOString()),
        'usersByInvoicePeriod': value.usersByInvoicePeriod,
        'paralegalUsersByInvoicePeriod': value.paralegalUsersByInvoicePeriod,
        'attorneyPrice': value.attorneyPrice,
        'paralegalPrice': value.paralegalPrice,
        'subscriptionDate': value.subscriptionDate === undefined ? undefined : (value.subscriptionDate === null ? null : value.subscriptionDate.toISOString()),
    };
}


