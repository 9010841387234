import { FirmBillingInfo, Firm, SubscriptionPlan, SubscriptionStatus, DiscountType } from 'briefpoint-client';

export interface BillingInfoClient extends FirmBillingInfo {
  trialEnds?: Date;
}

export interface FirmClient extends Firm {
  billingInfo: BillingInfoClient;
}

export function transformFirm(firm: any): FirmClient {
  return {
    ...firm,
    address: firm.address || {},
    billingInfo: {
      ...firm.billingInfo,
      trialEnds: firm.billingInfo?.trialEnds && new Date(firm.billingInfo?.trialEnds),
    },
  };
}

export interface SubscriptionPlanType {
  plan: SubscriptionPlan;
  title: string;
  price: number;
  priceString: string;
  discountedPrice?: number | null;
  discountedPriceString?: string;
  discountCode?: string | null;
  discountLength?: string | null;
  monthlyPrice?: string | null;
  discountType?: DiscountType;
  isUnlimited: boolean,
  paralegalPrice?: number,
  freeParalegals?: number
}

const SubscriptionPlans: { [key in SubscriptionPlan]: SubscriptionPlanType } = {
  [SubscriptionPlan.None]: { plan: SubscriptionPlan.None, title: 'None', priceString: 'NA', isUnlimited: true, price: 0 },
  [SubscriptionPlan.Monthly]: { plan: SubscriptionPlan.Monthly, title: 'Monthly', priceString: '$99', isUnlimited: true, price: 9900, paralegalPrice: 5900, freeParalegals: 0 },
  [SubscriptionPlan.Yearly]: { plan: SubscriptionPlan.Yearly, title: 'Yearly', priceString: '$89', isUnlimited: true, price: 106800, paralegalPrice: 58800, freeParalegals: 1 },
  [SubscriptionPlan.YearlyBundle]: { plan: SubscriptionPlan.YearlyBundle, title: 'Yearly Bundle', priceString: '$9,840', isUnlimited: true, price: 984000 },
  [SubscriptionPlan.AlaCarte]: { plan: SubscriptionPlan.AlaCarte, title: 'A la Carte', priceString: '$0', isUnlimited: false, price: 0 },
  [SubscriptionPlan.YearlyTiered]: { plan: SubscriptionPlan.YearlyTiered, title: 'Yearly Tiered', priceString: '$1000', isUnlimited: true, price: 1000 }, // Not actually being used, just fixing build
};

export { SubscriptionPlans };

export function isInTrial(firm: FirmClient, mustBeActive: boolean = false) {
  return (
    firm &&
    firm.billingInfo &&
    firm.billingInfo.subscriptionStatus === SubscriptionStatus.Trialing &&
    (!mustBeActive || (firm.billingInfo.trialEnds && firm.billingInfo.trialEnds > new Date()) || firm.billingInfo.creditsAvailable)
  );
}

export function hasActiveSubscription(firm: FirmClient) {
  return (
    firm &&
    firm.billingInfo.subscriptionStatus === SubscriptionStatus.Active &&
    firm.billingInfo.subscriptionPlan !== SubscriptionPlan.None
  );
}

export function hasCanceledSubscription(firm: FirmClient) {
  return firm && firm.billingInfo.subscriptionStatus === SubscriptionStatus.Canceled;
}

export function isInTrialOrHasActiveSubscription(firm: FirmClient) {
  return isInTrial(firm, true) || hasActiveSubscription(firm);
}
