/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserPlusFirmInfo,
    UserPlusFirmInfoFromJSON,
    UserPlusFirmInfoFromJSONTyped,
    UserPlusFirmInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserPage
 */
export interface UserPage {
    /**
     * 
     * @type {Array<UserPlusFirmInfo>}
     * @memberof UserPage
     */
    items?: Array<UserPlusFirmInfo> | null;
    /**
     * 
     * @type {number}
     * @memberof UserPage
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPage
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPage
     */
    itemsPerPage?: number;
}

export function UserPageFromJSON(json: any): UserPage {
    return UserPageFromJSONTyped(json, false);
}

export function UserPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(UserPlusFirmInfoFromJSON)),
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'itemsPerPage': !exists(json, 'itemsPerPage') ? undefined : json['itemsPerPage'],
    };
}

export function UserPageToJSON(value?: UserPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(UserPlusFirmInfoToJSON)),
        'totalPages': value.totalPages,
        'currentPage': value.currentPage,
        'itemsPerPage': value.itemsPerPage,
    };
}


