/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    Tag,
    TagFromJSON,
    TagToJSON,
    TagCreateRequest,
    TagCreateRequestFromJSON,
    TagCreateRequestToJSON,
} from '../models';

export interface TagCreateTagRequest {
    tagCreateRequest?: TagCreateRequest;
}

export interface TagFindTagsRequest {
    search?: string;
}

export interface TagGetTagsRequest {
    tagIds?: Array<number>;
}

/**
 * 
 */
export class TagApi extends runtime.BaseAPI {

    /**
     */
    async tagCreateTagRaw(requestParameters: TagCreateTagRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Tag>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Tag`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagCreateRequestToJSON(requestParameters.tagCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     */
    async tagCreateTag(requestParameters: TagCreateTagRequest, initOverrides?: RequestInit): Promise<Tag> {
        const response = await this.tagCreateTagRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tagFindTagsRaw(requestParameters: TagFindTagsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Tag>>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Tag/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagFromJSON));
    }

    /**
     */
    async tagFindTags(requestParameters: TagFindTagsRequest, initOverrides?: RequestInit): Promise<Array<Tag>> {
        const response = await this.tagFindTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async tagGetTagsRaw(requestParameters: TagGetTagsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Tag>>> {
        const queryParameters: any = {};

        if (requestParameters.tagIds) {
            queryParameters['tagIds'] = requestParameters.tagIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Tag`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagFromJSON));
    }

    /**
     */
    async tagGetTags(requestParameters: TagGetTagsRequest, initOverrides?: RequestInit): Promise<Array<Tag>> {
        const response = await this.tagGetTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
