/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateObjectionVariant,
    CreateObjectionVariantFromJSON,
    CreateObjectionVariantToJSON,
    DocumentType,
    DocumentTypeFromJSON,
    DocumentTypeToJSON,
    ExtraVariants,
    ExtraVariantsFromJSON,
    ExtraVariantsToJSON,
    FormInt,
    FormIntFromJSON,
    FormIntToJSON,
    Objection,
    ObjectionFromJSON,
    ObjectionToJSON,
    ObjectionVariant,
    ObjectionVariantFromJSON,
    ObjectionVariantToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UpdateObjectionVariant,
    UpdateObjectionVariantFromJSON,
    UpdateObjectionVariantToJSON,
} from '../models';

export interface ObjectionCreateVariantRequest {
    objectionId: number;
    createObjectionVariant?: CreateObjectionVariant;
}

export interface ObjectionDeleteVariantRequest {
    objectionId: number;
    variantId: number;
}

export interface ObjectionGetDefaultRequest {
    objectionId: number;
    documentType?: DocumentType;
}

export interface ObjectionGetListRequest {
    documentType?: DocumentType;
    includeFirm?: boolean;
}

export interface ObjectionGetMissingRequest {
    documentType?: DocumentType;
    caseId?: string;
    missingVariants?: Array<string>;
}

export interface ObjectionUpdateVariantRequest {
    objectionId: number;
    variantId: number;
    updateObjectionVariant?: UpdateObjectionVariant;
}

/**
 * 
 */
export class ObjectionApi extends runtime.BaseAPI {

    /**
     */
    async objectionCreateVariantRaw(requestParameters: ObjectionCreateVariantRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ObjectionVariant>> {
        if (requestParameters.objectionId === null || requestParameters.objectionId === undefined) {
            throw new runtime.RequiredError('objectionId','Required parameter requestParameters.objectionId was null or undefined when calling objectionCreateVariant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Objection/{objectionId}/variant`.replace(`{${"objectionId"}}`, encodeURIComponent(String(requestParameters.objectionId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateObjectionVariantToJSON(requestParameters.createObjectionVariant),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObjectionVariantFromJSON(jsonValue));
    }

    /**
     */
    async objectionCreateVariant(requestParameters: ObjectionCreateVariantRequest, initOverrides?: RequestInit): Promise<ObjectionVariant> {
        const response = await this.objectionCreateVariantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async objectionDeleteVariantRaw(requestParameters: ObjectionDeleteVariantRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.objectionId === null || requestParameters.objectionId === undefined) {
            throw new runtime.RequiredError('objectionId','Required parameter requestParameters.objectionId was null or undefined when calling objectionDeleteVariant.');
        }

        if (requestParameters.variantId === null || requestParameters.variantId === undefined) {
            throw new runtime.RequiredError('variantId','Required parameter requestParameters.variantId was null or undefined when calling objectionDeleteVariant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Objection/{objectionId}/variant/{variantId}`.replace(`{${"objectionId"}}`, encodeURIComponent(String(requestParameters.objectionId))).replace(`{${"variantId"}}`, encodeURIComponent(String(requestParameters.variantId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async objectionDeleteVariant(requestParameters: ObjectionDeleteVariantRequest, initOverrides?: RequestInit): Promise<void> {
        await this.objectionDeleteVariantRaw(requestParameters, initOverrides);
    }

    /**
     */
    async objectionGetDefaultRaw(requestParameters: ObjectionGetDefaultRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ObjectionVariant>> {
        if (requestParameters.objectionId === null || requestParameters.objectionId === undefined) {
            throw new runtime.RequiredError('objectionId','Required parameter requestParameters.objectionId was null or undefined when calling objectionGetDefault.');
        }

        const queryParameters: any = {};

        if (requestParameters.documentType !== undefined) {
            queryParameters['documentType'] = requestParameters.documentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Objection/briefpoint-default/{objectionId}`.replace(`{${"objectionId"}}`, encodeURIComponent(String(requestParameters.objectionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObjectionVariantFromJSON(jsonValue));
    }

    /**
     */
    async objectionGetDefault(requestParameters: ObjectionGetDefaultRequest, initOverrides?: RequestInit): Promise<ObjectionVariant> {
        const response = await this.objectionGetDefaultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async objectionGetFormIntsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<FormInt>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Objection/known-form-ints`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FormIntFromJSON));
    }

    /**
     */
    async objectionGetFormInts(initOverrides?: RequestInit): Promise<Array<FormInt>> {
        const response = await this.objectionGetFormIntsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async objectionGetListRaw(requestParameters: ObjectionGetListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Objection>>> {
        const queryParameters: any = {};

        if (requestParameters.documentType !== undefined) {
            queryParameters['documentType'] = requestParameters.documentType;
        }

        if (requestParameters.includeFirm !== undefined) {
            queryParameters['includeFirm'] = requestParameters.includeFirm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Objection`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ObjectionFromJSON));
    }

    /**
     */
    async objectionGetList(requestParameters: ObjectionGetListRequest, initOverrides?: RequestInit): Promise<Array<Objection>> {
        const response = await this.objectionGetListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async objectionGetMissingRaw(requestParameters: ObjectionGetMissingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExtraVariants>> {
        const queryParameters: any = {};

        if (requestParameters.documentType !== undefined) {
            queryParameters['documentType'] = requestParameters.documentType;
        }

        if (requestParameters.caseId !== undefined) {
            queryParameters['caseId'] = requestParameters.caseId;
        }

        if (requestParameters.missingVariants) {
            queryParameters['missingVariants'] = requestParameters.missingVariants;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Objection/missing`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtraVariantsFromJSON(jsonValue));
    }

    /**
     */
    async objectionGetMissing(requestParameters: ObjectionGetMissingRequest, initOverrides?: RequestInit): Promise<ExtraVariants> {
        const response = await this.objectionGetMissingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async objectionUpdateVariantRaw(requestParameters: ObjectionUpdateVariantRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.objectionId === null || requestParameters.objectionId === undefined) {
            throw new runtime.RequiredError('objectionId','Required parameter requestParameters.objectionId was null or undefined when calling objectionUpdateVariant.');
        }

        if (requestParameters.variantId === null || requestParameters.variantId === undefined) {
            throw new runtime.RequiredError('variantId','Required parameter requestParameters.variantId was null or undefined when calling objectionUpdateVariant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Objection/{objectionId}/variant/{variantId}`.replace(`{${"objectionId"}}`, encodeURIComponent(String(requestParameters.objectionId))).replace(`{${"variantId"}}`, encodeURIComponent(String(requestParameters.variantId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateObjectionVariantToJSON(requestParameters.updateObjectionVariant),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async objectionUpdateVariant(requestParameters: ObjectionUpdateVariantRequest, initOverrides?: RequestInit): Promise<void> {
        await this.objectionUpdateVariantRaw(requestParameters, initOverrides);
    }

}
