/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalPartners,
    ExternalPartnersFromJSON,
    ExternalPartnersFromJSONTyped,
    ExternalPartnersToJSON,
} from './';

/**
 * 
 * @export
 * @interface BooleanExternalPartnerResponse
 */
export interface BooleanExternalPartnerResponse {
    /**
     * 
     * @type {ExternalPartners}
     * @memberof BooleanExternalPartnerResponse
     */
    partner?: ExternalPartners;
    /**
     * 
     * @type {number}
     * @memberof BooleanExternalPartnerResponse
     */
    responseCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanExternalPartnerResponse
     */
    data?: boolean;
}

export function BooleanExternalPartnerResponseFromJSON(json: any): BooleanExternalPartnerResponse {
    return BooleanExternalPartnerResponseFromJSONTyped(json, false);
}

export function BooleanExternalPartnerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BooleanExternalPartnerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partner': !exists(json, 'partner') ? undefined : ExternalPartnersFromJSON(json['partner']),
        'responseCode': !exists(json, 'responseCode') ? undefined : json['responseCode'],
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function BooleanExternalPartnerResponseToJSON(value?: BooleanExternalPartnerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partner': ExternalPartnersToJSON(value.partner),
        'responseCode': value.responseCode,
        'data': value.data,
    };
}


