import { useAuth0 } from "@auth0/auth0-react";
import Loading from "components/Loading";
import { ReactNode } from "react";
import ValidateEmail from "screens/ValidateEmail";

type Props = {
  children: ReactNode;
}

function LoadingComponent() {
  return <Loading className="mt-3 authLoading" isLoading />;
}

function UserAuth({ children }: Props) {
  const { isAuthenticated, loginWithRedirect, isLoading, user } = useAuth0();

  // if Auth0 is loading, wait
  if (isLoading && !isAuthenticated) {
    return <LoadingComponent />;
  }

  // if not authenticated, redirect to login
  if (!isAuthenticated) {
    loginWithRedirect({ redirectUri: window.location.href });
  }

  if (user?.email_verified === false) {
    return <ValidateEmail user={user} />
  }
  return <>{children}</>;
}

export default UserAuth;