/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ObjectTypeEnum,
    ObjectTypeEnumFromJSON,
    ObjectTypeEnumFromJSONTyped,
    ObjectTypeEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface PutCommunicationItem
 */
export interface PutCommunicationItem {
    /**
     * 
     * @type {string}
     * @memberof PutCommunicationItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PutCommunicationItem
     */
    parentCommunicationId?: string | null;
    /**
     * 
     * @type {ObjectTypeEnum}
     * @memberof PutCommunicationItem
     */
    objectType?: ObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PutCommunicationItem
     */
    objectId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutCommunicationItem
     */
    communication?: string | null;
}

export function PutCommunicationItemFromJSON(json: any): PutCommunicationItem {
    return PutCommunicationItemFromJSONTyped(json, false);
}

export function PutCommunicationItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutCommunicationItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'parentCommunicationId': !exists(json, 'parentCommunicationId') ? undefined : json['parentCommunicationId'],
        'objectType': !exists(json, 'objectType') ? undefined : ObjectTypeEnumFromJSON(json['objectType']),
        'objectId': !exists(json, 'objectId') ? undefined : json['objectId'],
        'communication': !exists(json, 'communication') ? undefined : json['communication'],
    };
}

export function PutCommunicationItemToJSON(value?: PutCommunicationItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentCommunicationId': value.parentCommunicationId,
        'objectType': ObjectTypeEnumToJSON(value.objectType),
        'objectId': value.objectId,
        'communication': value.communication,
    };
}


