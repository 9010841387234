import React from "react";
import { Case, TagApi } from "briefpoint-client";

export async function loadTags(
  tagsSet: React.Dispatch<React.SetStateAction<Map<number, string> | undefined>>,
  tagsRef: React.MutableRefObject<Map<number, string>>,
  tagApi: TagApi
) {
  const tagsList = new Map<number, string>();
  const loadedTags = await tagApi.tagFindTags({});
  const partySpecificTags = [
    "Business",
    "Care Provider",
    "Debtor",
    "Driver",
    "Employer",
    "Government",
    "Insurance Company",
    "Landlord",
    "Manufacturer",
    "Owner",
    "Professional",
    "Tenant",
    "Officer",
    "Expert",
    "Witness",
    "Third Party",
    "Bank",
    "Broker",
    "Real Estate Agent",
  ];

  loadedTags.forEach((t) => {
    tagsList.set(t.id, t.name);
    if (t.ownerId || partySpecificTags.find((x) => x === t.name)) {
      tagsRef.current.set(t.id, t.name);
    }
  });
  tagsSet(tagsList);
}

export function filterTagsForParties(
  tags: Map<number, string>,
  _case: Case,
  onlyInclude?: Map<number, string>,
) {
  const ret = new Map<number, string>(tags);

  _case.tagIds?.forEach((t) => ret.delete(t));

  if (onlyInclude) {
    ret.forEach((r, key) => {
      if (!onlyInclude.has(key)) {
        ret.delete(key);
      }
    });
  }

  return ret;
}
