/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentSubType {
    Unknown = 0,
    SpecialInterrogatorries = 1,
    RequestsForProduction = 2,
    RequestsForAdmission = 3,
    Complaint = 4,
    FormInterrogatorries = 5,
    Petition = 6
}

export function DocumentSubTypeFromJSON(json: any): DocumentSubType {
    return DocumentSubTypeFromJSONTyped(json, false);
}

export function DocumentSubTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentSubType {
    return json as DocumentSubType;
}

export function DocumentSubTypeToJSON(value?: DocumentSubType | null): any {
    return value as any;
}

