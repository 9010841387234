import { ExternalPartners, Firm, User } from "briefpoint-client";
import { useCallback, useEffect } from "react";
import { useConfigContext } from "contexts/ConfigContext";
import { Dictionary } from "contexts/ConfigContext";
import { AppConfigurationClient, featureFlagPrefix, parseFeatureFlag } from "@azure/app-configuration";

const ENVIRONMENT: string = (window as any)._env_?.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT;

//TODO: Probably a better way to enumerate these
export const BRIDGE_FF = 'Bridge';
export const RICHTEXT_FF = 'RichText';
export const RIBBON_FF = 'Ribbon';
export const COMPLAINTS = 'Complaints';
export const MULTI_CLIENT_FF = 'MultiClientSupport';

type UseConfigType = [
  (partner: ExternalPartners) => ExternalPartnerConfig,
  () => Dictionary<(user?: User, firm?: Firm) => boolean>
];

export default function useConfig(): UseConfigType {

  const configCtx = useConfigContext();
  const { config, setConfig, featureFlags, setFeatureFlags } = configCtx;

  useEffect(() => {
    const loadConfig = async () => {
      // READONLY CONNECTIONSTRING ONLY!
      const client = new AppConfigurationClient('Endpoint=https://bp-public-config.azconfig.io;Id=YGfk;Secret=7+e+EOAwn5qzNxKtQMk8h/GltRwikTkPYnrNgUsqnfg=');

      const configs = client.listConfigurationSettings({ labelFilter: ENVIRONMENT })
      const configSettings: Dictionary<string> = new Dictionary<string>();
      const featureFlagSettings: Dictionary<(user?: User, firm?: Firm) => boolean> = new Dictionary<(user?: User, firm?: Firm) => boolean>();

      for await (const setting of configs) {
        const val = setting.value !== undefined ? setting : await client.getConfigurationSetting({ key: setting.key, label: ENVIRONMENT });

        if (val.key.startsWith(featureFlagPrefix) && val.value) {
          const flag = parseFeatureFlag(val);
          if (flag.value.conditions.clientFilters?.length) {
            const firmFilter = flag.value.conditions.clientFilters.find(x => x.name === 'Firm')?.parameters?.["firmId"];
            const userFilter = flag.value.conditions.clientFilters.find(x => x.name === 'User')?.parameters?.["userId"];

            if (firmFilter) {
              featureFlagSettings[flag.value.id!] = (_user?: User, firm?: Firm) => {
                if (firm && (firmFilter as string).includes(firm.id)) {
                  return true;
                }
                return false;
              };
              continue;
            }

            if (userFilter) {
              featureFlagSettings[flag.value.id!] = (user?: User, _firm?: Firm) => {
                if (user?.id && (userFilter as string).includes(user.id)) {
                  return true;
                }
                return false;
              };
              continue;
            }

            throw new Error("I only know how to filter on Users (with 1 param 'userId' containing a comma separated list of enabled User IDs) or Firms (with 1 param 'firmId' containing a comma separated list of enabled Firm IDs) at the moment.");
          } else {
            featureFlagSettings[flag.value.id!] = (_user?: User, _firm?: Firm) => flag.value.enabled;
          }

        } else if (val.value) {
          configSettings[setting.key] = val.value;
        }
      }
      setConfig(configSettings);
      setFeatureFlags(featureFlagSettings);
    }

    if (!config) {
      loadConfig();
    }

  }, [config, setConfig, featureFlags, setFeatureFlags]);

  const getPartnerConfig = useCallback((partner: ExternalPartners) => {

    const authBase = config![`ExternalPartnerConfig:${partner}:AuthBase`];
    const clientId = config![`ExternalPartnerConfig:${partner}:ClientId`];

    if (!authBase) {
      throw new Error(`No AuthBase config value found for ${partner}.`);
    }

    if (!clientId) {
      throw new Error(`No ClientId config value found for ${partner}.`);
    }

    return { partner, authBase, clientId };
  }, [config]);

  const getFeatureFlags = useCallback(() => {
    return featureFlags ?? new Dictionary<(user?: User, firm?: Firm) => boolean>();
  }, [featureFlags]);


  return [getPartnerConfig, getFeatureFlags];
}

export interface ExternalPartnerConfig {
  partner: ExternalPartners;
  authBase: string;
  clientId: string;
}
