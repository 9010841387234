/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalPartners,
    ExternalPartnersFromJSON,
    ExternalPartnersFromJSONTyped,
    ExternalPartnersToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExternalIntegration
 */
export interface ExternalIntegration {
    /**
     * 
     * @type {ExternalPartners}
     * @memberof ExternalIntegration
     */
    partner?: ExternalPartners;
    /**
     * 
     * @type {string}
     * @memberof ExternalIntegration
     */
    identifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalIntegration
     */
    location?: string | null;
}

export function ExternalIntegrationFromJSON(json: any): ExternalIntegration {
    return ExternalIntegrationFromJSONTyped(json, false);
}

export function ExternalIntegrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalIntegration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partner': !exists(json, 'partner') ? undefined : ExternalPartnersFromJSON(json['partner']),
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'location': !exists(json, 'location') ? undefined : json['location'],
    };
}

export function ExternalIntegrationToJSON(value?: ExternalIntegration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partner': ExternalPartnersToJSON(value.partner),
        'identifier': value.identifier,
        'location': value.location,
    };
}


