import React from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  id: string;
  text: string;
  direction?: string;
  icon?: React.ReactNode;
}

export default function Help({ text, icon = <QuestionCircle />, direction = 'top', id }: Props) {
  return (
    <OverlayTrigger placement={direction === 'top' ? 'top' : 'bottom-end'} overlay={<Tooltip id={id}><div dangerouslySetInnerHTML={{ __html: text}} /></Tooltip>}>
      <span className="text-muted px-2">{icon}</span>
    </OverlayTrigger>
  );
}
