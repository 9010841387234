import { Form } from "react-bootstrap";
import { CollectionItem, Case, DocumentSubType } from "briefpoint-client";
import { XLg } from "react-bootstrap-icons";
import styles from "./ReviewPage.module.scss";
import Button from "components/Button";
import { ChangeEvent, useCallback, useEffect } from "react";
import { pleadCollectionRemove, pleadCollectionAdd, pleadCollectionChange } from "./utils";
import PleadingField from "./Components/PleadingField";

function ReviewPageFourPd({ _case, allegations, selectedRequestTypes, setNumber, setAllegations, setSetNumber, setSelectedRequestTypes }: {
  _case: Case | undefined;
  allegations: Array<CollectionItem> | null;
  selectedRequestTypes: DocumentSubType[];
  setNumber: number;
  setSetNumber: React.Dispatch<React.SetStateAction<number>>;
  setAllegations: React.Dispatch<React.SetStateAction<Array<CollectionItem> | null>>;
  setSelectedRequestTypes: React.Dispatch<React.SetStateAction<DocumentSubType[]>>;
}) {

  // Replace the default extracted values from the document if existing case details are available
  const setExistingCaseDetails = useCallback(async () => {
    if (!_case) return;

    const { allegations } = _case;

    if (allegations && allegations.length > 0) setAllegations(allegations);

  }, [_case, setAllegations]);

  const handleRequestTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    if (checked) {
      setSelectedRequestTypes([...selectedRequestTypes, Number(value)]);
    } else {
      setSelectedRequestTypes(selectedRequestTypes.filter((item) => item !== Number(value)));
    }

  }

  useEffect(() => {
    setExistingCaseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className={styles.pendingSection}>
        <div className={styles.pendingSectionHeader}>
          <p>Allegations</p>
        </div>
        <div className={styles.pendingSectionFields}>
          {allegations !== null && allegations.length > 0
            ? (
              <>
                {allegations.map((item, index) => (
                  <div className={styles.allegationField}>
                    <Form.Group
                      key={`allegation-${item.number}`}
                      controlId={`formText${item.number}`}
                    >
                      <PleadingField label={`Allegation ${item.number}`} isTextArea pleadingCollectionIndex={index} pleadingCollection={allegations} setCollection={setAllegations} mappedFieldHandler={pleadCollectionChange} pleadingValue={item.text} />
                      <button
                        className={styles.pendingRemove}
                        onClick={() =>
                          pleadCollectionRemove(
                            index,
                            allegations,
                            setAllegations,
                          )}
                      >
                        <XLg />
                      </button>
                    </Form.Group>
                  </div>
                ))}
                <Button
                  variant="outline-secondary"
                  onClick={() =>
                    pleadCollectionAdd(allegations, setAllegations)}
                >
                  + Add Another
                </Button>
              </>
            )
            : (
              <>
                <p>No items available</p>
                <Button
                  variant="primary"
                  onClick={() =>
                    pleadCollectionAdd(allegations, setAllegations)}
                >
                  Add
                </Button>
              </>
            )}
          <div className={styles.pendingSectionHeader}>
            <p>Request types to draft</p>
          </div>
          <div className={styles.pendingSectionFields}>
            <Form.Group>
              <Form.Check
                id='rfa-check'
                type="checkbox"
                label="Requests for Admission"
                value={DocumentSubType.RequestsForAdmission}
                onChange={handleRequestTypeChange}
              />
              <Form.Check
                id='int-check'
                type="checkbox"
                label="Interrogatories"
                value={DocumentSubType.SpecialInterrogatorries}
                onChange={handleRequestTypeChange}
              />
              <Form.Check
                id='rfp-check'
                type="checkbox"
                label="Requests for Production"
                value={DocumentSubType.RequestsForProduction}
                onChange={handleRequestTypeChange}
              />
              <label htmlFor="set-number">Set Number</label>
              <Form.Control
                id='set-number'
                type="number"
                min="1"
                style={{ width: 80 }}
                className="mb-3"
                value={setNumber}
                size="sm"
                onChange={(event) => setSetNumber(parseInt(event.currentTarget.value))}
              />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewPageFourPd;
