/* tslint:disable */
/* eslint-disable */
/**
 * The Thing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    providerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    provider?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    email?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Account
     */
    createdOn?: Date;
}

export function AccountFromJSON(json: any): Account {
    return AccountFromJSONTyped(json, false);
}

export function AccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Account {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'providerId': !exists(json, 'providerId') ? undefined : json['providerId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'createdOn': !exists(json, 'createdOn') ? undefined : (new Date(json['createdOn'])),
    };
}

export function AccountToJSON(value?: Account | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'providerId': value.providerId,
        'userId': value.userId,
        'provider': value.provider,
        'email': value.email,
        'createdOn': value.createdOn === undefined ? undefined : (value.createdOn.toISOString()),
    };
}


