import Loading from 'components/Loading';
import useObjections from 'hooks/useObjections';
import { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { InfoCircle } from 'react-bootstrap-icons';
import Select from 'components/Select';
import { ObjectionType, ObjectionVariant, Role } from 'briefpoint-client';
import { FormModal } from 'components/Modals/Modal';
import { useAuth } from 'hooks/useAuth';
import { VariantOption } from 'components/Select/Select';
import { isDeletable, userCanEditVariant } from 'utils/objectionUtils';
import SelectCreate, { TagOption } from 'components/Select/TagsSelectCreate';
import { useTagApi } from 'hooks/useApi';
import ReactSelect, { OptionsType } from 'react-select';
import { caseTypeDisplay } from './QuestionsTable';
import { CaliforniaId } from 'components/CaseManagement/AddEditCaseModal';
import CustomEditor from 'components/Editor/CustomEditor';
import useConfig, { RICHTEXT_FF } from 'hooks/useConfig';

export default function AddEditObjection() {
  const [objections, updateVariant, createVariant, getDefaultObjection, deleteVariant] = useObjections();
  const location = useLocation();
  const initialType = new URLSearchParams(location.search).get('type');
  const isCopying = new URLSearchParams(location.search).get('copy');
  const { user, defaultJurisdiction } = useAuth()!;
  const [type, setType] = useState<number>();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [additionalContent, setAdditionalContent] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [toDefault, setToDefault] = useState(false);
  const [isSharedWithFirm, setIsSharedWithFirm] = useState<boolean | undefined>();
  const [objectionName, setObjectionName] = useState('');
  const [objectionType, setObjectionType] = useState<ObjectionType>(ObjectionType.Standard);
  const [error, setError] = useState('');
  const [showAlert, setShowAlert] = useState(true);
  const [deletingVariant, setDeletingVariant] = useState(false);
  const history = useHistory();
  const objectionVariant = location.state as ObjectionVariant;
  const [documentSubType, setDocumentSubType] = useState<number | undefined>(objectionVariant?.documentSubType);
  const isOwner = !objectionVariant || objectionVariant.ownerId === user?.id;
  const isChangingToFirm = !objectionVariant?.sharedWithFirmId && isSharedWithFirm;
  const allVariants = objections?.flatMap(x => x.variants);
  const [addingTags, setAddingTags] = useState(new Map<number, string>());
  const [availableTags, setAvailableTags] = useState<Map<number, string> | undefined>();
  const tagApi = useTagApi();
  const [, featureFlags] = useConfig();

  const useRichText = featureFlags()[RICHTEXT_FF] ? featureFlags()[RICHTEXT_FF](user, undefined) : false;
  const [caseType, setCaseType] = useState(objectionVariant?.caseTypeId);

  let subTypes: { [key: number]: string } = {
    1: 'Interrogatories',
    2: 'Requests for Production',
    3: 'Requests for Admission',
  };

  if (CaliforniaId === defaultJurisdiction?.id) {
    subTypes = {
      1: 'Special Interrogatories',
      2: 'Requests for Production',
      3: 'Requests for Admission',
      5: 'Form Interrogatories'
    };

    if (objectionType === ObjectionType.Response) {
      subTypes = {
        1: 'Special Interrogatories',
        2: 'Requests for Production',
        3: 'Requests for Admission'
      };
    }
  }

  const subtypelist = Object.keys(subTypes)
    .map(key => ({
      label: subTypes[Number(key)],
      value: parseInt(key),
      key: ''
    }));

  useEffect(() => {
    async function loadTags() {
      const tagsList = new Map<number, string>();
      const loadedTags = await tagApi.tagFindTags({});

      loadedTags.forEach(t => tagsList.set(t.id, t.name));
      setAvailableTags(tagsList);
    };
    loadTags();
  }, [tagApi]);

  useEffect(() => {
    if (objectionVariant && objectionVariant.tagIds) {
      const tagIdsSet = new Set(objectionVariant.tagIds);
      const objectionVariantTags = new Map(
        Array.from(availableTags?.entries() ?? []).filter(([key, _]) => tagIdsSet.has(key))
      );
      setAddingTags(objectionVariantTags);
    }
  }, [availableTags, objectionVariant, objectionVariant?.tagIds]);

  useEffect(() => {
    if (objectionVariant) {
      setType(objectionVariant.objectionId);
      setTitle(objectionVariant.name);
      setContent(objectionVariant.content);
      if (objectionVariant.additionalContent) {
        setAdditionalContent(objectionVariant.additionalContent);
      }
      if (!isCopying) {
        setIsDefault(objectionVariant.isDefault);
        setIsSharedWithFirm(!!objectionVariant.sharedWithFirmId ?? false)
        setToDefault(objectionVariant.isDefault);
      }
    }
    else if (initialType) {
      setType(parseInt(initialType));
    }
  }, [initialType, objectionVariant, isCopying]);

  useEffect(() => {
    if (objections && objectionVariant) {
      const objection = objections.find((objection) => objection.id === objectionVariant.objectionId);
      if (objection) {
        setObjectionName(objection.name);
        setObjectionType(objection.objectionType);
      }
    }
  }, [objectionVariant, objections]);

  useEffect(() => {
    if (objections && type) {
      const objection = objections.find((objection) => objection.id === type);
      if (objection) {
        setObjectionName(objection.name);
        setObjectionType(objection.objectionType);
        if (initialType) {
          setContent(objection.variants?.find(v => v.isDefault)?.content ?? '');
        }
      }
    }
  }, [objections, type, initialType]);

  const isResponseish = objectionType === ObjectionType.Response || objectionType === ObjectionType.NoResponse;

  function handleChangeContent(val: string) {
    setContent(val);
  }

  function handleChangeAdditionalContent(value: string) {
    setAdditionalContent(value);
  }

  function handleChangeTitle(event: React.ChangeEvent<HTMLInputElement>) {
    setTitle(event.target.value);
  }

  const transformedOptions = useMemo(() => {
    const opts: any[] = []

    opts.push({ value: null, label: 'All' });
    defaultJurisdiction?.caseClasses?.forEach(c => {
      opts.push({ value: c.id, label: c.name });
      c.subTypes?.forEach(s => {
        opts.push({ value: s.id, label: `└${s.shortName}` });
      });
    });

    return opts;
  }, [defaultJurisdiction]);

  async function onSave() {
    let hasError = false;

    if (!title.trim()) {
      setError('Please provide a Title.');
      hasError = true;
    }

    if (!content.trim() && !additionalContent.trim()) {
      setError('Please provide some Content.');
      hasError = true;
    }

    if (!type) {
      setError('Please select an Objection Type.');
      hasError = true;
    }
    if (!hasError && type) {

      //add new tags if neded
      const newTags = Array.from(addingTags.entries()).filter(tg => tg[0] < 0);
      const addedTags: Map<number, string> = new Map<number, string>();
      await Promise.all(newTags.map(async (nTag) => {
        const newTag = await tagApi.tagCreateTag({ tagCreateRequest: { tagName: nTag[1] } });
        addedTags.set(newTag.id, newTag.name);
      }));

      const tagIdsToSet = [...Array.from(addedTags.keys()), ...Array.from(addingTags.keys()).filter(tg => tg >= 0)]

      if (objectionVariant === undefined || isCopying || (isSharedWithFirm && !user?.role?.includes(Role.FirmAdmin))) {
        //Add
        await createVariant(type, title, content, toDefault, additionalContent, isOwner && isSharedWithFirm ? user?.firmId! : undefined, false, caseType || null, tagIdsToSet, documentSubType);
      } else {
        //Edit
        await updateVariant(objectionVariant.objectionId, objectionVariant.id, title, content, toDefault, additionalContent, isSharedWithFirm ? user?.firmId! : undefined, false, caseType || null, tagIdsToSet, documentSubType);
      }
      history.goBack();
    }
  }

  async function onDelete() {
    if (objectionVariant !== null) {
      await deleteVariant(objectionVariant.objectionId, objectionVariant.id);
      history.goBack();
    }
  }

  async function onInsert() {
    if (!type) return;
    const response = await getDefaultObjection(type);
    if (response) {
      if (response.content) {
        setContent(response.content);
      }

      if (response.additionalContent) {
        setAdditionalContent(response.additionalContent);
      }
    }
  }

  function EditObjectionLink() {
    if (isResponseish) {
      return (
        <>
          <Link to="/library?tab=responses">Responses</Link> {` > Edit`}
        </>
      );
    }
    return (
      <>
        <Link to="/library?tab=objections">All Objections</Link> {` > Edit`}
      </>
    );
  }

  const options: VariantOption[] = useMemo(
    () =>
      objections != null
        ? objections.flatMap((v) => [
          {
            value: v.id!,
            label: v.name,
            key: ''
          },
        ])
        : [],
    [objections]
  );

  function handleChangeCaseType(value?: string) {
    setCaseType(value ?? '');
  }

  function handleChangeTags(value: OptionsType<TagOption>) {
    const updatedTags = new Map<number, string>();
    value.forEach(option => {
      if (option.value !== null) {
        updatedTags.set(option.value, option.label);
      }
    });
    setAddingTags(updatedTags);
  }

  return (
    <>
      <Container className="page-container library-container">
        <div className="mb-4 bread-crumbs">
          <Link to={'/library'}>Library</Link> {'> '}
          {objectionVariant === undefined ? 'Create new' : EditObjectionLink()}
        </div>
        <h2>
          {objectionVariant === undefined ? 'Add' : (isCopying ? 'Copy' : 'Edit')} {isDefault ? 'default' : ''} {isResponseish ? 'response' : 'objection'}
        </h2>
        <Loading isLoading={!objections}>
          {error && <Alert className='col-8' variant="danger">{error}</Alert>}
          {showAlert && isOwner && (!isChangingToFirm && isSharedWithFirm && !isCopying) && <Alert className='col-8' onClose={() => setShowAlert(false)} dismissible variant='warning' >{'You are editing a Firm Standard usable by all firm members.'}</Alert>}
          {user?.role?.includes(Role.FirmAdmin) && (
            <>
              <span className='form-label'>Add to</span>
              <Form.Group>
                <Form.Check
                  id="existing-share-with-firm-no"
                  inline
                  type="radio"
                  label="Personal Library"
                  onChange={(e) => setIsSharedWithFirm(!e.target.checked)}
                  checked={!isSharedWithFirm}
                  disabled={objectionVariant && !!objectionVariant.sharedWithFirmId && !isCopying}
                />
                <Form.Check
                  id="existing-share-with-firm-yes"
                  type="radio"
                  inline
                  label="Firm Library"
                  onChange={(e) => setIsSharedWithFirm(e.target.checked)}
                  checked={isSharedWithFirm}
                  disabled={objectionVariant && !!objectionVariant.sharedWithFirmId && !isCopying}
                />
              </Form.Group>
            </>
          )}

          {objectionVariant ? (
            <h3 className='mt-2'>{objectionName}</h3>
          ) : (
            <Form.Group className="mt-3">
              <Form.Label>Objection Type</Form.Label>
              <Select
                className="select objection-type-select"
                options={options}
                onChange={(value: number | undefined) => setType(value)}
                selected={type}
                openPos='bottom'
              />
            </Form.Group>
          )}
          <Form.Group className="title-group">
            <Form.Label>Title</Form.Label>
            <Form.Control
              value={title}
              className="font-times mb-2"
              style={{ maxWidth: 500 }}
              onChange={handleChangeTitle}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Case Type</Form.Label>
            <ReactSelect
              onChange={(e) => handleChangeCaseType(e?.value ?? undefined)}
              id={`type-select`}
              className='select objection-type-select'
              options={transformedOptions}
              value={{ value: caseType, label: caseTypeDisplay(caseType, defaultJurisdiction, true) }} />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Document Type (Optional)</Form.Label>
            <Select
              className="select objection-type-select"
              options={subtypelist}
              onChange={(value: number | undefined) => setDocumentSubType(value)}
              selected={documentSubType}
              openPos='bottom'
              isClearable={true}
            />
          </Form.Group>
          <div className="content-wrapper">
            <Form.Label htmlFor='content-box'>{objectionType === ObjectionType.Response ? 'Intro' : 'Content'}</Form.Label>
            <Button className="insert-button" variant="outline-primary" size='sm' onClick={onInsert} disabled={!type}>
              Insert Briefpoint Default
            </Button>
          </div>
          <CustomEditor handleChange={handleChangeContent} editorContent={content} showMenu={useRichText} />
          {objectionType === ObjectionType.Response && (
            <Form.Group>
              <Form.Label>Response</Form.Label>
              <CustomEditor handleChange={handleChangeAdditionalContent} editorContent={additionalContent} showAdvancedOptions showMenu={useRichText} />
            </Form.Group>
          )}
          <Form.Group className="mt-3">
            <SelectCreate
              className='select objection-type-select'
              id={`objection-tag`}
              label="Tags"
              availableTags={availableTags}
              addingTags={addingTags}
              setAddingTags={setAddingTags}
              selected={Array.from(addingTags.keys())}
              onChange={(value) => handleChangeTags(value)}
              menuPlacement='top'
            />
          </Form.Group>
          <Form.Check
            id="existing-use-as-default"
            className="mt-2"
            type="checkbox"
            label={`Use as ${(userCanEditVariant(objectionVariant, user!) && isSharedWithFirm ? 'Firm' : 'Personal')} Default`}
            onChange={(e) => setToDefault(e.target.checked)}
            checked={toDefault}
          />
          <p className={'placeholder-link'}><InfoCircle className="mr-2" style={{ marginRight: 10 }} /><Link to="/how-to/#using_placeholders" target={'_blank'}>Learn how to customize your content with Placeholders.</Link></p>
          <div className="d-flex mt-5">
            <Button variant="primary" onClick={onSave}>
              {!isCopying ? 'Save' : 'Copy and Save'}
            </Button>
            <Button variant="link" onClick={() => history.goBack()}>
              Cancel
            </Button>
            {objectionVariant && isDeletable(objectionVariant, allVariants, user!) && (
              <Button onClick={() => setDeletingVariant(true)} className="delete-button" variant="link">
                Delete
              </Button>
            )}
          </div>
        </Loading>
      </Container>
      <FormModal
        title={'Are you sure?'}
        cancelText="Cancel"
        confirmText="Yes, Delete"
        show={deletingVariant}
        onClose={() => setDeletingVariant(false)}
        onConfirm={onDelete}
      >
        <div>{`Are you sure you want to delete this ${isResponseish ? 'response' : 'objection'} from your library? This cannot be undone.`}</div>
      </FormModal>
    </>
  );
}
