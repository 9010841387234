import { Case, Client } from 'briefpoint-client';
import Loading from 'components/Loading';
import { Modal } from 'components/Modals';
import { useCaseApi, useClientApi } from 'hooks/useApi';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { DocumentClient } from 'services/DocumentService';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { Typeahead } from 'react-bootstrap-typeahead';

const splitRegex = /[,;]/;

interface Props {
  doc?: DocumentClient;
  onClose: () => void;
  saveCaseClient: (document: DocumentClient, _case?: Case, client?: Client) => Promise<void>;
  allCases?: Case[];
  allClients?: Client[];
}

export default function SelectCaseModal({ doc, onClose, saveCaseClient, allCases, allClients }: Props) {
  const clientApi = useClientApi();
  const caseApi = useCaseApi();
  const { user } = useAuth()!;
  const [cases, setCases] = useState<Case[] | undefined>();
  const [clients, setClients] = useState<Client[] | undefined>();
  const [_case, setCase] = useState<Case | undefined>();
  const [client, setClient] = useState<Client | undefined>();
  const [judge, setJudge] = useState(doc?.info?.judge || '');
  const [caseNumber, setCaseNumber] = useState(doc?.info?.caseNumber || '');
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    function load() {
      if (allCases && allClients && doc) {
        setJudge(doc.info?.judge || '');
        setCaseNumber(doc.info?.caseNumber || '');
        if (!doc.info?.respondingPartyName) {
          setClients(allClients);
        } else {
          const potentialClientName = doc.info?.respondingPartyName?.split(splitRegex)[0];
          const foundClient = allClients.find(c => c.name?.toLowerCase() === potentialClientName.toLowerCase());
          if (!foundClient) {
            const c = { name: potentialClientName };
            setClients([...allClients, c]);
            setClient(c);
          } else {
            setClients(allClients);
            setClient(foundClient);
          }
        }

        if (!doc.info?.plaintiffs || !doc.info?.defendants) {
          setCases(allCases);
        } else {
          const st = `${doc.info.plaintiffs.split(splitRegex)[0]} vs ${doc.info.defendants.split(splitRegex)[0]}`;
          const foundCase = allCases.find(c => c.shortTitle?.toLowerCase() === st.toLowerCase() || (doc.info?.caseNumber && c.caseNumber && doc.info?.caseNumber)?.toLowerCase().includes(c.caseNumber));
          if (!foundCase) {
            const c = { shortTitle: st };
            setCases([...allCases, c]);
            setCase(c);
          } else {
            setCases(allCases);
            setCase(foundCase);
            setClient(allClients.find(x => x.id === foundCase.clientId));
          }
        }
      }
    };
    load();
  }, [doc, allCases, allClients]);

  if (!doc) {
    return null;
  }

  function handleClientChange(e: Option[]): void {
    const val = e[0];

    if (val) {
      if ((val as any).customOption) {
        setClient({ name: (val as any).name })
      } else {
        setClient(val as Client);
      }
    } else {
      setClient(undefined);
    }
  }

  function handleCaseChange(e: Option[]): void {
    const val = e[0];

    if (val) {
      if ((val as any).customOption) {
        setCase({ shortTitle: (val as any).shortTitle });
        setCaseNumber(caseNumber);
      } else {
        const c = val as Case;
        setCase(c);
        setClient(clients?.find(x => x.id === c.clientId));
      }
    } else {
      setCase(undefined);
    }
  }

  async function handleUpdateDocument(document: DocumentClient, tempClient: Client) {
    let success = true;
    try {
      await saveCaseClient(document, document._case, tempClient);
    } catch (error) {
      success = false;
    }
    setSaving(false);
    return success;
  }

  async function handleSave() {
    if (!_case || !client) {
      return;
    }
    setSaving(true);

    let tempCase = _case!;
    let tempClient = client!;
    // Creating a new case
    if (!_case?.id) {
      // Creating a new client
      if (!client?.id) {
        if (client && doc!.info?.respondingPartyName && client.name !== doc!.info?.respondingPartyName) {
          client.aliases = doc!.info?.respondingPartyName;
        }
        const newClient = await clientApi.clientPost({ firmId: user!.firmId!, client: client });
        tempClient = newClient;
      }

      // jurisdiction and venue should never be null here because you can only get to this menu if you've confirmed the info?
      tempCase.jurisdiction = doc!.info!.jurisdiction!;
      tempCase.venue = doc!.info!.venue!;
      tempCase.caseNumber = caseNumber;
      tempCase.judge = judge;
      tempCase.title ??= tempCase.shortTitle;

      const newCase = await caseApi.casePost({ firmId: user!.firmId!, clientId: tempClient.id!, _case: tempCase });
      tempCase = newCase;
    } else {
      if ((!_case.caseNumber && caseNumber) || (!_case.judge && judge)) {
        tempCase.caseNumber ??= caseNumber;
        tempCase.judge ??= judge;
        await caseApi.casePut({ firmId: user!.firmId!, id: tempCase.id!, _case: tempCase });
      }
    }

    doc!.caseId = tempCase.id;
    doc!.clientId = tempCase.clientId;
    doc!._case = tempCase;
    if (await handleUpdateDocument(doc!, tempClient)) {
      onClose();
    }
  }

  return (
    <Modal
      show={!!doc}
      onClose={onClose}
      onConfirm={handleSave}
      showCancelButton
      showConfirmButton
      confirmText='Save'
      confirming={saving}
      preventBackgroundClick
      disableConfirmButton={!_case || !client}
    >
      <Loading isLoading={!allCases && !allClients}>
        <>
          <label htmlFor='client'>Client</label>
          <div style={{ minHeight: '40px' }}>
            {(!client?.id || !_case?.id) ? <Typeahead
              onChange={(e) => handleClientChange(e)}
              selected={(client && [client]) || []}
              clearButton
              allowNew
              isInvalid={!client}
              id="client"
              newSelectionPrefix="Create a new Client: "
              options={clients || []}
              placeholder="Find or create a Client..."
              labelKey='name' /> : <p>{client?.name}</p>}
            {client && !client.id && <p>*Will create a new Client</p>}</div>
          <label htmlFor='case'>Case</label>
          <div style={{ minHeight: '40px' }}>
            <Typeahead
              onChange={(e) => handleCaseChange(e)}
              selected={(_case && [_case]) || []}
              clearButton
              allowNew
              isInvalid={!_case}
              id="case"
              newSelectionPrefix="Create a new Case: "
              options={cases?.filter(c => !client || c.clientId === client.id) || []}
              placeholder="Find or create a Case..."
              labelKey='shortTitle' />
            {_case && !_case.id && <p>*Will create a new Case</p>}</div>
          <div>
            <Form.Group>
              <label htmlFor='case-number'>Case Number</label>
              <Form.Control
                required
                disabled={!!caseNumber && !!_case && !!_case.id}
                id='case-number'
                value={_case?.caseNumber ?? caseNumber}
                onChange={(event) => setCaseNumber(event.currentTarget.value)}
              />
            </Form.Group>
          </div>
          <div>
            <label htmlFor='judge'>Judge</label>
            <Form.Control
              value={_case?.judge ?? judge}
              disabled={!!(_case?.judge ?? judge) && !!_case && !!_case.id}
              id='judge'
              onChange={(event) => setJudge(event.currentTarget.value)} />
          </div>
        </>
      </Loading>
    </Modal>
  );
}
